import { createAsyncThunk } from '@reduxjs/toolkit';
import { omit } from 'lodash';
import { TipoProdutoUtils } from '../../shared-components/select-tipo-produto/tipoProdutoUtils';
import { etapaFollowUpAPI } from '../etapa-follow-up/etapaFollowUpAPI';
import { followUpAPI } from './followUpAPI';
import { setErrorFeedback, setSuccessFeedback } from '../feedback/feedbackSlice';

const fetchAllOperatorsAsync = createAsyncThunk('followUp/fetchAllOperators', async () => {
  const { data } = await followUpAPI.fetchAll();
  return { data };
});

const fetchFollowUpImportacaoWithPaginationAsync = createAsyncThunk(
  'followUp/fetchFollowUpImportacaoWithPagination',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await followUpAPI.fetchByFilterPagination([
        {
          name: 'servicoProduto',
          value: TipoProdutoUtils.IMPORTACAO,
        },
        ...params,
      ]);

      return { data };
    } catch (err) {
      console.log(err);
      dispatch(setErrorFeedback({ message: `Erro ao buscar follow-up: ${err}` }));

      return rejectWithValue(err);
    }
  }
);

const fetchFollowUpExportacaoWithPaginationAsync = createAsyncThunk(
  'followUp/fetchFollowUpExportacaoWithPagination',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await followUpAPI.fetchByFilterPagination([
        {
          name: 'servicoProduto',
          value: TipoProdutoUtils.EXPORTACAO,
        },
        ...params,
      ]);

      return { data };
    } catch (err) {
      console.log(err);
      dispatch(setErrorFeedback({ message: `Erro ao buscar follow-up: ${err}` }));

      return rejectWithValue(err);
    }
  }
);

const fetchAllAsync = createAsyncThunk('followUp/fetchAll', async () => {
  const { data } = await followUpAPI.fetchByFilter([
    {
      name: 'servicoProduto',
      value: TipoProdutoUtils.IMPORTACAO,
    },
  ]);

  return { data };
});

const fetchAllAsyncReduzido = createAsyncThunk('followUp/fetchAll/reduzido', async () => {
  const { data } = await followUpAPI.fetchByFilterReduzido([
    {
      name: 'servicoProduto',
      value: TipoProdutoUtils.IMPORTACAO,
    },
  ]);

  return { data };
});

const fetchPreviewAsync = createAsyncThunk('followUp/fetchPreview', async (options) => {
  const filters = [
    {
      name: 'servicoProduto',
      value: TipoProdutoUtils.IMPORTACAO,
    },
  ];

  const { noDadoEmbarqueAssociated } = options;

  if (noDadoEmbarqueAssociated) {
    filters.push({ name: 'hasDadosEmbarque', value: false });
  }

  const { data } = await followUpAPI.fetchPreviewByFilter(filters);

  return { data };
});

const fetchByIdAsync = createAsyncThunk('followUp/fetchById', async (data) => {
  const response = await followUpAPI.fetchById(data);
  return {
    response: {
      status: response.status,
      data: response.data,
    },
  };
});

const activateByIdAsync = createAsyncThunk('followUp/activateById', async (id) => {
  const { status, data } = await followUpAPI.activateById(id);
  return { response: { status, data } };
});

const inactivateByIdAsync = createAsyncThunk('followUp/inactivateById', async (payload, thunkAPI) => {
  try {
    const { status, data } = await followUpAPI.inactivateById(payload);

    if (status === 200) {
      thunkAPI.dispatch(setSuccessFeedback({ message: 'Follow-up inativado com sucesso' }));
    }

    return { response: { status, data } };
  } catch (err) {
    if (err?.response?.data?.message) {
      thunkAPI.dispatch(setErrorFeedback({ message: `Erro ao inativar follow-up: ${err?.response?.data?.message}` }));
    } else {
      thunkAPI.dispatch(setErrorFeedback({ message: `Erro ao inativar follow-up` }));
    }

    return { response: { status: 500, data: err?.message } };
  }
});

const duplicarByIdAsync = createAsyncThunk('followUp/duplicarById', async (data, { rejectWithValue }) => {
  try {
    const response = await followUpAPI.duplicarById(data?.id, [], data?.payload);

    return {
      response: {
        status: response?.status,
        data: response?.data,
        ...omit(response, ['request', 'config']),
      },
    };
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

const reactivateEtapaByIdAsync = createAsyncThunk('followUp/reactivateEtapaById', async (id) => {
  const { status, data } = await etapaFollowUpAPI.activateById(id);
  return { response: { status, data } };
});

const inactivateEtapaByIdAsync = createAsyncThunk('followUp/inactivateEtapaById', async (id) => {
  const { status, data } = await etapaFollowUpAPI.inactivateById(id);
  return { response: { status, data } };
});

const clearStatusAsync = createAsyncThunk('followUp/clearStatus', async ({ id }, { rejectWithValue, dispatch }) => {
  try {
    const { status, data } = await followUpAPI.clearStatus(id);

    if (status === 200) {
      dispatch(setSuccessFeedback({ message: 'Status limpo com sucesso' }));
    }

    return { response: { status, data } };
  } catch (err) {
    dispatch(setErrorFeedback({ message: `Erro ao limpar status` }));
    return rejectWithValue(err);
  }
});

const fetchByNumeroAsync = createAsyncThunk('followUp/fetchByNumero', async (numero) => {
  const response = await followUpAPI.fetchByNumero(numero);
  return {
    response: {
      status: response.status,
      data: response.data,
    },
  };
});

const followUpThunks = {
  fetchAllAsync,
  fetchAllOperatorsAsync,
  fetchByIdAsync,
  activateByIdAsync,
  inactivateByIdAsync,
  duplicarByIdAsync,
  reactivateEtapaByIdAsync,
  inactivateEtapaByIdAsync,
  fetchPreviewAsync,
  clearStatusAsync,
  fetchFollowUpImportacaoWithPaginationAsync,
  fetchFollowUpExportacaoWithPaginationAsync,
  fetchByNumeroAsync,
  fetchAllAsyncReduzido,
};

export {
  followUpThunks,
  fetchAllAsync,
  fetchAllOperatorsAsync,
  fetchByIdAsync,
  activateByIdAsync,
  inactivateByIdAsync,
  duplicarByIdAsync,
  fetchPreviewAsync,
  reactivateEtapaByIdAsync,
  inactivateEtapaByIdAsync,
  clearStatusAsync,
  fetchFollowUpImportacaoWithPaginationAsync,
  fetchFollowUpExportacaoWithPaginationAsync,
  fetchByNumeroAsync,
  fetchAllAsyncReduzido,
};
