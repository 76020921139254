import { Chip, IconButton, useTheme } from '@material-ui/core';
import { Delete as DeleteIcon, Search as PageviewIcon } from '@material-ui/icons';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { userHasDeleteRole } from '../../../../../components/perfil/perfil-utils';
import DespesaReceitaContext from '../../../../../contexts/registrations/despesa-receita/DespesaReceitaContext';
import {
  activateByIdAsync,
  fetchAllAsync,
  inactivateByIdAsync,
  resetMode,
  resetModel,
  selectDespesasReceitas,
} from '../../../../../features/despesa-receita/despesaReceitaSlice';
import { getTipoModeloLabelByValue } from '../../../../../shared-components/select-tipo-modelo/QCXFinalSelectTipoModeloAutocomplete';
import QCXSimpleConsultPageTemplate from '../../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import useOperationController from '../../../../../utils/hooks/store/operation/useOperationController';
import { isAlternativeLoadingStatus } from '../../../../../utils/store/store-utils';

export default function DespesaReceitaConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const theme = useTheme();
  const history = useHistory();

  const { status } = useContext(DespesaReceitaContext);

  const dispatch = useDispatch();

  const despesasReceitas = useSelector(selectDespesasReceitas);

  const isAlternativeLoading = useMemo(() => isAlternativeLoadingStatus(status), [status]);

  const fetchAll = useCallback(() => {
    dispatch(fetchAllAsync());
  }, []);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
  }, []);

  const handleActivateInactivate = useOperationController(async (event, id) => {
    event.preventDefault();

    const { active } = despesasReceitas.find((despesaReceita) => despesaReceita.id === id);

    const isActivation = !active;

    if (isActivation) {
      dispatch(activateByIdAsync(id));
    } else {
      dispatch(inactivateByIdAsync(id));
    }
  });

  const handleClickOnEditButton = useCallback(
    (event, id) => {
      event.stopPropagation();
      history.push(t('com.muralis.qcx.url.modulosFinanceirosDespesaReceitaDetalhes', { id }));
    },
    [history]
  );

  const columns = [
    {
      field: 'id',
      headerName: t('com.muralis.qcx.codigo'),
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 90,
      valueGetter: ({ row }) => row?.id,
    },
    {
      field: 'description',
      headerName: t('com.muralis.qcx.descricao'),
      type: 'string',
      flex: 225,
      valueGetter: ({ row }) => row?.description,
    },
    {
      field: 'tipo',
      headerName: t('com.muralis.qcx.tipo'),
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 80,
      valueGetter: ({ row }) => getTipoModeloLabelByValue(row?.tipo),
      renderCell: ({ value }) => {
        const isReceita = value === t('com.muralis.qcx.receita') || value === t('com.muralis.qcx.credito');
        return (
          <Chip
            size="small"
            label={value}
            style={{
              backgroundColor: isReceita ? theme.palette.success.main : theme.palette.error.dark,
              color: isReceita ? theme.palette.success.contrastText : theme.palette.error.contrastText,
            }}
          />
        );
      },
    },
    {
      field: 'operacional',
      headerName: t('com.muralis.qcx.operacional'),
      headerAlign: 'center',
      align: 'center',
      type: 'boolean',
      flex: 85,
    },
    {
      field: 'aduaneira',
      headerName: t('com.muralis.qcx.aduaneira'),
      headerAlign: 'center',
      align: 'center',
      type: 'boolean',
      flex: 80,
    },
    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 80,
      renderCell: ({ row: { id, active } }) => (
        <>
          {userHasDeleteRole(authInfo, 'despesa-receita') && (
            <IconButton
              key={`stw-active${id}`}
              value={active}
              name={`stw-active-${id}`}
              onClick={(event) => handleActivateInactivate(event, id)}
            >
              <DeleteIcon color="error" />
            </IconButton>
          )}
          <IconButton
            key={`btn-view-${id}`}
            name={`btn-view-${id}`}
            onClick={(event) => handleClickOnEditButton(event, id)}
          >
            <PageviewIcon color="primary" />
          </IconButton>
        </>
      ),
    },
  ];

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloCadastrosFinanceiros'),
        name: t('com.muralis.qcx.financeiro.labelPlural'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.despesa.despesasReceitas'),
      },
    },
  ];

  const templateProps = {
    page: {
      title: t('com.muralis.qcx.despesa.despesasReceitas'),
      breadcrumbs,
    },
    control: {
      buttonNew: {
        description: t('com.muralis.qcx.despesa.novaDespesaReceita'),
        path: t('com.muralis.qcx.url.modulosFinanceirosDespesaReceitaNova'),
      },
    },
    table: {
      columns,
    },
  };

  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={despesasReceitas}
      isLoading={isAlternativeLoading}
      buttonNewText={t('com.muralis.qcx.acoes.nova').toUpperCase()}
      multipleFilter
      authInfo={authInfo}
      requiredRoles={['despesa-receita']}
    />
  );
}
