import { createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { isFunction } from 'lodash';
import { MediaType } from '../../utils/api/api-utils';
import { setErrorFeedback } from '../feedback/feedbackSlice';
import { faturamentoAPI } from './faturamentoAPI';

const fetchAllAsync = createAsyncThunk('faturamento/fetchAll', async () => {
  const { data } = await faturamentoAPI.fetchAll();
  return { data };
});

const fetchAllReduzidoAsync = createAsyncThunk('faturamento/fetchAllReduzido', async () => {
  const { data } = await faturamentoAPI.fetchAllReduzido();
  return { data };
});

const fetchByIdAsync = createAsyncThunk('faturamento/fetchById', async (data) => {
  const response = await faturamentoAPI.fetchById(data);
  return {
    response: {
      status: response.status,
      data: response.data,
    },
  };
});

const generateReportExtratoContaCorrenteAsync = createAsyncThunk(
  'faturamento/generateReportExtratoContaCorrente',
  async (payload) => {
    const { handlers, data } = payload;

    try {
      const response = await faturamentoAPI.generateReportExtratoContaCorrente(data);

      if (response?.status === 204) {
        const onNoContent = handlers?.onNoContent;
        if (isFunction(onNoContent)) {
          onNoContent();
          return;
        }
      }

      const xmlData = new Blob([response?.data], { type: MediaType.APPLICATION_PDF });

      const url = window.URL.createObjectURL(xmlData);
      window.open(url);
    } catch (e) {
      const onError = handlers?.onError;

      if (isFunction(onError)) {
        onError();
      }
    }
  }
);

const consultarNFSeByFaturamentoIdAsync = createAsyncThunk(
  'faturamento/consultarNFSeByFaturamentoId',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await faturamentoAPI.consultarNFSeByFaturamentoId(id);

      return {
        faturamentoId: id,
        response: {
          status: response.status,
          data: response.data,
        },
      };
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error?.message || '';

      const message = i18next.t('com.muralis.qcx.erro.erroConsultarNFSe', {
        erro: `${errorMessage}.`,
      });

      dispatch(setErrorFeedback({ message }));

      return rejectWithValue(error.response.data);
    }
  }
);

const buscarNovosLancamentosAsync = createAsyncThunk(
  'faturamento/buscarNovosLancamentos',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { id, capaId } = payload;

      const response = await faturamentoAPI.buscarNovosLancamentos(id, capaId);

      return {
        response: {
          status: response.status,
          data: response.data,
        },
      };
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error?.message || '';
      const message = `Erro ao buscar despesas: ${errorMessage}`;

      dispatch(setErrorFeedback({ message }));

      return rejectWithValue(error.response.data);
    }
  }
);

const removeDespesaAsync = createAsyncThunk('faturamento/removeDespesa', async (id, { rejectWithValue, dispatch }) => {
  try {
    const response = await faturamentoAPI.removeDespesa(id);

    return {
      response: {
        despesaId: id,
        status: response.status,
        data: response.data,
      },
    };
  } catch (error) {
    const errorMessage = error?.response?.data?.message || error?.message || '';
    const message = `Erro ao remover despesa: ${errorMessage}`;

    dispatch(setErrorFeedback({ message }));

    return rejectWithValue(error.response.data);
  }
});

const faturamentoThunks = {
  fetchAllAsync,
  fetchByIdAsync,
  generateReportExtratoContaCorrenteAsync,
  consultarNFSeByFaturamentoIdAsync,
  fetchAllReduzidoAsync,
  buscarNovosLancamentosAsync,
  removeDespesaAsync,
};

export {
  faturamentoThunks,
  fetchAllAsync,
  fetchByIdAsync,
  generateReportExtratoContaCorrenteAsync,
  consultarNFSeByFaturamentoIdAsync,
  fetchAllReduzidoAsync,
  buscarNovosLancamentosAsync,
  removeDespesaAsync,
};
