import {
    Button, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, Typography, makeStyles
} from "@material-ui/core";
import { useTranslation } from "react-i18next";


export interface DeclaracaoImportacaoDiagnosticoProps {
    isModalOpen: boolean;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    title: string;
    buttonTitle: string;
    children?: React.ReactNode;
    onConfirm: () => void
    disabled: boolean;
    isDuimp: boolean;
}

const useStyles = makeStyles({
    paper: {
        maxWidth: '80%',
        width: 800,
        '@media (max-width: 900px)': {
            maxWidth: '100%',
            width: '100%',
        },
    },
    title: {
        borderBottom: '2px solid #000000',
    },
});

const TransmissaoModal = ({ isModalOpen, setIsModalOpen, title, buttonTitle, children, onConfirm, disabled, isDuimp = false }: DeclaracaoImportacaoDiagnosticoProps) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const fechar = t('com.muralis.qcx.acoes.fechar');

    return (
        <Dialog
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{ paper: classes.paper }}
        >

            <DialogTitle id="alert-dialog-title">
                <Typography
                    color="inherit"
                    variant="h6"
                    align="center"
                    className={classes.title}
                >
                    {title}
                </Typography>
            </DialogTitle>

            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                { !isDuimp && (
                        <Button variant="contained"
                            onClick={onConfirm}
                            title={buttonTitle}
                            color="primary"
                            disabled={disabled}>
                            {buttonTitle}
                        </Button>
                    )
                }

                <Button variant="contained" onClick={() => setIsModalOpen(false)} color="secondary">
                    {fechar}
                </Button>

            </DialogActions>
        </Dialog>
    )

}
export default TransmissaoModal;
