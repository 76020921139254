import { Box, makeStyles } from '@material-ui/core';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectCliente } from '../../features/cliente/clienteSlice';
import {
  fetchAllAsync as fetchAllDespesaReceitasAsync,
  selectReceitas,
  selectDespesasReceitasOperacional,
} from '../../features/despesa-receita/despesaReceitaSlice';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXSelectServicoAutocomplete from '../../shared-components/select-servico/QCXSelectServicoAutocomplete';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';
import QCXFormTitle from '../form-title/QCXFormTitle';
import QCXClienteServicoDespesaReceitaBondManager from './bond-managers/QCXClienteServicoDespesaReceitaBondManager';
import { selectServicos } from '../../features/servico/servicoSlice';
import QCXClienteServicoDespesaReceitaGrid from './QCXClienteServicoDespesaReceitaGrid';
import QCXClienteServicoTabelaSdaBondManager from './bond-managers/QCXClienteServicoTabelaSdaBondManager';
import { fetchAllAsync as fetchAllTabelasSda, selectTabelasSda } from '../../features/tabela-sda/tabelaSdaSlice';

const useStyles = makeStyles(() => ({
  gridFields: {
    paddingBottom: '16px',
  },
}));

export default function QCXServicoVinculosForm({ isConsult, handleSubmit, controlComponentProps, ...restProps }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const classes = useStyles();

  const despesasReceitas = useSelector(selectDespesasReceitasOperacional);
  const receitas = useSelector(selectReceitas);
  const cliente = useSelector(selectCliente);

  const todosServicos = useSelector(selectServicos);
  const clienteServicos = cliente.servicos.map((s) => s.id);
  const clienteServicosSelecionados = todosServicos.filter((item) => clienteServicos.includes(item.id));

  const tabelasSda = useSelector(selectTabelasSda);

  useEffect(() => {
    dispatch(fetchAllDespesaReceitasAsync());
    dispatch(fetchAllTabelasSda());
  }, []);

  return (
    <QCXFormStepWizard onSubmit={handleSubmit} controlComponentProps={controlComponentProps} {...restProps}>
      {({ values }) => (
        <>
          <Box width="100%" mt={6}>
            <QCXFormTitle title="Serviços vinculados" boxProps={{ mb: 3 }} />

            <QCXClienteServicoDespesaReceitaGrid />

            <Box mt={3}>
              <QCXFormTitle title="Selecione o serviço" boxProps={{ mb: 3 }} />

              <QCXSelectServicoAutocomplete
                servicosList={clienteServicosSelecionados}
                id="autocomplete-select-servico-field"
                key="autocomplete-select-servico-field"
                name="servico.id"
                label={t('com.muralis.qcx.servico.labelSingular')}
                initialValues={values}
              />
            </Box>

            <Box mt={3}>
              <QCXFormSubtitle title="Vincular despesas/receitas de numerários" boxProps={{ mb: 3 }} />

              <QCXClienteServicoDespesaReceitaBondManager
                tipo="NUMERARIO"
                despesasReceitas={despesasReceitas}
                servicos={clienteServicosSelecionados}
                classes={classes}
                modes={{
                  isConsult,
                }}
              />
            </Box>

            <Box mt={3}>
              <QCXFormSubtitle title="Vincular receitas de faturamento" boxProps={{ mb: 3 }} />

              <QCXClienteServicoDespesaReceitaBondManager
                tipo="FATURAMENTO"
                despesasReceitas={receitas}
                servicos={clienteServicosSelecionados}
                classes={classes}
                modes={{
                  isConsult,
                }}
              />
            </Box>

            <Box mt={3}>
              <QCXFormSubtitle title="Vincular tabelas de sda" boxProps={{ mb: 3 }} />

              <QCXClienteServicoTabelaSdaBondManager
                tabelasSda={tabelasSda}
                servicos={clienteServicosSelecionados}
                modes={{
                  isConsult,
                }}
              />
            </Box>
          </Box>
        </>
      )}
    </QCXFormStepWizard>
  );
}
