import { MediaType, ReponseType } from '../../utils/api/api-utils';
import {
  doGetQCXRequest,
  doPostQCXRequest,
  doPutQCXInactivateRequest,
  doPutQCXRequest,
} from '../../utils/api/domain/domain-api-utils';
import {
  NUMERARIO_IMPORTACAO_ENDPOINT,
  QCX_CANCELAR_ENDPOINT,
  QCX_LISTAGEM_ENDPOINT,
  QCX_SOLICITACAO_PAGAMENTO_ENDPOINT,
  SOLICITAR_ENDPOINT,
  QCX_RECUSAR_ENDPOINT,
  QCX_APROVAR_ENDPOINT,
  QCX_APROVAR_LOTE_ENDPOINT,
  QCX_SOLICITADAS_ENDPOINT,
  QCX_RELATORIO_ENDPOINT,
  FOLLOW_UP_ENDPOINT,
  QCX_SALDO_ENDPOINT,
  QCX_TITULOS_ENDPOINT,
  QCX_ESTORNO_ENDPOINT,
  QCX_CONCILIAR_ENDPOINT,
} from '../../utils/api/domain/domain-endpoint-utils';

async function fetchAll() {
  return doGetQCXRequest([
    QCX_SOLICITACAO_PAGAMENTO_ENDPOINT
  ]);
}

async function fetchById(id) {
  return doGetQCXRequest([
    QCX_SOLICITACAO_PAGAMENTO_ENDPOINT,
    id,
  ]);
}

async function register(data, queryParams = [], config = {}) {
  return doPostQCXRequest(
    [QCX_SOLICITACAO_PAGAMENTO_ENDPOINT],
    data,
    queryParams,
    {
      ...config,
      headers: {
        'Content-Type': MediaType.MULTIPART_FORM_DATA,
      },
    }
  );
}

async function save(data, queryParams = [], config = {}) {
  return doPutQCXRequest(
    [QCX_SOLICITACAO_PAGAMENTO_ENDPOINT],
    data,
    queryParams,
    {
      ...config,
      headers: {
        'Content-Type': MediaType.MULTIPART_FORM_DATA,
      },
    }
  );
}

async function inactivateById(id) {
  return doPutQCXInactivateRequest(
    [QCX_SOLICITACAO_PAGAMENTO_ENDPOINT],
    id
  );
}

async function solicitar(id, data, queryParams = [], config = {}) {
  return doPutQCXRequest(
    [
      QCX_SOLICITACAO_PAGAMENTO_ENDPOINT,
      id,
      SOLICITAR_ENDPOINT,
    ],
    data,
    queryParams,
    config
  );
}

async function cancelarSolicitacaoById(id, config = {}) {
  return doPutQCXRequest(
    [
      QCX_SOLICITACAO_PAGAMENTO_ENDPOINT,
      id,
      QCX_CANCELAR_ENDPOINT,
    ],
    {},
    [],
    config
  );
}

async function aprovarSolicitacaoSaldoInsuficiente(config = {}) {
  return doPutQCXRequest(
    [
      QCX_SOLICITACAO_PAGAMENTO_ENDPOINT,
      QCX_APROVAR_ENDPOINT,
    ],
    config
  );
}

async function recusarSolicitacao(config = {}) {
  return doPutQCXRequest(
    [
      QCX_SOLICITACAO_PAGAMENTO_ENDPOINT,
      QCX_RECUSAR_ENDPOINT,
    ],
    config
  );
}

async function aprovarLote(lote, queryParams = []) {
  return doPutQCXRequest(
    [
      QCX_SOLICITACAO_PAGAMENTO_ENDPOINT,
      QCX_APROVAR_LOTE_ENDPOINT,
    ],
    lote,
    queryParams
  );
}

async function solicitarPagamento(id, data, queryParams = [], config = {}) {
  return doPostQCXRequest(
    [
      QCX_SOLICITACAO_PAGAMENTO_ENDPOINT,
      NUMERARIO_IMPORTACAO_ENDPOINT,
      id,
    ],
    data,
    queryParams,
    config
  );
}

async function fetchAllNonFaturadoAndLiquidado() {
  return doGetQCXRequest([
    QCX_SOLICITACAO_PAGAMENTO_ENDPOINT,
    QCX_LISTAGEM_ENDPOINT
  ]);
}

async function fetchAllSolicitadas() {
  return doGetQCXRequest([
    QCX_SOLICITACAO_PAGAMENTO_ENDPOINT,
    QCX_SOLICITADAS_ENDPOINT
  ]);
}

async function generateReportById(id) {
  return doGetQCXRequest(
    [
      QCX_SOLICITACAO_PAGAMENTO_ENDPOINT,
      QCX_RELATORIO_ENDPOINT,
      id
    ],
    [],
    {
      responseType: ReponseType.ARRAY_BUFFER,
    }
  );
}

async function fetchClientAndProcessBalances(id) {
  return doGetQCXRequest([
    QCX_SOLICITACAO_PAGAMENTO_ENDPOINT,
    FOLLOW_UP_ENDPOINT,
    id,
    QCX_SALDO_ENDPOINT,
  ]);
}

async function reverseMovimentacao(data, queryParams = [], config = {}) {
  return doPostQCXRequest(
    [
      QCX_SOLICITACAO_PAGAMENTO_ENDPOINT,
      QCX_TITULOS_ENDPOINT,
      QCX_ESTORNO_ENDPOINT
    ],
    data,
    queryParams,
    config
  );
}

async function conciliarEmLote(data) {
  return doPutQCXRequest([QCX_SOLICITACAO_PAGAMENTO_ENDPOINT, QCX_CONCILIAR_ENDPOINT], data);
}

const solicitacaoPagamentoAPI = {
  fetchAll,
  fetchById,
  register,
  save,
  inactivateById,
  solicitar,
  cancelarSolicitacaoById,
  solicitarPagamento,
  aprovarLote,
  fetchAllNonFaturadoAndLiquidado,
  fetchAllSolicitadas,
  aprovarSolicitacaoSaldoInsuficiente,
  recusarSolicitacao,
  generateReportById,
  fetchClientAndProcessBalances,
  reverseMovimentacao,
  conciliarEmLote,
};

export {
  solicitacaoPagamentoAPI,
  fetchAll,
  fetchById,
  register,
  save,
  inactivateById,
  solicitar,
  cancelarSolicitacaoById,
  solicitarPagamento,
  aprovarLote,
  fetchAllSolicitadas,
  fetchAllNonFaturadoAndLiquidado,
  aprovarSolicitacaoSaldoInsuficiente,
  recusarSolicitacao,
  generateReportById,
  fetchClientAndProcessBalances,
  reverseMovimentacao,
  conciliarEmLote,
};
