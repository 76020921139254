import React, { useEffect, useMemo } from 'react';
import { AddCircle as AddCircleIcon } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  confirmOperation,
  resetOperation,
  selectOperationActive,
  selectOperationMessage,
  selectOperationTitle,
  selectQuery,
} from '../../features/controller/controllerSlice';
import QCXConfirmDialog from '../../shared-components/dialog/QCXConfirmDialog';
import QCXMainLayout from '../../components/main-layout/QCXMainLayout';
import QCXProgressBackdrop from '../../shared-components/backdrop/QCXProgressBackdrop';
import QCXDataGridControl from '../../shared-components/data-grid-control/QCXDataGridControl';
import QCXErrorSnackbar from '../../shared-components/snackbar/QCXErrorSnackbar';
import QCXSuccessSnackbar from '../../shared-components/snackbar/QCXSuccessSnackbar';
import QCXFocusLock from '../../components/focus-lock/QCXFocusLock';
import { focusLockActions } from '../../features/focus-lock/focusLockSlice';
import useQueryParam from '../../utils/hooks/router/useQueryParam';

/**
 * @deprecated This component is deprecated and will be removed in a future release.
 * Please use `SimpleConsultPageTemplate` instead for all new development.
 * Reason: New component uses server side pagination and filtering.
 */
export default function QCXSimpleConsultPageTemplate({ ...props }) {
  const { t } = useTranslation();
  const {
    pageProps: { icon, title, breadcrumbs = [] },
    tableProps: { columns = [], defaultPageSize = 5, ...restTableProps },
    controlProps: { buttonNew, others = [] } = { buttonNew: null, others: [] },
    searchGridSize,
    buttonGridSize,
    tableData = [],
    entityPluralName,
    filterProp = 'code',
    filterPropGetter,
    isLoading = false,
    openBackdrop = false,
    successSnackbar,
    errorSnackbar,
    handleCloseSuccessSnackbar,
    handleCloseErrorSnackbar,
    multipleFilter = false,
    filterProps = [],
    buttonNewText = t('com.muralis.qcx.acoes.novo'),
    focusLockProps = {},
    children,
    alertMessage,
    authInfo = {},
    requiredRoles = [],
    showInactiveRows = false,
  } = props;

  console.log(restTableProps);

  const dispatch = useDispatch();

  const searchQueryParam = useQueryParam('q');

  const operationActive = useSelector(selectOperationActive);
  const operationTitle = useSelector(selectOperationTitle);
  const operationMessage = useSelector(selectOperationMessage);
  const query = useSelector(selectQuery);

  const defaultQuerySearch = useMemo(() => searchQueryParam || '', [searchQueryParam]);

  const handleConfirmDialog = () => {
    dispatch(confirmOperation());
  };

  const handleCloseDialog = () => {
    dispatch(resetOperation());
  };

  const createPermission = useMemo(
    () =>
      requiredRoles.every((usrRole) => {
        const role = `${usrRole}-incluir`;
        return authInfo?.roles?.includes(role);
      }),
    [authInfo, requiredRoles]
  );

  useEffect(() => {
    let unmount = false;

    const handleFocusLock = () => {
      if (!unmount) {
        dispatch(focusLockActions.lock());
      }
    };

    handleFocusLock();

    return () => {
      unmount = true;
    };
  }, []);

  return (
    <QCXMainLayout pageIcon={icon} pageTitle={title} breadcrumbs={breadcrumbs} authInfo={authInfo}>
      <QCXFocusLock {...focusLockProps}>
        {alertMessage}
        <QCXDataGridControl
          columns={columns}
          rows={tableData}
          filterProp={filterProp}
          filterPropGetter={filterPropGetter}
          pageSize={defaultPageSize}
          loading={isLoading}
          entityPluralName={entityPluralName}
          multipleFilter={multipleFilter}
          filterProps={filterProps}
          showInactiveRows={showInactiveRows}
          searchGridSize={searchGridSize}
          buttonGridSize={buttonGridSize}
          controlButtons={[
            ...(buttonNew
              ? [
                  {
                    to: buttonNew?.path,
                    description: buttonNewText,
                    startIcon: <AddCircleIcon />,
                    tooltip: {
                      description: createPermission
                        ? buttonNew?.description
                        : t('com.muralis.validacao.usuarioSemAutorizacao'),
                    },
                    onClick: buttonNew?.onClick,
                    disabled: buttonNew?.disabled || !createPermission,
                  },
                ]
              : []),
            ...others,
          ]}
          defaultQuerySearch={defaultQuerySearch}
          querySearch={query}
          {...restTableProps}
        />
      </QCXFocusLock>
      <div id="portal-elements">
        {successSnackbar?.open && (
          <QCXSuccessSnackbar
            open={successSnackbar?.open}
            content={successSnackbar?.message}
            handleClose={handleCloseSuccessSnackbar}
            {...successSnackbar}
          />
        )}
        {errorSnackbar?.open && (
          <QCXErrorSnackbar
            open={errorSnackbar?.open}
            content={errorSnackbar?.message}
            handleClose={handleCloseErrorSnackbar}
            {...errorSnackbar}
          />
        )}
        <QCXProgressBackdrop open={openBackdrop} />
        <QCXConfirmDialog
          open={operationActive}
          title={operationTitle}
          content={operationMessage}
          onClose={handleCloseDialog}
          onConfirm={handleConfirmDialog}
        />
        {children}
      </div>
    </QCXMainLayout>
  );
}
