import {
    doGetQCXRequest,
    doPostQCXRequest,
    doPutQCXActivateRequest,
    doPutQCXInactivateRequest,
    doPutQCXRequest,
  } from '../../utils/api/domain/domain-api-utils';
  import {
    GRUPO_DESPESA_RECEITA_ENDPOINT, FILTER_ENDPOINT,
  } from '../../utils/api/domain/domain-endpoint-utils';
  
  async function fetchAll() {
    return doGetQCXRequest([GRUPO_DESPESA_RECEITA_ENDPOINT.concat("/tenant")]);
  }
  
  async function fetchById(id) {
    return doGetQCXRequest([
        GRUPO_DESPESA_RECEITA_ENDPOINT,
      id,
    ]);
  }
  
  async function register(data, queryParams = []) {
    return doPostQCXRequest(
      [GRUPO_DESPESA_RECEITA_ENDPOINT],
      data,
      queryParams
    );
  }
  
  async function save(data, queryParams = []) {
    return doPutQCXRequest(
      [GRUPO_DESPESA_RECEITA_ENDPOINT],
      data,
      queryParams
    );
  }
  
  async function activateById(id) {
    return doPutQCXActivateRequest(
      [GRUPO_DESPESA_RECEITA_ENDPOINT],
      id
    );
  }
  
  async function inactivateById(id) {
    return doPutQCXInactivateRequest(
      [GRUPO_DESPESA_RECEITA_ENDPOINT],
      id
    );
  }
  
  async function fetchByFilter(queryParams = []) {
    return doGetQCXRequest(
      [
        GRUPO_DESPESA_RECEITA_ENDPOINT,
        FILTER_ENDPOINT,
      ],
      queryParams
    );
  }
  
  const despesaReceitaAPI = {
    fetchAll,
    fetchById,
    register,
    save,
    activateById,
    inactivateById,
    fetchByFilter,
  };
  
  export {
    despesaReceitaAPI,
    fetchAll,
    fetchById,
    register,
    save,
    activateById,
    inactivateById,
    fetchByFilter,
  };
  