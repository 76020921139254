import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { conciliacaoBancariaAPI } from '../../../../features/conciliacao-bancaria/conciliacaoBancariaAPI';
import { selectStatus, selectMode } from '../../../../features/conciliacao-bancaria/conciliacaoBancariaSelectors';
import {
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
} from '../../../../features/conciliacao-bancaria/conciliacaoBancariaSlice';
import QCXRegistrationFormPageTemplate from '../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';
import { normalizeNumeral } from '../../../../utils/general/general-utils';
import { isConsultMode, isCreateMode, isUpdateMode } from '../../../../utils/store/store-utils';
import QCXConciliacaoBancariaFinanceiroForm from './components/QCXConciliacaoBancariaFinanceiroForm';

export default function ConciliacaoBancariaRegistrationPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const history = useHistory();

  const { id } = useParams();

  const status = useSelector(selectStatus);

  const mode = useSelector(selectMode);

  const isCreate = useMemo(() => isCreateMode(mode), [mode]);

  const isConsult = useMemo(() => isConsultMode(mode), [mode]);

  const isUpdate = useMemo(() => isUpdateMode(mode), [mode]);

  const handleChangeToCreate = useCallback(() => {
    dispatch(changeToCreateMode());
  }, []);

  const handleChangeToConsult = () => {
    dispatch(changeToConsultMode());
  };

  const handleChangeToUpdate = () => {
    dispatch(changeToUpdateMode());
  };

  const handleCancelUpdate = useCallback(() => handleChangeToConsult());

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: t('com.muralis.qcx.inicio'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloFinanceiro'),
          name: t('com.muralis.qcx.financeiro.label'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloFinanceiroConciliacaoBancaria'),
          name: t('com.muralis.qcx.principal'),
        },
      },
      {
        text: {
          name: t('com.muralis.qcx.financeiro.conciliacaoBancaria'),
        },
      },
    ],
    []
  );

  const normalize = (data) => ({
    ...data,
    valorAReceber: normalizeNumeral(data?.valorAReceber),
  });

  const handleSubmit = async (data) => {
    const normalizedData = normalize(data);
    await conciliacaoBancariaAPI.criarConciliacaoBancaria(normalizedData);

    history.goBack();
  };

  return (
    <>
      <QCXRegistrationFormPageTemplate
        pageTitle={t('com.muralis.qcx.financeiro.conciliacaoBancaria')}
        showSubtitle={false}
        breadcrumbs={breadcrumbs}
        isCreate={isCreate}
        isUpdate={isUpdate}
        isConsult={isConsult}
        handleChangeToCreate={handleChangeToCreate}
        handleChangeToConsult={handleChangeToConsult}
        handleChangeToUpdate={handleChangeToUpdate}
        handleCancelUpdate={handleCancelUpdate}
        authInfo={authInfo}
      >
        {() => (
          <>
            <QCXConciliacaoBancariaFinanceiroForm
              handleSubmit={handleSubmit}
              isCreate={isCreate}
              isConsult={isConsult}
              isUpdate={isUpdate}
            />
          </>
        )}
      </QCXRegistrationFormPageTemplate>
    </>
  );
}
