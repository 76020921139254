import CardsGridSubmenu from '../../../common/components/cardsGrid/cardsGridSubmenu';
import BasicLayout from '../../../common/layouts/basicLayout';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { CardsGridItem } from '../../../common/components/cardsGrid/cardsGrid';
import EjectIcon from '@mui/icons-material/Eject';
import ImportExportIcon from '@mui/icons-material/ImportExport';

const cover: CardsGridItem = {
  title: 'Integração',
  icon: EjectIcon,
  path: '/integracao',
};

const gridItems: CardsGridItem[] = [
  {
    title: 'Escrita',
    icon: DriveFileRenameOutlineIcon,
    path: 'sapmtn/escrita',
  },
  {
    title: 'Leitura',
    icon: DriveFileRenameOutlineIcon,
    path: 'sapmtn/leitura',
  },
  {
    title: 'Correspondência de Dados',
    icon: ImportExportIcon,
    path: '/integracao/totvs-easy/correspondencia',
  },
];

const SapMtnPage = () => {
  return (
    <BasicLayout
      title={'Sap-Mtn'}
      icon={<EjectIcon color={'secondary'} />}
    >
      <CardsGridSubmenu cover={cover} items={gridItems}></CardsGridSubmenu>
    </BasicLayout>
  );
};

export default SapMtnPage;
