import CardsGridSubmenu from '../../../../common/components/cardsGrid/cardsGridSubmenu';
import BasicLayout from '../../../../common/layouts/basicLayout';
import { CardsGridItem } from '../../../../common/components/cardsGrid/cardsGrid';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DescriptionIcon from '@mui/icons-material/Description';

const cover: CardsGridItem = {
  title: 'Leitura',
  icon: AssessmentIcon,
  path: '/integracao/sapmtn',
};

const gridItems: CardsGridItem[] = [
  {
    title: 'Di',
    icon: DescriptionIcon,
    path: '/integracao/sapmtn/leitura/di',
  }
];

const SapMtnLeituraPage = () => {
  return (
    <BasicLayout title={'Sap-Mtn'} icon={<AssessmentIcon color={'secondary'} />}>
      <CardsGridSubmenu cover={cover} items={gridItems}></CardsGridSubmenu>
    </BasicLayout>
  );
};

export default SapMtnLeituraPage;
