import { Chip, IconButton, useTheme } from '@material-ui/core';
import {
  Delete as DeleteIcon, Search as PageviewIcon,
} from '@material-ui/icons';
import React, {
  useCallback, useContext, useEffect, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { userHasDeleteRole } from '../../../../../components/perfil/perfil-utils';
import GrupoDespesaReceitaContext from '../../../../../contexts/registrations/grupo-despesa-receita/GrupoDespesaReceitaContext';
import {
  activateByIdAsync,
  fetchAllAsync,
  inactivateByIdAsync,
  resetMode,
  resetModel,
  selectGrupoDespesasReceitas,
} from '../../../../../features/grupo-despesa-receita/grupoDespesaReceitaSlice';
import { getTipoModeloLabelByValue } from '../../../../../shared-components/select-tipo-modelo/QCXFinalSelectTipoModeloAutocomplete';
import QCXSimpleConsultPageTemplate from '../../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import useOperationController from '../../../../../utils/hooks/store/operation/useOperationController';
import { isAlternativeLoadingStatus } from '../../../../../utils/store/store-utils';

export default function GrupoDespesaReceitaConsultPage({ authInfo = {} }) {

  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();

  const { status } = useContext(GrupoDespesaReceitaContext);

  const dispatch = useDispatch();

  const grupoDespesasReceitas = useSelector(selectGrupoDespesasReceitas);

  const handleActivateInactivate = useOperationController(
    async (event, id) => {
      event.preventDefault();

      const { active } = grupoDespesasReceitas.find(
        (grupo) => (
          grupo.id === id
        )
      );

      const isActivation = !active;

      if (isActivation) {
        dispatch(activateByIdAsync(id));
      } else {
        dispatch(inactivateByIdAsync(id));
      }
    }
  );

  const handleClickOnEditButton = useCallback((event, id) => {
    event.stopPropagation();
    history.push(t('com.muralis.qcx.url.modulosFinanceirosGrupoDespesaReceitaDetalhes', { id }));
  }, ['history']);

  const isAlternativeLoading = useMemo(() => (
    isAlternativeLoadingStatus(status)
  ), [status]);

  const fetchAll = useCallback(() => {
    dispatch(fetchAllAsync());
  }, []);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
  }, []);

  const columns = [
    {
      field: 'id',
      align: 'center',
      headerName: 'Código',
      headerAlign: 'center',
      type: 'string',
      flex: 60,
      valueGetter: ({ row }) => (
        row?.id
      ),
    },
    {
      field: 'descricao',
      headerName: 'Descrição',
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 225,
      valueGetter: ({ row }) => (
        row?.description
      ),
    },
    {
      field: 'ativo',
      headerName: 'Ativo',
      headerAlign: 'center',
      align: 'center',
      type: 'boolean',
      flex: 80,
      valueGetter: ({ row }) => (
        row?.active
      ),
    },
    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 80,
      renderCell: ({ row: { id, active } }) => (
        <>
          {userHasDeleteRole(authInfo, 'grupo-despesa-receita') && (
          <IconButton
            key={`stw-active${id}`}
            value={active}
            name={`stw-active-${id}`}
            onClick={(event) => handleActivateInactivate(event, id)}
          >
            <DeleteIcon color="error" />
          </IconButton>
          )}
          <IconButton
            key={`btn-view-${id}`}
            name={`btn-view-${id}`}
            onClick={(event) => handleClickOnEditButton(event, id)}
          >
            <PageviewIcon color="primary" />
          </IconButton>
        </>
      ),
    },
  ];

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloCadastrosFinanceiros'),
        name: t('com.muralis.qcx.financeiro.labelPlural'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.despesa.grupoDespesasReceitas'),
      },
    },
  ];

  const templateProps = {
    page: {
      title: t('com.muralis.qcx.despesa.grupoDespesasReceitas'),
      breadcrumbs,
    },
    control: {
      buttonNew: {
        description: t('com.muralis.qcx.despesa.novaDespesaReceita'),
        path: t('com.muralis.qcx.url.modulosFinanceirosGrupoDespesaReceitaNova'),
      },
    },
    table: {
      columns,
    },
  };

   return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={grupoDespesasReceitas ?? []}
      isLoading={isAlternativeLoading}
      buttonNewText={t('com.muralis.qcx.acoes.nova').toUpperCase()}
      multipleFilter
      authInfo={authInfo}
      requiredRoles={['grupo-despesa-receita']}
    />
  );

}