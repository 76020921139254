import { Box, Grid, makeStyles, MenuItem, Typography } from '@material-ui/core';
import {
  Style as StyleIcon,
  AccountBalance as AccountBalanceIcon,
  Category as CategoryIcon,
} from '@mui/icons-material';
import { Adjust as AdjustIcon, InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { despesaReceitaAPI } from '../../features/despesa-receita/despesaReceitaAPI';
import { setErrorFeedback } from '../../features/feedback/feedbackSlice';
import { followUpActions } from '../../features/follow-up/followUpSlice';
import { numerarioImportacaoAPI } from '../../features/numerario-importacao/numerarioImportacaoAPI';
import {
  vinculoDespesaReceitaActions,
  vinculoDespesaReceitaSelectors,
} from '../../features/vinculo-despesa-receita/vinculoDespesaReceitaSlice';
import {
  vinculoFollowUpCapaNumerarioActions,
  vinculoFollowUpCapaNumerarioSelectors,
} from '../../features/vinculo-follow-up-capa-numerario/vinculoFollowUpCapaNumerarioSlice';
import QCXFinalAlternativeCurrencyField from '../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXFinalDatePickerField from '../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXFinalForm from '../../shared-components/final-form/QCXFinalForm';
import QCXFinalBondManagerOld from '../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManagerOld';
import QCXListItemIcon from '../../shared-components/list-item-icon/QCXListItemIcon';
import QCXFinalNumericDecimalField from '../../shared-components/final-numeric-decimal-field/QCXFinalNumericDecimalField';
import QCXSelectClienteAutocomplete from '../../shared-components/final-select-cliente-field/QCXSelectClienteAutocomplete';
import QCXSelectMoedaAutocomplete from '../../shared-components/final-select-moeda-field/QCXSelectMoedaAutocomplete';
import QCXFinalMultilineTextField from '../../shared-components/final-text-field/QCXFinalMultilineTextField';
import QCXSelectContaBancariaAutocompleteField from '../../shared-components/select-conta-bancaria/QCXSelectContaBancariaAutocompleteField';
import QCXSelectDespesaReceitaOperacionalAutoComplete from '../../shared-components/select-despesa-receita/QCXSelectDespesaReceitaOperacionalAutoComplete';
import QCXSelectFollowUpImportacaoAutocompleteField from '../../shared-components/select-follow-up/QCXSelectFollowUpImportacaoAutocompleteField';
import { isStrictEquals, normalizeNumeral, unnormalizeNumeral } from '../../utils/general/general-utils';
import { formatBrazilianNumericDecimal } from '../../utils/hooks/form/field/formatters';
import useValidators from '../../utils/hooks/useValidators';
import { maxValue, required } from '../../utils/validators/field/validator';
import QCXMoveFocusInside from '../focus-lock/QCXMoveFocusInside';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';
import QCXCardSolicitacao, { CardSolicitacaoTypeUtils } from './QCXCardSolicitacao';
import { BRAZILIAN } from '../../utils/hooks/form/field/mask-types';
import { taxaConversaoCambioAPI } from '../../features/taxa-conversao-cambio/taxaConversaoCambioAPI';
import { numerarioImportacaoActions } from '../../features/numerario-importacao/numerarioImportacaoSlice';
import { numerarioImportacaoSelectors } from '../../features/numerario-importacao/numerarioImportacaoSelectors';
import QCXInputAdornment from '../../shared-components/input-adornment/QCXInputAdornment';
import QCXReadOnlyTextField from '../../shared-components/text-field/QCXReadOnlyTextField';
import QCXFinalSelectTipoModeloAutocomplete, {
  getTipoModeloLabelByValue,
} from '../../shared-components/select-tipo-modelo/QCXFinalSelectTipoModeloAutocomplete';
import NumerarioImportacaoUtils from '../../utils/general/numerario-importacao/NumerarioImportacaoUtils';
import { isPerformingActionStatus, isPreparingActionStatus, isSubUpdateMode } from '../../utils/store/store-utils';
import NumerarioImportacaoStatusUtils from '../../utils/general/numerario-importacao/NumerarioImportacaoStatusUtils';
import QCXInfoAlert from '../../shared-components/alert/QCXInfoAlert';
import QCXWarningAlert from '../../shared-components/alert/QCXWarningAlert';
import QCXErrorAlert from '../../shared-components/alert/QCXErrorAlert';
import QCXFinalPercentualField from '../../shared-components/final-percentual-field/QCXFinalPercentualField';
import QCXButton from '../../shared-components/button/QCXButton';
import QCXCircularProgress from '../../shared-components/progress/QCXCircularProgress';

const useStyles = makeStyles(() => ({
  gridFields: {
    paddingBottom: '16px',
  },
  subtitle: {
    margin: '20px 0',
  },
}));

export default function QCXNumerarioImportacaoFinalForm({
  isLoading,
  isCreate,
  isExternal,
  initialValues,
  normalize,
  unnormalize,
  handleAlternativeSave,
  handleChangeToUpdate,
  handleCancelUpdate,
  authInfo = {},
  requiredRoles = [],
  ...restProps
}) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const classes = useStyles();

  const status = useSelector(numerarioImportacaoSelectors.selectStatus);
  const subMode = useSelector(numerarioImportacaoSelectors.selectSubMode);

  const isPreparingAction = useMemo(() => isPreparingActionStatus(status), [status]);

  const isPerformingAction = useMemo(() => isPerformingActionStatus(status), [status]);

  const isCancelado = useMemo(() => NumerarioImportacaoStatusUtils.isCancelado(initialValues?.status), [initialValues]);

  const isEnviado = useMemo(() => NumerarioImportacaoStatusUtils.isEnviado(initialValues?.status), [initialValues]);

  const isEnviadoImpresso = useMemo(
    () => NumerarioImportacaoStatusUtils.isEnviadoImpresso(initialValues?.status),
    [initialValues]
  );

  const isParcial = useMemo(() => NumerarioImportacaoStatusUtils.isParcial(initialValues?.status), [initialValues]);

  const isRecebido = useMemo(() => NumerarioImportacaoStatusUtils.isRecebido(initialValues?.status), [initialValues]);

  const statusLabel = useMemo(
    () => NumerarioImportacaoStatusUtils.getLabelByValue(initialValues?.status),
    [initialValues]
  );

  const isEdicaoBloqueada = useMemo(
    () => isCancelado || isEnviado || isEnviadoImpresso || isParcial || isRecebido,
    [isCancelado, isEnviado, isEnviadoImpresso, isParcial, isRecebido]
  );

  const resetRelatedModes = useCallback(() => {
    dispatch(vinculoFollowUpCapaNumerarioActions.resetMode());
    dispatch(vinculoDespesaReceitaActions.resetMode());
  }, []);

  const resetRelatedModels = useCallback(() => {
    dispatch(vinculoFollowUpCapaNumerarioActions.resetModel());
    dispatch(vinculoDespesaReceitaActions.resetModel());
  }, []);

  useEffect(() => {
    const clearOnUnmount = () => {
      resetRelatedModes();
      resetRelatedModels();
    };

    return clearOnUnmount;
  }, []);

  const isSubUpdate = useMemo(() => isSubUpdateMode(subMode), [subMode]);

  const fetchFollowUpsBy = useCallback(async (payload) => {
    const query = payload?.numerario?.id
      ? [
          {
            name: 'numerario',
            value: payload?.numerario?.id,
          },
        ]
      : [];

    query.push({ name: 'produto', value: 'importacao' });

    try {
      const response = await numerarioImportacaoAPI.filterFollowUpsBy(
        {
          importador: {
            id: payload?.importador?.id,
          },
        },
        query
      );

      if (response?.status === 200) {
        const { data } = response;

        if (_.isArrayLikeObject(data)) {
          dispatch(
            followUpActions.setList({
              data,
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        setErrorFeedback({
          message: t('com.muralis.qcx.erro.erroFiltroProcessosImportador'),
        })
      );
    }
  }, []);

  const taxaCambialValidators = useMemo(() => useValidators([required, maxValue(9.99999)]), []);

  const handleValorSolicitadoMoedaOnBlur = useCallback(
    (form, values) => async () => {
      const despesaReceitaId = Number(_.get(values, 'ignorableFields.lancamento.despesaReceita.id'));

      const despesaReceitaResponse = await despesaReceitaAPI.fetchById(despesaReceitaId);

      if (despesaReceitaResponse?.status !== 200) {
        return;
      }

      const foundDespesaReceita = despesaReceitaResponse?.data;

      const moedaId = foundDespesaReceita?.moeda?.id;

      const valorSolicitadoMoeda = normalizeNumeral(_.get(values, 'ignorableFields.lancamento.totalMoeda'));

      if (moedaId && (valorSolicitadoMoeda || valorSolicitadoMoeda === 0)) {
        const taxaConversao = normalizeNumeral(values?.draftFields?.current?.taxaCambio);

        const valorEmReaisPath = 'ignorableFields.lancamento.totalReal';

        if (!taxaConversao) {
          form.change(valorEmReaisPath, '0,00');

          return;
        }

        const valorEmReais = (valorSolicitadoMoeda * taxaConversao).toFixed(2);

        const formattedValorEmReais = unnormalizeNumeral(valorEmReais, formatBrazilianNumericDecimal(2));

        form.change(valorEmReaisPath, formattedValorEmReais);
      }
    },
    [unnormalizeNumeral, formatBrazilianNumericDecimal]
  );

  const handleSelecionarCapa = useCallback(async (selected, tools) => {
    const {
      formUtils: { form },
      stateUtils,
    } = tools;
    dispatch(numerarioImportacaoActions.preparingAction());

    form.change('draftFields.current.followUp.numero', selected?.followUp?.numero);

    dispatch(numerarioImportacaoActions.changeToSubUpdateMode());

    stateUtils.changeToUpdateMode();

    stateUtils.loading();

    stateUtils.setModel(selected);

    _.debounce(() => {
      form.change('draftFields.current', selected);

      dispatch(numerarioImportacaoActions.resetStatus());

      stateUtils.resetStatus();
    }, 500)();
  }, []);

  const followUpCapaReducerConfig = useMemo(
    () => ({
      selectors: {
        selectStatus: vinculoFollowUpCapaNumerarioSelectors.selectStatus,
        selectMode: vinculoFollowUpCapaNumerarioSelectors.selectMode,
        selectModel: vinculoFollowUpCapaNumerarioSelectors.selectModel,
      },
      actions: {
        loading: vinculoFollowUpCapaNumerarioActions.loading,
        resetStatus: vinculoFollowUpCapaNumerarioActions.resetStatus,
        changeToUpdateMode: vinculoFollowUpCapaNumerarioActions.changeToUpdateMode,
        changeToCreateMode: vinculoFollowUpCapaNumerarioActions.changeToCreateMode,
        resetMode: vinculoFollowUpCapaNumerarioActions.resetMode,
        setModel: vinculoFollowUpCapaNumerarioActions.setModel,
        resetModel: vinculoFollowUpCapaNumerarioActions.resetModel,
      },
    }),
    []
  );

  const followUpCapaGeneralConfig = useMemo(
    () => ({
      feedbackConfig: {
        add: {
          success: {
            message: t('com.muralis.qcx.mensagem.capaItemAdicionadoComSucesso'),
          },
        },
        update: {
          success: {
            message: t('com.muralis.qcx.mensagem.capaItemAtualizadoComSucesso'),
          },
        },
        remove: {
          success: {
            message: t('com.muralis.qcx.mensagem.capaItemRemovidoComSucesso'),
          },
        },
      },
      actionConfig: {
        add: {
          success: {
            disableUpdateList: true,
            disableResetRootValues: true,
          },
        },
        update: {
          success: {
            disableUpdateList: true,
          },
        },
        remove: {
          confirm: {
            title: t('com.muralis.qcx.acoes.confirmarOperacao'),
            message: t('com.muralis.qcx.mensagem.confirmarOperacaoCapaItemNumerarioRemoverMensagem'),
          },
        },
      },
    }),
    []
  );

  const followUpCapaFormProps = useMemo(
    () => ({
      rootName: 'draftFields.current',
      fields: [
        {
          name: 'followUp.id',
          label: t('com.muralis.qcx.processo'),
        },
      ],
    }),
    []
  );

  const followUpCapaListProps = useMemo(
    () => ({
      name: 'capas',
      columns: [
        {
          field: 'id',
          headerName: t('com.muralis.qcx.processo'),
          headerAlign: 'center',
          align: 'center',
          flex: 160,
          valueGetter: ({ row }) => row?.followUp?.numero || '',
        },
        {
          field: 'importador',
          headerName: t('com.muralis.qcx.cliente.label'),
          headerAlign: 'left',
          align: 'left',
          flex: 200,
          valueGetter: ({ row }) => row?.followUp?.importador?.pessoa?.nome || '',
        },
        {
          field: 'servico',
          headerName: t('com.muralis.qcx.servico.labelSingular'),
          flex: 180,
          valueGetter: ({ row }) => row?.followUp?.servico?.nome || '-',
        },
        {
          field: 'servico.viaTransporte',
          headerName: t('com.muralis.qcx.transporte.label'),
          flex: 160,
          valueGetter: ({ row }) => row?.followUp?.servico?.viaTransporte?.description || '-',
        },
      ],
      renderOtherMenuItems: (...args) => {
        const [{ id, ...restRow }, popupState, , tools] = args;

        return (
          <>
            <MenuItem
              key={`btn-selecionar-capa-${id}`}
              name={`btn-selecionar-capa-${id}`}
              onClick={(event) => {
                popupState.close(event);

                handleSelecionarCapa({ id, ...restRow }, tools);
              }}
            >
              <QCXListItemIcon>
                <AdjustIcon fontSize="small" color="primary" />
              </QCXListItemIcon>
              <Typography
                variant="inherit"
                style={{
                  fontSize: 12,
                }}
              >
                {t('com.muralis.qcx.acoes.selecionar').toUpperCase()}
              </Typography>
            </MenuItem>
          </>
        );
      },
    }),
    [handleSelecionarCapa]
  );

  const handleAddCapaFollowUpVinculo = useCallback(
    async (currentCapa, _currentVinculos, handleSuccessAddVinculo, tools) => {
      try {
        dispatch(numerarioImportacaoActions.performingAction());

        const {
          formUtils: { form, values },
        } = tools;

        const fetchAndLoadById = async (numerarioId) => {
          const numerarioResponse = await numerarioImportacaoAPI.fetchById(numerarioId);

          const isSuccessfulNumerarioStatusCode = [numerarioResponse?.status].includes(200);

          if (isSuccessfulNumerarioStatusCode) {
            const foundNumerario = numerarioResponse?.data;
            const unnormalizedNumerario = unnormalize(foundNumerario);

            const sortedCapasNumerarioByItem = _.sortBy(unnormalizedNumerario?.capas, 'item');

            const updatedNumerario = {
              ...unnormalizedNumerario,
              capas: sortedCapasNumerarioByItem,
            };

            dispatch(numerarioImportacaoActions.changeToBackgroundCreateMode());

            dispatch(numerarioImportacaoActions.setModel(updatedNumerario));

            form.restart(updatedNumerario);

            const savedCurrentCapa = _.last(sortedCapasNumerarioByItem);

            handleSelecionarCapa(savedCurrentCapa, tools);

            handleSuccessAddVinculo([]);

            const followUpFilterPayload = {
              numerario: {
                id: foundNumerario?.id,
              },
              importador: foundNumerario?.importador,
            };

            await fetchFollowUpsBy(followUpFilterPayload);
          }
        };

        const novaCapa = {
          followUp: currentCapa?.followUp,
        };

        const isPrimeiraCapa = !values?.id;

        if (isPrimeiraCapa) {
          const payload = {
            importador: values?.importador,
            capas: [novaCapa],
          };

          const normalizedData = normalize(payload);

          const numerarioResponse = await numerarioImportacaoAPI.register(normalizedData);

          const isSuccessfulStatusCode = [numerarioResponse?.status].includes(201);

          if (isSuccessfulStatusCode) {
            const createdNumerario = numerarioResponse?.data;

            await fetchAndLoadById(createdNumerario?.id);
          }
        } else {
          const payload = {
            ...values,
            capas: [...values.capas, novaCapa],
          };

          const normalizedData = normalize(payload);

          const numerarioResponse = await numerarioImportacaoAPI.save(normalizedData);

          const isSuccessfulStatusCode = [numerarioResponse?.status].includes(200);

          if (isSuccessfulStatusCode) {
            const savedNumerario = numerarioResponse?.data;

            await fetchAndLoadById(savedNumerario?.id);
          }
        }
      } catch (error) {
        throw new Error(error?.response?.data?.message || error?.message);
      } finally {
        dispatch(numerarioImportacaoActions.resetStatus());
      }
    },
    []
  );

  const handleUpdateCapaFollowUpVinculo = useCallback(
    async (currentCapa, _currentVinculos, handleSuccessUpdateVinculo, tools) => {
      try {
        dispatch(numerarioImportacaoActions.performingAction());

        const {
          formUtils: { form, values },
        } = tools;

        const fetchAndLoadById = async (numerarioId, currentCapaId) => {
          const numerarioResponse = await numerarioImportacaoAPI.fetchById(numerarioId);

          const isSuccessfulNumerarioStatusCode = [numerarioResponse?.status].includes(200);

          if (isSuccessfulNumerarioStatusCode) {
            const foundNumerario = numerarioResponse?.data;
            const unnormalizedNumerario = unnormalize(foundNumerario);

            dispatch(numerarioImportacaoActions.changeToBackgroundCreateMode());

            dispatch(numerarioImportacaoActions.setModel(unnormalizedNumerario));

            form.restart(unnormalizedNumerario);

            const savedCurrentCapa = foundNumerario?.capas?.find((capa) => capa?.id === currentCapaId);

            handleSelecionarCapa(savedCurrentCapa, tools);

            handleSuccessUpdateVinculo();

            const followUpFilterPayload = {
              numerario: {
                id: foundNumerario?.id,
              },
              importador: foundNumerario?.importador,
            };

            await fetchFollowUpsBy(followUpFilterPayload);
          }
        };

        const fieldLevelValidations = [
          {
            field: 'moeda.id',
            validators: [required],
          },
          {
            field: 'taxaCambio',
            validators: [required, maxValue(9.99999)],
          },
          {
            field: 'contaBancariaImpostosFederais.id',
            validators: [required],
          },
          {
            field: 'contaBancariaImpostosEstaduais.id',
            validators: [required],
          },
          {
            field: 'contaBancariaDespesas.id',
            validators: [required],
          },
        ];

        const validationError = fieldLevelValidations.find(({ field, validators }) => {
          const error = validators.some((validator) => {
            const value = _.get(currentCapa, field);
            const result = validator.call(this, value);

            return result !== undefined;
          });

          return error;
        });

        if (validationError !== undefined) {
          form.submit();

          return;
        }

        const currentCapaId = currentCapa?.id;

        const updatedCapas = values?.capas?.map((capa) => (capa?.id === currentCapaId ? currentCapa : capa));

        const payload = {
          ...values,
          capas: updatedCapas,
        };

        const normalizedData = normalize(payload);

        const numerarioResponse = await numerarioImportacaoAPI.save(normalizedData);

        const isSuccessfulStatusCode = [numerarioResponse?.status].includes(200);

        if (isSuccessfulStatusCode) {
          const savedNumerario = numerarioResponse?.data;

          await fetchAndLoadById(savedNumerario?.id, currentCapaId);
        }
      } catch (error) {
        throw new Error(error?.response?.data?.message || error?.message);
      } finally {
        dispatch(numerarioImportacaoActions.resetStatus());
      }
    },
    [normalize, fetchFollowUpsBy, handleSelecionarCapa]
  );

  const handleClearCapaFollowUpDecorator = useCallback(({ formUtils: { form } }) => {
    dispatch(vinculoDespesaReceitaActions.resetMode());
    dispatch(vinculoDespesaReceitaActions.resetModel());

    dispatch(numerarioImportacaoActions.resetSubMode());

    form.change('draftFields.current.taxaCambio', '');
    form.resetFieldState('draftFields.current.moeda.id');
    form.resetFieldState('draftFields.current.taxaCambio');
    // form.resetFieldState('draftFields.current.contaBancariaImpostosFederais.id');
    // form.resetFieldState('draftFields.current.contaBancariaImpostosEstaduais.id');
    // form.resetFieldState('draftFields.current.contaBancariaDespesas.id');

    form.change('ignorableFields.lancamento', undefined);
    form.change('ignorableFields.lancamento.totalMoeda', '');
    form.change('ignorableFields.lancamento.totalReal', '');
    form.resetFieldState('ignorableFields.lancamento.totalMoeda');
    form.resetFieldState('ignorableFields.lancamento.totalReal');
  }, []);

  const handleRemoveCapaFollowUp = useCallback(
    async ({ target, handleSuccess, tools }) => {
      try {
        dispatch(numerarioImportacaoActions.loading());

        const {
          formUtils: { form, values },
        } = tools;

        const fetchAndLoadById = async (numerarioId) => {
          const numerarioResponse = await numerarioImportacaoAPI.fetchById(numerarioId);

          const isSuccessfulNumerarioStatusCode = [numerarioResponse?.status].includes(200);

          if (isSuccessfulNumerarioStatusCode) {
            const foundNumerario = numerarioResponse?.data;
            const unnormalizedNumerario = unnormalize(foundNumerario);

            dispatch(numerarioImportacaoActions.setModel(unnormalizedNumerario));

            form.restart(unnormalizedNumerario);

            const followUpFilterPayload = {
              numerario: {
                id: foundNumerario?.id,
              },
              importador: foundNumerario?.importador,
            };

            await fetchFollowUpsBy(followUpFilterPayload);
          }
        };

        const updatedCapas = values?.capas?.filter((existingCapa) => target?.id !== existingCapa?.id);

        const payload = {
          ...values,
          capas: updatedCapas,
        };

        const normalizedData = normalize(payload);

        const numerarioResponse = await numerarioImportacaoAPI.save(normalizedData);

        const isSuccessfulStatusCode = [numerarioResponse?.status].includes(200);

        if (isSuccessfulStatusCode) {
          dispatch(vinculoFollowUpCapaNumerarioActions.resetMode());

          handleSuccess(target);
          handleClearCapaFollowUpDecorator(tools);

          const savedNumerario = numerarioResponse?.data;

          await fetchAndLoadById(savedNumerario?.id);

          dispatch(numerarioImportacaoActions.resetStatus());
        }
      } catch (error) {
        throw new Error(error?.response?.data?.message || error?.message);
      } finally {
        dispatch(numerarioImportacaoActions.resetStatus());
      }
    },
    [normalize, unnormalize, fetchFollowUpsBy]
  );

  const handleCapaFollowUpAlreadyExists = useCallback(() => false, []);

  const isEmptyCapas = useMemo(
    () => _.isUndefined(initialValues?.capas) || _.isEmpty(initialValues?.capas),
    [initialValues]
  );

  const despesaReceitaReducerConfig = useMemo(
    () => ({
      selectors: {
        selectStatus: vinculoDespesaReceitaSelectors.selectStatus,
        selectMode: vinculoDespesaReceitaSelectors.selectMode,
        selectModel: vinculoDespesaReceitaSelectors.selectModel,
      },
      actions: {
        loading: vinculoDespesaReceitaActions.loading,
        resetStatus: vinculoDespesaReceitaActions.resetStatus,
        changeToUpdateMode: vinculoDespesaReceitaActions.changeToUpdateMode,
        changeToCreateMode: vinculoDespesaReceitaActions.changeToCreateMode,
        resetMode: vinculoDespesaReceitaActions.resetMode,
        setModel: vinculoDespesaReceitaActions.setModel,
        resetModel: vinculoDespesaReceitaActions.resetModel,
      },
    }),
    []
  );

  const despesaReceitaFormProps = useMemo(
    () => ({
      rootName: 'ignorableFields.lancamento',
      fields: [
        {
          name: 'despesaReceita.id',
          label: t('com.muralis.qcx.despesa.despesaReceita'),
        },
        {
          name: 'totalMoeda',
          label: t('com.muralis.qcx.valorSolicitado'),
        },
        {
          name: 'totalReal',
          label: t('com.muralis.qcx.moeda.valorReais'),
        },
        {
          name: 'despesaReceita.tipo',
          label: t('com.muralis.qcx.conta.contaDebito'),
        },
      ],
    }),
    []
  );

  const despesaReceitaListProps = useMemo(
    () => ({
      name: 'draftFields.current.lancamentos',
      columns: [
        {
          field: 'despesaReceita.description',
          headerName: t('com.muralis.qcx.despesa.despesaReceita'),
          headerAlign: 'left',
          align: 'left',
          flex: 240,
          valueGetter: ({ row }) => row?.despesaReceita?.description || '',
        },
        {
          field: 'totalMoeda',
          headerName: t('com.muralis.qcx.valorSolicitado'),
          headerAlign: 'right',
          align: 'right',
          flex: 180,
          valueGetter: ({ row }) => row?.totalMoeda,
          valueFormatter: ({ value }) => {
            const unnormalizedValue = unnormalizeNumeral(value, formatBrazilianNumericDecimal(2));

            if (unnormalizedValue) {
              return unnormalizedValue;
            }

            if (value) {
              return value;
            }

            return '-';
          },
        },
        {
          field: 'totalReal',
          headerName: t('com.muralis.qcx.moeda.valorReais'),
          headerAlign: 'right',
          align: 'right',
          flex: 180,
          valueGetter: ({ row }) => row?.totalReal,
          valueFormatter: ({ value }) => {
            const unnormalizedValue = unnormalizeNumeral(value, formatBrazilianNumericDecimal(2));

            if (unnormalizedValue) {
              return `R$ ${unnormalizedValue}`;
            }

            if (value) {
              return `R$ ${value}`;
            }

            return '-';
          },
        },
        {
          field: 'despesaReceita.tipo',
          headerName: t('com.muralis.qcx.conta.contaDebito'),
          flex: 220,
          valueGetter: ({ row }) => getTipoModeloLabelByValue(row?.despesaReceita?.tipo) || '-',
        },
      ],
    }),
    []
  );

  const handleAddDespesaReceitaVinculo = useCallback(
    async (currentVinculo, currentVinculos, handleSuccessAddVinculo) => {
      const despesaReceitaResponse = await despesaReceitaAPI.fetchById(currentVinculo?.despesaReceita?.id);

      if (despesaReceitaResponse?.status === 200) {
        const vinculo = {
          id: uuid(),
          ...currentVinculo,
          despesaReceita: {
            ...despesaReceitaResponse?.data,
          },
        };

        const updatedVinculos = [...currentVinculos, vinculo];

        handleSuccessAddVinculo(updatedVinculos);
      }
    },
    []
  );

  const oldHandleUpdateDespesaReceitaVinculo = useCallback(
    async (currentVinculo, currentVinculos, handleSuccessUpdateVinculo) => {
      const despesaReceitaResponse = await despesaReceitaAPI.fetchById(currentVinculo?.despesaReceita?.id);

      if (despesaReceitaResponse?.status === 200) {
        const updatedVinculo = {
          ...currentVinculo,
          despesaReceita: {
            ...despesaReceitaResponse?.data,
          },
        };

        const updatedVinculos =
          currentVinculos?.map((existingVinculo) =>
            isStrictEquals(existingVinculo?.id, updatedVinculo?.id) ? updatedVinculo : existingVinculo
          ) || currentVinculos;

        handleSuccessUpdateVinculo(updatedVinculos);
      }
    },
    []
  );

  const handleUpdateDespesaReceitaVinculo = useCallback(
    async (currentVinculo, currentVinculos, handleSuccessUpdateVinculo) => {
      if (currentVinculo?.despesaReceita?.id) {
        const updatedVinculo = {
          ...currentVinculo,
          despesaReceita: {
            ...currentVinculo?.despesaReceita,
          },
        };

        const updatedVinculos =
          currentVinculos?.map((existingVinculo) =>
            isStrictEquals(existingVinculo?.id, updatedVinculo?.id) ? updatedVinculo : existingVinculo
          ) || currentVinculos;

        handleSuccessUpdateVinculo(updatedVinculos);
      }
    },
    []
  );

  const handleDespesaReceitaAlreadyExists = useCallback(
    (currentVinculo, currentVinculosList) =>
      currentVinculosList.some(
        (vinculo) =>
          !isStrictEquals(vinculo?.id, currentVinculo?.id) &&
          isStrictEquals(vinculo?.despesaReceita?.id, currentVinculo?.despesaReceita?.id) &&
          isStrictEquals(vinculo?.totalMoeda, currentVinculo?.totalMoeda) &&
          isStrictEquals(vinculo?.totalReal, currentVinculo?.totalReal)
      ),
    []
  );

  const handleImportadorCapaIdChange = useCallback(
    (form, values) => async (value) => {
      if (!value) {
        form.change('ignorableFields.capa.followUp.id', undefined);

        return;
      }

      const payload = {
        numerario: {
          id: values?.id,
        },
        importador: {
          id: value,
        },
      };

      await fetchFollowUpsBy(payload);
    },
    [fetchFollowUpsBy]
  );

  const handleMoedaCapaIdChange = useCallback(
    (form, values) => async (value, previous) => {
      if ((previous && value && value !== previous) || (value && !values?.draftFields?.current?.taxaCambio)) {
        const response = await taxaConversaoCambioAPI.fetchByFilter([
          {
            name: 'moeda',
            value,
          },
        ]);

        if (response?.status === 200) {
          const taxaConversaoCambio = _.get(response, 'data[0]');
          const taxaConversao = taxaConversaoCambio?.taxaConversao;

          if (taxaConversao) {
            const formattedTaxaConversao = unnormalizeNumeral(taxaConversao, formatBrazilianNumericDecimal(5));

            form.change('draftFields.current.taxaCambio', formattedTaxaConversao);
          }
        }
      }
    },
    []
  );

  const handleDespesaReceitaIdChange = useCallback(
    (form) => async (value) => {
      if (!value) {
        form.change('ignorableFields.lancamento', undefined);
        form.change('ignorableFields.lancamento.totalMoeda', '');
        form.change('ignorableFields.lancamento.totalReal', '');
        form.change('ignorableFields.lancamento.despesaReceita.tipo', undefined);

        form.resetFieldState('ignorableFields.lancamento.totalMoeda');
        form.resetFieldState('ignorableFields.lancamento.totalReal');
        form.resetFieldState('ignorableFields.lancamento.despesaReceita.tipo');

        return;
      }

      const despesaReceitaResponse = await despesaReceitaAPI.fetchById(value);

      if (despesaReceitaResponse?.status === 200) {
        const despesaReceitaFound = despesaReceitaResponse?.data;

        form.change('ignorableFields.lancamento.despesaReceita.tipo', despesaReceitaFound?.tipo);
      }
    },
    []
  );

  const handleValorSolicitadoDespesaReceitaChange = useCallback(
    (form) => async (value) => {
      if (!value) {
        form.change('ignorableFields.lancamento.totalReal', '');
      }
    },
    []
  );

  const listeners = useMemo(
    () => [
      {
        name: 'importador.id',
        fn: handleImportadorCapaIdChange,
      },
      {
        name: 'draftFields.current.moeda.id',
        fn: handleMoedaCapaIdChange,
      },
      {
        name: 'ignorableFields.lancamento.despesaReceita.id',
        fn: handleDespesaReceitaIdChange,
      },
      {
        name: 'ignorableFields.lancamento.totalMoeda',
        fn: handleValorSolicitadoDespesaReceitaChange,
      },
    ],
    [
      handleImportadorCapaIdChange,
      handleMoedaCapaIdChange,
      handleDespesaReceitaIdChange,
      handleValorSolicitadoDespesaReceitaChange,
    ]
  );

  const formControlButtons = useMemo(
    () => ({
      create: {
        description: t('com.muralis.qcx.acoes.solicitar'),
        disabled: isSubUpdate || isEmptyCapas,
        hidden: isExternal,
      },
      edit: {
        hidden: true,
      },
      save: {
        hidden: true,
      },
      cancel: {
        hidden: true,
      },
    }),
    [isSubUpdate, isExternal, isEmptyCapas]
  );

  return (
    <QCXFinalForm
      initialValues={initialValues}
      handleAlternativeSave={handleAlternativeSave}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      onChangeListeners={listeners}
      controlButtons={formControlButtons}
      authInfo={authInfo}
      requiredRoles={requiredRoles}
      alternativeSave
      disablePristine
      {...restProps}
    >
      {({ form, values }) => (
        <>
          <Grid item container spacing={3}>
            {(isEnviado || isEnviadoImpresso) && (
              <Grid
                item
                xs={12}
                style={{
                  marginTop: '0.5rem',
                  paddingBottom: '!important 0px',
                }}
              >
                <QCXWarningAlert>
                  <Trans i18nKey="com.muralis.qcx.numerarioImportacao.avisoAlteracoesBloqueadasStatus">
                    <b>
                      {{
                        status: statusLabel,
                      }}
                    </b>
                  </Trans>
                </QCXWarningAlert>
              </Grid>
            )}
            {isCancelado && (
              <Grid
                item
                xs={12}
                style={{
                  marginTop: '0.5rem',
                  paddingBottom: '!important 0px',
                }}
              >
                <QCXErrorAlert>
                  <Trans i18nKey="com.muralis.qcx.numerarioImportacao.avisoSolicitacaoCancelada">
                    <b />
                  </Trans>
                </QCXErrorAlert>
              </Grid>
            )}
            {!values?.draftFields?.current?.id && NumerarioImportacaoUtils.getQuantidadeFrom(values) > 0 && (
              <Grid
                item
                xs={12}
                style={{
                  marginTop: isEnviado || isEnviadoImpresso || isCancelado ? '-0.5rem' : '0.5rem',
                  paddingBottom: '!important 0px',
                }}
              >
                <QCXInfoAlert>{t('com.muralis.qcx.numerarioImportacao.avisoNenhumaCapaItemSelecionado')}</QCXInfoAlert>
              </Grid>
            )}
            {NumerarioImportacaoUtils.getQuantidadeFrom(values) > 0 && (
              <Grid
                item
                xs={12}
                style={{
                  marginTop:
                    !values?.draftFields?.current?.id || isEnviado || isEnviadoImpresso || isCancelado
                      ? '-0.5rem'
                      : '0.5rem',
                  paddingBottom: '!important 0px',
                }}
              >
                <QCXInfoAlert closable unmountOnClose>
                  <Trans i18nKey="com.muralis.qcx.numerarioImportacao.ajudaVisualizarCapaItem">
                    <b />
                    <b />
                  </Trans>
                </QCXInfoAlert>
              </Grid>
            )}
            <QCXFinalBondManagerOld
              listProps={followUpCapaListProps}
              formProps={followUpCapaFormProps}
              reducerConfig={followUpCapaReducerConfig}
              generalConfig={followUpCapaGeneralConfig}
              handleAdd={handleAddCapaFollowUpVinculo}
              handleUpdate={handleUpdateCapaFollowUpVinculo}
              handleRemove={handleRemoveCapaFollowUp}
              handleAlreadyExists={handleCapaFollowUpAlreadyExists}
              onClearDecorator={handleClearCapaFollowUpDecorator}
              readOnlyMode={isEdicaoBloqueada}
              disableDefaultRemove
              disableConsult
            >
              {({ loading, updating }) => (
                <Grid item container xs={8} spacing={2}>
                  <Grid item className={classes.gridFields} xs={12} sm={12} md={6}>
                    <QCXMoveFocusInside>
                      <QCXSelectClienteAutocomplete
                        id="autocomplete-select-importador-field"
                        key="autocomplete-select-importador-field"
                        name="importador.id"
                        label={t('com.muralis.qcx.cliente.label')}
                        initialValues={values}
                        filter={{
                          importador: true,
                        }}
                        disabled={
                          isEdicaoBloqueada ||
                          isPreparingAction ||
                          isPerformingAction ||
                          (!!values?.id && !!values?.importador?.id)
                        }
                      />
                    </QCXMoveFocusInside>
                  </Grid>
                  <Grid item className={classes.gridFields} xs={12} sm={12} md={6}>
                    {!updating && (
                      <QCXSelectFollowUpImportacaoAutocompleteField
                        id="autocomplete-select-follow-up-field"
                        key="autocomplete-select-follow-up-field"
                        name="draftFields.current.followUp.id"
                        label={t('com.muralis.qcx.processo')}
                        initialValues={values}
                        controlled
                        disabled={
                          !values?.importador?.id ||
                          isEdicaoBloqueada ||
                          isPreparingAction ||
                          isPerformingAction ||
                          loading
                        }
                      />
                    )}
                    {updating && (
                      <QCXReadOnlyTextField
                        id="autocomplete-select-follow-up-field"
                        key="autocomplete-select-follow-up-field"
                        name="draftFields.current.followUp.numero"
                        label={t('com.muralis.qcx.processo')}
                        InputProps={{
                          endAdornment: (
                            <QCXInputAdornment position="end" style={{ paddingRight: '12px' }}>
                              <InfoOutlinedIcon htmlColor="#31278385" fontSize="small" />
                            </QCXInputAdornment>
                          ),
                        }}
                        defaultValue={values?.draftFields?.current?.followUp?.numero}
                        disabled={isPreparingAction || isPerformingAction || loading}
                        disableHelperText
                        {...restProps}
                      />
                    )}
                  </Grid>
                </Grid>
              )}
            </QCXFinalBondManagerOld>

            <Grid item xs={12}>
              <QCXFormSubtitle className={classes.subtitle} title={t('com.muralis.qcx.banco.dadosBancarios')} />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <QCXSelectMoedaAutocomplete
                id="autocomplete-select-moeda-capa-field"
                key="autocomplete-select-moeda-capa-field"
                name="draftFields.current.moeda.id"
                label={t('com.muralis.qcx.moeda.label')}
                fieldProps={{ validate: required }}
                initialValues={values}
                disabled={isEdicaoBloqueada || isPreparingAction || isPerformingAction || !isSubUpdate}
                required
              />
            </Grid>

            <Grid item className={classes.gridFields} xs={12} sm={6} md={2}>
              <QCXFinalDatePickerField
                id="date-picker-data-solicitacao-capa-field"
                key="date-picker-data-solicitacao-capa-field"
                name="draftFields.current.dataSolicitacao"
                label={t('com.muralis.qcx.numerarioImportacao.dataSolicitacao')}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <QCXFinalNumericDecimalField
                id="numeric-decimal-taxa-cambial-capa-field"
                key="numeric-decimal-taxa-cambial-capa-field"
                name="draftFields.current.taxaCambio"
                label={t('com.muralis.qcx.cotacaoMoeda.taxaCambial')}
                scale={5}
                validate={taxaCambialValidators}
                disabled={isEdicaoBloqueada || isPreparingAction || isPerformingAction || !isSubUpdate}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <QCXFinalPercentualField
                id="percentual-valor-spread-capa-field"
                key="percentual-valor-spread-capa-field"
                name="importador.percentualSpread"
                label="Spread"
                disabled={isEdicaoBloqueada || isPreparingAction || isPerformingAction || !isSubUpdate}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <QCXButton
                color="secondary"
                onClick={() => {
                  if (values?.draftFields.current.lancamentos.length === 0) {
                    return;
                  }

                  const spread = normalizeNumeral(values?.importador.percentualSpread) / 100 ?? 0;

                  values?.draftFields.current.lancamentos.forEach((lancamento, index) => {
                    const { totalMoeda, totalReal } = lancamento;

                    form.change(
                      `draftFields.current.lancamentos[${index}].totalMoeda`,
                      normalizeNumeral(totalMoeda) * (1 + spread)
                    );
                    form.change(
                      `draftFields.current.lancamentos[${index}].totalReal`,
                      normalizeNumeral(totalReal) * (1 + spread)
                    );
                  });
                }}
                disabled={isEdicaoBloqueada || isPreparingAction || isPerformingAction || !isSubUpdate}
                fullWidth
                tooltip
                tooltipDescription={t('com.muralis.qcx.acoes.atualizar')}
              >
                {isLoading ? <QCXCircularProgress color="disabled" size={20} /> : 'Calcular Spread'}
              </QCXButton>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <QCXSelectContaBancariaAutocompleteField
                id="autocomplete-select-conta-bancaria-debito-impostos-federais-capa-field"
                key="autocomplete-select-conta-bancaria-debito-impostos-federais-capa-field"
                name="draftFields.current.contaBancariaImpostosFederais"
                label={t('com.muralis.qcx.conta.contaDebitoImpostosFederais')}
                initialValues={values}
                fieldProps={{ validate: required }}
                disabled={isEdicaoBloqueada || isPreparingAction || isPerformingAction || !isSubUpdate}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <QCXSelectContaBancariaAutocompleteField
                id="autocomplete-select-conta-bancaria-debito-impostos-estaduais-capa-field"
                key="autocomplete-select-conta-bancaria-debito-impostos-estaduais-capa-field"
                name="draftFields.current.contaBancariaImpostosEstaduais"
                label={t('com.muralis.qcx.conta.contaDebitoImpostosEstaduais')}
                initialValues={values}
                fieldProps={{ validate: required }}
                disabled={isEdicaoBloqueada || isPreparingAction || isPerformingAction || !isSubUpdate}
                controlled
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <QCXSelectContaBancariaAutocompleteField
                id="autocomplete-select-conta-bancaria-debito-despesas-capa-field"
                key="autocomplete-select-conta-bancaria-debito-despesas-capa-field"
                name="draftFields.current.contaBancariaDespesas"
                label={t('com.muralis.qcx.conta.contaDebitoDespesas')}
                initialValues={values}
                fieldProps={{ validate: required }}
                disabled={isEdicaoBloqueada || isPreparingAction || isPerformingAction || !isSubUpdate}
                controlled
                required
              />
            </Grid>
            <Grid item xs={12}>
              <QCXFinalMultilineTextField
                id="multiline-text-observacao-capa-field"
                key="multiline-text-observacao-capa-field"
                name="draftFields.current.observacao"
                label={t('com.muralis.qcx.observacoesAdicionais')}
                rows={10}
                disabled={isEdicaoBloqueada || isPreparingAction || isPerformingAction || !isSubUpdate}
              />
            </Grid>
            <Grid item xs={12}>
              <QCXFormSubtitle
                className={classes.subtitle}
                title={t('com.muralis.qcx.numerarioImportacao.despesaReceitasModeloNumerario')}
              />
            </Grid>

            <QCXFinalBondManagerOld
              listProps={despesaReceitaListProps}
              formProps={despesaReceitaFormProps}
              reducerConfig={despesaReceitaReducerConfig}
              handleAdd={handleAddDespesaReceitaVinculo}
              handleUpdate={handleUpdateDespesaReceitaVinculo}
              handleAlreadyExists={handleDespesaReceitaAlreadyExists}
              disableButtons={isPreparingAction || isPerformingAction || !isSubUpdate}
              readOnlyMode={isEdicaoBloqueada}
            >
              <>
                <Grid item xs={12} sm={12} md={6}>
                  <QCXSelectDespesaReceitaOperacionalAutoComplete
                    id="autocomplete-select-despesa-receita-capa-field"
                    key="autocomplete-select-despesa-receita-capa-field"
                    name="ignorableFields.lancamento.despesaReceita.id"
                    label={t('com.muralis.qcx.despesa.despesaReceita')}
                    initialValues={values}
                    disabled={isEdicaoBloqueada || isPreparingAction || isPerformingAction || !isSubUpdate}
                  />
                </Grid>
                <Grid item className={classes.gridFields} xs={12} sm={6} md={3}>
                  <QCXFinalAlternativeCurrencyField
                    id="currency-valor-solicitado-despesa-receita-capa-field"
                    key="currency-valor-solicitado-despesa-receita-capa-field"
                    name="ignorableFields.lancamento.totalMoeda"
                    label={t('com.muralis.qcx.valorSolicitado')}
                    onBlur={handleValorSolicitadoMoedaOnBlur(form, values)}
                    validate={maxValue(999999999999)}
                    disabled={
                      isEdicaoBloqueada ||
                      isPreparingAction ||
                      isPerformingAction ||
                      !isSubUpdate ||
                      !values?.ignorableFields?.lancamento?.despesaReceita?.id
                    }
                    formatOnBlur
                  />
                </Grid>
                <Grid item className={classes.gridFields} xs={12} sm={6} md={3}>
                  <QCXFinalAlternativeCurrencyField
                    id="currency-valor-reais-despesa-receita-capa-field"
                    key="currency-valor-reais-despesa-receita-capa-field"
                    name="ignorableFields.lancamento.totalReal"
                    label={t('com.muralis.qcx.moeda.valorReais')}
                    intl={BRAZILIAN}
                    validate={maxValue(999999999999)}
                    adorned
                    disabled
                  />
                </Grid>
                <Grid item className={classes.gridFields} xs={12} sm={12} md={6}>
                  <QCXFinalSelectTipoModeloAutocomplete
                    id="autocomplete-select-tipo-modelo-field"
                    key="autocomplete-select-tipo-modelo-field"
                    name="ignorableFields.lancamento.despesaReceita.tipo"
                    label={t('com.muralis.qcx.conta.contaDebito')}
                    initialValues={values}
                    controlled
                    disabled
                  />
                </Grid>
              </>
            </QCXFinalBondManagerOld>
          </Grid>
          <Grid item xs={12}>
            <QCXFormSubtitle
              className={classes.subtitle}
              title={t('com.muralis.qcx.numerarioImportacao.resumoSolicitacao')}
            />
          </Grid>
          <Grid item container spacing={5}>
            <Grid item xs={12} sm={12} md={4}>
              <QCXCardSolicitacao
                type={CardSolicitacaoTypeUtils.ICON}
                renderIcon={(props) => <CategoryIcon {...props} />}
                content={{
                  title: t('com.muralis.qcx.numerarioImportacao.totalItens'),
                  value: NumerarioImportacaoUtils.getQuantidadeFrom(values?.draftFields?.current),
                }}
                color="#F39200"
                loading={isPreparingAction || isPerformingAction || isLoading}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <QCXCardSolicitacao
                type={CardSolicitacaoTypeUtils.ICON}
                renderIcon={(props) => <AccountBalanceIcon {...props} />}
                content={{
                  title: t('com.muralis.qcx.numerarioImportacao.totalSolicitado'),
                  value: NumerarioImportacaoUtils.getTotalMoedaFrom(values?.draftFields?.current),
                }}
                color="#F39200"
                loading={isPreparingAction || isPerformingAction || isLoading}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <QCXCardSolicitacao
                type={CardSolicitacaoTypeUtils.SYMBOL}
                renderSymbol={(props) => <Box {...props}>BRL</Box>}
                content={{
                  title: t('com.muralis.qcx.numerarioImportacao.totalReais'),
                  value: NumerarioImportacaoUtils.getTotalRealFrom(values?.draftFields?.current),
                }}
                color="#F39200"
                disableIcon
                loading={isPreparingAction || isPerformingAction || isLoading}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <QCXCardSolicitacao
                type={CardSolicitacaoTypeUtils.ICON}
                renderIcon={(props) => <StyleIcon {...props} />}
                content={{
                  title: t('com.muralis.qcx.numerarioImportacao.totalProcessos'),
                  value: NumerarioImportacaoUtils.getQuantidadeFrom(values),
                }}
                color="#312783"
                loading={isLoading || isPerformingAction}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <QCXCardSolicitacao
                type={CardSolicitacaoTypeUtils.ICON}
                renderIcon={(props) => <AccountBalanceIcon {...props} />}
                content={{
                  title: t('com.muralis.qcx.numerarioImportacao.totalValorProcessos'),
                  value: NumerarioImportacaoUtils.getTotalMoedaFrom(values),
                }}
                color="#312783"
                loading={isLoading || isPerformingAction}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <QCXCardSolicitacao
                type={CardSolicitacaoTypeUtils.SYMBOL}
                renderSymbol={(props) => <Box {...props}>BRL</Box>}
                content={{
                  title: t('com.muralis.qcx.numerarioImportacao.totalReais'),
                  value: NumerarioImportacaoUtils.getTotalRealFrom(values),
                }}
                color="#312783"
                disableIcon
                loading={isLoading || isPerformingAction}
              />
            </Grid>
          </Grid>
        </>
      )}
    </QCXFinalForm>
  );
}
