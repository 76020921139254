import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import { Search as PageviewIcon, Delete as DeleteIcon, Explore as ExploreIcon } from '@material-ui/icons';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import SimpleConsultPageTemplate from '../../../../../templates/simple-consult-page/SimpleConsultPageTemplate/SimpleConsultPageTemplate';
import {
  activateByIdAsync,
  inactivateByIdAsync,
  fetchPaginateAsync,
  selectEstados,
  selectEstadoCount,
  selectStatus,
  resetMode,
  resetModel,
} from '../../../../../features/estado/estadoSlice';
import { ALTERNATIVE_LOADING_STATUS } from '../../../../../features/status';
import { userHasDeleteRole } from '../../../../../components/perfil/perfil-utils';

export default function EstadoConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const history = useHistory();

  const dispatch = useDispatch();
  const estados = useSelector(selectEstados);
  const rowsCount = useSelector(selectEstadoCount);
  const status = useSelector(selectStatus);
  const isAlternativeLoading = useMemo(() => status === ALTERNATIVE_LOADING_STATUS, [status]);

  const fetchAll = useCallback(() => {
    dispatch(
      fetchPaginateAsync([
        { name: 'page', value: 1 },
        { name: 'size', value: 10 },
      ])
    );
  }, [dispatch]);

  useEffect(() => {
    fetchAll();
    dispatch(resetMode());
    dispatch(resetModel());
  }, [fetchAll, dispatch]);

  const columns = [
    {
      field: 'id',
      headerName: t('com.muralis.qcx.codigo'),
      headerAlign: 'center',
      align: 'center',
      flex: 110,
      valueGetter: ({ row }) => row?.id,
    },
    {
      field: 'descricao',
      headerName: t('com.muralis.qcx.descricao'),
      type: 'string',
      flex: 275,
      valueGetter: ({ row }) => row?.description,
    },
    {
      field: 'pais',
      headerName: 'País',
      type: 'string',
      flex: 165,
      valueGetter: ({ row }) => row?.pais?.description,
    },
    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 150,
      renderCell: ({ row: { id, active } }) => (
        <>
          {userHasDeleteRole(authInfo, 'estado-cadastro') && (
            <IconButton
              key={`stw-active${id}`}
              value={active}
              name={`stw-active-${id}`}
              onClick={(event) => handleActivateInactivate(event, id)}
            >
              <DeleteIcon color="error" />
            </IconButton>
          )}
          <IconButton
            key={`btn-view-${id}`}
            name={`btn-view-${id}`}
            onClick={(event) => handleClickOnEditButton(event, id)}
          >
            <PageviewIcon color="primary" />
          </IconButton>
        </>
      ),
    },
  ];

  const handleActivateInactivate = useCallback(
    (event, id) => {
      event.preventDefault();

      const selectedEstado = estados.find((estado) => estado.id === id);
      const isActivation = !selectedEstado.active;

      if (isActivation) {
        dispatch(activateByIdAsync(id)).then(() => {
          fetchAll();
        });
      } else {
        dispatch(inactivateByIdAsync(id)).then(() => {
          fetchAll();
        });
      }
    },
    [dispatch, estados, fetchAll]
  );

  const handleClickOnEditButton = useCallback(
    (event, id) => {
      history.push(t('com.muralis.qcx.url.moduloOperacionaisEstadoDetalhes', { id }));
    },
    [history, t]
  );

  const handleConfigChange = useCallback(
    ({ currentPage, pageSize, filterInputValue }) => {
      dispatch(
        fetchPaginateAsync([
          { name: 'page', value: currentPage },
          { name: 'size', value: pageSize },
          { name: 'descricao', value: filterInputValue },
        ])
      );
    },
    [dispatch]
  );

  return (
    <SimpleConsultPageTemplate
      pageProps={{
        title: t('com.muralis.qcx.estado.labelPlural'),
        icon: <ExploreIcon />,
        breadcrumbs: [
          { link: { to: '/', name: t('com.muralis.qcx.inicio') } },
          { text: { name: t('com.muralis.qcx.cadastros') } },
          { link: { to: t('com.muralis.qcx.url.moduloOperacionais'), name: t('com.muralis.qcx.operacionais') } },
          { text: { name: t('com.muralis.qcx.estado.labelPlural') } },
        ],
      }}
      controlProps={{
        buttonNew: { description: 'Novo Estado', path: t('com.muralis.qcx.url.moduloOperacionaisEstadoNovo') },
      }}
      tableProps={{ columns, rowsCount, onConfigChangeCallback: handleConfigChange }}
      tableData={estados}
      isLoading={isAlternativeLoading}
      filterPropGetter={(estado) => estado.descricao}
      buttonNewText={t('com.muralis.qcx.acoes.novo').toUpperCase()}
      authInfo={authInfo}
      requiredRoles={['estado-cadastro']}
    />
  );
}
