import { CircularProgress, IconButton, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
import {
  Delete as DeleteIcon,
  MoreHoriz as MoreHorizIcon,
  Search as PageviewIcon,
  Receipt as ReceiptIcon,
} from '@material-ui/icons';
import { isEmpty, isFunction } from 'lodash';
import { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { Dispatch, MouseEvent, SetStateAction, useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCalculator as FaCalculatorIcon, FaFileExcel } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import QCXMoveFocusInside from '../../../../../components/focus-lock/QCXMoveFocusInside';
import { userHasDeleteRole, userHasUpdateRole } from '../../../../../components/perfil/perfil-utils';
import QCXPopupState from '../../../../../components/popup-state/QCXPopupState';
import { setQuery } from '../../../../../features/controller/controllerSlice';
import { setErrorFeedback, setSuccessFeedback } from '../../../../../features/feedback/feedbackSlice';
import { followUpAPI } from '../../../../../features/follow-up/followUpAPI';
import { FAILURE_STATUS } from '../../../../../features/status';
import QCXConfirmDialog from '../../../../../shared-components/dialog/QCXConfirmDialog';
import QCXFinalFormDialog from '../../../../../shared-components/dialog/QCXFinalFormDialog';
import QCXListItemIcon from '../../../../../shared-components/list-item-icon/QCXListItemIcon';
import SimpleConsultPageTemplate from '../../../../../templates/simple-consult-page/SimpleConsultPageTemplate/SimpleConsultPageTemplate';

import {
  CLOSED_STATUS,
  DONE_STATUS,
  FAIL_STATUS,
  SUBMITTING_STATUS,
  isOpenStatus,
} from '../../../../../utils/hooks/form/dialog/formDialogUtils';
import useFormDialogAsync from '../../../../../utils/hooks/form/dialog/useFormDialogAsync';
import useFormDialogSync from '../../../../../utils/hooks/form/dialog/useFormDialogSync';
import { isRefresh } from '../../../../../utils/store/store-utils';
import { required } from '../../../../../utils/validators/field/validator';
import useConfigurePopUp, { ConfigurePopUpProps } from '../../../../common/hooks/popUps/useConfigurePopUp';
import { calculateById } from '../api/custeiosAPI';

import { selectCusteios, selectHistoryModel, selectRefresh, selectCusteioCount } from '../api/custeiosSelectors';

import {
  changeToConsultMode,
  changeToCreateMode,
  custeiosImportacaoActions,
  failure,
  loading,
  resetBackgroundMode,
  resetMode,
  resetModel,
  resetRefresh,
  resetSubMode,
  setError,
  setModel,
  setResponse,
  success,
  updateOnList,
} from '../api/custeiosSlice';

import {
  activateByIdAsync,
  fetchAllAsync,
  generateSpreadsheetById,
  inactivateByIdAsync,
  fetchPaginateAsync,
} from '../api/custeiosThunks';

import { Custeio } from '../custeio.types';
import QCXSelectFollowUpImportacaoAutocompleteField from './components/QCXSelectFollowUpImportacaoAutocompleteField';
import useGetApplicationState from '../../../../../features/application-state/useGetApplicationState';
import { startLoading } from '../../../../../features/application-state/applicationStateSlice';
import QCXFinalTextField from '../../../../../shared-components/final-text-field/QCXFinalTextField';
import { declaracaoImportacaoAPI } from '../../../../../features/declaracao-importacao/declaracaoImportacaoAPI';
import { setModel as setDIModel } from '../../../../../features/declaracao-importacao/declaracaoImportacaoSlice';

export default function CusteiosConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const history = useHistory();

  const dispatch = useDispatch();

  const refresh = useSelector(selectRefresh);
  const custeios = useSelector(selectCusteios);
  const historyModel = useSelector(selectHistoryModel);
  const { isLoading } = useGetApplicationState();

  const fetchAll = useCallback(() => {
    // dispatch(fetchAllAsync());
    dispatch(
      fetchPaginateAsync([
        { name: 'page', value: 1 },
        { name: 'size', value: 10 },
      ])
    );
  }, []);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
    dispatch(resetSubMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
  }, []);

  useEffect(() => {
    const checkIfIsRefresh = () => {
      if (isRefresh(refresh)) {
        fetchAll();
        dispatch(resetRefresh());
      }
    };

    checkIfIsRefresh();
  }, [refresh]);

  useEffect(() => {
    const checkIfExistsQuery = () => {
      if (!isEmpty(historyModel) && historyModel?.last?.numero) {
        dispatch(setQuery(historyModel?.last?.numero));
      }
    };

    checkIfExistsQuery();
  }, [historyModel]);

  const handleConsultarClick = useCallback(
    (event: MouseEvent<HTMLLIElement, globalThis.MouseEvent>, data: any) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        dispatch(changeToConsultMode());

        history.push(t('com.muralis.qcx.url.importacaoCusteiosDetalhes', { id: data?.id }));
      }
    },
    [history]
  );

  const popUpConfig: ConfigurePopUpProps = {
    primaryConfirmCallback: async (data: any) => {
      await dispatch(inactivateByIdAsync(data?.id));
      dispatch(
        fetchPaginateAsync([
          { name: 'page', value: 1 },
          { name: 'size', value: 10 },
        ])
      );
    },
  };

  const [handleActivateOrInactivateByIdClickMiddleware, operationOfActivateOrInactivateById] =
    useConfigurePopUp(popUpConfig);

  const handleConfigureActivateOrInactivateByIdClick = useCallback(
    (row: any, popupState: any) => {
      const { id, atributosAdicionais, active } = row;

      const handleActivateOrInactivateByIdClick = (event: MouseEvent<HTMLLIElement, globalThis.MouseEvent>) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.acoes.confirmarOperacaoExclusao').toUpperCase(),
              message: t('com.muralis.qcx.acoes.confirmacaoAcaoDI', {
                acao: active ? t('com.muralis.qcx.acoes.excluir') : t('com.muralis.qcx.acoes.recuperar'),
                numero: atributosAdicionais?.numero,
              }),
            },
          });

          const dataOfOperationActivateOrInactivate = {
            id,
            active,
          };

          handleActivateOrInactivateByIdClickMiddleware({
            onOpenCallback: configureOperation,
            args: dataOfOperationActivateOrInactivate,
          });
        }
      };

      return handleActivateOrInactivateByIdClick;
    },
    [handleActivateOrInactivateByIdClickMiddleware]
  );

  const [handleCalcularClick, formDialogCalculoStatus, handleFormDialogCalculoStatus] = useFormDialogSync(
    async (event: any, data: any) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();
      }
    },
    []
  ) as [(...args: any[]) => void, string, Dispatch<SetStateAction<string>>];

  const handleCloseFormDialogCalculo = useCallback(
    () => handleFormDialogCalculoStatus(CLOSED_STATUS),
    [handleFormDialogCalculoStatus]
  );

  const handleCalcular = useCallback(
    async (values: any) => {
      try {
        dispatch(loading());

        handleFormDialogCalculoStatus(SUBMITTING_STATUS);

        const response = await calculateById(values?.id);

        if (response.status === 200) {
          // const isNaoCalculada = isNaoCalculadaStatus(values?.calculada);
          const processo = values?.atributosAdicionais?.processo;
          // const responseMessage = (
          //   t('com.muralis.qcx.mensagem.DIEstado', { processo, estado: isNaoCalculada ? t('com.muralis.qcx.estado.calculada') : t('com.muralis.qcx.estado.recalculada') })
          // );

          const responseMessage = 'Calculado com sucesso';

          const simplifiedResponse = {
            status: response?.status,
            message: responseMessage,
            data: response?.data,
          };

          dispatch(success());
          dispatch(
            updateOnList({
              data: response?.data,
            })
          );
          dispatch(setResponse(simplifiedResponse));

          dispatch(custeiosImportacaoActions.refresh());

          handleFormDialogCalculoStatus(DONE_STATUS);

          dispatch(setSuccessFeedback({ message: responseMessage }));
        }
      } catch (error) {
        handleFormDialogCalculoStatus(FAIL_STATUS);

        const errorMessage = 'Erro ao calcular';

        dispatch(setErrorFeedback({ message: errorMessage }));

        // dispatch(failure());
        dispatch(
          setError({
            message: errorMessage,
          })
        );
        // dispatch(setResponse(
        //   error.response
        // ));
      }
    },
    [handleFormDialogCalculoStatus]
  );

  const handleGerarPlanilha = useCallback(
    (id: string | number) => {
      try {
        if (id) {
          dispatch(startLoading());
          dispatch(generateSpreadsheetById({ id }));
        }
      } catch (error) {
        console.log(error);
      }
    },
    [dispatch]
  );

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: t('com.muralis.qcx.processo'),
        headerAlign: 'center',
        align: 'center',
        flex: 140,
        valueGetter: ({ row }: { row: Custeio }) => row?.fatura?.atributosAdicionais?.processo || '-',
      },
      {
        field: 'importador',
        headerName: t('com.muralis.qcx.cliente.label'),
        flex: 180,
        valueGetter: ({ row }: { row: Custeio }) => row?.cliente?.pessoa?.nome || '-',
      },
      {
        field: 'importador.cnpj',
        headerName: t('com.muralis.qcx.empresa.CNPJ'),
        flex: 140,

        valueGetter: ({ row }: { row: Custeio }) => row?.cliente?.pessoa?.cnpj || '-',
      },
      {
        field: 'atributosAdicionais.registro',
        headerName: t('com.muralis.qcx.NDIDUIMP'),
        flex: 110,

        valueGetter: ({ row }: { row: Custeio }) => row?.fatura?.atributosAdicionais?.registro || '-',
      },
      {
        field: 'atributosAdicionais.dataRegistroSiscomex',
        headerName: t('com.muralis.qcx.dataRegistro'),
        flex: 110,
        valueGetter: ({ row }: { row: Custeio }) => row?.fatura?.atributosAdicionais?.dataRegistro || '-',
      },
      {
        field: 'atributosAdicionais.status',
        headerName: t('com.muralis.qcx.status'),
        flex: 110,
        valueGetter: ({ row }: { row: Custeio }) => row?.status || '-',
      },
      {
        field: 'actions',
        headerName: t('com.muralis.qcx.acoes.label'),
        headerAlign: 'center',
        align: 'center',
        flex: 120,
        renderCell: ({ row: { id, atributosAdicionais, active } }: { row: any }) => (
          <>
            <QCXPopupState popupId="popup-menu-follow-up">
              {(popupState: any) => (
                <>
                  <Tooltip title={t('com.muralis.qcx.maisOpcoes') as string} arrow>
                    <IconButton
                      key={`btn-more-options-${id}`}
                      name={`btn-more-options-${id}`}
                      {...bindTrigger(popupState)}
                    >
                      <MoreHorizIcon color="secondary" />
                    </IconButton>
                  </Tooltip>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);

                        handleConsultarClick(event, {
                          id,
                        });
                      }}
                    >
                      <QCXListItemIcon>
                        <PageviewIcon fontSize="small" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.consultar').toUpperCase()}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);
                        handleGerarPlanilha(id);
                      }}
                      disabled={isLoading}
                    >
                      <QCXListItemIcon>
                        {isLoading ? (
                          <CircularProgress variant="indeterminate" size={20} />
                        ) : (
                          <FaFileExcel color="green" size={20} />
                        )}
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {'Exportar planilha'}
                      </Typography>
                    </MenuItem>
                    {userHasUpdateRole(authInfo, 'importacao-custeio') && (
                      <MenuItem
                        onClick={(event) => {
                          popupState.close(event);
                          handleCalcular({
                            id,
                            atributosAdicionais,
                            active,
                          });
                        }}
                      >
                        <QCXListItemIcon>
                          <FaCalculatorIcon color="green" size={20} />
                        </QCXListItemIcon>
                        <Typography
                          variant="inherit"
                          style={{
                            fontSize: 12,
                          }}
                        >
                          {t('com.muralis.qcx.acoes.calcular').toUpperCase()}
                        </Typography>
                      </MenuItem>
                    )}
                    {userHasDeleteRole(authInfo, 'importacao-custeio') && (
                      <>
                        <MenuItem
                          onClick={handleConfigureActivateOrInactivateByIdClick(
                            {
                              id,
                              atributosAdicionais,
                              active,
                            },
                            popupState
                          )}
                        >
                          <QCXListItemIcon>
                            <DeleteIcon fontSize="small" color="error" />
                          </QCXListItemIcon>
                          <Typography
                            variant="inherit"
                            style={{
                              fontSize: 12,
                            }}
                          >
                            {t('com.muralis.qcx.acoes.excluir').toUpperCase()}
                          </Typography>
                        </MenuItem>
                      </>
                    )}
                  </Menu>
                </>
              )}
            </QCXPopupState>
          </>
        ),
      },
    ],
    [handleConsultarClick, handleConfigureActivateOrInactivateByIdClick, isLoading]
  );

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloImportacao'),
        name: t('com.muralis.qcx.importacao.label'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.custeios.labelPlural'),
      },
      default: true,
    },
  ];

  const [handleButtonNewClick, formDialogNewStatus, handleFormDialogNewStatus] = useFormDialogAsync(
    async (event: MouseEvent<HTMLLIElement, globalThis.MouseEvent>) => {
      event.stopPropagation();

      dispatch(changeToCreateMode());

      history.push(t('com.muralis.qcx.url.importacaoCusteiosNovo'));
    },
    [history]
  ) as [(...args: any[]) => void, string, Dispatch<SetStateAction<string>>];

  const handleCloseFormDialogNew = useCallback(
    () => handleFormDialogNewStatus(CLOSED_STATUS),
    [handleFormDialogNewStatus]
  );

  const isFormDialogNewOpen = useMemo(() => isOpenStatus(formDialogNewStatus), [formDialogNewStatus]);

  const handleProcessoNewSubmit = useCallback(
    async (values: any) => {
      try {
        dispatch(loading());

        const response = await followUpAPI.fetchByFilter([{ name: 'processo', value: values?.numeroProcesso }]);

        const followUp = response?.data?.[0];

        if (!followUp) {
          dispatch(setErrorFeedback({ message: 'Processo não encontrado.' }));
          return;
        }

        const diResponse = await declaracaoImportacaoAPI.fetchProcessoCusteio([
          { name: 'followUpId', value: followUp.id },
        ]);

        if (diResponse?.status === 200 && diResponse?.data) {
          dispatch(setDIModel(diResponse.data));
        }

        if (response?.status === 200 && followUp) {
          dispatch(setModel({ followUp }));

          handleFormDialogNewStatus(DONE_STATUS);
        }
      } catch (error: any) {
        handleFormDialogNewStatus(FAILURE_STATUS);

        let errorMessage = t('com.muralis.qcx.erro.erroCarregarProcesso');
        if (error?.response && error?.response?.data) {
          errorMessage = t('com.muralis.qcx.erro.erroCarregarProcessoEspecifico', {
            erro: error?.response?.data?.message,
          });
        }

        dispatch(failure());
        dispatch(
          setError({
            message: errorMessage,
          })
        );
      }
    },
    [handleFormDialogNewStatus]
  );

  interface ConfigChangeParams {
    currentPage: number;
    pageSize: number;
    filterInputValue: string;
  }

  const handleConfigChange = useCallback(({ currentPage, pageSize, filterInputValue }: ConfigChangeParams) => {
    dispatch(
      fetchPaginateAsync([
        { name: 'page', value: currentPage },
        { name: 'size', value: pageSize },
        { name: 'numero', value: filterInputValue },
      ])
    );
  }, []);

  const rowsCount = useSelector(selectCusteioCount);

  const templateProps = {
    page: {
      title: t('com.muralis.qcx.custeios.labelPlural'),
      icon: <ReceiptIcon />,
      breadcrumbs,
    },
    control: {
      buttonNew: {
        description: t('com.muralis.qcx.followup.novoRegistroFollowUp'),
        onClick: handleButtonNewClick,
      },
    },
    table: {
      columns,
      rowsCount,
      onConfigChangeCallback: handleConfigChange,
    },
  };

  return (
    <SimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={custeios}
      isLoading={isLoading}
      authInfo={authInfo}
      requiredRoles={['importacao-follow-up']}
    >
      <QCXFinalFormDialog
        key="form-dialog-processo-registro-manual-di-duimp"
        id="form-dialog-processo-registro-manual-di-duimp"
        title="Novo Registro de Custeio"
        content={t('com.muralis.qcx.preencherCampoContinuar')}
        dialogActionsProps={{
          button: {
            submit: {
              description: t('com.muralis.qcx.acoes.continuar'),
              size: 'small',
            },
            cancel: {
              description: t('com.muralis.qcx.acoes.cancelar'),
              size: 'small',
            },
          },
        }}
        open={isFormDialogNewOpen}
        initialValues={{}}
        onSubmit={handleProcessoNewSubmit}
        onClose={handleCloseFormDialogNew}
      >
        {() => (
          <QCXMoveFocusInside>
            <QCXFinalTextField
              id="text-field-numero-processo"
              key="text-field-numero-processo"
              name="numeroProcesso"
              label={t('com.muralis.qcx.numeroProcesso')}
              required
            />
          </QCXMoveFocusInside>
        )}
      </QCXFinalFormDialog>
      <QCXConfirmDialog
        key="confirm-dialog-activate-inactivate-follow-up"
        id="confirm-dialog-activate-inactivate-follow-up"
        open={operationOfActivateOrInactivateById?.isPopUpOpen}
        title={operationOfActivateOrInactivateById?.title}
        content={operationOfActivateOrInactivateById?.message}
        endContent={operationOfActivateOrInactivateById?.endMessage}
        onConfirm={operationOfActivateOrInactivateById.confirmPrimaryAction}
        onClose={operationOfActivateOrInactivateById?.reset}
        renderMiddleMessage={undefined}
        onSecondaryConfirm={undefined}
      />
    </SimpleConsultPageTemplate>
  );
}
