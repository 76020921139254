import { Grid, makeStyles } from '@material-ui/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import QCXFormTitle from '../../../../../components/form-title/QCXFormTitle';
import QCXFinalAlternativeCurrencyField from '../../../../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXFinalDateTimePickerField from '../../../../../shared-components/final-date-time-picker-field/QCXFinalDateTimePickerField';
import QCXFinalForm from '../../../../../shared-components/final-form/QCXFinalForm';
import QCXSelectMoedaAutocomplete from '../../../../../shared-components/final-select-moeda-field/QCXSelectMoedaAutocomplete';
import QCXSelectContaBancariaAutocompleteField from '../../../../../shared-components/select-conta-bancaria/QCXSelectContaBancariaAutocompleteField';
import QCXSelectDespesaReceitaAutocomplete from '../../../../../shared-components/select-despesa-receita/QCXSelectDespesaReceitaAutocomplete';
import { getCurrentDate } from '../../../../../utils/general/general-utils';
import { OperacaoMovimentacaoUtils } from '../../../../../utils/general/movimentacao/movimentacaoUtils';
import SolicitacaoPagamentoUtils from '../../../../../utils/general/solicitacao-pagamento/SolicitacaoPagamentoUtils';
import { required as requiredValidator } from '../../../../../utils/validators/field/validator';

const useStyles = makeStyles(() => ({
  gridFields: {
    paddingBottom: '16px',
  },
  subtitle: {
    margin: '20px 0',
  },
}));

export default function QCXConciliacaoBancariaFinanceiroForm({
  isCreate,
  isConsult,
  isUpdate,
  initialValues,
  handleSubmit,
}) {
  const { t } = useTranslation();

  const classes = useStyles();

  const internalInitialValues = useMemo(
    () =>
      isCreate
        ? {
            ...initialValues,
            dataSolicitacao: getCurrentDate().toDate(),
            tipo: OperacaoMovimentacaoUtils.ENTRADA,
            status: SolicitacaoPagamentoUtils.SOLICITACAO_PENDENTE,
          }
        : initialValues,
    [isCreate, initialValues]
  );

  const configureDespesaReceitaFilterPredicateWith = useMemo(
    () => [
      {
        name: 'credito',
        value: 'CREDITO',
      },
    ],
    [initialValues]
  );

  return (
    <QCXFinalForm
      isConsult={isConsult}
      isUpdate={isUpdate}
      initialValues={internalInitialValues}
      handleSubmitValues={handleSubmit}
    >
      {({ form, values }) => (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <Grid item className={classes.gridFields} xs={12} sm={6} md={4}>
                <QCXFinalDateTimePickerField
                  id="date-picker-data-solicitacao-field"
                  key="date-picker-data-solicitacao-field"
                  name="dataSolicitacao"
                  label={t('com.muralis.qcx.numerarioImportacao.dataSolicitacao')}
                  disabled
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <QCXFormTitle
                title={t('com.muralis.qcx.despesa.labelPlural')}
                boxProps={{
                  pt: 1,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <QCXSelectDespesaReceitaAutocomplete
                id="autocomplete-select-field-despesa"
                key="autocomplete-select-field-despesa"
                name="despesaReceita.id"
                label={t('com.muralis.qcx.despesa.labelPlural')}
                initialValues={values}
                disabled={isConsult}
                fieldProps={{ validate: requiredValidator }}
                filterParams={configureDespesaReceitaFilterPredicateWith}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <QCXSelectMoedaAutocomplete
                id="select-field-moeda-seguro"
                key="select-field-moeda-seguro"
                name="moeda.id"
                label={t('com.muralis.qcx.moeda.label')}
                disabled={isConsult}
                initialValues={values}
                fieldProps={{ validate: requiredValidator }}
                defaultCode={SolicitacaoPagamentoUtils.isAdministrativa(values?.tipo) ? '790' : undefined}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <QCXSelectContaBancariaAutocompleteField
                id="autocomplete-select-conta-bancaria-pagamento-field"
                key="autocomplete-select-conta-bancaria-pagamento-field"
                name="contaBancaria"
                label={t('com.muralis.qcx.conta.contaCorrente')}
                initialValues={values}
                disabled={isConsult}
                fieldProps={{ validate: requiredValidator }}
                filtraControlaSaldo={SolicitacaoPagamentoUtils.isAdministrativa(values?.tipo)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <QCXFinalAlternativeCurrencyField
                id="financeiro-valor-receber-field"
                key="financeiro-valor-receber-field"
                name="valorAReceber"
                label={t('com.muralis.qcx.valorReceber')}
                disabled={isConsult}
                required
              />
            </Grid>
          </Grid>
        </>
      )}
    </QCXFinalForm>
  );
}
