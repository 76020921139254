import { ReponseType, validateQueryParams } from '../../utils/api/api-utils';
import { doGetQCXRequest, doPostQCXRequest, doPutQCXRequest } from '../../utils/api/domain/domain-api-utils';
import {
  QCX_FATURAMENTO_ENDPOINT,
  FOLLOW_UP_ENDPOINT,
  IMPORTADOR_ENDPOINT,
  FATURAR_ENDPOINT,
  QCX_CANCELAR_ENDPOINT,
  QCX_RELATORIO_ENDPOINT,
  DEMONSTRACAO_DESPESA_ENDPOINT,
  EXTRATO_ENDPOINT,
  TRANSFERIR_ENDPOINT,
  EMITIR_ENDPOINT,
  NFSE_ENDPOINT,
  CONSULTAR_NFSE_ENDPOINT,
} from '../../utils/api/domain/domain-endpoint-utils';

async function fetchAll() {
  return doGetQCXRequest([QCX_FATURAMENTO_ENDPOINT]);
}

async function fetchAllReduzido() {
  return doGetQCXRequest([QCX_FATURAMENTO_ENDPOINT, 'fetch', 'reduzido']);
}

async function fetchById(id) {
  return doGetQCXRequest([QCX_FATURAMENTO_ENDPOINT, id]);
}

async function register(data, queryParams = [], config = {}) {
  return doPostQCXRequest([QCX_FATURAMENTO_ENDPOINT], data, queryParams, config);
}

async function save(data, queryParams = [], config = {}) {
  return doPutQCXRequest([QCX_FATURAMENTO_ENDPOINT], data, queryParams, config);
}

async function filterFollowUpsBy(data, query = []) {
  if (!data?.importador?.id) {
    return Promise.reject();
  }
  return doGetQCXRequest(
    [QCX_FATURAMENTO_ENDPOINT, FOLLOW_UP_ENDPOINT, IMPORTADOR_ENDPOINT, data?.importador?.id],
    query
  );
}

async function faturar(id, data, queryParams = [], config = {}) {
  return doPutQCXRequest([QCX_FATURAMENTO_ENDPOINT, FATURAR_ENDPOINT, id], data, queryParams, config);
}

async function calcular(id, data, queryParams = [], config = {}) {
  return doPutQCXRequest([QCX_FATURAMENTO_ENDPOINT, 'calcular', id], data, queryParams, config);
}

async function cancelarById(id, config = {}) {
  return doPutQCXRequest([QCX_FATURAMENTO_ENDPOINT, QCX_CANCELAR_ENDPOINT, id], {}, [], config);
}

async function generateDemonstrativoReportById(id, params) {
  return doGetQCXRequest(
    [QCX_FATURAMENTO_ENDPOINT, QCX_RELATORIO_ENDPOINT, DEMONSTRACAO_DESPESA_ENDPOINT, id],
    params,
    {
      responseType: ReponseType.ARRAY_BUFFER,
    }
  );
}

async function generatePrestacaoReportById(id, params) {
  return doGetQCXRequest([QCX_FATURAMENTO_ENDPOINT, QCX_RELATORIO_ENDPOINT, 'prestacaocontas', id], params, {
    responseType: ReponseType.ARRAY_BUFFER,
  });
}

async function generateReportExtratoContaCorrente(params = []) {
  const validatedParams = validateQueryParams(params);
  if (validatedParams.length) {
    return doGetQCXRequest([QCX_FATURAMENTO_ENDPOINT, QCX_RELATORIO_ENDPOINT, EXTRATO_ENDPOINT], validatedParams, {
      responseType: 'blob',
    });
  }
  return { data: [] };
}

async function transferirSaldo(data, queryParams = [], config = {}) {
  return doPutQCXRequest([QCX_FATURAMENTO_ENDPOINT, TRANSFERIR_ENDPOINT], data, queryParams, config);
}

async function emitirNFSeById(id) {
  return doGetQCXRequest([QCX_FATURAMENTO_ENDPOINT, EMITIR_ENDPOINT, NFSE_ENDPOINT, id]);
}

async function consultarNFSeByFaturamentoId(id) {
  return doGetQCXRequest([QCX_FATURAMENTO_ENDPOINT, 'consultar-nfse', id]);
}

async function baixarPDFByNFSeIdPlugNotas(id) {
  return doGetQCXRequest([QCX_FATURAMENTO_ENDPOINT, NFSE_ENDPOINT, 'baixar-pdf', id], [], { responseType: 'blob' });
}

async function baixarXMLByNFSeIdPlugNotas(id) {
  return doGetQCXRequest([QCX_FATURAMENTO_ENDPOINT, NFSE_ENDPOINT, 'baixar-xml', id], [], { responseType: 'blob' });
}

async function cancelarNFSeByIdPlugNotas(id) {
  return doGetQCXRequest([QCX_FATURAMENTO_ENDPOINT, NFSE_ENDPOINT, 'cancelar', id]);
}

async function reabrir(id) {
  return doPostQCXRequest([QCX_FATURAMENTO_ENDPOINT, 'reabrir', id]);
}

async function buscarNovosLancamentos(id, capaId) {
  return doPostQCXRequest(
    [QCX_FATURAMENTO_ENDPOINT, 'busca-novos-lancamentos', id],
    [],
    [{ name: 'faturamentoCapaId', value: capaId }]
  );
}

async function removeDespesa(id) {
  return doGetQCXRequest([QCX_FATURAMENTO_ENDPOINT, 'remove-despesa', id]);
}

const faturamentoAPI = {
  fetchAll,
  fetchById,
  save,
  register,
  filterFollowUpsBy,
  faturar,
  calcular,
  cancelarById,
  generateDemonstrativoReportById,
  generatePrestacaoReportById,
  generateReportExtratoContaCorrente,
  transferirSaldo,
  emitirNFSeById,
  consultarNFSeByFaturamentoId,
  baixarPDFByNFSeIdPlugNotas,
  baixarXMLByNFSeIdPlugNotas,
  cancelarNFSeByIdPlugNotas,
  reabrir,
  buscarNovosLancamentos,
  fetchAllReduzido,
  removeDespesa,
};

export {
  faturamentoAPI,
  fetchAll,
  fetchById,
  filterFollowUpsBy,
  save,
  register,
  faturar,
  calcular,
  cancelarById,
  generateDemonstrativoReportById,
  generatePrestacaoReportById,
  generateReportExtratoContaCorrente,
  transferirSaldo,
  emitirNFSeById,
  consultarNFSeByFaturamentoId,
  baixarPDFByNFSeIdPlugNotas,
  baixarXMLByNFSeIdPlugNotas,
  cancelarNFSeByIdPlugNotas,
  reabrir,
  buscarNovosLancamentos,
  fetchAllReduzido,
  removeDespesa,
};
