import _, { isFunction, isEmpty } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FaCalculator as FaCalculatorIcon } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { IconButton } from '@material-ui/core';
import {
  Search as PageviewIcon,
  Receipt as ReceiptIcon,
  MoreHoriz as MoreHorizIcon,
  Block as BlockIcon,
  Web as WebIcon,
  RotateLeft as RotateLeftIcon,
} from '@material-ui/icons';
import {
  Summarize as SummarizeIcon,
  TurnSlightRight as TurnSlightRightIcon,
  ReceiptLong as ReceiptLongIcon,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { bindTrigger, bindMenu } from 'material-ui-popup-state';
import axios from 'axios';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { isAlternativeLoadingStatus, isRefresh } from '../../../../utils/store/store-utils';
import { setQuery } from '../../../../features/controller/controllerSlice';
import { SUBMITTING_STATUS, FAIL_STATUS, DONE_STATUS } from '../../../../utils/hooks/form/dialog/formDialogUtils';
import {
  generateReportExtratoContaCorrenteAsync,
  consultarNFSeByFaturamentoIdAsync,
  fetchAllReduzidoAsync,
} from '../../../../features/faturamento/faturamentoThunks';
import {
  failure,
  success,
  loading,
  changeToCreateMode,
  resetBackgroundMode,
  resetHistoryModel,
  resetMode,
  resetModel,
  resetRefresh,
  resetSubMode,
  refresh as refreshAction,
  changeToUpdateMode,
  changeToBackgroundUpdateMode,
  setRelatedReportModel,
} from '../../../../features/faturamento/faturamentoSlice';
import {
  selectRefresh,
  selectHistoryModel,
  selectFaturamentosReduzidos,
} from '../../../../features/faturamento/faturamentoSelectors';
import { setErrorFeedback, setSuccessFeedback, setWarningFeedback } from '../../../../features/feedback/feedbackSlice';
import useFormDialogSync from '../../../../utils/hooks/form/dialog/useFormDialogSync';
import useOperationMiddleware from '../../../../utils/hooks/operation/middleware/useOperationMiddleware';
import FaturamentoUtils from '../../../../utils/general/faturamento/FaturamentoUtils';
import QCXSimpleConsultPageTemplate from '../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import FaturamentoContext from '../../../../contexts/financial/faturamento/FaturamentoContext';
import QCXListItemIcon from '../../../../shared-components/list-item-icon/QCXListItemIcon';
import QCXPopupState from '../../../../components/popup-state/QCXPopupState';
import QCXConfirmDialog from '../../../../shared-components/dialog/QCXConfirmDialog';
import QCXRelatorioFaturamentoDialogForm from '../../../../components/faturamento/QCXRelatorioFaturamentoDialogForm';
import QCXFaturamentoTransferirSaldoDialogForm from '../../../../components/faturamento/QCXFaturamentoTransferirSaldoDialogForm';
import { formatDate, unnormalizeNumeral, normalizeData } from '../../../../utils/general/general-utils';
import { faturamentoAPI } from '../../../../features/faturamento/faturamentoAPI';
import useGenericDownloader from '../../../../utils/hooks/downloader/useGenericDownloader';
import followUpDestinoFaturamentoThunks from '../../../../features/follow-up-destino-faturamento/followUpDestinoFaturamentoThunks';
import followUpOrigemFaturamentoThunks from '../../../../features/follow-up-origem-faturamento/followUpOrigemFaturamentoThunks';
import { formatBrazilianNumericDecimal } from '../../../../utils/hooks/form/field/formatters';
import FaturamentoNfseListaDialog from './components/FaturamentoNfseListaDialog';
import linguagemUtils from '../../../../utils/general/report/linguagemUtils';

export default function FaturamentoConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const history = useHistory();

  const { status } = useContext(FaturamentoContext);

  const dispatch = useDispatch();

  const refresh = useSelector(selectRefresh);
  const historyModel = useSelector(selectHistoryModel);
  const faturamentosReduzidos = useSelector(selectFaturamentosReduzidos);

  const [isNfseDialogOpen, setIsNfseDialogOpen] = useState(false);
  const [selectedFaturamentoId, setSelectedFaturamentoId] = useState(null);
  const selectedFaturamento = faturamentosReduzidos.find((f) => f.id === selectedFaturamentoId);

  const genericDownloader = useGenericDownloader({
    autoDownload: true,
  });

  const isAlternativeLoading = useMemo(() => isAlternativeLoadingStatus(status), [status]);

  const fetchAll = useCallback(() => {
    dispatch(fetchAllReduzidoAsync());
  }, []);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
    dispatch(resetSubMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
    dispatch(resetHistoryModel());
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
  }, []);

  useEffect(() => {
    const checkIfIsRefresh = () => {
      if (isRefresh(refresh)) {
        fetchAll();
        dispatch(resetRefresh());
      }
    };

    checkIfIsRefresh();
  }, [refresh]);

  useEffect(() => {
    const checkIfExistsQuery = () => {
      if (!isEmpty(historyModel) && historyModel?.last?.processo) {
        dispatch(setQuery(historyModel?.last?.processo));
      }
    };

    checkIfExistsQuery();
  }, [historyModel]);

  const handleConsultarClick = useCallback(
    (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        // configureDependencies();

        dispatch(changeToUpdateMode());
        dispatch(changeToBackgroundUpdateMode());

        history.push(t('com.muralis.qcx.url.financeiroFaturamentoDetalhes', { id: data?.id }));
      }
    },
    [
      // configureDependencies,
      history,
      t,
    ]
  );

  const [handleDemonstrativoMiddleware, operationOfDemonstrativo] = useOperationMiddleware(async (data) => {
    const fetcher = async () => {
      dispatch(loading());

      const response = await faturamentoAPI.generateDemonstrativoReportById(data?.id, [
        { name: 'linguagem', value: data?.linguagem },
      ]);

      return response;
    };

    const handlers = {
      onSuccess: () => {
        dispatch(success());

        const successMessage = t('com.muralis.qcx.mensagem.relatorioDemonstrativoDespesaGerado', {
          numero: FaturamentoUtils.formatProcessosCapasReduzido(data?.capas) || '-',
        });

        dispatch(
          setSuccessFeedback({
            message: successMessage,
          })
        );

        dispatch(refreshAction());

        dispatch(setQuery(FaturamentoUtils.formatProcessosCapasReduzido(data?.capas) || '-'));
      },
      onNoContent: () => {
        dispatch(failure());

        const infoMessage = t('com.muralis.qcx.mensagem.relatorioDemonstrativoDespesaNaoFoiGerado', {
          numero: FaturamentoUtils.formatProcessosCapasReduzido(data?.capas) || '-',
        });

        dispatch(
          setErrorFeedback({
            message: infoMessage,
          })
        );
      },
      onError: (reason) => {
        dispatch(failure());

        const errorMessage = t('com.muralis.qcx.erro.erroGerarRelatorioDemonstrativoDespesa', {
          numero: FaturamentoUtils.formatProcessosCapasReduzido(data?.capas) || '-',
          mensagem: reason?.response?.data?.message || '',
        });

        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
      },
    };

    genericDownloader.downloadFileById(fetcher, handlers);
  }, []);

  const [handlePrestacaoMiddleware, operationOfPretacao] = useOperationMiddleware(async (data) => {
    const fetcher = async () => {
      dispatch(loading());

      const response = await faturamentoAPI.generatePrestacaoReportById(data?.id);

      return response;
    };

    const handlers = {
      onSuccess: () => {
        dispatch(success());

        const successMessage = t('com.muralis.qcx.mensagem.relatorioPrestacaoContasGerado', {
          numero: FaturamentoUtils.formatProcessosCapasReduzido(data?.capas) || '-',
        });

        dispatch(
          setSuccessFeedback({
            message: successMessage,
          })
        );

        dispatch(refreshAction());

        dispatch(setQuery(FaturamentoUtils.formatProcessosCapasReduzido(data?.capas) || '-'));
      },
      onNoContent: () => {
        dispatch(failure());

        const infoMessage = t('com.muralis.qcx.mensagem.relatorioPrestacaoContasNaoFoiGerado', {
          numero: FaturamentoUtils.formatProcessosCapasReduzido(data?.capas) || '-',
        });

        dispatch(
          setErrorFeedback({
            message: infoMessage,
          })
        );
      },
      onError: (reason) => {
        dispatch(failure());

        const errorMessage = t('com.muralis.qcx.erro.erroGerarRelatorioPrestacaoContas', {
          numero: FaturamentoUtils.formatProcessosCapasReduzido(data?.capas) || '-',
          mensagem: reason?.response?.data?.message || '',
        });

        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
      },
    };

    genericDownloader.downloadFileById(fetcher, handlers);
  }, []);

  const handleDemonstrativoClick = useCallback(
    (row, popupState, linguagem) => {
      const { id, capas } = row;

      const handleGenerateDemonstrativoClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.emitirDemonstrativo'),
              message: t('com.muralis.qcx.mensagem.confirmarOperacaoEmitirDemonstrativo'),
            },
          });

          const dataOfOperationDemonstrativo = {
            id,
            linguagem,
            capas,
          };

          handleDemonstrativoMiddleware(configureOperation, dataOfOperationDemonstrativo);
        }
      };

      return handleGenerateDemonstrativoClick;
    },
    [handleDemonstrativoMiddleware]
  );

  const handlePrestacaoClick = useCallback(
    (row, popupState) => {
      const { id, capas } = row;

      const handleGeneratePrestacaoClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.emitirPrestacaoContas'),
              message: t('com.muralis.qcx.mensagem.confirmarOperacaoEmitirPrestacaoContas'),
            },
          });

          const dataOfOperationDemonstrativo = {
            id,
            capas,
          };

          handlePrestacaoMiddleware(configureOperation, dataOfOperationDemonstrativo);
        }
      };

      return handleGeneratePrestacaoClick;
    },
    [handleDemonstrativoMiddleware]
  );

  const [handleCancelarMiddleware, operationOfCancelarFaturamento] = useOperationMiddleware(async (data) => {
    try {
      dispatch(loading());

      const responseOfCancelarFaturamento = await faturamentoAPI.cancelarById(data?.id);

      if (responseOfCancelarFaturamento?.status === 200) {
        const message = t('com.muralis.qcx.mensagem.faturamentoCancelado');

        dispatch(success());
        dispatch(setSuccessFeedback({ message }));
        dispatch(refreshAction());
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error?.message || '';

      const message = t('com.muralis.qcx.erro.erroCancelarFaturamento', {
        erro: errorMessage,
      });

      dispatch(failure());
      dispatch(setErrorFeedback({ message }));
    }
  }, []);

  const handleCancelarFaturamentoClick = useCallback(
    (row, popupState) => {
      const { id, capas } = row;

      const handleCancelarClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.confirmarOperacaoCancelamento'),
              message: t('com.muralis.qcx.mensagem.confirmarOperacaoCancelarFaturamento', {
                numero: FaturamentoUtils.formatProcessosCapasReduzido(capas) || '-',
              }),
              endMessage: t('com.muralis.qcx.mensagem.certezaDesejaCancelar'),
            },
          });

          const dataOfOperationCancelar = {
            id,
          };

          handleCancelarMiddleware(configureOperation, dataOfOperationCancelar);
        }
      };

      return handleCancelarClick;
    },
    [handleCancelarMiddleware]
  );

  const [handleNFSeMiddleware, operationOfNFSe] = useOperationMiddleware(async (data) => {
    try {
      dispatch(loading());

      const responseOfEmitirFaturamento = await faturamentoAPI.emitirNFSeById(data?.id);

      if (responseOfEmitirFaturamento?.status === 200) {
        const message = t('com.muralis.qcx.mensagem.nfseEmitida');

        dispatch(success());
        dispatch(setSuccessFeedback({ message }));
        dispatch(refreshAction());
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error?.message || '';

      const message = t('com.muralis.qcx.erro.erroEmitirNFSe', {
        erro: `${errorMessage}.`,
      });

      dispatch(failure());
      dispatch(setErrorFeedback({ message }));
    }
  }, []);

  const handleNFSeClick = useCallback(
    (row, popupState) => {
      const { id } = row;

      const handleGenerateNFSeClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.emitirNFSe'),
              message: t('com.muralis.qcx.mensagem.confirmarOperacaoEmitirNFSe'),
            },
          });

          const dataOfOperationEmitirNFSe = {
            id,
          };

          handleNFSeMiddleware(configureOperation, dataOfOperationEmitirNFSe);
        }
      };

      return handleGenerateNFSeClick;
    },
    [handleNFSeMiddleware]
  );

  const handleBuscarLancamentosClick = useCallback(async (id) => {
    dispatch(loading());

    try {
      const response = await faturamentoAPI.buscarNovosLancamentos(id);

      if (response.status > 400) {
        dispatch(
          setErrorFeedback({
            message: 'Houve um erro ao trazer despesas',
          })
        );
      }
    } catch (err) {
      dispatch(
        setErrorFeedback({
          message: 'Houve um erro ao trazer despesas',
        })
      );
    } finally {
      dispatch(success());
    }
  }, []);

  const [
    handleOpenTransferencia,
    formDialogTransferenciaStatus,
    handleFormDialogTransferenciaStatus,
    // eslint-disable-next-line no-unused-vars
  ] = useFormDialogSync(async (event, data) => {
    if (_.isFunction(event?.stopPropagation)) {
      event.stopPropagation();
    }
  }, []);

  const handleTransferenciaSubmit = useCallback(
    async (event) => {
      try {
        event?.preventDefault?.();
        event?.stopPropagation?.();

        dispatch(loading());
        handleFormDialogTransferenciaStatus(SUBMITTING_STATUS);

        const response = await faturamentoAPI.transferirSaldo({
          origem: {
            id: event?.origem?.id,
          },
          destino: {
            id: event?.destino?.id,
          },
        });

        if (response?.status === 200) {
          const feedbackMessage = t('com.muralis.qcx.mensagem.saldoTransferidoSucesso');

          handleFormDialogTransferenciaStatus(DONE_STATUS);

          dispatch(success());

          dispatch(
            setSuccessFeedback({
              message: feedbackMessage,
            })
          );

          dispatch(refreshAction());
        }
      } catch (error) {
        const errorMessage = error?.message
          ? t('com.muralis.qcx.erro.erroTransferirSaldoEspecifico', { erro: error?.response?.data?.message })
          : t('com.muralis.qcx.erro.erroTransferirSaldo');

        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );

        dispatch(failure());
        handleFormDialogTransferenciaStatus(FAIL_STATUS);
      }
    },
    [handleFormDialogTransferenciaStatus]
  );

  const [handleFaturarMiddleware, operationOfFaturar] = useOperationMiddleware(async (data) => {
    try {
      dispatch(loading());

      const responseOfFaturarFaturamento = await faturamentoAPI.faturar(data?.id);

      if (responseOfFaturarFaturamento?.status === 200) {
        const message = t('com.muralis.qcx.mensagem.faturamentoRealizado');

        dispatch(success());
        dispatch(setSuccessFeedback({ message }));
        dispatch(refreshAction());
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error?.message || '';

      const message = t('com.muralis.qcx.erro.erroFaturarFaturamento', {
        erro: errorMessage,
      });

      dispatch(failure());
      dispatch(setErrorFeedback({ message }));
    }
  }, []);

  const [handleReabrirMiddleware, operationOfReabrir] = useOperationMiddleware(async (data) => {
    try {
      dispatch(loading());

      const responseOfReabrirFaturamento = await faturamentoAPI.reabrir(data?.id);

      if (responseOfReabrirFaturamento?.status === 204) {
        const message = t('com.muralis.qcx.mensagem.faturamentoReaberto');

        dispatch(success());
        dispatch(setSuccessFeedback({ message }));
        dispatch(refreshAction());
      }
    } catch (error) {
      const errorMessage = error?.response?.data || error?.response?.data?.message || error?.message || '';

      const message = t('com.muralis.qcx.erro.erroReabrirFaturamento', {
        erro: errorMessage,
      });

      dispatch(failure());
      dispatch(setErrorFeedback({ message }));
    }
  }, []);

  const handleFaturarClick = useCallback(
    (row, popupState) => {
      const { id, capas } = row;

      const handleGenerateFaturarClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.acoes.confirmarOperacaoFaturar'),
              message: t('com.muralis.qcx.mensagem.confirmarOperacaoFaturarFaturamento', {
                numero: FaturamentoUtils.formatProcessosCapasReduzido(capas) || '-',
              }),
            },
          });

          const dataOfOperationFaturar = {
            id,
          };

          handleFaturarMiddleware(configureOperation, dataOfOperationFaturar);
        }
      };

      return handleGenerateFaturarClick;
    },
    [handleFaturarMiddleware]
  );

  const handleReabrirClick = useCallback((row, popupState) => {
    const { id, capas } = row;

    const handleGenerateReabrirClick = (event) => {
      if (isFunction(event?.stopPropagation)) {
        popupState.close(event);

        event.stopPropagation();

        const configureOperation = () => ({
          options: {
            title: t('com.muralis.qcx.acoes.confirmarOperacaoFaturar'),
            message: t('com.muralis.qcx.mensagem.confirmarOperacaoReabrirFaturamento', {
              numero: FaturamentoUtils.formatProcessosCapasReduzido(capas) || '-',
            }),
          },
        });

        const dataOfOperationReabrir = {
          id,
        };

        handleReabrirMiddleware(configureOperation, dataOfOperationReabrir);
      }
    };

    return handleGenerateReabrirClick;
  }, []);

  const [handleCalcularMiddleware, operationOfCalcular] = useOperationMiddleware(async (data) => {
    try {
      dispatch(loading());

      const responseOfCalcularFaturamento = await faturamentoAPI.calcular(data?.id);

      if (responseOfCalcularFaturamento?.status === 200) {
        const message = t('com.muralis.qcx.mensagem.calculoRealizado');

        dispatch(success());
        dispatch(setSuccessFeedback({ message }));
        dispatch(refreshAction());
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error?.message || '';

      const message = t('com.muralis.qcx.erro.erroCalcularFaturamento', {
        erro: errorMessage,
      });

      dispatch(failure());
      dispatch(setErrorFeedback({ message }));
    }
  }, []);

  const handleCalcularClick = useCallback(
    (row, popupState) => {
      const { id, capas } = row;

      const handleGenerateCalcularClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.acoes.confirmarOperacaoCalcular'),
              message: t('com.muralis.qcx.mensagem.confirmarOperacaoCalcularFaturamento', {
                numero: FaturamentoUtils.formatProcessosCapasReduzido(capas) || '-',
              }),
            },
          });

          const dataOfOperationCalcular = {
            id,
          };

          handleCalcularMiddleware(configureOperation, dataOfOperationCalcular);
        }
      };

      return handleGenerateCalcularClick;
    },
    [handleCalcularMiddleware]
  );

  const handleButtonNewClick = useCallback(
    (event) => {
      event.stopPropagation();

      dispatch(changeToCreateMode());

      history.push(t('com.muralis.qcx.url.financeiraFaturamentoNovo'));
    },
    [history]
  );

  const [handleOpenRelatorioFaturamento, formDialogRelatorioStatus, handleFormDialogRelatorioStatus] =
    useFormDialogSync(async (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        dispatch(
          setRelatedReportModel({
            ...data,
          })
        );
      }
    }, []);

  const handleRelatorioFaturamentoSubmit = async (event) => {
    event?.preventDefault?.();
    event?.stopPropagation?.();

    const datainicial = normalizeData(event?.vencimentoDe);
    const datafinal = normalizeData(event?.vencimentoAte);
    const followupid = event?.followUp?.id;
    const clienteid = event?.cliente?.id;

    const handlers = {
      onNoContent: () => {
        dispatch(failure());

        const infoMessage = 'Preencha todos os campos marcados com * para gerar o relatório.';

        dispatch(
          setWarningFeedback({
            message: infoMessage,
          })
        );
      },
      onError: () => {
        dispatch(failure());

        const errorMessage = t('com.muralis.qcx.mensagem.naoFoiPossivelGerarRelatorioContas', {
          tipoRelatorio: t('com.muralis.qcx.financeiro.faturamento'),
        });

        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
      },
    };

    dispatch(
      generateReportExtratoContaCorrenteAsync({
        data: [
          {
            name: 'datainicial',
            value: datainicial,
          },
          {
            name: 'datafinal',
            value: datafinal,
          },
          {
            name: 'followupid',
            value: followupid,
          },
          {
            name: 'clienteid',
            value: clienteid,
          },
        ],
        handlers,
      })
    );

    handleFormDialogRelatorioStatus(DONE_STATUS);
  };

  const handleOpenNFSeDialogClick = useCallback((row, popupState) => (event) => {
    event.stopPropagation();
    popupState.close(event);

    setIsNfseDialogOpen(true);
    setSelectedFaturamentoId(row.id);
  });

  const handleConsultNFSeClick = useCallback(async () => {
    if (!selectedFaturamentoId) return;

    dispatch(consultarNFSeByFaturamentoIdAsync(selectedFaturamentoId));
  }, [selectedFaturamentoId]);

  const baixarNFSePdf = useCallback(async (nfseId) => {
    try {
      dispatch(loading());

      const response = await faturamentoAPI.baixarPDFByNFSeIdPlugNotas(nfseId);

      const file = new File([response.data], `${nfseId}.pdf`, { type: 'application/pdf' });
      const url = window.URL.createObjectURL(file);
      const anchor = document.createElement('a');

      anchor.href = url;
      anchor.download = file.name;
      anchor.click();

      dispatch(success());
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error?.message || '';

      const message = t('com.muralis.qcx.erro.erroConsultarNFSe', {
        erro: `${errorMessage}.`,
      });

      dispatch(failure());
      dispatch(setErrorFeedback({ message }));
    }
  }, []);

  const baixarNFSeXml = useCallback(async (nfseId) => {
    try {
      dispatch(loading());

      const response = await faturamentoAPI.baixarXMLByNFSeIdPlugNotas(nfseId);

      const file = new File([response.data], `${nfseId}.xml`, { type: 'text/xml' });
      const url = window.URL.createObjectURL(file);
      const anchor = document.createElement('a');

      anchor.href = url;
      anchor.download = file.name;
      anchor.click();

      dispatch(success());
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error?.message || '';

      const message = t('com.muralis.qcx.erro.erroConsultarNFSe', {
        erro: `${errorMessage}.`,
      });

      dispatch(failure());
      dispatch(setErrorFeedback({ message }));
    }
  }, []);

  const cancelarNFSe = useCallback(async (nfseId) => {
    try {
      dispatch(loading());

      await faturamentoAPI.cancelarNFSeByIdPlugNotas(nfseId);

      dispatch(success());
      dispatch(
        setSuccessFeedback({
          message: 'Cancelamento da NFS-e solicitado!',
        })
      );
      dispatch(refreshAction());
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error?.message || '';

      const message = t('com.muralis.qcx.erro.erroConsultarNFSe', {
        erro: `${errorMessage}.`,
      });

      dispatch(failure());
      dispatch(setErrorFeedback({ message }));
    }
  }, []);

  const columns = useMemo(
    () => [
      {
        field: 'processo',
        headerName: t('com.muralis.qcx.NumeroProcesso'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 150,
        valueGetter: ({ row }) => FaturamentoUtils.formatProcessosCapasReduzido(row?.capas) || '-',
      },
      {
        field: 'importador',
        headerName: t('com.muralis.qcx.importador.label'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 180,
        valueGetter: ({ row }) => row?.importadorNome || '-',
      },
      {
        field: 'faturamento',
        headerName: t('com.muralis.qcx.faturamento.label'),
        headerAlign: 'center',
        align: 'center',
        type: 'date',
        flex: 150,
        valueGetter: ({ row }) => formatDate(row?.insertionDate, 'YYYY-MM-DDTHH-mm-ss.zzz', 'DD/MM/YYYY') || '-',
      },
      {
        field: 'vencimento',
        headerName: t('com.muralis.qcx.vencimento'),
        headerAlign: 'center',
        align: 'center',
        type: 'date',
        flex: 180,
        valueGetter: ({ row }) => formatDate(row?.dataVencimento, 'YYYY-MM-DDTHH-mm-ss.zzz', 'DD/MM/YYYY') || '-',
      },
      {
        field: 'faturado',
        headerName: t('com.muralis.qcx.faturado'),
        headerAlign: 'center',
        align: 'numeric',
        flex: 120,
        valueGetter: ({ row }) =>
          unnormalizeNumeral(row?.totalReceberReal || row?.totalPagarReal || 0, formatBrazilianNumericDecimal(2)),
      },
      {
        field: 'valorPagar',
        headerName: t('com.muralis.qcx.valorPagar'),
        headerAlign: 'center',
        align: 'center',
        type: 'numeric',
        flex: 150,
        valueGetter: ({ row }) => unnormalizeNumeral(row?.totalPagarReal || 0, formatBrazilianNumericDecimal(2)),
      },
      {
        field: 'valorReceber',
        headerName: t('com.muralis.qcx.valorReceber'),
        headerAlign: 'center',
        align: 'center',
        type: 'numeric',
        flex: 150,
        valueGetter: ({ row }) => unnormalizeNumeral(row?.totalReceberReal || 0, formatBrazilianNumericDecimal(2)),
      },
      {
        field: 'nroNota',
        headerName: 'Número da Nota',
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 150,
        valueGetter: ({ row }) => row?.nfseList?.[0]?.numeroNota ?? '',
      },
      {
        field: 'dataEmissao',
        headerName: 'Data da Emissão',
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 150,
        valueGetter: ({ row }) => {
          const dataEmissao = row?.nfseList?.[0]?.insertionDate;
          return dataEmissao ? formatDate(dataEmissao, 'YYYY-MM-DDTHH-mm-ss.zzz', 'DD/MM/YYYY') : '';
        },
      },
      {
        field: 'status',
        headerName: t('com.muralis.qcx.status'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 150,
        valueGetter: ({ row }) => row?.status?.replaceAll('_', ' ') || '-',
      },
      {
        field: 'actions',
        headerName: t('com.muralis.qcx.acoes.label'),
        headerAlign: 'center',
        align: 'center',
        flex: 180,
        renderCell: ({ row }) => (
          <>
            <QCXPopupState popupId="popup-menu-faturamento">
              {(popupState) => (
                <>
                  <IconButton
                    key={`btn-more-options-${row?.id}`}
                    name={`btn-more-options-${row?.id}`}
                    {...bindTrigger(popupState)}
                  >
                    <MoreHorizIcon color="secondary" size="small" />
                  </IconButton>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem
                      onClick={(event) => {
                        handleConsultarClick(event, {
                          id: row?.id,
                          status,
                        });
                        popupState.close(event);
                      }}
                    >
                      <QCXListItemIcon>
                        <PageviewIcon fontSize="small" color="primary" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.consultar').toUpperCase()}
                      </Typography>
                    </MenuItem>

                    <MenuItem
                      onClick={handleCancelarFaturamentoClick(row, popupState)}
                      disabled={
                        !(FaturamentoUtils.isSolicitado(row?.status) || FaturamentoUtils.isFaturado(row?.status))
                      }
                    >
                      <QCXListItemIcon>
                        <BlockIcon fontSize="small" color="error" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.cancelar').toUpperCase()}
                      </Typography>
                    </MenuItem>

                    <MenuItem
                      onClick={handleDemonstrativoClick(row, popupState, linguagemUtils.PT_BR)}
                      disabled={
                        !FaturamentoUtils.isFaturado(row?.status) && !FaturamentoUtils.isStatusNfseEmitida(row?.status)
                      }
                    >
                      <QCXListItemIcon>
                        <SummarizeIcon htmlColor="orange" size="small" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.demonstrativo').toUpperCase()}
                      </Typography>
                    </MenuItem>

                    <MenuItem
                      onClick={handlePrestacaoClick(row, popupState)}
                      disabled={
                        !FaturamentoUtils.isFaturado(row?.status) && !FaturamentoUtils.isStatusNfseEmitida(row?.status)
                      }
                    >
                      <QCXListItemIcon>
                        <SummarizeIcon htmlColor="orange" size="small" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.prestacaoContas').toUpperCase()}
                      </Typography>
                    </MenuItem>

                    <MenuItem
                      onClick={handleDemonstrativoClick(row, popupState, linguagemUtils.EN)}
                      disabled={
                        !FaturamentoUtils.isFaturado(row?.status) && !FaturamentoUtils.isStatusNfseEmitida(row?.status)
                      }
                    >
                      <QCXListItemIcon>
                        <SummarizeIcon htmlColor="orange" size="small" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.demonstrativoIngles').toUpperCase()}
                      </Typography>
                    </MenuItem>

                    <MenuItem
                      onClick={handleDemonstrativoClick(row, popupState, linguagemUtils.ES)}
                      disabled={
                        !FaturamentoUtils.isFaturado(row?.status) && !FaturamentoUtils.isStatusNfseEmitida(row?.status)
                      }
                    >
                      <QCXListItemIcon>
                        <SummarizeIcon htmlColor="orange" size="small" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.demonstrativoEspanhol').toUpperCase()}
                      </Typography>
                    </MenuItem>

                    {/*
                    <MenuItem
                      onClick={(event) => {
                        handleBuscarLancamentosClick(row?.id);
                        popupState.close(event);
                      }}
                      disabled={FaturamentoUtils.isFaturado(row?.status)}
                    >
                      <QCXListItemIcon>
                        <PageviewIcon fontSize="small" color="secondary" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        TRAZER DESPESAS
                      </Typography>
                    </MenuItem>
                   */}

                    <MenuItem
                      onClick={handleNFSeClick(row, popupState)}
                      disabled={!FaturamentoUtils.isFaturado(row?.status)}
                    >
                      <QCXListItemIcon>
                        <WebIcon color="primary" size="small" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.emitirNFSe').toUpperCase()}
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleOpenNFSeDialogClick(row, popupState)}>
                      <QCXListItemIcon>
                        <PageviewIcon color="primary" size="small" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        CONSULTAR NFS-E
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        dispatch(followUpOrigemFaturamentoThunks.fetchAllAsync(row?.id));
                        dispatch(followUpDestinoFaturamentoThunks.fetchAllAsync(row?.importador?.id));
                        handleOpenTransferencia(event);
                      }}
                      disabled={!FaturamentoUtils.isSolicitado(row?.status)}
                    >
                      <QCXListItemIcon>
                        <TurnSlightRightIcon htmlColor="#312783" size="small" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.transferirSaldo').toUpperCase()}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={handleCalcularClick(row, popupState)}
                      disabled={!FaturamentoUtils.isSolicitado(row?.status)}
                    >
                      <QCXListItemIcon>
                        <FaCalculatorIcon color="green" size={20} />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.calcular').toUpperCase()}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={handleFaturarClick(row, popupState)}
                      disabled={!FaturamentoUtils.isCalculado(row?.status)}
                    >
                      <QCXListItemIcon>
                        <ReceiptLongIcon htmlColor="green" size="small" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.faturar').toUpperCase()}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={handleReabrirClick(row, popupState)}
                      disabled={
                        !FaturamentoUtils.isFaturado(row?.status) && !FaturamentoUtils.isStatusNfseEmitida(row?.status)
                      }
                    >
                      <QCXListItemIcon>
                        <RotateLeftIcon htmlColor="green" size="small" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        REABRIR
                      </Typography>
                    </MenuItem>
                  </Menu>
                </>
              )}
            </QCXPopupState>
          </>
        ),
      },
    ],
    []
  );

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: t('com.muralis.qcx.inicio'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloFinanceiro'),
          name: t('com.muralis.qcx.financeiro.label'),
        },
      },
      {
        text: {
          name: t('com.muralis.qcx.faturamento.label'),
        },
        default: true,
      },
    ],
    []
  );

  const templateProps = useMemo(
    () => ({
      page: {
        title: t('com.muralis.qcx.faturamento.label'),
        icon: <ReceiptIcon />,
        breadcrumbs,
      },
      control: {
        buttonNew: {
          description: t('com.muralis.qcx.faturamento.novoFaturamento'),
          onClick: handleButtonNewClick,
        },
        others: [
          {
            description: t('com.muralis.qcx.relatorio'),
            color: 'secondary',
            onClick: handleOpenRelatorioFaturamento,
          },
        ],
      },
      table: {
        columns,
        pageSize: 15,
      },
    }),
    [breadcrumbs, columns]
  );

  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={faturamentosReduzidos}
      isLoading={isAlternativeLoading}
      buttonNewText={t('com.muralis.qcx.acoes.novo').toUpperCase()}
      authInfo={authInfo}
      requiredRoles={['faturamento']}
    >
      <QCXConfirmDialog
        key="confirm-dialog-cancelar-faturamento"
        id="confirm-dialog-cancelar-faturamento"
        open={operationOfCancelarFaturamento?.active}
        title={operationOfCancelarFaturamento?.title}
        content={operationOfCancelarFaturamento?.message}
        endContent={operationOfCancelarFaturamento?.endMessage}
        onConfirm={operationOfCancelarFaturamento.confirm}
        onClose={operationOfCancelarFaturamento?.reset}
      />
      <QCXConfirmDialog
        key="confirm-dialog-gerar-demonstrativo"
        id="confirm-dialog-gerar-demonstrativo"
        open={operationOfDemonstrativo?.active}
        title={operationOfDemonstrativo?.title}
        content={operationOfDemonstrativo?.message}
        endContent={operationOfDemonstrativo?.endMessage}
        onConfirm={operationOfDemonstrativo.confirm}
        onClose={operationOfDemonstrativo?.reset}
      />
      <QCXConfirmDialog
        key="confirm-dialog-gerar-prestacao-contas"
        id="confirm-dialog-gerar-prestacao-contas"
        open={operationOfPretacao?.active}
        title={operationOfPretacao?.title}
        content={operationOfPretacao?.message}
        endContent={operationOfPretacao?.endMessage}
        onConfirm={operationOfPretacao.confirm}
        onClose={operationOfPretacao?.reset}
      />
      <QCXConfirmDialog
        key="confirm-dialog-gerar-NFS-e"
        id="confirm-dialog-gerar-NFS-e"
        open={operationOfNFSe?.active}
        title={operationOfNFSe?.title}
        content={operationOfNFSe?.message}
        endContent={operationOfNFSe?.endMessage}
        onConfirm={operationOfNFSe.confirm}
        onClose={operationOfNFSe?.reset}
      />
      <QCXConfirmDialog
        key="confirm-dialog-faturar"
        id="confirm-dialog-faturar"
        open={operationOfFaturar?.active}
        title={operationOfFaturar?.title}
        content={operationOfFaturar?.message}
        endContent={operationOfFaturar?.endMessage}
        onConfirm={operationOfFaturar.confirm}
        onClose={operationOfFaturar?.reset}
      />
      <QCXConfirmDialog
        key="confirm-dialog-reabrir"
        id="confirm-dialog-reabrir"
        open={operationOfReabrir?.active}
        title={operationOfReabrir?.title}
        content={operationOfReabrir?.message}
        endContent={operationOfReabrir?.endMessage}
        onConfirm={operationOfReabrir.confirm}
        onClose={operationOfReabrir?.reset}
      />
      <QCXConfirmDialog
        key="confirm-dialog-calcular"
        id="confirm-dialog-calcular"
        open={operationOfCalcular?.active}
        title={operationOfCalcular?.title}
        content={operationOfCalcular?.message}
        endContent={operationOfCalcular?.endMessage}
        onConfirm={operationOfCalcular.confirm}
        onClose={operationOfCalcular?.reset}
      />
      <QCXFaturamentoTransferirSaldoDialogForm
        handleOnSubmit={(event) => handleTransferenciaSubmit(event)}
        handleFormStatus={handleFormDialogTransferenciaStatus}
        formDialogStatus={formDialogTransferenciaStatus}
      />
      <QCXRelatorioFaturamentoDialogForm
        handleOnSubmit={(event) => handleRelatorioFaturamentoSubmit(event)}
        handleFormStatus={handleFormDialogRelatorioStatus}
        formDialogStatus={formDialogRelatorioStatus}
      />
      <FaturamentoNfseListaDialog
        nfseList={selectedFaturamento?.nfseList ?? []}
        isOpen={isNfseDialogOpen}
        closeModal={() => setIsNfseDialogOpen(false)}
        onConsultNFSe={handleConsultNFSeClick}
        onPdfDownload={baixarNFSePdf}
        onXmlDownload={baixarNFSeXml}
        onCancelNfse={cancelarNFSe}
      />
    </QCXSimpleConsultPageTemplate>
  );
}
