import { Grid } from '@material-ui/core';
import React from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import QCXFinalCheckboxField from '../final-checkbox-field/QCXFinalCheckboxField';
import QCXFinalAlternativeCurrencyField from '../final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXSelectMoedaAutocomplete from '../final-select-moeda-field/QCXSelectMoedaAutocomplete';

export default function QCXMoedaFreteFormGroup({
  isConsult,
  handleValorNaMoedaOnBlur,
  valorValidators,
  intl,
  classes,
}) {
  const { t } = useTranslation();

  const form = useForm();
  const { values } = useFormState();
  const tipo = values?.atributosAdicionais?.tipo;

  return (
    <>
      {tipo === 'DUE' && <Grid item xs={12} sm={12} md={4} lg={4} />}
      {tipo !== 'DUE' && (
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <QCXSelectMoedaAutocomplete
            id="select-field-moeda-frete"
            key="select-field-moeda-frete"
            name="frete.moeda.id"
            label={t('com.muralis.qcx.frete.moedaFreteInternacional')}
            disabled={isConsult}
            initialValues={values}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <QCXFinalAlternativeCurrencyField
          id="currency-valor-moeda-frete-prepaid-field"
          key="currency-valor-moeda-frete-prepaid-field"
          name="frete.valorMoedaPrepaid"
          validate={valorValidators}
          onBlur={
            tipo !== 'DUE' &&
            handleValorNaMoedaOnBlur(form, values, 'frete.moeda', 'frete.valorMoedaPrepaid', 'frete.valorRealPrepaid')
          }
          label={tipo === 'DUE' ? 'Valor Do Frete Na Moeda' : t('com.muralis.qcx.frete.valorFretePrepaidMoeda')}
          disabled={isConsult || !values?.frete?.moeda?.id}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <QCXFinalAlternativeCurrencyField
          id="currency-valor-real-moeda-frete-prepaid-field"
          key="currency-valor-real-moeda-frete-prepaid-field"
          name="frete.valorRealPrepaid"
          label={tipo === 'DUE' ? 'Valor Do Frete em reais' : t('com.muralis.qcx.frete.valorFretePrepaidReais')}
          intl={intl}
          adorned
          disabled
          helperText={!isConsult && t('com.muralis.qcx.mensagem.arredondamentoValor')}
        />
      </Grid>

      {tipo !== 'DUE' && (
        <Grid item container justify="flex-end" xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <QCXFinalCheckboxField
              id="checkbox-frete-embutir-item-field"
              key="checkbox-frete-embutir-item-field"
              name="frete.embutirItem"
              label={t('com.muralis.qcx.frete.embutirFreteItens')}
              disabled={isConsult}
            />
          </Grid>
          <Grid item className={classes.moedaInternacionalFieldLeft} xs={12} sm={12} md={4} lg={4}>
            <QCXFinalAlternativeCurrencyField
              id="currency-valor-moeda-frete-collect-field"
              key="currency-valor-moeda-frete-collect-field"
              name="frete.valorMoedaCollect"
              onBlur={handleValorNaMoedaOnBlur(
                form,
                values,
                'frete.moeda',
                'frete.valorMoedaCollect',
                'frete.valorRealCollect'
              )}
              label={t('com.muralis.qcx.frete.valorFreteCollectMoeda')}
              validate={valorValidators}
              disabled={isConsult || !values?.frete?.moeda?.id}
            />
          </Grid>
          <Grid item className={classes.moedaInternacionalFieldRight} xs={12} sm={12} md={4} lg={4}>
            <QCXFinalAlternativeCurrencyField
              id="currency-valor-real-moeda-frete-collect-field"
              key="currency-valor-real-moeda-frete-collect-field"
              name="frete.valorRealCollect"
              label={t('com.muralis.qcx.frete.valorFreteCollectReais')}
              intl={intl}
              adorned
              disabled
              helperText={!isConsult && t('com.muralis.qcx.mensagem.arredondamentoValor')}
            />
          </Grid>
        </Grid>
      )}
      {tipo !== 'DUE' && (
        <Grid item container justify="flex-end" xs={12} sm={12} md={12} lg={12}>
          <Grid item className={classes.moedaInternacionalFieldLeft} xs={12} sm={12} md={4} lg={4}>
            <QCXFinalAlternativeCurrencyField
              id="currency-valor-moeda-frete-territorio-nacional-field"
              key="currency-valor-moeda-frete-territorio-nacional-field"
              name="frete.valorFreteNacionalMoeda"
              validate={valorValidators}
              onBlur={handleValorNaMoedaOnBlur(
                form,
                values,
                'frete.moeda',
                'frete.valorFreteNacionalMoeda',
                'frete.valorFreteNacionalReal'
              )}
              label={t('com.muralis.qcx.frete.valorFreteNacionalMoeda')}
              disabled={isConsult || !values?.frete?.moeda?.id}
            />
          </Grid>
          <Grid item className={classes.moedaInternacionalFieldRight} xs={12} sm={12} md={4} lg={4}>
            <QCXFinalAlternativeCurrencyField
              id="currency-valor-real-moeda-frete-territorio-nacional-field"
              key="currency-valor-real-moeda-frete-territorio-nacional-field"
              name="frete.valorFreteNacionalReal"
              label={t('com.muralis.qcx.frete.valorFreteNacionalReais')}
              intl={intl}
              adorned
              disabled
              helperText={!isConsult && t('com.muralis.qcx.mensagem.arredondamentoValor')}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}
