/* eslint-disable no-plusplus */
import { Box, Grid, IconButton, InputBase, makeStyles, Paper, useMediaQuery, useTheme } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import _, { isEmpty, result } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { resetQuery } from '../../features/controller/controllerSlice';
import QCXButton from '../button/QCXButton';
import QCXDataGrid from '../data-grid/QCXDataGrid';

const useStyles = makeStyles((theme) => ({
  onlySearchBar: {
    padding: '4px 8px 4px 0px',
  },
  search: {
    paddingLeft: '0.3rem',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '0',
    background: 'transparent',
    border: '0',
    borderBottom: '1px solid rgb(0 0 0 / 54%)',
    '&:hover': {
      borderBottomColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    '&::placeholder': {
      textTransform: 'uppercase',
    },
  },
  iconButton: {
    padding: 10,
  },
  firstButton: {
    width: '100%',
    minHeight: '44px',
    '& .MuiButton-startIcon': {
      marginRight: '4px',
    },
  },
  otherButton: {
    width: '100%',
    minHeight: '44px',
  },
  table: {
    paddingTop: theme.spacing(3),
  },
}));

export default function QCXDataGridControl({
  columns = [],
  rows = [],
  pageSize = 5,
  disableColumnMenu = true,
  disableColumnReorder = true,
  disableColumnResize = true,
  loading = false,
  controlButtons = [],
  filterPropGetter,
  entityPluralName,
  defaultQuerySearch = '',
  querySearch = undefined,
  showInactiveRows = false,
  buttonGridSize = undefined,
  searchGridSize = undefined,
  ignoreControlButtonsLimit = false,
  ...restProps
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const dispatch = useDispatch();

  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [filteredData, setFilteredData] = useState([]);
  // Colunas com funcao ValueGetter, que define que elas podem ser filtradas
  const [filterableColumns, setFilterableColumns] = useState([]);
  // Valor do campos de texto que realizara o filtro
  const [filterInputValue, setFilterInputValues] = useState(defaultQuerySearch || '');
  // ovelay de carregamento enquanto funcao de filtrar executa
  const [isFiltering, setIsFiltering] = useState(false);

  const [activeRows, setActiveRows] = useState([]);

  const { localData } = restProps;

  useEffect(() => {
    const handleQuerySearch = () => {
      if (querySearch) {
        setFilterInputValues(querySearch);
        dispatch(resetQuery());
      }
    };

    handleQuerySearch();
  }, [querySearch]);

  const buttonsQuantity = useMemo(() => controlButtons?.length, [controlButtons]);

  useEffect(() => {
    if (!showInactiveRows) {
      return setActiveRows(rows.filter((row) => row.active !== false));
    }
    return setActiveRows(rows);
  }, [rows]);

  useEffect(() => {
    setFilteredData(activeRows);
  }, [activeRows]);

  useEffect(() => {
    setFilterableColumns(columns.filter((item) => _.isFunction(item.valueGetter)));
  }, [columns]);

  const filter = useCallback(() => {
    const allData = activeRows;

    const filterParts = filterInputValue?.split('|');
    if (!filterParts?.length) {
      setFilteredData(rows);
      return;
    }
    const resultData = [];
    for (let i = 0; i < allData.length; i++) {
      const row = allData[i];

      let filterableRowConcat = '';
      for (let j = 0; j < filterableColumns.length; j++) {
        filterableRowConcat += filterableColumns[j]?.valueGetter({ row });
      }

      if (!filterableRowConcat) {
        setFilteredData(rows);
        return;
      }
      const containsFilter = filterParts.every((item) =>
        filterableRowConcat.toLowerCase().includes(item.toLowerCase().trim())
      );

      if (containsFilter) {
        resultData.push(row);
      }
    }

    if (localData) {
      resultData.push(...localData);
    }

    setFilteredData(resultData);
  }, [rows, activeRows, filterInputValue, setFilteredData, filterableColumns]);

  useEffect(() => {
    setIsFiltering(true);
    filter();
    setIsFiltering(false);
  }, [filter, setIsFiltering]);

  const handleChangeInputFilterField = useCallback(
    ({ target: { value } }) => {
      setFilterInputValues(value);
    },
    [setFilterInputValues]
  );

  return (
    <Grid container item justify="space-between" alignItems="center" spacing={0} xs={12} sm={12} md={12} xl={12}>
      <Box width="100%" display="flex" flexDirection="row" flexWrap="wrap" style={{ gap: '0.75rem' }}>
        <Box
          flex={1}
          minWidth="600px"
          style={{
            paddingLeft: '5px',
            ...(isSmDown || buttonsQuantity === 0
              ? {
                  paddingRight: '12px',
                }
              : {}),
          }}
        >
          <Paper className={classes.search} elevation={0}>
            <InputBase
              className={classes.input}
              placeholder={t('com.muralis.qcx.filtro.placeholder', {
                colunas: filterableColumns.map((item) => item.headerName.toUpperCase()).join(' | '),
              }).toUpperCase()}
              onChange={handleChangeInputFilterField}
              value={filterInputValue}
              inputProps={{ 'aria-label': t('com.muralis.qcx.pesquisar').toUpperCase() }}
            />
            <IconButton className={classes.iconButton} aria-label="search" disabled>
              <SearchIcon />
            </IconButton>
          </Paper>
        </Box>
        <Box
          style={{ gap: '0.5rem' }}
          display="flex"
          justify="flex-end"
          alignItems="center"
          flexWrap="wrap"
          spacing={1}
        >
          {controlButtons.map(({ to, description, startIcon, tooltip, onClick, ...restControlButtonProps }, index) => {
            if (index > 2 && !ignoreControlButtonsLimit) {
              return null;
            }

            const isFirstButton = index === 0;

            return (
              <Box key={`control-button-${index + 1}`}>
                <QCXButton
                  className={isFirstButton ? classes.firstButton : classes.otherButton}
                  color={isFirstButton ? 'primary' : 'secondary'}
                  startIcon={startIcon}
                  tooltip={tooltip && !isEmpty(tooltip)}
                  tooltipDescription={tooltip?.description}
                  {...(to ? { to, component: Link } : {})}
                  {...(onClick ? { onClick } : {})}
                  {...restControlButtonProps}
                >
                  {description}
                </QCXButton>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Grid container item direction="row" className={classes.table}>
        <QCXDataGrid
          columns={columns}
          rows={filteredData}
          disableColumnMenu={disableColumnMenu}
          disableColumnReorder={disableColumnReorder}
          disableColumnResize={disableColumnResize}
          loading={loading || isFiltering}
          pageSize={pageSize}
          filterModel={restProps?.filterModel}
          {...restProps}
        />
      </Grid>
    </Grid>
  );
}
