import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@material-ui/icons';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QCXBox from '../../shared-components/box/QCXBox';
import QCXIconButton from '../../shared-components/button/QCXIconButton';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXFinalAlternativeCurrencyField from '../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXFinalDatePickerField from '../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXFinalNumericIntegerField from '../../shared-components/final-numeric-integer-field/QCXFinalNumericIntegerField';
import QCXFinalOnblurMaskedField from '../../shared-components/final-onblur-masked-field/QCXFinalOnblurMaskedField';
import QCXFinalPercentualField from '../../shared-components/final-percentual-field/QCXFinalPercentualField';
import QCXSelectCnaeAutocomplete from '../../shared-components/final-select-cnae-field/QCXSelectCnaeAutocomplete';
import QCXSelectMoedaAutocomplete from '../../shared-components/final-select-moeda-field/QCXSelectMoedaAutocomplete';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXFinalCertificateInputFile from '../../shared-components/input-file/QCXFinalCertificateInputFile';
import QCXSelectContaBancariaAutocompleteField from '../../shared-components/select-conta-bancaria/QCXSelectContaBancariaAutocompleteField';
import QCXSelectContaContabilAutocomplete from '../../shared-components/select-conta-contabil/QCXSelectContaContabilAutocomplete';
import QCXSelectIndicadorInscricaoEstadualAutocomplete from '../../shared-components/select-indicador-inscricao-estadual/QCXSelectIndicadorInscricaoEstadualAutocomplete';
import QCXSelectTipoRetencaoAutocomplete from '../../shared-components/select-tipo-retencao/QCXSelectTipoRetencaoAutocomplete';
import QCXTextFieldInscricaoSuframa from '../../shared-components/text-field-inscricao-suframa/QCXTextFieldInscricaoSuframa';
import { MediaType } from '../../utils/api/api-utils';
import { OPTANTE_SIMPLES_NACIONAL, RETER } from '../../utils/general/cliente/clienteUtils';
import TipoCalculoSeguroUtils from '../../utils/general/tipo-calculo-seguro/TipoCalculoSeguroUtils';
import { formatTelefoneFixo } from '../../utils/hooks/form/field/formatters';
import { BRAZILIAN } from '../../utils/hooks/form/field/mask-types';
import { numberOnly, parseTelefoneFixoWithDDD } from '../../utils/hooks/form/field/parsers';
import useValidators from '../../utils/hooks/useValidators';
import { isCurrentOrFutureDate, required as requiredValidator } from '../../utils/validators/field/validator';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';
import SelectAmbiente from './SelectAmbiente';
import SelectNumeroPoEasy from './SelectNumeroPoEasy';
import SelectNumeroProcessoEasy from './SelectNumeroProcessoEasy';
import SelectTipoRateioTaxaSiscomex from './SelectTipoRateioTaxaSiscomex';
import SelectFormaCalculoSeguro from '../../shared-components/moeda-seguro-form-group/QCXSelectFormaCalculoSeguro';
import QCXSelectTipoIssAutocomplete from '../../shared-components/select-tipo-iss/QCXSelectTipoIssAutocomplete';

const useStyles = makeStyles((theme) => ({
  aloneGridField: {
    [theme.breakpoints.down('xs')]: {
      paddingRight: '0px',
    },
    [theme.breakpoints.up('sm')]: {
      paddingRight: '11px',
    },
  },
}));

export default function   QCXClienteTecnicalDataForm({
  initialValues,
  handleSubmit,
  isConsult,
  isCreate,
  controlComponentProps,
  authInfo = {},
  requiredRoles = [],
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => setShowPassword((prevState) => !prevState);

  const vencimentoValidators = useMemo(() => useValidators([requiredValidator, isCurrentOrFutureDate]), []);

  const currentDate = moment().toDate();

  const certificadoDigitalGetter = useCallback((values) => {
    if (!values?.id && values?.length > 0) {
      return values[0];
    }

    return values;
  }, []);

  const certificadoDigitalNameGetter = useCallback((file) => {
    if (file?.id) {
      return file?.nome;
    }

    return file?.name;
  }, []);

  const handleCertificadoDigitalDanfeSenhaChange = useCallback(
    (form, values) => (value) => {
      if (
        !value ||
        (values?.certificadoDigitalDanfeSenhaConfirma && values?.certificadoDigitalDanfeSenhaConfirma !== value)
      ) {
        form.change('certificadoDigitalDanfeSenhaConfirma', '');
        form.resetFieldState('certificadoDigitalDanfeSenhaConfirma');
      }
    },
    []
  );

  const listeners = useMemo(
    () => [
      {
        name: 'certificadoDigitalDanfeSenha',
        fn: handleCertificadoDigitalDanfeSenhaChange,
      },
    ],
    [handleCertificadoDigitalDanfeSenhaChange]
  );

  return (
    <QCXFormStepWizard
      initialValues={initialValues}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      onChangeListeners={listeners}
      authInfo={authInfo}
      requiredRoles={requiredRoles}
    >
      {({ values }) => (
        <>
          <Typography variant="h6" color="primary">
            <QCXBox fontWeight="fontWeightBold" pt={5}>
              {t('com.muralis.qcx.dadosTecnicos').toUpperCase()}
            </QCXBox>
          </Typography>
          <Grid container spacing={2} style={{ marginTop: '1rem' }}>
            {values?.tipoPessoa !== 'PESSOA_FISICA' && (
              <>
                <Grid item xs={12} sm={6} md={4}>
                  <QCXSelectCnaeAutocomplete
                    id="text-field-cnae"
                    key="text-field-cnae"
                    name="pessoa.cnae.id"
                    label={t('com.muralis.qcx.empresa.CNAE')}
                    validate={requiredValidator}
                    disabled={isConsult}
                    initialValues={values}
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <QCXFinalTextField
                    id="input-pessoa-inscricao-estadual"
                    key="input-pessoa-inscricao-estadual"
                    name="pessoa.inscricaoEstadual"
                    label={t('com.muralis.qcx.empresa.inscricaoEstadual')}
                    parse={numberOnly}
                    maxLength={15}
                    disabled={isConsult}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <QCXFinalTextField
                    id="input-pessoa-inscricao-municipal"
                    key="input-pessoa-inscricao-municipal"
                    name="pessoa.inscricaoMunicipal"
                    label={t('com.muralis.qcx.empresa.inscricaoMunicipal')}
                    parse={numberOnly}
                    maxLength={15}
                    disabled={isConsult}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={6} md={4}>
              <QCXTextFieldInscricaoSuframa
                id="text-field-inscricao-suframa"
                key="text-field-inscricao-suframa"
                name="inscricaoSuframa"
                label={t('com.muralis.qcx.incricaoSuframa')}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXSelectIndicadorInscricaoEstadualAutocomplete
                name="indicadorInscricaoEstadual"
                label={t('com.muralis.qcx.indicadorInscricaoEstadual.label')}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXSelectContaContabilAutocomplete
                id="select-field-conta-contabil"
                key="select-field-conta-contabil"
                name="planoDeConta.id"
                label={t('com.muralis.qcx.empresa.contaContabil')}
                fieldProps={{ validate: requiredValidator }}
                initialValues={values}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <QCXFinalCheckboxField
                id="checkbox-tipo-field"
                key="checkbox-tipo-field"
                name="optanteSimplesNacional"
                value={OPTANTE_SIMPLES_NACIONAL}
                label={t('com.muralis.qcx.empresa.optanteSimplesNacional')}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12}>
              <QCXFormSubtitle title={t('com.muralis.qcx.retencao')} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXFinalPercentualField
                id="text-field-retencao-pis"
                key="text-field-retencao-pis"
                name="aliquotaPis"
                label={t('com.muralis.qcx.PISpercentual')}
                scale={7}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXFinalPercentualField
                id="text-field-retencao-cofins"
                key="text-field-retencao-cofins"
                name="aliquotaCofins"
                label={t('com.muralis.qcx.COFINSpercentual')}
                scale={7}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXFinalPercentualField
                id="text-field-retencao-csll"
                key="text-field-retencao-csll"
                name="aliquotaCsll"
                label={t('com.muralis.qcx.CSLLpercentual')}
                scale={7}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXFinalAlternativeCurrencyField
                id="text-field-valor-minimo"
                key="text-field-valor-minimo"
                name="valorMinimo"
                label={t('com.muralis.qcx.valorMinimo')}
                intl={BRAZILIAN}
                adorned
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXFinalCheckboxField
                id="checkbox-tipo-reter"
                key="checkbox-tipo-reter"
                name="reter"
                value={RETER}
                label={t('com.muralis.qcx.reterAbaixoValorMinimo')}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXSelectTipoRetencaoAutocomplete
                id="select-field-tipo-retencao"
                key="select-field-tipo-retencao"
                name="tipoRetencao"
                label={t('com.muralis.qcx.tipoRetencao.label')}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXFinalPercentualField
                id="text-field-retencao-iss"
                key="text-field-retencao-iss"
                name="aliquotaIss"
                label='% ISS'
                scale={7}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXSelectTipoIssAutocomplete
                id="select-field-tipo-iss"
                key="select-field-tipo-iss"
                name="tipoIss"
                label="Tipo Retenção ISS"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXFinalPercentualField
                id="text-field-retencao-ir"
                key="text-field-retencao-ir"
                name="aliquotaIr"
                label='% IR'
                scale={7}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXSelectTipoIssAutocomplete
                id="select-field-tipo-ir"
                key="select-field-tipo-ir"
                name="tipoIr"
                label="Tipo Retenção IR"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12}>
              <QCXFormSubtitle title={t('com.muralis.qcx.faturamento.label')} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXFinalNumericIntegerField
                id="final-numeric-integer-dia-vencimento-nfs-e"
                key="final-numeric-integer-dia-vencimento-nfs-e"
                name="diasParaVencimento"
                label={t('com.muralis.qcx.diasVencimentoNFSe')}
                scale={7}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXFinalNumericIntegerField
                id="final-numeric-integer-dia-limite-faturamento"
                key="final-numeric-integer-dia-limite-faturamento"
                name="diaLimiteParaFaturamento"
                label={t('com.muralis.qcx.diaLimiteFaturamento')}
                scale={7}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12}>
              <QCXFormSubtitle title={t('com.muralis.qcx.seguro.label')} />
            </Grid>
            
            <Grid item xs={12} sm={6} md={4}>
              <QCXSelectMoedaAutocomplete
                id="select-field-moeda-seguro"
                key="select-field-moeda-seguro"
                name="moeda.id"
                label={t('com.muralis.qcx.seguro.moedaSeguro')}
                disabled={isConsult}
                initialValues={values}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
              <SelectFormaCalculoSeguro
                id="select-forma-calculo-seguro"
                key="select-forma-calculo-seguro"
                name="parametroCalcSeguro"
                label="Parâmetro de calculo do seguro"
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <QCXFinalPercentualField
                id="valor-percentual-seguro-field"
                key="valor-percentual-seguro-field"
                name="valorPercentualSeguro"
                label={t('com.muralis.qcx.seguro.percentualSeguro')}
                scale={7}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <QCXFinalCheckboxField
                id="checkbox-seguro-calcula-sobre-mle-frete-field"
                key="checkbox-seguro-calcula-sobre-mle-frete-field"
                name="tipoCalculoSeguro"
                label={t('com.muralis.qcx.seguro.calcularSeguroMLEFrete')}
                value={TipoCalculoSeguroUtils.CALCULA_SOBRE_MLE_FRETE}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <QCXFormSubtitle title="Tipo de Rateio" />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <SelectTipoRateioTaxaSiscomex
                id="select-field-tipo-rateio-taxa-siscomex"
                key="select-field-tipo-rateio-taxa-siscomex"
                name="tipoRateioTaxaSiscomex"
                label="Tipo de Rateio Taxa Siscomex"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <QCXFormSubtitle title="Calcular Spread" />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <QCXFinalPercentualField
                id="percentual-spread-field"
                key="percentual-spread-field"
                name="percentualSpread"
                label="Percentual"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="h6" color="primary">
                <QCXBox fontWeight="fontWeightBold" pt={5}>
                  {'Integração Totvs'.toUpperCase()}
                </QCXBox>
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <QCXFormSubtitle title="Saída Totvs Danfe" />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <SelectNumeroProcessoEasy
                id="select-field-numero-processo-easy"
                key="select-field-numero-processo-easy"
                name="numeroProcessoEasy"
                label="Número do Processo Easy"
                disabled={isConsult}
                initialValues={values}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <SelectNumeroPoEasy
                id="select-field-numero-po-totvs"
                key="select-field-numero-po-totvs"
                name="numeroPoTotvs"
                label="Número da PO"
                disabled={isConsult}
                initialValues={values}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <QCXFormSubtitle title="Saída Totvs Numerário" />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <SelectNumeroProcessoEasy
                id="select-field-numero-processo-easy"
                key="select-field-numero-processo-easy"
                name="numProcessoEasyNumerario"
                label="Número da Ref. do Despachante"
                disabled={isConsult}
                initialValues={values}
                isNumerario
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <QCXFormSubtitle title="Saída Totvs Despesas" />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <SelectNumeroProcessoEasy
                id="select-field-numero-processo-easy"
                key="select-field-numero-processo-easy"
                name="numProcessoEasyDespesa"
                label="Número do Processo"
                disabled={isConsult}
                initialValues={values}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <SelectNumeroPoEasy
                id="select-field-numero-po-totvs"
                key="select-field-numero-po-totvs"
                name="numPoTotvsDespesa"
                label="Número da Ref. do Despachante"
                disabled={isConsult}
                initialValues={values}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <QCXFinalCheckboxField
                id="checkbox-zerar-despesas"
                key="checkbox-zerar-despesas"
                name="zerarDespesasTXT"
                label="Zerar despesas acessórias no TXT"
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="h6" color="primary">
                <QCXBox fontWeight="fontWeightBold" pt={5}>
                  {t('com.muralis.qcx.limiteCreditoContasBancarias').toUpperCase()}
                </QCXBox>
              </Typography>
            </Grid>

            <Grid item container xs={12}>
              <Grid item className={classes.aloneGridField} xs={12} sm={6} md={4}>
                <QCXFinalAlternativeCurrencyField
                  id="text-field-limite-credito"
                  key="text-field-limite-credito"
                  name="limiteCredito"
                  label={t('com.muralis.qcx.limiteCredito')}
                  intl={BRAZILIAN}
                  adorned
                  required
                  disabled={isConsult}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <QCXSelectContaBancariaAutocompleteField
                id="autocomplete-select-conta-bancaria-impostos-estaduais-field"
                key="autocomplete-select-conta-bancaria-impostos-estaduais-field"
                name="contaBancariaImpostosEstaduais"
                label={t('com.muralis.qcx.conta.contaBancariaImpostosEstaduais')}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <QCXSelectContaBancariaAutocompleteField
                id="autocomplete-select-conta-bancaria-impostos-federais-field"
                key="autocomplete-select-conta-bancaria-impostos-federais-field"
                name="contaBancariaImpostosFederais"
                label={t('com.muralis.qcx.contaBancaria.contaBancariaImpostosFederais')}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <QCXSelectContaBancariaAutocompleteField
                id="autocomplete-select-conta-bancaria-pagamento-despesas-field"
                key="autocomplete-select-conta-bancaria-pagamento-despesas-field"
                name="contaBancariaPagamentoDespesas"
                label={t('com.muralis.qcx.contaBancaria.contaBancariaPagamentoDespesas')}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <QCXFormSubtitle title={t('com.muralis.qcx.vencimentoRadarProcuracaoEmail')} />
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <QCXFinalDatePickerField
                id="date-picker-vencimento-radar"
                key="date-picker-vencimento-radar"
                name="vencimentoRadar"
                label={t('com.muralis.qcx.vencimentoRadar')}
                minDate={currentDate}
                validate={!isConsult && vencimentoValidators}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <QCXFinalDatePickerField
                id="date-picker-vencimento-procuracao"
                key="date-picker-vencimento-procuracao"
                name="vencimentoProcuracao"
                label={t('com.muralis.qcx.vencimentoProcuracao')}
                minDate={currentDate}
                validate={!isConsult && vencimentoValidators}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <QCXFinalTextField
                id="text-field-contato-nfe-email"
                key="text-field-contato-nfe-email"
                name="contatoNfe.email"
                type="email"
                label={t('com.muralis.qcx.NFSE.emailRecebimentoNFSe')}
                validate={requiredValidator}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <QCXFinalOnblurMaskedField
                id="text-field-telefone"
                key="text-field-telefone"
                name="contatoNfe.telefone"
                label={t('com.muralis.qcx.telefone')}
                format={formatTelefoneFixo}
                parse={parseTelefoneFixoWithDDD}
                maxLength={13}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <QCXFormSubtitle title={t('com.muralis.qcx.DANFE.label')} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalCertificateInputFile
                id="upload-input-certificado"
                key="upload-input-certificado"
                name="certificadoDigital"
                label={t('com.muralis.qcx.certificado.certificadoDigital')}
                tooltipTitle={t('com.muralis.qcx.certificado.carregarCertificadoDigital')}
                accept={MediaType.ALL}
                fileGetter={certificadoDigitalGetter}
                fileNameGetter={certificadoDigitalNameGetter}
                disableRemoveControl
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <QCXFinalTextField
                id="certificado-senha"
                key="certificado-senha"
                name="certificadoDigitalDanfeSenha"
                type={showPassword ? 'text' : 'password'}
                label={t('com.muralis.qcx.certificado.senhaCertificado')}
                maxLength={255}
                disabled={isConsult}
                InputProps={
                  isCreate
                    ? {
                        endAdornment: (
                          <QCXIconButton onClick={togglePasswordVisibility} disableTooltip disabled={isConsult}>
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </QCXIconButton>
                        ),
                      }
                    : undefined
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <QCXFinalTextField
                id="certificado-senha-confirma"
                key="certificado-senha-confirma"
                name="certificadoDigitalDanfeSenhaConfirma" // <- este campo não é enviado
                type="password"
                label={t('com.muralis.qcx.confirmeSenha')}
                maxLength={255}
                validate={(inputText) => {
                  const campoPrimeiro = values.certificadoDigitalDanfeSenha;
                  const campoConfirma = inputText;

                  if (
                    campoPrimeiro &&
                    campoPrimeiro.length > 0 &&
                    campoConfirma &&
                    campoConfirma.length > 0 &&
                    campoPrimeiro !== campoConfirma
                  ) {
                    return t('com.muralis.qcx.validacao.senhaNaoConfere');
                  }

                  return undefined;
                }}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <QCXFinalNumericIntegerField
                id="serie-danfe"
                key="serie-danfe"
                name="serieDanfe"
                label={t('com.muralis.qcx.DANFE.serie')}
                maxLength={3}
                defaultValue="1"
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <QCXFinalNumericIntegerField
                id="numero-danfe"
                key="numero-danfe"
                name="proximoNumeroDanfe"
                label={t('com.muralis.qcx.DANFE.proximoNumeroDANFE')}
                maxLength={9}
                defaultValue="1"
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3}>
              <SelectAmbiente
                id="select-ambiente"
                key="select-ambiente"
                name="ambienteDanfe"
                label="Ambiente"
                disabled={isConsult}
              />
            </Grid>

            {/** Filler space */}
            <Grid item xs={0} sm={0} md={3} lg={3} />

            <Grid item xs={12} sm={6} md={3} lg={3}>
              <QCXFinalCheckboxField
                id="checkbox-oea"
                key="checkbox-oea"
                name="oea"
                label={t('com.muralis.qcx.OEA')}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <QCXFinalCheckboxField
                id="checkbox-restricao-financeira"
                key="checkbox-restricao-financeira"
                name="restricaoFinanceira"
                label={t('com.muralis.qcx.restricaoFinanceira')}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <QCXFinalCheckboxField
                id="checkbox-importador"
                key="checkbox-importador"
                name="tipoEmpresa.importador"
                label={t('com.muralis.qcx.importador.label')}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <QCXFinalCheckboxField
                id="checkbox-exportador"
                key="checkbox-exportador"
                name="tipoEmpresa.exportador"
                label={t('com.muralis.qcx.exportador.label')}
                disabled={isConsult}
              />
            </Grid>
          </Grid>
        </>
      )}
    </QCXFormStepWizard>
  );
}
