const buildStrExpression = (valor, objCondicionante) => {
  if (!valor) valor = false;
  if (!objCondicionante) {
    return false; // Base case: no more nested conditions, return false
  }
  if(objCondicionante && objCondicionante.composicao) {
    const strLocal = buildStrExpression(valor, objCondicionante.condicao);

    if (strLocal) {
      return `(${valor} ${ objCondicionante.operador} ${objCondicionante.valor}) ${objCondicionante.composicao} ${strLocal}`
    }

    return `(${valor} ${ objCondicionante.operador} ${objCondicionante.valor})`
    
  }

  return `(${valor} ${ objCondicionante.operador} ${objCondicionante.valor})`
}

const evaluateExpression = (valor, objCondicionante) => {
  let strExpression = buildStrExpression(valor, objCondicionante)
  if(!strExpression) return false;

  const notAllowedOperators = ['const', 'let', 'var', 'function', ':', '=>', '{', '}', 'return', 'eval', 'if', '?', 'null', 'undefined'];
  notAllowedOperators.map(strBlock => {
    if(strExpression.includes(strBlock)) {
      // throw new Error("Erro ao avaliar a expressão !!")
      console.log("Erro ao avaliar a expressão !!")
      return false
    }
    return null
  })

  if(!strExpression) return false;
  strExpression = strExpression.replace(/\b0+(\d)/g, '$1'); // remove zero a esquerda (importante para que o numero nao seja interpretado como um octal)
  return eval(strExpression)
}

export {
  evaluateExpression,
  buildStrExpression,
};