import {
  Button,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import {
  CancelOutlined as CancelOutlinedIcon,
  CheckCircle as CheckCircleIcon,
  Delete as DeleteIcon,
  Description as DescriptionIcon,
  MoreHoriz as MoreHorizIcon,
  GetApp as GetAppIcon,
  Search as PageviewIcon,
  ReplyAll as ReplyAllIcon,
  Send as SendIcon,
  ViewList as ViewListIcon,
  Publish as FileUploadIcon,
  Sync as SyncIcon,
} from '@material-ui/icons';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import _ from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import * as XLSX from 'xlsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { userHasDeleteRole } from '../../../../../components/perfil/perfil-utils';
import CatalogoProdutosContext from '../../../../../contexts/registrations/catalogo-produtos/CatalogoProdutosContext';
import {
  activateByIdAsync,
  batchTransmiteCatalogosAsync,
  changeToCreateMode,
  changeToTransferMode,
  fetchCatalogoProdutosByFilterAsync,
  generateExcelAsync,
  inactivateByIdAsync,
  resetBackgroundMode,
  resetMode,
  resetModel,
  resetRelatedEspecificacaoModel,
  resetSubMode,
  selectCatalogosProdutos,
  selectCatalogosProdutosCount,
  selectMode,
  selectRelatedSelectionCatalogoList,
  setRelatedSelectionCatalogoList,
  importaCatalogosPlanilhaAsync,
  recuperaCatalogoPortalUnicoByIdAsync,
} from '../../../../../features/catalogo-produtos/catalogoProdutosSlice';
import { changeControlTo as changeControlCfopOperacoesFiscais } from '../../../../../features/cfop-operacoes-fiscais/cfopOperacoesFiscaisSlice';
import { IDLE_CONTROL } from '../../../../../features/config-control';
import { setSuccessFeedback } from '../../../../../features/feedback/feedbackSlice';
import { changeControlTo as changeControlNaladiNccaTo } from '../../../../../features/naladi-ncca/naladiNccaSlice';
import { changeControlTo as changeControlNaladiShTo } from '../../../../../features/naladi-sh/naladiShSlice';
import { changeControlTo as changeControlNcmTo } from '../../../../../features/ncm-subitem/ncmSubitemSlice';
import { changeControlTo as changeControlPaisTo } from '../../../../../features/pais/paisSlice';
import { ALTERNATIVE_LOADING_STATUS } from '../../../../../features/status';
import { changeControlTo as changeControlUnidadeMedidaTo } from '../../../../../features/unidade-medida/unidadeDeMedidaSlice';
import SimpleConsultPageTemplate from '../../../../../templates/simple-consult-page/SimpleConsultPageTemplate/SimpleConsultPageTemplate';
import { isNoneMode, isTransferMode } from '../../../../../utils/store/store-utils';
import CatalogoProdutosAdvancedFilterForm from './components/CatalogoProdutosAdvancedFilterForm';
import ExportarCatalogoPopup from './components/ExportarCatalogoPopup';
import RelatorioPopup from './components/RelatorioPopup';
import getISODateOrTodaysISODate from './utils/getISODateOrTodaysISODate';
import CatalogoProdutosTransmitirModal from './CatalogoProdutosFiltroRepassePage/components/CatalogoProdutosTransmitirModal';
import TableCellWithTooltip from '../../../../../shared-components/tooltip-cell/TableCellWithTooltip';
import QCXListItemIcon from '../../../../../shared-components/list-item-icon/QCXListItemIcon';
import CatalogoProdutosSelecionarTransmissaoModal from './CatalogoProdutosFiltroRepassePage/components/CatalogoProdutosSelecionarTransmissaoModal';
import QCXPopupState from '../../../../../components/popup-state/QCXPopupState';

export default function CatalogoProdutosConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const history = useHistory();
  const [openExportarPopup, setOpenExportarPopup] = useState(false);
  const [openRelatorio, setOpenRelatorio] = useState(false);
  const [relatorio, setRelatorio] = useState('');
  const [openStateModal, setOpenStateModal] = useState(false);
  const [catalogoState, setCatalogoState] = useState('ATIVADO');
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [data, setData] = useState([]);

  const [openSelecionarTransmissaoModal, setOpenSelecionarTransmissaoModal] = useState(false);
  const [transmissaoState, setTransmissaoState] = useState(false);

  const { status } = useContext(CatalogoProdutosContext);
  const dispatch = useDispatch();
  const mode = useSelector(selectMode);
  const catalogosProdutos = useSelector(selectCatalogosProdutos);
  const relatedSelectionCatalogoList = useSelector(selectRelatedSelectionCatalogoList);
  const [fetchFilterParams, setFetchFilterParams] = useState([]);

  const isAlternativeLoading = useMemo(() => status === ALTERNATIVE_LOADING_STATUS, [status]);
  const isNone = useMemo(() => isNoneMode(mode), [mode]);
  const isTransfer = useMemo(() => isTransferMode(mode), [mode]);
  const isEmptySelectionList = useMemo(
    () =>
      !relatedSelectionCatalogoList ||
      (_.isArrayLikeObject(relatedSelectionCatalogoList) && _.isEmpty(relatedSelectionCatalogoList)),
    [relatedSelectionCatalogoList]
  );
  const handleShowReport = useCallback(
    (event) => {
      event.stopPropagation();
      const relatorioDados = generateRelatorioIntegracao(catalogosProdutos);
      setRelatorio(relatorioDados);
      setOpenRelatorio(true);
    },
    [catalogosProdutos]
  );

  const handleSelectionModelChange = useCallback(
    ({ selectionModel }) => {
      if (isTransfer) {
        dispatch(setRelatedSelectionCatalogoList(selectionModel));
      }
    },
    [isTransfer]
  );

  const fetchAll = async () => {
    dispatch(
      fetchCatalogoProdutosByFilterAsync([
        { name: 'page', value: 1 },
        { name: 'size', value: 10 },
      ])
    );
  };

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
    dispatch(resetSubMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
    dispatch(resetRelatedEspecificacaoModel());
  }, []);

  const resetDependencies = useCallback(() => {
    dispatch(changeControlPaisTo(IDLE_CONTROL));
    dispatch(changeControlNcmTo(IDLE_CONTROL));
    dispatch(changeControlNaladiShTo(IDLE_CONTROL));
    dispatch(changeControlNaladiNccaTo(IDLE_CONTROL));
    dispatch(changeControlUnidadeMedidaTo(IDLE_CONTROL));
    dispatch(changeControlCfopOperacoesFiscais(IDLE_CONTROL));
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
    resetDependencies();
  }, []);

  const handleActivateInactivate = async (event, id) => {
    event.preventDefault();
    const { active } = catalogosProdutos.find((item) => item.id === id);
    const isActivation = !active;
    if (isActivation) {
      dispatch(activateByIdAsync(id));
    } else {
      dispatch(inactivateByIdAsync(id));
    }
  };

  const handleClickOnButtonNew = useCallback((event) => {
    event.stopPropagation();
    dispatch(changeToCreateMode());
  }, []);

  const handleClickOnEditButton = useCallback(
    (_event, id) => history.push(t('com.muralis.qcx.url.moduloOperacionaisCatalogosProdutosDetalhes', { id })),
    [history]
  );

  const handleClickLogsButton = useCallback(
    (_event, id) => history.push(t('com.muralis.qcx.url.moduloOperacionaisCatalogosProdutosLogs', { id })),
    [history]
  );

  const handleRecuperarPortalUnicoButton = useCallback(
    (_event, id) => dispatch(recuperaCatalogoPortalUnicoByIdAsync(id)),
    []
  );

  const getDescriptionCliente = (row) => {
    const clientes = _.get(row, 'clientes');
    if (clientes && _.isArray(clientes) && clientes.length) {
      return clientes[0].description;
    }
    return '';
  };

  const generateRelatorioIntegracao = (dados) =>
    dados
      .filter((catalogo) => catalogo.relatorioIntegracao)
      .map((catalogo) => ({
        id: catalogo.id,
        partNumber: catalogo.partnumber,
        cliente: getDescriptionCliente(catalogo),
        erros: catalogo.relatorioIntegracao,
      }));

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.codigo'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloOperacionais'),
        name: t('com.muralis.qcx.operacionais'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.catalogoProdutosMercadoria'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.principal'),
      },
    },
  ];
  const columns = [
    {
      field: 'partnumber',
      headerName: t('com.muralis.qcx.item.partNumber'),
      type: 'string',
      width: 180,
      valueGetter: ({ row }) => row?.partnumber,
    },
    {
      field: 'apelido',
      headerName: t('com.muralis.qcx.apelido'),
      type: 'string',
      width: 240,
      valueGetter: ({ row }) => row?.apelido,
    },
    {
      field: 'especificacao',
      headerName: t('com.muralis.qcx.especificacao.label'),
      type: 'string',
      flex: 1,
      renderCell: ({ row }) => <TableCellWithTooltip value={row?.especificacao} />,
    },
    {
      field: 'ncm',
      headerName: t('com.muralis.qcx.NCM.label'),
      hide: false,
      type: 'string',
      width: 100,
      valueGetter: ({ row }) => row?.ncm?.code,
    },
    {
      field: 'cliente',
      headerName: t('com.muralis.qcx.cliente.label'),
      type: 'string',
      width: 240,
      valueGetter: ({ row }) => getDescriptionCliente(row),
    },
    {
      field: 'clientes',
      headerName: t('com.muralis.qcx.nomeEmpresaClientes'),
      hide: true,
      type: 'string',
      flex: 1,
      valueGetter: ({ row }) => row?.clientes?.map((item) => item?.pessoa?.nome).join(', '),
    },
    {
      field: 'versao',
      headerName: t('com.muralis.qcx.versao'),
      hide: false,
      type: 'string',
      width: 110,
      valueGetter: ({ row }) => row?.versao ?? '',
    },
    {
      field: 'codigoIntegracao',
      headerName: t('com.muralis.qcx.codigo'),
      hide: false,
      type: 'string',
      width: 110,
      valueGetter: ({ row }) => row?.codigoIntegracao ?? '',
    },
    {
      field: 'statusIntegracao',
      headerName: t('com.muralis.qcx.status'),
      hide: false,
      type: 'string',
      width: 100,
      valueGetter: ({ row }) => row?.statusIntegracao ?? '',
    },
    {
      field: 'id',
      headerName: t('com.muralis.qcx.acoes.label'),
      width: 150,
      align: 'center',
      renderCell: ({ row: { id, active } }) => (
        <QCXPopupState popupId="popup-menu-catalogo-produtos">
          {(popupState) => (
            <>
              <IconButton key={`btn-more-options-${id}`} name={`btn-more-options-${id}`} {...bindTrigger(popupState)}>
                <MoreHorizIcon color="secondary" size={20} />
              </IconButton>
              <Menu {...bindMenu(popupState)}>
                <MenuItem
                  onClick={(event) => {
                    popupState.close(event);

                    handleClickOnEditButton(event, id);
                  }}
                >
                  <QCXListItemIcon>
                    <PageviewIcon color="primary" />
                  </QCXListItemIcon>
                  <Typography
                    variant="inherit"
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {t('com.muralis.qcx.acoes.consultar')}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={(event) => {
                    popupState.close(event);

                    handleClickLogsButton(event, id);
                  }}
                >
                  <QCXListItemIcon>
                    <DescriptionIcon color="primary" />
                  </QCXListItemIcon>
                  <Typography
                    variant="inherit"
                    style={{
                      fontSize: 12,
                    }}
                  >
                    Consultar logs
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={(event) => {
                    popupState.close(event);

                    handleRecuperarPortalUnicoButton(event, id);
                  }}
                >
                  <QCXListItemIcon>
                    <SyncIcon color="secondary" />
                  </QCXListItemIcon>
                  <Typography
                    variant="inherit"
                    style={{
                      fontSize: 12,
                    }}
                  >
                    Recuperar do Portal Único
                  </Typography>
                </MenuItem>
                {userHasDeleteRole(authInfo, 'catalogo-produtos') ? (
                  <MenuItem
                    onClick={(event) => {
                      popupState.close(event);

                      handleActivateInactivate(event, id);
                    }}
                  >
                    <QCXListItemIcon>
                      <DeleteIcon color="error" />
                    </QCXListItemIcon>
                    <Typography
                      variant="inherit"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {t('com.muralis.qcx.acoes.excluir')}
                    </Typography>
                  </MenuItem>
                ) : null}
              </Menu>
            </>
          )}
        </QCXPopupState>
      ),
    },
  ];

  const filterPropGetter = useCallback((item) => item.description, []);

  const handleConfigChange = useCallback(({ currentPage, pageSize, filterInputValue, advancedFilter }) => {
    const startDate = getISODateOrTodaysISODate(advancedFilter?.insertionDateRangeStart);
    const endDate = getISODateOrTodaysISODate(advancedFilter?.insertionDateRangeEnd);
    const dateRange =
      advancedFilter?.insertionDateRangeStart || advancedFilter?.insertionDateRangeEnd
        ? `${startDate};${endDate}`
        : undefined;
    const partnumberFilter = advancedFilter?.partnumbersTags?.join(';') ?? filterInputValue;
    const normalizedTags = advancedFilter?.tags?.reduce((acc, prev) => `${prev}~${acc}`, '');
    const statusFilters = advancedFilter?.statusFilters?.map((s) => s.value) ?? [];

    const pageParams = [
      { name: 'page', value: currentPage },
      { name: 'size', value: pageSize },
    ];
    const filterParams = [
      { name: 'partnumber', value: partnumberFilter },
      { name: 'insertionDateRange', value: dateRange },
      { name: 'clienteId', value: advancedFilter?.cliente },
      { name: 'ncm', value: advancedFilter?.ncm?.id },
      { name: 'tags', value: normalizedTags },
      { name: 'statuses', value: statusFilters },
    ];
    const params = pageParams.concat(filterParams);

    setFetchFilterParams(filterParams);

    dispatch(fetchCatalogoProdutosByFilterAsync(params));
  }, []);

  const rowsCount = useSelector(selectCatalogosProdutosCount);

  const handleEnableTransferMode = (event) => {
    event.stopPropagation();
    dispatch(changeToTransferMode());
  };

  const handleCancelTransfer = useCallback((event) => {
    event.stopPropagation();
    dispatch(resetMode());
  }, []);

  const handleConfirmTransfer = useCallback((event) => {
    event.stopPropagation();
    setOpenSelecionarTransmissaoModal(true);
  }, []);

  const handleStateChange = (event) => {
    setCatalogoState(event.target.value);
  };

  const handleSelecionarTransmissaoStateChange = (event) => {
    setTransmissaoState(event.target.value);
  };

  const handleSelecionarTransmissaoStateConfirm = () => {
    setOpenSelecionarTransmissaoModal(false);

    if (transmissaoState === 'catalogo') {
      setOpenStateModal(true);
    } else {
      handleStateConfirm(false);
    }
  };

  const handleStateConfirm = (transmitirProdutos = true) => {
    setOpenStateModal(false);

    const payload = {
      ids: [...relatedSelectionCatalogoList],
      state: catalogoState,
      transmitirProdutos,
    };

    dispatch(batchTransmiteCatalogosAsync(payload));

    dispatch(
      setSuccessFeedback({
        message: 'Sucesso ao enviar',
      })
    );

    dispatch(resetMode());
  };

  const navigateToFiltroRepasse = () =>
    history.push(t('com.muralis.qcx.url.moduloOperacionaisCatalogosProdutosFiltroRepasse'));

  const handleExportXML = ({ di, duimp }) => {
    const tipos = [];

    if (di) tipos.push('FATURA');
    if (duimp) tipos.push('DUIMP');

    const normalizedTipos = tipos.join(';');

    dispatch(
      generateExcelAsync([
        { name: 'page', value: 1 },
        { name: 'size', value: rowsCount + 1 },
        { name: 'tipos', value: normalizedTipos },
        ...fetchFilterParams,
      ])
    );
  };

  const templateProps = useMemo(
    () => ({
      page: {
        title: t('com.muralis.qcx.catalogoProdutos.label'),
        icon: <ViewListIcon />,
        breadcrumbs,
      },
      control: {
        ...(isNone
          ? {
              buttonNew: {
                description: t('com.muralis.qcx.catalogoProdutos.novoCatalogoProduto'),
                path: t('com.muralis.qcx.url.moduloOperacionaisCatalogosProdutosNovo'),
                onClick: handleClickOnButtonNew,
              },
            }
          : {}),

        others: [
          ...(isNone
            ? [
                {
                  description: 'Repassar',
                  startIcon: <ReplyAllIcon />,
                  color: 'secondary',
                  tooltip: {
                    description: 'Repassar e transmitir',
                  },
                  onClick: navigateToFiltroRepasse,
                },
                {
                  description: 'Relatório',
                  startIcon: <AssignmentTurnedInIcon />,
                  color: 'primary',
                  tooltip: {
                    description: 'Relatório de Envio',
                  },
                  onClick: handleShowReport,
                },
                {
                  description: 'Exportar',
                  startIcon: <GetAppIcon />,
                  color: 'primary',
                  tooltip: {
                    description: 'Exportar para XML',
                  },
                  onClick: () => setOpenExportarPopup(true),
                },
                {
                  description: 'Importar',
                  startIcon: <FileUploadIcon />,
                  color: 'primary',
                  tooltip: {
                    description: 'Importar Planilha',
                  },
                  onClick: () => fileInputRef.current.click(),
                },
                {
                  description: 'Transmitir',
                  startIcon: <SendIcon />,
                  color: 'secondary',
                  tooltip: {
                    description: 'Transmitir',
                  },
                  onClick: handleEnableTransferMode,
                },
              ]
            : []),
          ...(isTransfer
            ? [
                {
                  description: t('com.muralis.qcx.acoes.confirmarSelecao'),
                  startIcon: <CheckCircleIcon />,
                  color: 'secondary',
                  tooltip: {
                    description: t('com.muralis.qcx.acoes.confirmarSelecaoDestino', {
                      destino: isTransfer
                        ? t('com.muralis.qcx.transferencia.envioTransferencia')
                        : t('com.muralis.qcx.estorno.envioEstorno'),
                    }),
                  },
                  onClick: handleConfirmTransfer,
                  disabled: isEmptySelectionList,
                },
                {
                  description: <CancelOutlinedIcon />,
                  variant: 'outlined',
                  color: 'default',
                  tooltip: {
                    description: t('com.muralis.qcx.acoes.cancelar'),
                  },
                  onClick: handleCancelTransfer,
                },
              ]
            : []),
        ],
      },
      table: {
        columns,
        checkboxSelection: isTransfer,
        selectionModel: relatedSelectionCatalogoList,
        onSelectionModelChange: handleSelectionModelChange,
        onConfigChangeCallback: handleConfigChange,
        rowsPerPageOptions: [5, 10, 25, 50, 100],
        rowsCount,
      },
    }),
    [
      handleConfigChange,
      handleSelectionModelChange,
      handleClickOnButtonNew,
      t,
      columns,
      rowsCount,
      breadcrumbs,
      isTransfer,
      relatedSelectionCatalogoList,
      isEmptySelectionList,
    ]
  );

  const handleFileRead = (fileParam) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const binaryStr = event.target.result;

      // Lendo o arquivo usando o SheetJS
      const workbook = XLSX.read(binaryStr, { type: 'binary' });

      // Pegando apenas a primeira aba
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      // Convertendo para JSON
      const jsonData = XLSX.utils.sheet_to_json(sheet);
      setData(jsonData);
      console.log(jsonData);
      console.log('Dados do arquivo:', jsonData);

      // normalize
      const catalogosNormalizados = [];

      jsonData.forEach((catalogo) => {
        const catalogoAtual = {
          catalogoId: catalogo['Catalogo ID'],
          partnumber: catalogo.Partnumber,
          codIntegracao: catalogo['Cod. Integração'],
          versao: catalogo['Versão'],
        };

        // obtem atributos
        const maxSeq = 10;
        const atributosArr = [];
        for (let i = 1; i <= maxSeq; i += 1) {
          const chaveAtributo = `Atributo ${i}`;
          const tipoAtributo = `Tipo atributo ${i}`;
          const chaveValor = `Valor atributo ${i}`;

          if (catalogo[chaveAtributo]) {
            const matches = catalogo[chaveAtributo].match(/ATT_\d+/g);

            atributosArr.push({
              atributo: matches[0],
              valor: catalogo[chaveValor],
            });
          }
        }

        catalogoAtual.atributos = atributosArr;
        catalogosNormalizados.push(catalogoAtual);
      });

      dispatch(importaCatalogosPlanilhaAsync(catalogosNormalizados));
    };

    reader.readAsBinaryString(fileParam);
  };

  return (
    <SimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={catalogosProdutos}
      isLoading={isAlternativeLoading}
      filterPropGetter={filterPropGetter}
      authInfo={authInfo}
      requiredRoles={['catalogo-produtos']}
      advancedFilterFormChildren={CatalogoProdutosAdvancedFilterForm}
      advancedFilterKeyAccessors={['ncm.id', 'tags', 'partnumbersTags', 'cliente', 'statusFilters']}
    >
      <RelatorioPopup open={openRelatorio} onClose={() => setOpenRelatorio(false)} relatorio={relatorio} />
      <ExportarCatalogoPopup
        open={openExportarPopup}
        onClose={() => setOpenExportarPopup(false)}
        onConfirm={handleExportXML}
      />

      <div>
        <input
          ref={fileInputRef}
          type="file"
          accept=".xlsx, .xls, .csv"
          onChange={(event) => {
            console.log(event.target.files[0]);
            setFile(event.target.files[0]);
            handleFileRead(event.target.files[0]);
          }}
          style={{ display: 'none' }}
        />
      </div>

      <CatalogoProdutosSelecionarTransmissaoModal
        isModalOpen={openSelecionarTransmissaoModal}
        closeModal={() => setOpenSelecionarTransmissaoModal(false)}
        transmissaoState={transmissaoState}
        handleTransmissaoStateChange={handleSelecionarTransmissaoStateChange}
        handleConfirm={handleSelecionarTransmissaoStateConfirm}
      />

      <CatalogoProdutosTransmitirModal
        isModalOpen={openStateModal}
        closeModal={() => setOpenStateModal(false)}
        catalogoState={catalogoState}
        handleCatalogoStateChange={handleStateChange}
        handleConfirm={handleStateConfirm}
      />
    </SimpleConsultPageTemplate>
  );
}
