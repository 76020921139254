import _ from 'lodash';
import { formatBrazilianNumericDecimal } from '../../hooks/form/field/formatters';
import { isStrictEquals, padLeadingZeros, unnormalizeNumeral } from '../general-utils';

const STATUS_SOLICITADO = 'SOLICITADO';
const STATUS_CANCELADO = 'CANCELADO';
const STATUS_CALCULADO = 'CALCULADO';
const STATUS_FATURADO = 'FATURADO';
const STATUS_AGUARDANDO_VALIDACAO_NOTA = 'AGUARDANDO_VALIDACAO_NOTA';
const STATUS_NFSE_EMITIDA = 'NFSE_EMITIDA';

const isStatusSolicitado = (key) => isStrictEquals(key, STATUS_SOLICITADO);

const isStatusCancelado = (key) => isStrictEquals(key, STATUS_CANCELADO);

const isStatusCalculado = (key) => isStrictEquals(key, STATUS_CALCULADO);

const isStatusFaturado = (key) => isStrictEquals(key, STATUS_FATURADO);

const isStatusAguardandoValidacaoNota = (key) => isStrictEquals(key, STATUS_AGUARDANDO_VALIDACAO_NOTA);

const isStatusNfseEmitida = (key) => isStrictEquals(key, STATUS_NFSE_EMITIDA);

const getQuantidadeFrom = (values) => _.get(values, 'quantidade') || 0;

const getTotalMoedaFrom = (values) => {
  const totalMoeda = _.get(values, 'totalMoeda');
  const formatted = unnormalizeNumeral(totalMoeda, formatBrazilianNumericDecimal(2));

  return formatted;
};

const getTotalRealFrom = (values) => {
  const totalReal = _.get(values, 'totalReal');
  const formatted = unnormalizeNumeral(totalReal, formatBrazilianNumericDecimal(2));

  return formatted;
};

const formatProcessosCapas = (capas, max) => {
  const quantidadeCapas = capas.length;
  const capasExistentes = quantidadeCapas > 0;

  if (capasExistentes) {
    const mapProcessoCapas = capas
      .map((capa) => capa?.followUp?.numero)
      .slice(0, max && max > 0 ? max : quantidadeCapas);

    const formatProcessosCapasString = mapProcessoCapas.join(' | ');

    return quantidadeCapas > max ? `${formatProcessosCapasString}...` : formatProcessosCapasString;
  }

  return undefined;
};

const formatProcessosCapasReduzido = (capas, max) => {
  const quantidadeCapas = capas.length;
  const capasExistentes = quantidadeCapas > 0;

  if (capasExistentes) {
    const mapProcessoCapas = capas
      .map((capa) => capa?.numeroProcesso)
      .slice(0, max && max > 0 ? max : quantidadeCapas);

    const formatProcessosCapasString = mapProcessoCapas.join(' | ');

    return quantidadeCapas > max ? `${formatProcessosCapasString}...` : formatProcessosCapasString;
  }

  return undefined;
};

const formatIdentificacao = (identificacao) => {
  if (!(identificacao?.sequencial || identificacao?.ano)) {
    return undefined;
  }

  const { sequencial, ano } = identificacao;

  const sequencialWithLeadingZeros = padLeadingZeros(sequencial, 4);
  const formattedIdentificacao = `${sequencialWithLeadingZeros}/${ano}`;

  return formattedIdentificacao;
};

const FaturamentoUtils = Object.freeze({
  SOLICITADO: STATUS_SOLICITADO,
  CANCELADO: STATUS_CANCELADO,
  FATURADO: STATUS_FATURADO,
  NOTA_FISCAL_EMITIDA: STATUS_AGUARDANDO_VALIDACAO_NOTA,
  NFSE_EMITIDA: STATUS_NFSE_EMITIDA,
  isSolicitado: isStatusSolicitado,
  isCancelado: isStatusCancelado,
  isCalculado: isStatusCalculado,
  isFaturado: isStatusFaturado,
  isAguardandoValidacaoNota: isStatusAguardandoValidacaoNota,
  isStatusNfseEmitida,
  formatProcessosCapas,
  formatProcessosCapasReduzido,
  formatIdentificacao,
  getQuantidadeFrom,
  getTotalRealFrom,
  getTotalMoedaFrom,
});

export default FaturamentoUtils;
