/* eslint-disable import/extensions */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import { CircularProgress, Grid, IconButton, Snackbar } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import {
  AcUnit as AcUnitIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  Delete as DeleteIcon,
  DoneAll as DoneAllIcon,
  FileCopy as FileCopyIcon,
  MoreHoriz as MoreHorizIcon,
  Search as PageviewIcon,
  Receipt as ReceiptIcon,
} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import _, { isEmpty, isFunction } from 'lodash';
import { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FaCalculator as FaCalculatorIcon, FaDownload, FaUpload } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { v4 as uuid } from 'uuid';
import DeclaracaoUnicaExportacaoUtils from '../../../../components/declaracao-unica-exportacao/DeclaracaoUnicaExportacaoUtils';
import QCXMoveFocusInside from '../../../../components/focus-lock/QCXMoveFocusInside';
import { userHasDeleteRole } from '../../../../components/perfil/perfil-utils';
import QCXPopupState from '../../../../components/popup-state/QCXPopupState';
import DeclaracaoUnicaExportacaoContext from '../../../../contexts/export/declaracao-unica-exportacao/DeclaracaoUnicaExportacaoContext';
import { IDLE_CONTROL } from '../../../../features/config-control';
import { setQuery } from '../../../../features/controller/controllerSlice';
import {
  declaracaoUnicaExportacaoSelectors,
  selectDeclaracoesUnicasExportacao,
  selectDuesCount,
  selectHistoryModel,
  selectRefresh,
} from '../../../../features/declaracao-unica-exportacao/declaracaoUnicaExportacaoSelectors';
import {
  changeToConsultMode,
  changeToCreateMode,
  declaracaoUnicaExportacaoActions,
  failure,
  resetBackgroundMode,
  resetHistoryModel,
  resetMode,
  resetModel,
  resetRefresh,
  resetSubMode,
  setError,
  setProcesso,
  setRelatedItensXmlDanfeMetadata,
  setRelatedModelFollowUp,
  success,
  loading,
} from '../../../../features/declaracao-unica-exportacao/declaracaoUnicaExportacaoSlice';
import {
  activateByIdFromFaturaAsync,
  calculaDUEAposZerarCamposAsync,
  calculateByIdFromFaturaAsync,
  conferirByIdFromFaturaAsync,
  declaracaoUnicaExportacaoThunks,
  fetchDuesByFilterAsync,
  inactivateByIdFromFaturaAsync,
  liberarByIdFromFaturaAsync,
  reabrirByIdAsync,
  retificarByIdFromFaturaAsync,
} from '../../../../features/declaracao-unica-exportacao/declaracaoUnicaExportacaoThunks';
import { estadoActions } from '../../../../features/estado/estadoSlice';
import { setErrorFeedback } from '../../../../features/feedback/feedbackSlice';
import { followUpExportacaoActions } from '../../../../features/follow-up-exportacao/followUpExportacaoSlice';
import { followUpAPI } from '../../../../features/follow-up/followUpAPI';
import QCXInfoAlert from '../../../../shared-components/alert/QCXInfoAlert';
import QCXConfirmDialog from '../../../../shared-components/dialog/QCXConfirmDialog';
import QCXDialogContentText from '../../../../shared-components/dialog/QCXDialogContentText';
import QCXFinalFormDialog from '../../../../shared-components/dialog/QCXFinalFormDialog';
import QCXFinalDropzoneField from '../../../../shared-components/dropzone/QCXFinalDropzoneField';
import QCXFormListenerManager from '../../../../shared-components/form-listener/QCXFormListenerManager';
import QCXListItemIcon from '../../../../shared-components/list-item-icon/QCXListItemIcon';
import QCXSelectFollowUpExportacaoAutocompleteField from '../../../../shared-components/select-follow-up/QCXSelectFollowUpExportacaoAutocompleteField';
import { TipoProdutoUtils } from '../../../../shared-components/select-tipo-produto/tipoProdutoUtils';
import SpreadsheetImportModal from '../../../../ts/common/components/modals/spreadsheetImportModal.tsx';
import { useSpreadsheet } from '../../../../ts/common/hooks/spreadsheets/useSpreadsheet.ts';
import FollowUpUtils from '../../../../utils/general/follow-up/FollowUpUtils';
import { formatDate } from '../../../../utils/general/general-utils';
import {
  isCalculadaStatus,
  isConferidaStatus,
  isLiberadaStatus,
  isNaoCalculadaStatus,
  isNaoConferidaStatus,
} from '../../../../utils/general/operations/operationUtils';
import {
  CLOSED_STATUS,
  DONE_STATUS,
  FAIL_STATUS,
  SUBMITTING_STATUS,
  isOpenStatus,
} from '../../../../utils/hooks/form/dialog/formDialogUtils';
import useFormDialogAsync from '../../../../utils/hooks/form/dialog/useFormDialogAsync';
import useOperationMiddleware from '../../../../utils/hooks/operation/middleware/useOperationMiddleware';
import { isRefresh } from '../../../../utils/store/store-utils';
import { required } from '../../../../utils/validators/field/validator';

// eslint-disable-next-line import/no-unresolved
import QCXFinalMultilineTextField from '../../../../shared-components/final-text-field/QCXFinalMultilineTextField';
import { TIPO_DUE } from '../../../../utils/general/fatura/faturaUtils';
import useFormDialogSync from '../../../../utils/hooks/form/dialog/useFormDialogSync';
// eslint-disable-next-line import/no-unresolved
import { startLoading } from '../../../../features/application-state/applicationStateSlice';
import useGetApplicationState from '../../../../features/application-state/useGetApplicationState';
import SimpleConsultPageTemplate from '../../../../templates/simple-consult-page/SimpleConsultPageTemplate/SimpleConsultPageTemplate';
import useConfigurePopUp from '../../../../ts/common/hooks/popUps/useConfigurePopUp';
import SearchProcessoTextField from './components/SearchProcessoTextField';

export default function DeclaracaoUnicaExportacaoConsultPage({ authInfo = {} }) {
  const { isLoading } = useGetApplicationState();

  const [dueRetificacao, setDueRetificacao] = useState(null);
  const { t } = useTranslation();
  const [handleOpenModal, popupStatus, setStatus] = useFormDialogSync((data) => {
    setDueRetificacao({ id: data.id, xml: data.xmlIntegracao });
  });

  const history = useHistory();

  const { status } = useContext(DeclaracaoUnicaExportacaoContext);

  const dispatch = useDispatch();

  const duplicataRelatedModel = useSelector(declaracaoUnicaExportacaoSelectors.selectRelatedDuplicataModel);

  const [novaDueFollowUp, setNovaDueFollowUp] = useState(null);

  const spreadSheetConfig = useMemo(
    () => ({
      dataFetchEndpoint: `${process.env.REACT_APP_RELATORIOS_API_BASE_URL}/${process.env.REACT_APP_RELATORIOS_API_DUE_MERCADORIAS_ENDPOINT}`,
      dataSaveEndpoint: `${process.env.REACT_APP_RELATORIOS_API_BASE_URL}/${process.env.REACT_APP_RELATORIOS_API_DUE_MERCADORIAS_ENDPOINT}`,
      sheets: {
        0: {
          name: 'Mercadorias',
          formatedHeaders: [
            'Mercadoria ID',
            'Mercadoria Adicionais ID',
            'Part Number',
            'Quantidade Estatística',
            'Peso Líquido',
            'Unidade de Medida Estatística',
            'NCM',
            'Valor Total MCV do Item',
            'Valor Total do Item na Moeda',
            'Especificação',
            'Justificativa',
          ],
          headers: [
            'mercadoria_id',
            'mercadoria_adicionais_id',
            'partnumber',
            'quantidade_estatistica',
            'peso_liquido',
            'unidade_de_medida_estatistica',
            'ncm_code',
            'valor_total_mcv_moeda',
            'valor_total_mcv_real',
            'especificacao',
            'descricao_justificativa',
          ],
        },
        1: {
          name: 'Operações Exportação Consignada',
          formatedHeaders: [
            'Operação Exportação Consignada ID',
            'Part Number',
            'Item',
            'Número',
            'Processo',
            'Quantidade Estatística Associada',
            'Número da Adição',
            'Tipo Documento DUE',
            'Mercadoria DUE ID',
          ],
          headers: [
            'id',
            'partnumber',
            'item',
            'numero',
            'processo',
            'quantidade_estatistica',
            'numero_adicao',
            'tipo_documento_due',
            'mercadoria_due_id',
          ],
        },
        2: {
          name: 'Operações Exportação Temporária',
          formatedHeaders: [
            'Operação Exportação Temporária ID',
            'Part Number',
            'Item',
            'Número',
            'Processo',
            'Quantidade Estatística Associada',
            'Número da Adição',
            'Tipo Documento DUE',
            'Mercadoria DUE ID',
          ],
          headers: [
            'id',
            'partnumber',
            'item',
            'numero',
            'processo',
            'quantidade_estatistica',
            'numero_adicao',
            'tipo_documento_due',
            'mercadoria_due_id',
          ],
        },
      },
      fileNamePrefix: 'DUE Mercadorias',
      requestMethod: 'POST',
    }),
    []
  );

  const { generateSpreadsheet, importSpreadsheet, status: spreadsheetStatus } = useSpreadsheet(spreadSheetConfig);

  const [isSpreadsheetModalOpen, setIsSpreadsheetModalOpen] = useState(false);
  const [isSpreadsheetSnackbarOpen, setIsSpreadsheetSnackbarOpen] = useState(false);
  const [selectedDueId, setSelectedDueId] = useState(null);

  const handleSpreadsheetModalOpen = useCallback((id) => {
    setIsSpreadsheetModalOpen(true);
    setSelectedDueId(id);
  }, []);

  const handleOpenSpreadsheetSnackbar = useCallback(() => {
    setIsSpreadsheetSnackbarOpen(true);
  }, []);

  const handleCloseSpreadsheetSnackbar = useCallback(() => {
    setIsSpreadsheetSnackbarOpen(false);
  }, []);

  const handleImportSpreadsheet = useCallback(
    (file) => {
      if (file) {
        const tempSpreadSheetConfig = { ...spreadSheetConfig };
        tempSpreadSheetConfig.requestBody = {
          faturaId: selectedDueId,
        };
        importSpreadsheet(tempSpreadSheetConfig, file);
      }
    },
    [selectedDueId]
  );

  const [modalStatus, setModalStatus] = useState(DeclaracaoUnicaExportacaoUtils.MODAL_STATUS_IDLE);

  useEffect(() => {
    if (spreadsheetStatus?.message !== 'Ocioso') {
      handleOpenSpreadsheetSnackbar();
    }
    if (spreadsheetStatus?.message === 'Planilha importada com sucesso') {
      setIsSpreadsheetModalOpen(false);
    }
  }, [spreadsheetStatus]);

  const declaracoesUnicasExportacao = useSelector(selectDeclaracoesUnicasExportacao);
  const refresh = useSelector(selectRefresh);
  const historyModel = useSelector(selectHistoryModel);

  const fetchAll = useCallback(() => {
    dispatch(
      fetchDuesByFilterAsync([
        { name: 'page', value: 1 },
        { name: 'size', value: 10 },
      ])
    );
  }, []);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
    dispatch(resetSubMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
    dispatch(resetHistoryModel());
  }, []);

  const configureDependencies = useCallback(() => {
    dispatch(estadoActions.changeControlTo(IDLE_CONTROL));
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
  }, []);

  const resetModalStatus = useCallback(() => {
    setModalStatus(DeclaracaoUnicaExportacaoUtils.MODAL_STATUS_IDLE);
  }, []);

  const showModalAlert = useCallback(() => {
    setModalStatus(DeclaracaoUnicaExportacaoUtils.MODAL_STATUS_SHOW_ALERT);
  }, []);

  const showModalDragAndDrop = useCallback(() => {
    setModalStatus(DeclaracaoUnicaExportacaoUtils.MODAL_STATUS_SHOW_DRAG_AND_DROP);
  }, []);

  useEffect(
    () => () => {
      resetModalStatus();
    },
    [resetModalStatus]
  );

  useEffect(() => {
    const checkIfIsRefresh = () => {
      if (isRefresh(refresh)) {
        fetchAll();
        dispatch(resetRefresh());
      }
    };

    checkIfIsRefresh();
  }, [refresh]);

  useEffect(() => {
    const checkIfExistsQuery = () => {
      if (!isEmpty(historyModel) && historyModel?.last?.processo) {
        dispatch(setQuery(historyModel?.last?.processo));
      }
    };

    checkIfExistsQuery();
  }, [historyModel]);

  const filterAvailableFollowUp = useCallback(async () => {
    const availableFollowUpsResponse = await followUpAPI
      .fetchByFilter([
        {
          name: 'servicoProduto',
          value: TipoProdutoUtils.EXPORTACAO,
        },
        {
          name: 'filtrarVinculados',
          value: false,
        },
      ])
      .catch((reason) => {
        const errorMessage = t('com.muralis.qcx.erro.erroConsultarFollowUp', {
          dado: reason?.response && reason?.response?.data ? `: ${reason?.response?.data?.message}.` : '.',
        });
        dispatch(failure());
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
      });

    if (availableFollowUpsResponse?.status === 200) {
      const availableFollowUps = availableFollowUpsResponse?.data;
      if (_.isArrayLikeObject(availableFollowUps) && _.isEmpty(availableFollowUps)) {
        dispatch(failure());

        dispatch(
          setErrorFeedback({
            message: t('com.muralis.qcx.validacao.naoEncontradoFollowUp'),
          })
        );

        return;
      }

      dispatch(followUpExportacaoActions.resetControl());
      dispatch(
        followUpExportacaoActions.setList({
          data: availableFollowUps,
        })
      );
    }
  }, []);

  const [handleDuplicarClick, formDialogDuplicacaoStatus, handleFormDialogDuplicacaoStatus] = useFormDialogSync(
    async (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();
        await filterAvailableFollowUp();
        dispatch(
          declaracaoUnicaExportacaoActions.setRelatedDuplicataModel({
            id: data?.id,
            atributosAdicionais: {
              tipo: TIPO_DUE,
            },
            meta: {
              from: {
                processo: data?.processo,
              },
            },
          })
        );
      }
    },
    [filterAvailableFollowUp]
  );

  const handleDuplicarByProcessoSubmit = useCallback(
    (values) => {
      dispatch(
        declaracaoUnicaExportacaoThunks.duplicarByIdFromFaturaAsync({
          id: values?.id,
          meta: {
            from: {
              processo: values?.meta?.from?.processo,
            },
          },
          payload: {
            followUp: {
              id: values?.followUp?.id,
            },
            atributosAdicionais: {
              tipo: values?.atributosAdicionais?.tipo,
            },
          },
        })
      );

      handleFormDialogDuplicacaoStatus(DONE_STATUS);
    },
    [handleFormDialogDuplicacaoStatus]
  );

  const [handleOpenModalZerarCamposCalculoDue, zerarCamposCalculoDuePopUpConfig] = useConfigurePopUp({
    primaryConfirmCallback: async ({ id }) => {
      dispatch(calculaDUEAposZerarCamposAsync({ id }));
    },
    secondaryConfirmCallback: async ({ id }) => {
      dispatch(calculateByIdFromFaturaAsync({ id }));
    },
    initialOptions: {
      title: 'Deseja atualizar os campos do item da Mercadoria?',
      message:
        'Se optar por atualizar os campos, os campos "Valor Total MCV do Item" e "Valor Total do Item na Moeda" serão zerados antes do calcúlo.',
      endMessage: 'Escolha "Sim" para atualizar o campo e "Não" para manter os valores',
    },
  });

  const [handleOpenPopUpReabrirDue, reabriDUEPopUpConfig] = useConfigurePopUp({
    primaryConfirmCallback: async ({ id }) => {
      dispatch(reabrirByIdAsync({ id }));
    },
    initialOptions: {
      title: 'Deseja reabrir a DUE?',
    },
  });

  const [handleCalculoDUEIdClickMiddleware, operationOfCalcularDUEById] = useOperationMiddleware((data) => {
    handleOpenModalZerarCamposCalculoDue({ args: data });
  }, []);

  const [handleConferirByIdClickMiddleware, operationOfConferirById] = useOperationMiddleware(async (data) => {
    dispatch(
      conferirByIdFromFaturaAsync({
        id: data?.id,
      })
    );
  }, []);

  const handleConsultarClick = useCallback(
    (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        configureDependencies();

        dispatch(changeToConsultMode());

        history.push(t('com.muralis.qcx.url.exportacaoDUEDetalhes', { id: data?.id }));
      }
    },
    [configureDependencies, history, t]
  );

  const [handleLiberarByIdClickMiddleware, operationOfLiberarById] = useOperationMiddleware(async (data) => {
    dispatch(
      liberarByIdFromFaturaAsync({
        id: data?.id,
      })
    );
  }, []);

  const [handleActivateOrInactivateByIdClickMiddleware, operationOfActivateOrInactivateById] = useOperationMiddleware(
    async (data) => {
      const isInactivation = data?.active;

      if (isInactivation) {
        dispatch(inactivateByIdFromFaturaAsync(data?.id));

        return;
      }

      dispatch(activateByIdFromFaturaAsync(data?.id));
    },
    [declaracoesUnicasExportacao]
  );

  const [handleButtonNewClick, formDialogNewStatus, handleFormDialogNewStatus] = useFormDialogAsync(
    async (event) => {
      event.stopPropagation();

      configureDependencies();

      dispatch(changeToCreateMode());

      history.push(t('com.muralis.qcx.url.exportacaoDUENova'));
    },
    [history, t]
  );

  const normalize = useCallback(
    (unnormalizedData) => ({
      ...unnormalizedData,
      arquivosXmlDanfe: [
        ...(_.isArrayLikeObject(unnormalizedData?.arquivosXmlDanfe) ? unnormalizedData?.arquivosXmlDanfe : []),
      ],
    }),
    []
  );

  const handleFollowUpModalStatus = useCallback(
    () => async (followUp) => {
      if (followUp) {
        if (!followUp?.metadata && followUp?.id) {
          showModalDragAndDrop();
          return;
        }

        showModalAlert();
        return;
      }
      resetModalStatus();
    },
    [resetModalStatus, showModalAlert, showModalDragAndDrop]
  );

  const handleButtonNewClickMiddleware = useCallback(
    async (event) => handleButtonNewClick(event),
    [filterAvailableFollowUp, handleButtonNewClick]
  );

  const handleProcessoNewSubmit = useCallback(
    async (values) => {
      if (!novaDueFollowUp) return;

      try {
        dispatch(loading());

        handleFormDialogNewStatus(SUBMITTING_STATUS);

        const normalizedData = normalize(values);

        const arquivosXmlDanfe = normalizedData?.arquivosXmlDanfe;

        if (!_.isEmpty(arquivosXmlDanfe)) {
          const formData = FollowUpUtils.parseXmlDanfeListToFormData(arquivosXmlDanfe);

          await followUpAPI.processXml(novaDueFollowUp, formData);
        }

        const response = await followUpAPI.fetchByFilter([{ name: 'processo', value: values?.numeroProcesso }]);

        const followUp = response?.data?.[0];

        if (followUp) {
          const metadata = followUp?.metadata;
          let itensXmlDanfe = [];

          if (metadata) {
            itensXmlDanfe = JSON.parse(metadata)?.map((item) => ({
              ...item,
              id: uuid(),
            }));

            dispatch(setRelatedItensXmlDanfeMetadata(itensXmlDanfe));
          }

          dispatch(setRelatedModelFollowUp(followUp));

          dispatch(
            setProcesso({
              processo: followUp?.numero,
              followUp,
            })
          );

          resetModalStatus();
          handleFormDialogNewStatus(DONE_STATUS);
          dispatch(success());
        }
      } catch (error) {
        handleFormDialogNewStatus(FAIL_STATUS);

        const errorMessage = t('com.muralis.qcx.erro.erroCarregarProcessoEspecifico', {
          erro: !isEmpty(error?.response) ? error?.response?.data?.message : error?.message,
        });

        dispatch(failure());
        dispatch(
          setError({
            message: errorMessage,
          })
        );
      }
    },
    [normalize, resetModalStatus, handleFormDialogNewStatus, novaDueFollowUp]
  );

  const handleCalcularOnClick = useCallback(
    (row, popupState) => {
      const { id, atributosAdicionais, calculada } = row;

      const handleCalcularDUEClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const isSelectedDUENaoCalculada = isNaoCalculadaStatus(calculada);

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.acoes.confirmarOperacaoCalculo'),
              message: isSelectedDUENaoCalculada
                ? `${t('com.muralis.qcx.acoes.operacaoDUECalculada', {
                    processo: atributosAdicionais?.processo,
                  })}`
                : `${t('com.muralis.qcx.acoes.operacaoDUERecalculada', {
                    processo: atributosAdicionais?.processo,
                  })}`,
              endMessage: t('com.muralis.qcx.acoes.confirmacaoAcao', {
                acao: isSelectedDUENaoCalculada
                  ? t('com.muralis.qcx.acoes.calculo')
                  : t('com.muralis.qcx.acoes.recalculo'),
              }),
            },
          });
          const dataOfOperationCalcular = {
            id,
            meta: {
              calculada,
            },
          };

          handleCalculoDUEIdClickMiddleware(configureOperation, dataOfOperationCalcular);
        }
      };

      return handleCalcularDUEClick;
    },
    [handleCalculoDUEIdClickMiddleware]
  );

  const handleDUEConferirByIdClick = useCallback(
    (row, popupState) => {
      const { id, atributosAdicionais, metaFields } = row;

      const handleConferirByIdClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.acoes.confirmarOperacaoConferencia').toUpperCase(),
              message: t('com.muralis.qcx.confirmarOperacaoDUEConferida', {
                processo: atributosAdicionais?.processo,
              }),
              endMessage: t('com.muralis.qcx.certezaConferila'),
            },
          });

          const dataOfOperationConferir = {
            id,
            metaFields,
          };

          handleConferirByIdClickMiddleware(configureOperation, dataOfOperationConferir);
        }
      };

      return handleConferirByIdClick;
    },
    [handleConferirByIdClickMiddleware]
  );

  const isProcessoRetificacao = useCallback((processo) => {
    // Check if processo ends with "-RET"
    const regex = /-RET$/;
    return regex.test(processo);
  }, []);

  const handleDUEActivateOrInactivateByIdClick = useCallback(
    (row, popupState) => {
      const { id, atributosAdicionais, active } = row;

      const handleActivateOrInactivateByIdClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.acoes.confirmarOperacaoExclusao').toUpperCase(),
              message: t('com.muralis.qcx.certezaExcluirRecuperarDUE', {
                acao: active
                  ? t('com.muralis.qcx.acoes.excluir').toLowerCase()
                  : t('com.muralis.qcx.acoes.recuperar').toLowerCase(),
                processo: atributosAdicionais?.processo,
              }),
            },
          });

          const dataOfOperationActivateOrInactivate = {
            id,
            active,
          };

          handleActivateOrInactivateByIdClickMiddleware(configureOperation, dataOfOperationActivateOrInactivate);
        }
      };

      return handleActivateOrInactivateByIdClick;
    },
    [handleActivateOrInactivateByIdClickMiddleware]
  );

  const handleDUELiberarByIdClick = useCallback(
    (row, popupState) => {
      const { id, atributosAdicionais, metaFields } = row;

      let handleLiberarByIdClick;

      if (atributosAdicionais?.registro) {
        handleLiberarByIdClick = () => {
          handleOpenModal({ ...atributosAdicionais, id });
        };
      } else {
        handleLiberarByIdClick = (event) => {
          if (isFunction(event?.stopPropagation)) {
            popupState.close(event);

            event.stopPropagation();

            const configureOperation = () => ({
              options: {
                title: t('com.muralis.qcx.confirmarLiberacao').toUpperCase(),
                message: t('com.muralis.qcx.certezaLiberarRegistroDUE', {
                  processo: atributosAdicionais?.processo,
                }),
              },
            });

            const dataOfOperationLiberar = {
              id,
              metaFields,
            };

            handleLiberarByIdClickMiddleware(configureOperation, dataOfOperationLiberar);
          }
        };
      }

      return handleLiberarByIdClick;
    },
    [handleLiberarByIdClickMiddleware]
  );

  const isFormDialogNewOpen = useMemo(() => isOpenStatus(formDialogNewStatus), [formDialogNewStatus]);

  const handleReabrirClick = useCallback(({ id }) => {
    handleOpenPopUpReabrirDue({ args: { id } });
  });

  const handleCloseFormDialogNew = useCallback(() => {
    setNovaDueFollowUp(null);

    handleFormDialogNewStatus(CLOSED_STATUS);
    _.debounce(() => {
      resetModalStatus();
    }, 200)();
  }, [handleFormDialogNewStatus, resetModalStatus]);

  const columns = useMemo(
    () => [
      {
        field: 'processo',
        headerName: t('com.muralis.qcx.NumeroProcesso'),
        headerAlign: 'center',
        align: 'center',
        flex: 150,
        valueGetter: ({ row }) => row?.atributosAdicionais?.processo || '-',
      },
      {
        field: 'exportador',
        headerName: t('com.muralis.qcx.exportador.label'),
        headerAlign: 'center',
        align: 'center',
        flex: 180,
        valueGetter: ({ row }) => row?.importador || '-',
      },
      {
        field: 'numero',
        headerName: t('com.muralis.qcx.numeroDUE'),
        headerAlign: 'center',
        align: 'center',
        flex: 150,
        valueGetter: ({ row }) => row?.atributosAdicionais?.registro || '-', // TODO: Alterar nome da prop
      },
      {
        field: 'dataRegistro',
        headerName: t('com.muralis.qcx.dataRegistro'),
        headerAlign: 'center',
        align: 'center',
        flex: 180,
        valueGetter: ({ row }) => formatDate(row?.atributosAdicionais?.dataRegistro) || '-', // TODO: Alterar nome da prop
      },
      {
        field: 'status',
        headerName: t('com.muralis.qcx.status'),
        headerAlign: 'center',
        align: 'center',
        flex: 120,
        valueGetter: ({ row }) => row?.atributosAdicionais?.status || '-',
      },
      {
        field: 'calculada',
        headerName: t('com.muralis.qcx.calculada'),
        headerAlign: 'center',
        align: 'center',
        type: 'boolean',
        flex: 150,
        valueGetter: ({ row }) => isCalculadaStatus(row?.calculada),
      },
      {
        field: 'conferida',
        headerName: t('com.muralis.qcx.conferida'),
        headerAlign: 'center',
        align: 'center',
        type: 'boolean',
        flex: 150,
        valueGetter: ({ row: { atributosAdicionais } }) => isConferidaStatus(atributosAdicionais?.conferida),
      },
      {
        field: 'actions',
        headerName: t('com.muralis.qcx.acoes.label'),
        headerAlign: 'center',
        align: 'center',
        flex: 180,
        renderCell: ({ row: { id, atributosAdicionais, calculada, active, metaFields } }) => (
          <>
            <QCXPopupState popupId="popup-menu-due">
              {(popupState) => (
                <>
                  <IconButton
                    key={`btn-more-options-${id}`}
                    name={`btn-more-options-${id}`}
                    {...bindTrigger(popupState)}
                  >
                    <MoreHorizIcon color="secondary" size={20} />
                  </IconButton>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);

                        handleConsultarClick(event, {
                          id,
                          status,
                        });
                      }}
                    >
                      <QCXListItemIcon>
                        <PageviewIcon fontSize="small" color="primary" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.consultar').toUpperCase()}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={handleCalcularOnClick(
                        {
                          id,
                          atributosAdicionais,
                          calculada,
                        },
                        popupState
                      )}
                    >
                      <QCXListItemIcon>
                        <FaCalculatorIcon color="green" size={20} />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {isNaoCalculadaStatus(calculada)
                          ? t('com.muralis.qcx.acoes.calcular').toUpperCase()
                          : t('com.muralis.qcx.acoes.recalcular').toUpperCase()}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={handleDUEConferirByIdClick(
                        {
                          id,
                          atributosAdicionais,
                          metaFields,
                        },
                        popupState
                      )}
                      disabled={isNaoCalculadaStatus(calculada) || isConferidaStatus(atributosAdicionais?.conferida)}
                    >
                      <QCXListItemIcon>
                        <DoneAllIcon
                          color="green"
                          size={20}
                          htmlColor={
                            isNaoCalculadaStatus(calculada) || isConferidaStatus(atributosAdicionais?.conferida)
                              ? 'rgba(0, 0, 0, 0.38)'
                              : 'green'
                          }
                        />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.conferir').toUpperCase()}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={handleDUELiberarByIdClick(
                        {
                          id,
                          atributosAdicionais,
                          metaFields,
                        },
                        popupState
                      )}
                      disabled={
                        isNaoConferidaStatus(atributosAdicionais?.conferida) ||
                        (isLiberadaStatus(atributosAdicionais?.liberada) &&
                          !isProcessoRetificacao(atributosAdicionais?.processo))

                        // (isNaoCalculadaStatus(calculada)
                        //   || isNaoConferidaStatus(atributosAdicionais?.conferida)
                        //   || isLiberadaStatus(atributosAdicionais?.liberada))
                        // && !isProcessoRetificacao(atributosAdicionais?.processo)
                      }
                    >
                      <QCXListItemIcon>
                        <AssignmentTurnedInIcon
                          color="orange"
                          size={20}
                          htmlColor={
                            isNaoConferidaStatus(atributosAdicionais?.conferida) ||
                            (isLiberadaStatus(atributosAdicionais?.liberada) &&
                              !isProcessoRetificacao(atributosAdicionais?.processo))
                              ? 'rgba(0, 0, 0, 0.38)'
                              : 'orange'
                          }
                        />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                        disabled
                      >
                        {t('com.muralis.qcx.acoes.liberarRegistro').toUpperCase()}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);
                        handleDuplicarClick(event, {
                          id,
                          processo: atributosAdicionais?.processo,
                        });
                      }}
                      disabled={
                        isNaoCalculadaStatus(calculada) ||
                        isNaoConferidaStatus(atributosAdicionais?.conferida) ||
                        !isLiberadaStatus(atributosAdicionais?.liberada)
                      }
                    >
                      <QCXListItemIcon>
                        <FileCopyIcon
                          fontSize="small"
                          htmlColor={
                            isNaoCalculadaStatus(calculada) ||
                            isNaoConferidaStatus(atributosAdicionais?.conferida) ||
                            !isLiberadaStatus(atributosAdicionais?.liberada)
                              ? 'rgba(0, 0, 0, 0.38)'
                              : 'orange'
                          }
                        />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {'Retificar DUE'.toUpperCase()}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);
                        handleReabrirClick({ id });
                      }}
                      disabled={!atributosAdicionais?.registro}
                    >
                      <QCXListItemIcon>
                        <AcUnitIcon
                          fontSize="small"
                          htmlColor={
                            isNaoCalculadaStatus(calculada) ||
                            isNaoConferidaStatus(atributosAdicionais?.conferida) ||
                            !isLiberadaStatus(atributosAdicionais?.liberada)
                              ? 'rgba(0, 0, 0, 0.38)'
                              : 'orange'
                          }
                        />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.reabrir').toUpperCase()}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={() => generateSpreadsheet(`id=${id}`, atributosAdicionais?.processo)}
                      disabled={spreadsheetStatus?.loading}
                    >
                      <QCXListItemIcon>
                        {spreadsheetStatus?.loading ? (
                          <CircularProgress size={20} />
                        ) : (
                          <FaDownload color="orange" size={20} />
                        )}
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        EXPORTAR PLANILHA DE MERCADORIAS
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={() => handleSpreadsheetModalOpen(id)}>
                      <QCXListItemIcon>
                        <FaUpload color="green" size={20} />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        IMPORTAR PLANILHA DE MERCADORIAS
                      </Typography>
                    </MenuItem>
                    {userHasDeleteRole(authInfo, 'exportacao-due') && (
                      <>
                        <MenuItem
                          onClick={handleDUEActivateOrInactivateByIdClick(
                            {
                              id,
                              atributosAdicionais,
                              active,
                            },
                            popupState
                          )}
                          disabled={!active}
                        >
                          <QCXListItemIcon>
                            <DeleteIcon size={20} color={active ? 'error' : 'disabled'} />
                          </QCXListItemIcon>
                          <Typography
                            variant="inherit"
                            style={{
                              fontSize: 12,
                            }}
                            disabled
                          >
                            {t('com.muralis.qcx.acoes.excluir').toUpperCase()}
                          </Typography>
                        </MenuItem>
                      </>
                    )}
                  </Menu>
                </>
              )}
            </QCXPopupState>
          </>
        ),
      },
    ],
    [
      handleDUEActivateOrInactivateByIdClick,
      handleDUELiberarByIdClick,
      handleDUEConferirByIdClick,
      handleCalcularOnClick,
      handleConsultarClick,
      spreadsheetStatus,
    ]
  );

  const formDialogDuplicacaoContent = useMemo(
    () =>
      t('com.muralis.qcx.mensagem.preenchaNumeroNovoProcessoDuplicarDUE', {
        processo: duplicataRelatedModel?.meta?.from?.processo,
      }),
    [duplicataRelatedModel]
  );

  const isFormDialogDuplicacaoOpen = useMemo(() => isOpenStatus(formDialogDuplicacaoStatus));

  const handleCloseFormDialogDuplicacao = useCallback(
    () => handleFormDialogDuplicacaoStatus(CLOSED_STATUS),
    [handleFormDialogDuplicacaoStatus]
  );

  const handleCloseFormDialog = useCallback(() => setStatus(CLOSED_STATUS), [setStatus]);

  const open = useMemo(() => isOpenStatus(popupStatus), [popupStatus]);
  const handleSubmit = useCallback(
    async (values) => {
      dispatch(
        retificarByIdFromFaturaAsync({
          id: dueRetificacao?.id,
          motivo: values?.motivo,
        })
      );

      handleCloseFormDialog();
    },
    [dueRetificacao]
  );

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: t('com.muralis.qcx.inicio'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloExportacao'),
          name: t('com.muralis.qcx.exportacao.label'),
        },
      },
      {
        text: {
          name: t('com.muralis.qcx.dueExportacao.sigla'),
        },
        default: true,
      },
    ],
    []
  );

  const handleConfigChange = useCallback(({ currentPage, pageSize, filterInputValue }) => {
    dispatch(
      fetchDuesByFilterAsync([
        { name: 'page', value: currentPage },
        { name: 'size', value: pageSize },
        { name: 'numero', value: filterInputValue },
      ])
    );
  }, []);

  const rowsCount = useSelector(selectDuesCount);

  const templateProps = useMemo(
    () => ({
      page: {
        title: t('com.muralis.qcx.dueExportacao.sigla'),
        icon: <ReceiptIcon />,
        breadcrumbs,
      },
      control: {
        buttonNew: {
          description: t('com.muralis.qcx.dueExportacao.novaDUE'),
          onClick: handleButtonNewClickMiddleware,
        },
      },
      table: {
        onConfigChangeCallback: handleConfigChange,
        columns,
        rowsCount,
      },
    }),
    [breadcrumbs, columns, handleButtonNewClickMiddleware, handleConfigChange, rowsCount]
  );

  return (
    <SimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={declaracoesUnicasExportacao}
      isLoading={isLoading}
      buttonNewText={t('com.muralis.qcx.acoes.nova').toUpperCase()}
      authInfo={authInfo}
      requiredRoles={['exportacao-due']}
    >
      <SpreadsheetImportModal
        isOpen={isSpreadsheetModalOpen}
        setIsOpen={setIsSpreadsheetModalOpen}
        onSubmit={handleImportSpreadsheet}
        instructions="Não modifique as colunas Mercadoria ID e Mercadoria Adicionais ID"
      />
      <Snackbar
        open={isSpreadsheetSnackbarOpen}
        onClose={handleCloseSpreadsheetSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert severity={spreadsheetStatus?.severtiy ?? 'info'}>{spreadsheetStatus.message}</Alert>
      </Snackbar>
      <QCXFinalFormDialog
        key="form-dialog-duplicacao-due"
        id="form-dialog-duplicacao-due"
        title="Duplicação da DUE"
        content={formDialogDuplicacaoContent}
        dialogActionsProps={{
          button: {
            submit: {
              description: t('com.muralis.qcx.acoes.duplicar'),
              size: 'small',
            },
            cancel: {
              description: t('com.muralis.qcx.acoes.cancelar'),
              size: 'small',
            },
          },
        }}
        open={isFormDialogDuplicacaoOpen}
        initialValues={duplicataRelatedModel}
        onSubmit={handleDuplicarByProcessoSubmit}
        onClose={handleCloseFormDialogDuplicacao}
      >
        {() => (
          <QCXMoveFocusInside>
            <QCXSelectFollowUpExportacaoAutocompleteField
              key="select-autocomplete-processo-field"
              id="select-autocomplete-processo-field"
              name="followUp.id"
              label={t('com.muralis.qcx.processo')}
              validate={required}
              controlled
              required
            />
          </QCXMoveFocusInside>
        )}
      </QCXFinalFormDialog>
      <QCXFinalFormDialog
        key="form-dialog-retificar-due"
        id="form-dialog-retificar-due"
        title="Retificar DUE"
        content="Preencha as informações para retificar a DUE"
        dialogActionsProps={{
          button: {
            submit: {
              description: 'Retificar',
              size: 'small',
              disabled: false,
            },
            cancel: {
              description: 'Cancelar',
              size: 'small',
            },
          },
        }}
        open={open}
        initialValues={{}}
        onSubmit={handleSubmit}
        onClose={handleCloseFormDialog}
      >
        {() => (
          <Grid item container xs={12}>
            <Grid item xs={12}>
              <QCXMoveFocusInside>
                <QCXFinalMultilineTextField
                  id="multiline-text-field-mensagem-email"
                  key="multiline-text-field-mensagem-email"
                  name="motivo"
                  label="Motivo da retificação"
                  rows={10}
                />
              </QCXMoveFocusInside>
            </Grid>
          </Grid>
        )}
      </QCXFinalFormDialog>
      <QCXConfirmDialog
        key="confirm-dialog-calculo-due"
        id="confirm-dialog-calculo-due"
        open={operationOfCalcularDUEById?.active}
        title={operationOfCalcularDUEById?.title}
        content={operationOfCalcularDUEById?.message}
        endContent={operationOfCalcularDUEById?.endMessage}
        onConfirm={operationOfCalcularDUEById.confirm}
        onClose={operationOfCalcularDUEById?.reset}
      />
      <QCXConfirmDialog
        key="confirm-dialog-zerar-campos-calculo-due"
        id="confirm-dialog-zerar-campos-calculo-due"
        open={zerarCamposCalculoDuePopUpConfig?.isPopUpOpen}
        title={zerarCamposCalculoDuePopUpConfig?.title}
        content={zerarCamposCalculoDuePopUpConfig?.message}
        endContent={zerarCamposCalculoDuePopUpConfig?.endMessage}
        onConfirm={zerarCamposCalculoDuePopUpConfig?.confirmPrimaryAction}
        onSecondaryConfirm={zerarCamposCalculoDuePopUpConfig?.confirmSecondaryAction}
        onClose={zerarCamposCalculoDuePopUpConfig?.reset}
        buttonGroupOptions={{
          confirm: {
            description: 'Sim',
          },
          secondaryConfirm: {
            description: 'Não',
          },
        }}
      />
      <QCXConfirmDialog
        key="confirm-dialog-conferencia-due"
        id="confirm-dialog-conferencia-due"
        open={operationOfConferirById?.active}
        title={operationOfConferirById?.title}
        content={operationOfConferirById?.message}
        endContent={operationOfConferirById?.endMessage}
        onConfirm={operationOfConferirById.confirm}
        onClose={operationOfConferirById?.reset}
      />
      <QCXConfirmDialog
        key="confirm-dialog-liberacao-due"
        id="confirm-dialog-liberacao-due"
        open={operationOfLiberarById?.active}
        title={operationOfLiberarById?.title}
        content={operationOfLiberarById?.message}
        endContent={operationOfLiberarById?.endMessage}
        onConfirm={operationOfLiberarById.confirm}
        onClose={operationOfLiberarById?.reset}
      />
      <QCXConfirmDialog
        key="confirm-dialog-reabrir-due"
        id="confirm-dialog-reabrir-due"
        open={reabriDUEPopUpConfig?.isPopUpOpen}
        title={reabriDUEPopUpConfig?.title}
        content={reabriDUEPopUpConfig?.message}
        endContent={reabriDUEPopUpConfig?.endMessage}
        onConfirm={reabriDUEPopUpConfig.confirmPrimaryAction}
        onClose={reabriDUEPopUpConfig?.reset}
      />
      <QCXFinalFormDialog
        key="form-dialog-processo-due"
        id="form-dialog-processo-due"
        title={t('com.muralis.qcx.dueExportacao.novoRegistroDUE')}
        content={t('com.muralis.qcx.preencherCampoContinuar')}
        dialogActionsProps={{
          button: {
            submit: {
              description: t('com.muralis.qcx.acoes.continuar'),
              size: 'small',
            },
            cancel: {
              description: t('com.muralis.qcx.acoes.cancelar'),
              size: 'small',
            },
          },
        }}
        open={isFormDialogNewOpen}
        initialValues={{}}
        onSubmit={handleProcessoNewSubmit}
        onClose={handleCloseFormDialogNew}
        submitDisabled={!novaDueFollowUp}
      >
        {({ values }) => (
          <>
            <Grid item container spacing={2} xs={12}>
              {DeclaracaoUnicaExportacaoUtils.isModalStatusShowAlert(modalStatus) && (
                <Grid
                  item
                  style={{
                    paddingBottom: '24px',
                  }}
                  xs={12}
                >
                  <QCXInfoAlert>
                    <Trans i18nKey="com.muralis.qcx.mensagem.processoPossuiArquivosXMLDANFE">
                      <strong />
                      <strong />
                    </Trans>
                  </QCXInfoAlert>
                </Grid>
              )}
              <Grid item xs={12}>
                <QCXMoveFocusInside>
                  <SearchProcessoTextField
                    onSearchCallback={(f) => {
                      handleFollowUpModalStatus()(f);
                      setNovaDueFollowUp(f);
                    }}
                  />
                </QCXMoveFocusInside>
              </Grid>
              {DeclaracaoUnicaExportacaoUtils.isModalStatusShowDragAndDrop(modalStatus) && (
                <Grid item xs={12}>
                  <QCXDialogContentText id="final-form-dialog-description">
                    {t('com.muralis.qcx.dueExportacao.crieDUEDocumentoXml')}
                  </QCXDialogContentText>
                  <QCXFinalDropzoneField
                    id="final-dropzone-multiple-file-field"
                    key="final-dropzone-multiple-file-field"
                    name="arquivosXmlDanfe"
                    options={{
                      accept: {
                        'application/xml': ['.xml'],
                        'text/xml': ['.xml'],
                      },
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </>
        )}
      </QCXFinalFormDialog>
      <QCXConfirmDialog
        key="confirm-dialog-activate-inactivate-due"
        id="confirm-dialog-activate-inactivate-due"
        open={operationOfActivateOrInactivateById?.active}
        title={operationOfActivateOrInactivateById?.title}
        content={operationOfActivateOrInactivateById?.message}
        endContent={operationOfActivateOrInactivateById?.endMessage}
        onConfirm={operationOfActivateOrInactivateById.confirm}
        onClose={operationOfActivateOrInactivateById?.reset}
      />
    </SimpleConsultPageTemplate>
  );
}
