import { Grid, makeStyles } from '@material-ui/core';
import _, { isEmpty, isFunction } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { selectCatalogosProdutos } from '../../features/catalogo-produtos/catalogoProdutosSlice';
import { selectRelatedModelFollowUp } from '../../features/declaracao-unica-exportacao/declaracaoUnicaExportacaoSelectors';
import { estadoActions } from '../../features/estado/estadoSlice';
import { selectRelatedMercadoriaModel } from '../../features/fatura/faturaSelectors';
import { paisActions } from '../../features/pais/paisSlice';
import { selectTiposDocumentoDueConsignacao } from '../../features/tipo-documento-due-consignacao/tipoDocumentoDueConsignacaoSlice';
import { selectTiposDocumentoDueTemporaria } from '../../features/tipo-documento-due-temporaria/tipoDocumentoDueTemporariaSlice';
import { fetchById as fetchTipoDocumentoDueByIdAsync } from '../../features/tipo-documento-due/tipoDocumentoDueAPI';
import { selectTiposDocumentoFiscal } from '../../features/tipo-documento-fiscal/tipoDocumentoFiscalSlice';
import { unidadeDeMedidaActions } from '../../features/unidade-medida/unidadeDeMedidaSlice';
import {
  vinculoOperacaoExportacaoConsignacaoVinculadaDUEActions,
  vinculoOperacaoExportacaoConsignacaoVinculadaDUESelectors,
} from '../../features/vinculo-operacao-exportacao-consignacao-vinculada-due/vinculoOperacaoExportacaoConsignacaoVinculadaDUESlice';
import {
  vinculoOperacaoExportacaoTemporariaVinculadaDUEActions,
  vinculoOperacaoExportacaoTemporariaVinculadaDUESelectors,
} from '../../features/vinculo-operacao-exportacao-temporaria-vinculada-due/vinculoOperacaoExportacaoTemporariaVinculadaDUESlice';
import QCXFinalMultilineTextField from '../../shared-components/final-text-field/QCXFinalMultilineTextField';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import {
  QUANTIDADE_X_VALOR,
  getTipoCalculoValue,
} from '../../shared-components/select-forma-calculo/formaCalculoUtils';
import { PAIS_BRASIL_CODE, configurePaisGetterByCode } from '../../shared-components/select-pais/paisUtils';
import QCXSelectTipoDocumentoDueConsignacaoAutocomplete from '../../shared-components/select-tipo-documento-due/QCXSelectTipoDocumentoDueConsignacaoAutocomplete';
import QCXSelectTipoDocumentoDueTemporariaAutocomplete from '../../shared-components/select-tipo-documento-due/QCXSelectTipoDocumentoDueTemporariaAutocomplete';
import TipoDocumentoDueUtils from '../../shared-components/select-tipo-documento-due/TipoDocumentoDueUtils';
import TipoDocumentoFiscalUtils from '../../shared-components/select-tipo-documento-fiscal/TipoDocumentoFiscalUtils';
import QCXCustomRegistrationTabControlTemplate from '../../templates/custom-registration-one-to-many-page/QCXCustomRegistrationTabControlTemplate';
import useFaturaMercadoriaListeners from '../../utils/general/fatura/faturaMercadoriaListeners';
import { TIPO_FATURA, getPendenciasFaturaOcr } from '../../utils/general/fatura/faturaUtils';
import { isStrictEquals, unnormalizeNumeral } from '../../utils/general/general-utils';
import { formatBrazilianNumericDecimal } from '../../utils/hooks/form/field/formatters';
import useExternallyFilling from '../../utils/hooks/form/field/useExternallyFilling';
import useValidators from '../../utils/hooks/useValidators';
import { isCurrentOrPastDate, validCpfOrCnpj } from '../../utils/validators/field/validator';
import QCXAtosConcessoriosFormGroup from '../declaracao-unica-exportacao/QCXAtosConcessoriosFormGroup';
import QCXDadosNotaFiscalFormGroup from '../declaracao-unica-exportacao/QCXDadosNotaFiscalFormGroup';
import QCXFinalAtributoNcmMercadoriaGroup from '../declaracao-unica-exportacao/QCXFinalAtributoNcmMercadoriaGroup';
import QCXJustificativaMercadoriaGroup from '../declaracao-unica-exportacao/QCXJustificativaMercadoriaGroup';
import QCXLPCOFormGroup from '../declaracao-unica-exportacao/QCXLPCOFormGroup';
import QCXMercadoriaAmparadaPorFormGroup from '../declaracao-unica-exportacao/QCXMercadoriaAmparadaPorFormGroup';
import QCXNotaComplementarFormGroup from '../declaracao-unica-exportacao/QCXNotaComplementarFormGroup';
import QCXNotaReferenciadaFormGroup from '../declaracao-unica-exportacao/QCXNotaReferenciadaFormGroup';
import QCXNotaReferenciadaFormularioFormGroup from '../declaracao-unica-exportacao/QCXNotaReferenciadaFormularioFormGroup';
import QCXOperacaoExportacaoFormGroup from '../declaracao-unica-exportacao/QCXOperacaoExportacaoFormGroup';
import QCXFaturaForceTabReload from './QCXFaturaForceTabReload';
import QCXFaturaMercadoriaExportacaoStandardFormGroup from './QCXFaturaMercadoriaExportacaoStandardFormGroup';

const useStyles = makeStyles((theme) => ({
  valorTotalReaisFieldLeft: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '16px',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '16px',
    },
    paddingBottom: '16px',
  },
}));

export default function QCXFaturaMercadoriaExportacaoForm({
  isIdle,
  isLoading,
  isPreparingAction,
  isCreate,
  isConsult,
  isUpdate,
  isSubNone,
  isSubCreate,
  isSubConsult,
  isSubUpdate,
  isBackgroundDelete,
  isSubBackgroundCreate,
  isSubBackgroundUpdate,
  isSubBackgroundDelete,
  faturaValues,
  initialValues,
  fatura,
  tipo = TIPO_FATURA,
  defaultItem,
  handleSubmit,
  handleNew,
  handleEdit,
  handleChangeToSubConsult,
  handleChangeModel,
  handleCancelCreate,
  handleCancelUpdate,
  handleChangeToConsult,
  handleRemoveByForm,
  handleRemoveByTable,
  handleClearForm,
  handleChangeToSubNone,
  handleChangeToUpdate,
  handleMetadataUpdate,
  handleLoadingStatus,
  handleResetStatus,
  controlComponentProps,
  tabManagerProps = {},
  formPaginationControlProps = {},
  buttonGroupOptions = {},
  ...restProps
}) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const classes = useStyles();

  const [isFirstFormLoadValues, setIsFirstFormLoadValues] = useState(true);
  const [isFilling, subscribeToFilling] = useExternallyFilling();

  const [isQuantidade, setIsQuantidadeUnidade] = useState(false);
  const [isQuilograma, setIsQuilogramaUnidade] = useState(false);
  const [estadosCarregados, setEstadosCarregados] = useState(false);

  const unidadeMedidaList = useSelector(unidadeDeMedidaActions.selectUnidadesdeMedida);
  const listCatalogo = useSelector(selectCatalogosProdutos);
  const tiposDocumentoDueConsignacao = useSelector(selectTiposDocumentoDueConsignacao);
  const tiposDocumentoDueTemporaria = useSelector(selectTiposDocumentoDueTemporaria);
  const paises = useSelector(paisActions.selectPaises);
  const relatedFollowUp = useSelector(selectRelatedModelFollowUp);
  const tiposDocumentosFiscais = useSelector(selectTiposDocumentoFiscal);

  const mercadorias = useMemo(() => {
    const faturaMercadorias = _.cloneDeep(fatura?.mercadorias || []);
    const faturaPendencias = getPendenciasFaturaOcr(fatura);

    if (faturaPendencias !== null && faturaPendencias.temPendencias) {
      faturaMercadorias.forEach((mercadoria) => {
        const pendenciaMercadoria = faturaPendencias.mercadorias.find(
          (pendencia) => pendencia.idMercadoria === mercadoria.id
        );

        if (pendenciaMercadoria) {
          mercadoria.metadata = pendenciaMercadoria;
          mercadoria.pendencias = pendenciaMercadoria.pendencias;
        }
      });
    }

    return faturaMercadorias;
  }, [fatura]);

  useEffect(() => {
    if (isSubNone) {
      setIsFirstFormLoadValues(true);
    }
  }, [isSubNone]);

  useEffect(() => {
    dispatch(paisActions.fetchAllAsync());

    const resetDependencies = () => {
      dispatch(estadoActions.resetList());
      dispatch(paisActions.resetList());
      setEstadosCarregados(false);
    };

    return resetDependencies;
  }, []);

  const isFillCommonDataMode = useMemo(() => isSubCreate, [isSubCreate]);

  const followUpHasMetadata = useMemo(() => relatedFollowUp?.metadata, [relatedFollowUp]);

  const isTipoDocumentoFiscalSemNotaFiscal = useMemo(
    () =>
      TipoDocumentoFiscalUtils.isSemNotaFiscal(tiposDocumentosFiscais)(
        faturaValues?.atributosAdicionais?.tipoDocumentoFiscal?.id
      ),
    [faturaValues]
  );

  const defaultPaisNotaFiscalValue = useMemo(() => {
    const getPaisByCode = configurePaisGetterByCode(paises);
    const defaultPaisBrasil = getPaisByCode(PAIS_BRASIL_CODE);

    return followUpHasMetadata
      ? {}
      : {
          notaFiscal: {
            ...initialValues?.due?.notaFiscal,
            estado: {
              ...initialValues?.due?.notaFiscal?.estado,
              pais: defaultPaisBrasil,
            },
          },
        };
  }, [paises, initialValues, followUpHasMetadata]);

  useEffect(() => {
    const paisId = defaultPaisNotaFiscalValue?.notaFiscal?.estado?.pais?.id;

    if (paisId && !estadosCarregados && !followUpHasMetadata) {
      dispatch(estadoActions.fetchByFilterAsync([{ name: 'pais', value: paisId }]));

      setEstadosCarregados(true);
    }
  }, [defaultPaisNotaFiscalValue, estadosCarregados, followUpHasMetadata]);

  const internalInitialValues = useMemo(
    () =>
      isFillCommonDataMode
        ? {
            ...initialValues,
            tipoCalculo: QUANTIDADE_X_VALOR,
            due: {
              ...initialValues?.due,
              ...defaultPaisNotaFiscalValue,
            },
            partnumberAutocompleteSelector: initialValues?.partnumber,
            ignorableFields: {
              ...initialValues?.ignorableFields,
              atoConcessorio: {
                ...initialValues?.ignorableFields?.atoConcessorio,
                exportadorBenecifiario: true,
              },
            },
          }
        : {
            partnumberAutocompleteSelector: initialValues?.partnumber,
            partNumberSelector: initialValues?.partnumber,
            ...initialValues,
            due: {
              ...initialValues?.due,
              ...defaultPaisNotaFiscalValue,
            },
            ignorableFields: {
              ...initialValues?.ignorableFields,
              atoConcessorio: {
                ...initialValues?.ignorableFields?.atoConcessorio,
                exportadorBenecifiario: true,
              },
            },
          },
    [isFillCommonDataMode, initialValues, faturaValues]
  );

  const handleAlternativeSaveMercadoria = useCallback(() => {
    if (isEmpty(controlComponentProps) || !isFunction(controlComponentProps?.handleAlternativeSave)) {
      throw Error('Error: "handleAlternativeSave" is not a function.');
    }

    const { handleAlternativeSave } = controlComponentProps;
    const mercadoriaStepIndex = 3;

    handleAlternativeSave(null, mercadoriaStepIndex);
  }, [controlComponentProps]);

  const tableProps = useMemo(
    () => ({
      title: t('com.muralis.qcx.mercadoria.mercadoriasAdicionais'),
      columns: [
        {
          field: 'id',
          headerName: t('com.muralis.qcx.item.labelSingular'),
          headerAlign: 'center',
          align: 'center',
          flex: 90,
          valueGetter: ({ row }) => row?.id,
        },
        {
          field: 'numDanfe',
          headerName: 'Número Danfe',
          headerAlign: 'center',
          align: 'center',
          flex: 160,
          valueGetter: ({ row }) => row?.due?.numeroDanfeExportacao || '-',
        },
        {
          field: 'ncm',
          headerName: 'NCM',
          headerAlign: 'center',
          align: 'center',
          flex: 160,
          valueGetter: ({ row }) => row?.ncm?.code || '-',
        },
        {
          field: 'ncmUnidadeMedida',
          headerName: 'Unidade de Medida',
          headerAlign: 'center',
          align: 'center',
          flex: 160,
          valueGetter: ({ row }) => row?.ncm?.unidadeDeMedida?.description || '-',
        },
        {
          field: 'partnumber',
          headerName: t('com.muralis.qcx.item.partNumber'),
          headerAlign: 'center',
          align: 'center',
          flex: 200,
          valueGetter: ({ row }) => row?.partnumber || t('com.muralis.qcx.validacao.naoPreenchido'),
        },
        {
          field: 'quantidade',
          headerName: t('com.muralis.qcx.quantidade.label'),
          headerAlign: 'center',
          align: 'center',
          flex: 180,
          valueGetter: ({ row }) => unnormalizeNumeral(row?.quantidade, formatBrazilianNumericDecimal(5)) || '-',
        },
        {
          field: 'valorUnitarioMoeda',
          headerName: t('com.muralis.qcx.valorUnitario'),
          headerAlign: 'right',
          align: 'right',
          flex: 160,
          valueFormatter: ({ row: { valorUnitarioMoeda } }) =>
            unnormalizeNumeral(valorUnitarioMoeda, formatBrazilianNumericDecimal(2)) || '-',
        },
        {
          field: 'valorTotalMoeda',
          headerName: t('com.muralis.qcx.mercadoria.valorTotalItem'),
          headerAlign: 'right',
          align: 'right',
          flex: 180,
          valueFormatter: ({ row: { valorTotalMoeda } }) =>
            unnormalizeNumeral(valorTotalMoeda, formatBrazilianNumericDecimal(2)) || '-',
        },
      ],
    }),
    []
  );

  const isTipoDocumentoDueDUE = useCallback((value, list) => TipoDocumentoDueUtils.isDUE(list)(value), []);

  const operacaoExportacaoConsignacaoVinculadaListProps = useMemo(
    () => ({
      name: 'due.operacoesExportacaoConsignacoes',
      columns: [
        {
          field: 'tipoDocumentoDue',
          headerName: t('com.muralis.qcx.documento.tipoDocumento'),
          headerAlign: 'center',
          align: 'center',
          flex: 190,
          valueGetter: ({ row }) => row?.tipoDocumentoDue?.description || '-',
        },
        {
          field: 'numero',
          headerName: t('com.muralis.qcx.documento.NumeroDocumento'),
          headerAlign: 'center',
          align: 'center',
          flex: 210,
          valueGetter: ({ row }) => row?.numero || '-',
        },
        {
          field: 'item',
          headerName: t('com.muralis.qcx.dueExportacao.ItemDUE'),
          headerAlign: 'center',
          align: 'center',
          flex: 200,
          valueGetter: ({ row }) => row?.item || '-',
        },
        {
          field: 'processo',
          headerName: t('com.muralis.qcx.NumeroProcesso'),
          headerAlign: 'center',
          align: 'center',
          flex: 200,
          valueGetter: ({ row }) => row?.processo || '-',
        },
        {
          field: 'quantidadeEstatistica',
          headerName: t('com.muralis.qcx.quantidade.QuantidadeEstatistica'),
          headerAlign: 'center',
          align: 'center',
          flex: 200,
          valueGetter: ({ row }) => row?.quantidadeEstatistica || '-',
        },
      ],
    }),
    []
  );

  const operacaoExportacaoConsignacaoVinculadaFormProps = useCallback(
    (values) => ({
      rootName: 'ignorableFields.operacaoConsignacaoVinculada',
      fields: [
        {
          name: 'tipoDocumentoDue.id',
          label: t('com.muralis.qcx.documento.tipoDocumento'),
        },
        {
          name: 'numero',
          label: t('com.muralis.qcx.documento.numeroDocumento'),
        },
        {
          name: 'item',
          label: t('com.muralis.qcx.dueExportacao.ItemDUE'),
          required: isTipoDocumentoDueDUE(
            values?.ignorableFields?.operacaoConsignacaoVinculada?.tipoDocumentoDue?.id,
            tiposDocumentoDueConsignacao
          ),
        },
        {
          name: 'processo',
          label: t('com.muralis.qcx.numeroProcessoADM'),
          required: false,
        },
        {
          name: 'quantidadeEstatistica',
          label: t('com.muralis.qcx.quantidade.quantidadeEstatisticaAssociada'),
        },
      ],
    }),
    [tiposDocumentoDueConsignacao, isTipoDocumentoDueDUE]
  );

  const operacaoExportacaoConsignacaoVinculadaReducerConfig = useMemo(
    () => ({
      selectors: {
        selectStatus: vinculoOperacaoExportacaoConsignacaoVinculadaDUESelectors.selectStatus,
        selectMode: vinculoOperacaoExportacaoConsignacaoVinculadaDUESelectors.selectMode,
        selectModel: vinculoOperacaoExportacaoConsignacaoVinculadaDUESelectors.selectModel,
      },
      actions: {
        loading: vinculoOperacaoExportacaoConsignacaoVinculadaDUEActions.loading,
        resetStatus: vinculoOperacaoExportacaoConsignacaoVinculadaDUEActions.resetStatus,
        changeToUpdateMode: vinculoOperacaoExportacaoConsignacaoVinculadaDUEActions.changeToUpdateMode,
        changeToCreateMode: vinculoOperacaoExportacaoConsignacaoVinculadaDUEActions.changeToCreateMode,
        resetMode: vinculoOperacaoExportacaoConsignacaoVinculadaDUEActions.resetMode,
        setModel: vinculoOperacaoExportacaoConsignacaoVinculadaDUEActions.setModel,
        resetModel: vinculoOperacaoExportacaoConsignacaoVinculadaDUEActions.resetModel,
      },
    }),
    []
  );

  const handleAddOperacaoExportacao = useCallback(
    (form, fieldName) => async (currentVinculo, currentVinculos, handleSuccessAddVinculo) => {
      const tipoDocumentoDueResponse = await fetchTipoDocumentoDueByIdAsync(currentVinculo?.tipoDocumentoDue?.id);

      if (tipoDocumentoDueResponse?.status === 200) {
        const foundTipoDocumentoDue = tipoDocumentoDueResponse?.data;

        const vinculo = {
          id: uuid(),
          tipoDocumentoDue: foundTipoDocumentoDue,
          numero: currentVinculo?.numero,
          item: currentVinculo?.item,
          processo: currentVinculo?.processo,
          quantidadeEstatistica: currentVinculo?.quantidadeEstatistica,
        };

        const updatedVinculos = [...currentVinculos, vinculo];

        handleSuccessAddVinculo(updatedVinculos);

        form.resetFieldState(fieldName);
        form.change(fieldName, '');
      }
    },
    []
  );

  const handleUpdateOperacaoExportacao = useCallback(
    async (currentVinculo, currentVinculos, handleSuccessUpdateVinculo) => {
      const tipoDocumentoDueResponse = await fetchTipoDocumentoDueByIdAsync(currentVinculo?.tipoDocumentoDue?.id);

      if (tipoDocumentoDueResponse?.status === 200) {
        const foundTipoDocumentoDue = tipoDocumentoDueResponse?.data;

        const updatedVinculo = {
          ...currentVinculo,
          tipoDocumentoDue: foundTipoDocumentoDue,
        };

        const updatedVinculos =
          currentVinculos?.map((existingVinculo) =>
            isStrictEquals(existingVinculo?.id, updatedVinculo?.id) ? updatedVinculo : existingVinculo
          ) || currentVinculos;

        handleSuccessUpdateVinculo(updatedVinculos);
      }
    },
    []
  );

  const handleOperacaoExportacaoAlreadyExists = useCallback(
    (currentVinculo, currentVinculosList) =>
      currentVinculosList.some(
        (vinculo) =>
          isStrictEquals(vinculo?.tipoDocumentoDue?.id, currentVinculo?.tipoDocumentoDue?.id) &&
          isStrictEquals(vinculo?.numero, currentVinculo?.numero) &&
          isStrictEquals(vinculo?.item, currentVinculo?.item) &&
          isStrictEquals(vinculo?.processo, currentVinculo?.processo) &&
          isStrictEquals(vinculo?.quantidadeEstatistica, currentVinculo?.quantidadeEstatistica)
      ),
    []
  );

  const operacaoExportacaoConsignacaoVinculadaFieldProps = useMemo(
    () => ({
      operacaoExportacao: {
        tipoDocumentoDue: {
          id: 'select-field-tipo-documento-operacao-consignacao-vinculada-due',
          key: 'select-field-tipo-documento-operacao-consignacao-vinculada-due',
          name: 'ignorableFields.operacaoConsignacaoVinculada.tipoDocumentoDue.id',
          reducerConfig: {
            selectList: selectTiposDocumentoDueConsignacao,
          },
        },
        numeroDocumento: {
          id: 'text-field-numero-documento-operacao-consignacao-vinculada',
          key: 'text-field-numero-documento-operacao-consignacao-vinculada',
          name: 'ignorableFields.operacaoConsignacaoVinculada.numero',
        },
        itemDue: {
          id: 'text-field-item-due-operacao-consignacao-vinculada',
          key: 'text-field-item-due-operacao-consignacao-vinculada',
          name: 'ignorableFields.operacaoConsignacaoVinculada.item',
        },
        numeroProcessoAdm: {
          id: 'text-field-numero-processo-adm-operacao-consignacao-vinculada',
          key: 'text-field-numero-processo-adm-operacao-consignacao-vinculada',
          name: 'ignorableFields.operacaoConsignacaoVinculada.processo',
        },
        quantidadeEstatisticaAssociada: {
          id: 'numeric-decimal-quantidade-estatistica-associada-operacao-consignacao-vinculada-field',
          key: 'numeric-decimal-quantidade-estatistica-associada-operacao-consignacao-vinculada-field',
          name: 'ignorableFields.operacaoConsignacaoVinculada.quantidadeEstatistica',
        },
      },
    }),
    []
  );

  const operacaoExportacaoTemporariaVinculadaListProps = useMemo(
    () => ({
      name: 'due.operacoesExportacaoTemporarias',
      columns: [
        {
          field: 'tipoDocumentoDue',
          headerName: t('com.muralis.qcx.documento.tipoDocumento'),
          headerAlign: 'center',
          align: 'center',
          flex: 190,
          valueGetter: ({ row }) => row?.tipoDocumentoDue?.description || '-',
        },
        {
          field: 'numero',
          headerName: t('com.muralis.qcx.documento.NumeroDocumento'),
          headerAlign: 'center',
          align: 'center',
          flex: 210,
          valueGetter: ({ row }) => row?.numero || '-',
        },
        {
          field: 'item',
          headerName: t('com.muralis.qcx.dueExportacao.ItemDUE'),
          headerAlign: 'center',
          align: 'center',
          flex: 150,
          valueGetter: ({ row }) => row?.item || '-',
        },
        {
          field: 'processo',
          headerName: t('com.muralis.qcx.NumeroProcesso'),
          headerAlign: 'center',
          align: 'center',
          flex: 200,
          valueGetter: ({ row }) => row?.processo || '-',
        },
        {
          field: 'quantidadeEstatistica',
          headerName: t('com.muralis.qcx.quantidade.QuantidadeEstatistica'),
          headerAlign: 'center',
          align: 'center',
          flex: 200,
          valueFormatter: ({ row }) => row?.quantidadeEstatistica || '-',
        },
      ],
    }),
    []
  );

  const operacaoExportacaoTemporariaVinculadaFormProps = useCallback(
    (values) => ({
      rootName: 'ignorableFields.operacaoTemporariaVinculada',
      fields: [
        {
          name: 'tipoDocumentoDue.id',
          label: t('com.muralis.qcx.documento.tipoDocumento'),
        },
        {
          name: 'numero',
          label: t('com.muralis.qcx.documento.numeroDocumento'),
        },
        {
          name: 'item',
          label: t('com.muralis.qcx.dueExportacao.ItemDUE'),
          required: isTipoDocumentoDueDUE(
            values?.ignorableFields?.operacaoTemporariaVinculada?.tipoDocumentoDue?.id,
            tiposDocumentoDueTemporaria
          ),
        },
        {
          name: 'processo',
          label: t('com.muralis.qcx.numeroProcessoADM'),
          required: false,
        },
        {
          name: 'quantidadeEstatistica',
          label: t('com.muralis.qcx.quantidade.quantidadeEstatisticaAssociada'),
        },
      ],
    }),
    [tiposDocumentoDueTemporaria, isTipoDocumentoDueDUE]
  );

  const operacaoExportacaoTemporariaVinculadaReducerConfig = useMemo(
    () => ({
      selectors: {
        selectStatus: vinculoOperacaoExportacaoTemporariaVinculadaDUESelectors.selectStatus,
        selectMode: vinculoOperacaoExportacaoTemporariaVinculadaDUESelectors.selectMode,
        selectModel: vinculoOperacaoExportacaoTemporariaVinculadaDUESelectors.selectModel,
      },
      actions: {
        loading: vinculoOperacaoExportacaoTemporariaVinculadaDUEActions.loading,
        resetStatus: vinculoOperacaoExportacaoTemporariaVinculadaDUEActions.resetStatus,
        changeToUpdateMode: vinculoOperacaoExportacaoTemporariaVinculadaDUEActions.changeToUpdateMode,
        changeToCreateMode: vinculoOperacaoExportacaoTemporariaVinculadaDUEActions.changeToCreateMode,
        resetMode: vinculoOperacaoExportacaoTemporariaVinculadaDUEActions.resetMode,
        setModel: vinculoOperacaoExportacaoTemporariaVinculadaDUEActions.setModel,
        resetModel: vinculoOperacaoExportacaoTemporariaVinculadaDUEActions.resetModel,
      },
    }),
    []
  );

  const operacaoExportacaoTemporariaVinculadaFieldProps = useMemo(
    () => ({
      operacaoExportacao: {
        tipoDocumentoDue: {
          id: 'select-field-tipo-documento-due-operacao-temporaria-vinculada',
          key: 'select-field-tipo-documento-due-operacao-temporaria-vinculada',
          name: 'ignorableFields.operacaoTemporariaVinculada.tipoDocumentoDue.id',
          reducerConfig: {
            selectList: selectTiposDocumentoDueTemporaria,
          },
        },
        numeroDocumento: {
          id: 'text-field-numero-documento-operacao-temporaria-vinculada',
          key: 'text-field-numero-documento-operacao-temporaria-vinculada',
          name: 'ignorableFields.operacaoTemporariaVinculada.numero',
        },
        itemDue: {
          id: 'text-field-item-due-operacao-temporaria-vinculada',
          key: 'text-field-item-due-operacao-temporaria-vinculada',
          name: 'ignorableFields.operacaoTemporariaVinculada.item',
        },
        numeroProcessoAdm: {
          id: 'text-field-numero-processo-adm-operacao-temporaria-vinculada',
          key: 'text-field-numero-processo-adm-operacao-temporaria-vinculada',
          name: 'ignorableFields.operacaoTemporariaVinculada.processo',
        },
        quantidadeEstatisticaAssociada: {
          id: 'numeric-decimal-quantidade-estatistica-associada-operacao-temporaria-vinculada-field',
          key: 'numeric-decimal-quantidade-estatistica-associada-operacao-temporaria-vinculada-field',
          name: 'ignorableFields.operacaoTemporariaVinculada.quantidadeEstatistica',
        },
      },
    }),
    []
  );

  const dataEmissaoValidators = useMemo(() => useValidators([isCurrentOrPastDate]), []);

  const cpfCnpjEmitenteValidators = useMemo(() => useValidators([validCpfOrCnpj]), []);

  const dadosNotaFiscalFieldProps = useMemo(
    () => ({
      notaFiscal: {
        uf: {
          id: 'select-field-uf',
          key: 'select-field-uf',
          name: 'due.notaFiscal.estado.id',
        },
        data: {
          id: 'date-picker-date-emissao',
          key: 'date-picker-date-emissao',
          name: 'due.notaFiscal.data',
          options: {
            validate: !(isConsult || isSubConsult || isSubNone) && dataEmissaoValidators,
          },
        },
        cpfCnpjEmitente: {
          id: 'masked-text-cpf-cnpj-emitente-field',
          key: 'masked-text-cpf-cnpj-emitente-field',
          name: 'due.notaFiscal.cpfCnpjEmitente',
          options: {
            validate: cpfCnpjEmitenteValidators,
          },
        },
        modeloNotaFiscal: {
          id: 'select-field-modelo-nota-fiscal',
          key: 'select-field-modelo-nota-fiscal',
          name: 'due.notaFiscal.modeloNotaFiscal.id',
        },
        serie: {
          id: 'text-field-serie',
          key: 'text-field-serie',
          name: 'due.notaFiscal.serie',
        },
        numero: {
          id: 'text-field-numero-nota-fiscal',
          key: 'text-field-numero-nota-fiscal',
          name: 'due.notaFiscal.numero',
        },
      },
    }),
    [isConsult, isSubConsult, isSubNone, dataEmissaoValidators, cpfCnpjEmitenteValidators]
  );

  const localeTableText = useMemo(
    () => ({
      noRowsLabel: t('com.muralis.qcx.mercadoria.nenhumaMercadoriaEncontrada'),
    }),
    []
  );

  const currentTitleSubPage = useMemo(
    () =>
      isSubCreate ? t('com.muralis.qcx.mercadoria.novaMercadoria') : t('com.muralis.qcx.mercadoria.dadosMercadoria'),
    [isSubCreate]
  );

  const modes = useMemo(
    () => ({
      isConsult,
      isSubConsult,
      isSubNone,
      isSubCreate,
      isSubUpdate,
      isFillCommonDataMode,
      isLoading,
      isUpdate,
    }),
    [isConsult, isSubConsult, isSubNone, isSubCreate, isSubUpdate, isFillCommonDataMode, isLoading, isUpdate]
  );

  const formProps = useMemo(
    () => ({
      faturaValues,
      listCatalogo,
      unidadeMedidaList,
      isFirstFormLoadValues,
      setIsFirstFormLoadValues,
      rootName: 'adicionais',
    }),
    [faturaValues, listCatalogo, unidadeMedidaList, isFirstFormLoadValues, setIsFirstFormLoadValues]
  );

  const quantidadeEstatisticaProps = useMemo(
    () => ({
      isQuantidade,
      isQuilograma,
      setIsQuantidadeUnidade,
      setIsQuilogramaUnidade,
    }),
    [isQuantidade, isQuilograma, setIsQuantidadeUnidade, setIsQuilogramaUnidade]
  );

  const [onBlurObjectHandlers, changeListeners] = useFaturaMercadoriaListeners({
    modes,
    formProps,
    subscribeToFilling,
    quantidadeEstatisticaProps,
    getTipoCalculoValue,
  });

  return (
    <QCXFormStepWizard
      initialValues={internalInitialValues}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      onChangeListeners={changeListeners}
      alternativeMode
      disablePristine
      {...restProps}
    >
      {({ form, submitting, values, submitSucceeded }) => (
        <QCXCustomRegistrationTabControlTemplate
          showButtonsTop
          initialValues={internalInitialValues}
          title={currentTitleSubPage}
          tableProps={tableProps}
          tableData={mercadorias}
          isPreparingAction={isPreparingAction}
          isIdle={isIdle}
          isLoading={isLoading}
          isCreate={isCreate}
          isConsult={isConsult}
          isUpdate={isUpdate}
          isSubNone={isSubNone}
          isSubCreate={isSubCreate}
          isSubConsult={isSubConsult}
          isSubUpdate={isSubUpdate}
          isBackgroundDelete={isBackgroundDelete}
          isSubBackgroundCreate={isSubBackgroundCreate}
          isSubBackgroundUpdate={isSubBackgroundUpdate}
          isSubBackgroundDelete={isSubBackgroundDelete}
          submitting={submitting}
          submitSucceeded={submitSucceeded}
          selectModel={selectRelatedMercadoriaModel}
          defaultItem={defaultItem}
          handleNew={handleNew}
          handleEdit={handleEdit}
          handleChangeToSubConsult={handleChangeToSubConsult}
          handleChangeModel={handleChangeModel}
          handleCancelCreate={handleCancelCreate}
          handleCancelUpdate={handleCancelUpdate}
          handleChangeToConsult={handleChangeToConsult}
          handleRemoveByForm={handleRemoveByForm}
          handleRemoveByTable={handleRemoveByTable}
          handleClearForm={handleClearForm}
          defaultRestartedValues={{
            partnumberAutocompleteSelector: '',
            partNumberSelector: '',
            partnumber: '',
            descricaoResumida: '',
            pesoLiquido: '',
            valorUnitarioMoeda: '',
            destaqueNcm: '',
            adicionais: {
              numeroSerie: '',
              marca: '',
              modelo: '',
              dataValidade: '',
              anoFabricacao: '',
              lote: '',
              especificacao: '',
            },
            quantidadeEstatistica: '',
            quantidade: '',
            valorTotalMoeda: '',
            percentualDesconto: '',
            valorTotalMcvMoeda: '',
            tipoCalculo: QUANTIDADE_X_VALOR,
            icms: '',
          }}
          handleChangeToSubNone={handleChangeToSubNone}
          handleChangeToUpdate={handleChangeToUpdate}
          handleLoadingStatus={handleLoadingStatus}
          handleResetStatus={handleResetStatus}
          localeTableText={localeTableText}
          filterPropGetter={(row) => row?.partnumber}
          handleAlternativeSave={handleAlternativeSaveMercadoria}
          handleMetadataUpdate={handleMetadataUpdate}
          tabManagerProps={tabManagerProps}
          formPaginationControlProps={formPaginationControlProps}
          buttonGroupOptions={buttonGroupOptions}
          form={form}
        >
          {() => (
            <>
              <QCXFaturaForceTabReload />
              <QCXFaturaMercadoriaExportacaoStandardFormGroup
                tipo={tipo}
                modes={modes}
                classes={classes}
                isFilling={isFilling}
                quantidadeEstatisticaProps={quantidadeEstatisticaProps}
                onBlurObjectHandlers={onBlurObjectHandlers}
              />
              {!(followUpHasMetadata || isTipoDocumentoFiscalSemNotaFiscal) && (
                <QCXDadosNotaFiscalFormGroup
                  disabled={isConsult || isSubConsult || isSubNone}
                  fieldProps={dadosNotaFiscalFieldProps}
                />
              )}

              <QCXJustificativaMercadoriaGroup disabled={isConsult || isSubConsult || isSubNone} />
              <QCXFinalAtributoNcmMercadoriaGroup
                isParentConsult={isConsult}
                isSubConsult={isSubConsult}
                disabled={isConsult || isSubConsult || isSubNone}
              />

              <QCXLPCOFormGroup
                isParentConsult={isConsult || isSubConsult || isSubNone}
                disabled={isConsult || isSubConsult || isSubNone}
              />
              <QCXMercadoriaAmparadaPorFormGroup
                isParentConsult={isConsult || isSubConsult || isSubNone}
                disabled={isConsult || isSubConsult || isSubNone}
                isFilling={isFilling}
              />
              <QCXOperacaoExportacaoFormGroup
                title={t('com.muralis.qcx.operacaoExportacaoVinculadaImportacaoAnteriorReexportacao')}
                isParentConsult={isConsult || isSubConsult || isSubNone}
                listProps={operacaoExportacaoConsignacaoVinculadaListProps}
                formProps={operacaoExportacaoConsignacaoVinculadaFormProps(values)}
                reducerConfig={operacaoExportacaoConsignacaoVinculadaReducerConfig}
                handleAdd={handleAddOperacaoExportacao(
                  form,
                  'ignorableFields.operacaoConsignacaoVinculada.quantidadeEstatistica'
                )}
                handleUpdate={handleUpdateOperacaoExportacao}
                handleAlreadyExists={handleOperacaoExportacaoAlreadyExists}
                fieldProps={operacaoExportacaoConsignacaoVinculadaFieldProps}
                disabled={isConsult || isSubConsult || isSubNone}
              >
                {({ operacaoExportacao, loading, disabled }) => (
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <QCXSelectTipoDocumentoDueConsignacaoAutocomplete
                      {...operacaoExportacao.tipoDocumentoDue}
                      label={t('com.muralis.qcx.documento.tipoDocumento')}
                      initialValues={values}
                      disabled={disabled || loading}
                    />
                  </Grid>
                )}
              </QCXOperacaoExportacaoFormGroup>
              <QCXOperacaoExportacaoFormGroup
                title={t('com.muralis.qcx.operacaoExportacaoTemporariaRegulacaoVendaConsignacao')}
                isParentConsult={isConsult || isSubConsult || isSubNone}
                listProps={operacaoExportacaoTemporariaVinculadaListProps}
                formProps={operacaoExportacaoTemporariaVinculadaFormProps(values)}
                reducerConfig={operacaoExportacaoTemporariaVinculadaReducerConfig}
                handleAdd={handleAddOperacaoExportacao(
                  form,
                  'ignorableFields.operacaoTemporariaVinculada.quantidadeEstatistica'
                )}
                handleUpdate={handleUpdateOperacaoExportacao}
                handleAlreadyExists={handleOperacaoExportacaoAlreadyExists}
                fieldProps={operacaoExportacaoTemporariaVinculadaFieldProps}
                disabled={isConsult || isSubConsult || isSubNone}
              >
                {({ operacaoExportacao, loading, disabled }) => (
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <QCXSelectTipoDocumentoDueTemporariaAutocomplete
                      {...operacaoExportacao.tipoDocumentoDue}
                      label={t('com.muralis.qcx.documento.tipoDocumento')}
                      initialValues={values}
                      disabled={disabled || loading}
                    />
                  </Grid>
                )}
              </QCXOperacaoExportacaoFormGroup>
              <QCXNotaReferenciadaFormGroup
                isParentConsult={isConsult || isSubConsult || isSubNone}
                disabled={isConsult || isSubConsult || isSubNone}
              />
              <QCXNotaComplementarFormGroup
                isParentConsult={isConsult || isSubConsult || isSubNone}
                disabled={isConsult || isSubConsult || isSubNone}
              />
              <QCXNotaReferenciadaFormularioFormGroup
                isParentConsult={isConsult || isSubConsult || isSubNone}
                disabled={isConsult || isSubConsult || isSubNone}
              />
              <QCXAtosConcessoriosFormGroup
                isParentConsult={isConsult || isSubConsult || isSubNone}
                disabled={isConsult || isSubConsult || isSubNone}
              />
              {!(isConsult || isSubNone || isSubCreate) && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <QCXFinalMultilineTextField
                    id="text-especificacao-multiline-field"
                    key="text-especificacao-multiline-field"
                    name="adicionais.especificacaoCompleta"
                    label={t('com.muralis.qcx.especificacao.especificacaoCompletaMercadoria')}
                    disabled={isConsult || isSubConsult || isSubNone}
                    rows={5}
                    InputProps={{
                      readOnly: true,
                    }}
                    helperText={
                      !(isConsult || isSubNone || isSubConsult) && t('com.muralis.qcx.mensagem.campoHabilitadoLeitura')
                    }
                  />
                </Grid>
              )}
            </>
          )}
        </QCXCustomRegistrationTabControlTemplate>
      )}
    </QCXFormStepWizard>
  );
}
