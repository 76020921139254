import PivotTableChartIcon from '@mui/icons-material/PivotTableChart';
import ExtensionIcon from '@mui/icons-material/Extension';
import { CardsGridItem } from '../../../common/components/cardsGrid/cardsGrid';
import BasicLayout from '../../../common/layouts/basicLayout';
import CardsGridSubmenu from '../../../common/components/cardsGrid/cardsGridSubmenu';
import ReceiptIcon from '@mui/icons-material/Receipt';

const cover: CardsGridItem = {
  title: 'Integração',
  icon: PivotTableChartIcon,
  path: '/integracao',
};

const gridItems: CardsGridItem[] = [
  {
    title: 'Faturas',
    icon: ReceiptIcon,
    path: '/integracao/dior/faturas',
    requiredRoles: ['planilha-fatura-dior'],
  },
];

const DiorPage = () => {
  return (
    <BasicLayout title={'Integração'} icon={<ExtensionIcon color={'secondary'} />}>
      <CardsGridSubmenu cover={cover} items={gridItems}></CardsGridSubmenu>
    </BasicLayout>
  );
};

export default DiorPage;
