import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

const TIPO_ISS_LIST = [
  { label: 'Débito', value: 'debito' },
  { label: 'Crédito', value: 'credito' },
];

function QCXSelectTipoIssAutocomplete({
  label,
  name,
  initialValues,
  disabled,
  ...restProps
}) {
  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    // check which item in TIPO_ISS_LIST matches initialValues[name]
    const foundValue = TIPO_ISS_LIST.find(
      (item) => item?.value === _.get(initialValues, name)
    );
    if (foundValue) {
      setDefaultValue(foundValue);
    } else {
      setDefaultValue('');
    }
  }, [initialValues, name]);

  const filterOptions = useMemo(() => (
    createFilterOptions({
      stringify: (option) => option.label + option.value,
    })
  ), []);

  const getOptionLabel = useCallback(
    (item) => item?.label || '',
    []
  );

  const getOptionValue = useCallback(
    (item) => item?.value || '',
    []
  );

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={TIPO_ISS_LIST}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      disabled={disabled}
      {...restProps}
    />
  );
}

export default QCXSelectTipoIssAutocomplete;
