import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton } from '@material-ui/core';
import {
  Search as PageviewIcon,
  Receipt as ReceiptIcon,
  MoreHoriz as MoreHorizIcon,
  Print as PrintIcon,
  HighlightOff as HighlightOffIcon,
} from '@material-ui/icons';
import { Article as ArticleIcon } from '@mui/icons-material';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import _, { isFunction, isEmpty } from 'lodash';
import { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { followUpAPI } from '../../../../features/follow-up/followUpAPI';
import { isAlternativeLoadingStatus, isRefresh } from '../../../../utils/store/store-utils';
import { formatDate, unnormalizeNumeral } from '../../../../utils/general/general-utils';
import { setQuery } from '../../../../features/controller/controllerSlice';
import useGenericDownloader from '../../../../utils/hooks/downloader/useGenericDownloader';
import {
  setProcesso,
  setTipo,
  changeToConsultMode,
  changeToCreateMode,
  resetBackgroundMode,
  resetHistoryModel,
  resetMode,
  resetModel,
  resetRefresh,
  resetSubMode,
  failure,
  setError,
  loading,
  refresh as refreshAction,
  success,
} from '../../../../features/solicitacao-pagamento/solicitacaoPagamentoSlice';
import { fetchAllNonFaturadoAndLiquidadoAsync } from '../../../../features/solicitacao-pagamento/solicitacaoPagamentoThunks';
import {
  selectRefresh,
  selectHistoryModel,
  selectSolicitacoesPagamento,
  selectRelatedReportModel,
} from '../../../../features/solicitacao-pagamento/solicitacaoPagamentoSelectors';
import QCXSimpleConsultPageTemplate from '../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import SolicitacaoPagamentoContext from '../../../../contexts/financial/solicitacao-pagamento/SolicitacaoPagamentoContext';
import QCXListItemIcon from '../../../../shared-components/list-item-icon/QCXListItemIcon';
import QCXPopupState from '../../../../components/popup-state/QCXPopupState';
import QCXFinalFormDialog from '../../../../shared-components/dialog/QCXFinalFormDialog';
import QCXMoveFocusInside from '../../../../components/focus-lock/QCXMoveFocusInside';
import QCXSelectTipoSolicitacaoPagamentoAutocompleteField from '../../../../shared-components/select-tipo-solicitacao-pagamento/QCXSelectTipoSolicitacaoPagamentoAutocompleteField';

import { userHasDeleteRole } from '../../../../components/perfil/perfil-utils';
import { required } from '../../../../utils/validators/field/validator';
import {
  CLOSED_STATUS,
  DONE_STATUS,
  FAIL_STATUS,
  isOpenStatus,
  SUBMITTING_STATUS,
} from '../../../../utils/hooks/form/dialog/formDialogUtils';
import { estadoActions } from '../../../../features/estado/estadoSlice';
import useFormDialogAsync from '../../../../utils/hooks/form/dialog/useFormDialogAsync';
import { IDLE_CONTROL } from '../../../../features/config-control';
import QCXFormListenerManager from '../../../../shared-components/form-listener/QCXFormListenerManager';
import SolicitacaoPagamentoUtils from '../../../../utils/general/solicitacao-pagamento/SolicitacaoPagamentoUtils';
import { formatBrazilianNumericDecimal } from '../../../../utils/hooks/form/field/formatters';
import useOperationMiddleware from '../../../../utils/hooks/operation/middleware/useOperationMiddleware';
import QCXConfirmDialog from '../../../../shared-components/dialog/QCXConfirmDialog';
import { solicitacaoPagamentoAPI } from '../../../../features/solicitacao-pagamento/solicitacaoPagamentoAPI';
import { setErrorFeedback, setSuccessFeedback } from '../../../../features/feedback/feedbackSlice';
import QCXSelectFollowUpAutocompleteField from '../../../../shared-components/select-follow-up/QCXSelectFollowUpAutocompleteField';
import FinalSelectFollowUpWithSearch from '../../import/follow-up/Components/FinalSelectFollowUpWithSearch';
import TableCellWithTooltip from '../../../../shared-components/tooltip-cell/TableCellWithTooltip';

export default function SolicitacaoPagamentoConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const history = useHistory();

  const genericDownloader = useGenericDownloader({
    autoDownload: true,
  });

  const { status } = useContext(SolicitacaoPagamentoContext);

  const dispatch = useDispatch();

  const [isTipoSolicitacaoPagamentoOperacional, setIsTipoSolicitacaoPagamentoOperacional] = useState(false);

  const solicitacoesPagamento = useSelector(selectSolicitacoesPagamento);
  const refresh = useSelector(selectRefresh);
  const historyModel = useSelector(selectHistoryModel);
  const reportRelatedModel = useSelector(selectRelatedReportModel);

  const solicitacaoPagamentoInput = 'solicitacaoPagamento.tipo';

  const isAlternativeLoading = useMemo(() => isAlternativeLoadingStatus(status), [status]);

  const fetchAll = useCallback(() => {
    dispatch(fetchAllNonFaturadoAndLiquidadoAsync());
  }, []);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
    dispatch(resetSubMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
    dispatch(resetHistoryModel());
  }, []);

  const configureDependencies = useCallback(() => {
    dispatch(estadoActions.changeControlTo(IDLE_CONTROL));
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
  }, []);

  useEffect(() => {
    const checkIfIsRefresh = () => {
      if (isRefresh(refresh)) {
        fetchAll();
        dispatch(resetRefresh());
      }
    };

    checkIfIsRefresh();
  }, [refresh]);

  useEffect(() => {
    const checkIfExistsQuery = () => {
      if (!isEmpty(historyModel) && historyModel?.last?.processo) {
        dispatch(setQuery(historyModel?.last?.processo));
      }
    };

    checkIfExistsQuery();
  }, [historyModel]);

  const handleConsultarClick = useCallback(
    (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        configureDependencies();

        dispatch(changeToConsultMode());

        history.push(t('com.muralis.qcx.url.financeiroSolicitacaoPagamentoDetalhes', { id: data?.id }));
      }
    },
    [configureDependencies, history, t]
  );

  const [handleButtonNewClick, formDialogNewStatus, handleFormDialogNewStatus] = useFormDialogAsync(
    async (event) => {
      event.stopPropagation();

      configureDependencies();

      dispatch(changeToCreateMode());

      history.push(t('com.muralis.qcx.url.financeiroSolicitacaoPagamentoNova'));
    },
    [history, t]
  );

  const [handleImprimirByIdMiddleware, operationOfImprimirById] = useOperationMiddleware(async (data) => {
    const numeroFollowUp = data?.followUp?.numero;

    const fetcher = async () => {
      dispatch(loading());

      const response = await solicitacaoPagamentoAPI.generateReportById(data?.id);

      return response;
    };

    const handlers = {
      onSuccess: () => {
        dispatch(success());

        const successMessage = numeroFollowUp
          ? t('com.muralis.qcx.mensagem.relatorioSolicitacaoPagamentoGeradoEspecifico', {
              numero: numeroFollowUp,
            })
          : t('com.muralis.qcx.mensagem.relatorioSolicitacaoPagamentoGerado');

        dispatch(
          setSuccessFeedback({
            message: successMessage,
          })
        );

        dispatch(refreshAction());
      },
      onNoContent: () => {
        dispatch(failure());

        const infoMessage = numeroFollowUp
          ? t('com.muralis.qcx.mensagem.relatorioSolicitacaoPagamentoNaoFoiGeradoEspecifico', {
              numero: numeroFollowUp,
            })
          : t('com.muralis.qcx.mensagem.relatorioSolicitacaoPagamentoNaoFoiGerado');
        dispatch(
          setErrorFeedback({
            message: infoMessage,
          })
        );
      },
      onError: (reason) => {
        dispatch(failure());

        const errorMessage = t('com.muralis.qcx.erro.erroGerarRelatorio', {
          mensagem: `${reason?.response && reason?.response?.data ? `: ${reason?.response?.data?.message}.` : '.'}`,
        });
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
      },
    };

    genericDownloader.downloadFileById(fetcher, handlers);
  }, []);

  const handleImprimirByIdClick = useCallback(
    (row, popupState) => {
      const { id, followUp } = row;

      const numeroFollowUp = followUp?.numero;
      const handleImprimirById = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: numeroFollowUp
                ? t('com.muralis.qcx.mensagem.confirmarOperacaoGerarRelatorioSolicitacaoPagamentoEspecifico', {
                    numero: numeroFollowUp,
                  })
                : t('com.muralis.qcx.mensagem.confirmarOperacaoGerarRelatorioSolicitacaoPagamento'),
            },
          });

          const dataOfOperationImprimirById = {
            id,
            followUp,
          };

          handleImprimirByIdMiddleware(configureOperation, dataOfOperationImprimirById);
        }
      };

      return handleImprimirById;
    },
    [handleImprimirByIdMiddleware]
  );

  const normalize = useCallback(
    (unnormalizedData) => ({
      ...unnormalizedData,
    }),
    []
  );

  const onTipoSolicitacaoPagamentoChange = useCallback(
    () => async (value) => {
      const tipoSolicitacaoPagamentoOperacional = SolicitacaoPagamentoUtils.isOperacional(value);

      if (tipoSolicitacaoPagamentoOperacional) {
        setIsTipoSolicitacaoPagamentoOperacional(true);
        return;
      }

      setIsTipoSolicitacaoPagamentoOperacional(false);
    },
    [setIsTipoSolicitacaoPagamentoOperacional, reportRelatedModel]
  );

  const [handleSolicitarByMiddleware, operationOfSolicitar] = useOperationMiddleware(async (data) => {
    try {
      dispatch(loading());

      const responseOfConsultNumerario = await solicitacaoPagamentoAPI.solicitar(data?.id);

      if (responseOfConsultNumerario?.status === 200) {
        const message = data?.followUp?.numero
          ? t('com.muralis.qcx.mensagem.solicitacaoIdentificadorRealizada', {
              identificador: data?.followUp?.numero,
            })
          : t('com.muralis.qcx.mensagem.solicitacaoRealizada');

        dispatch(success());
        dispatch(setSuccessFeedback({ message }));
        dispatch(refreshAction());
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error?.message || '';

      const message = data?.followUp?.numero
        ? t('com.muralis.qcx.erro.erroSolicitacaoIdentificador', {
            identificador: data?.followUp?.numero,
            erro: errorMessage,
          })
        : t('com.muralis.qcx.mensagem.erroSolicitacao', {
            erro: errorMessage,
          });

      dispatch(failure());
      dispatch(setErrorFeedback({ message }));
    }
  }, []);

  const handleSolicitarClick = useCallback(
    (row, popupState) => {
      const { id, followUp } = row;

      const handleSolicitar = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const title = followUp?.numero
            ? t('com.muralis.qcx.acoes.confirmarOperacaoSolicitacaoIdentificador', {
                identificador: followUp?.numero,
              })
            : t('com.muralis.qcx.acoes.confirmarOperacaoSolicitacao');

          const configureOperation = () => ({
            options: {
              title,
            },
          });

          const dataOfOperationSolicitar = {
            id,
            numero: followUp?.numero,
          };

          handleSolicitarByMiddleware(configureOperation, dataOfOperationSolicitar);
        }
      };

      return handleSolicitar;
    },
    [handleSolicitarByMiddleware]
  );

  const [handleCancelarByMiddleware, operationOfCancelar] = useOperationMiddleware(async (data) => {
    try {
      dispatch(loading());

      const responseOfConsultNumerario = await solicitacaoPagamentoAPI.cancelarSolicitacaoById(data?.id);

      if (responseOfConsultNumerario?.status === 200) {
        const message = t('com.muralis.qcx.mensagem.solicitacaoPagamentoCancelada');

        dispatch(success());
        dispatch(setSuccessFeedback({ message }));
        dispatch(refreshAction());
      }
    } catch (error) {
      const message = t('com.muralis.qcx.mensagem.erroCancelamentoSolicitacaoPagamento', {
        erro: error?.response?.data?.message || error?.message || '',
      });

      dispatch(failure());
      dispatch(setErrorFeedback({ message }));
    }
  }, []);

  const handleCancelarClick = useCallback(
    (row, popupState) => {
      const { id, followUp } = row;

      const handleCancelar = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.acoes.confirmacaoCancelamentoSolicitacaoPagamento'),
            },
            message: t('com.muralis.qcx.acoes.operacaoCancelamentoSolicitacaoPagamento'),
          });

          const dataOfOperationCancelarSolicitacaoPagamento = {
            id,
            numero: followUp?.numero,
          };

          handleCancelarByMiddleware(configureOperation, dataOfOperationCancelarSolicitacaoPagamento);
        }
      };

      return handleCancelar;
    },
    [handleCancelarByMiddleware]
  );

  const handleButtonNewClickMiddleware = useCallback(
    async (event) => {
      handleButtonNewClick(event);
    },
    [handleButtonNewClick]
  );

  const handleProcessoNewSubmit = useCallback(
    async (values) => {
      try {
        dispatch(loading());

        handleFormDialogNewStatus(SUBMITTING_STATUS);

        const normalizedData = normalize(values);
        const response = await followUpAPI.fetchById(normalizedData?.followUp?.id);

        if (response?.status === 200) {
          dispatch(setProcesso(response?.data));
        }
        dispatch(setTipo(values?.solicitacaoPagamento?.tipo));

        handleFormDialogNewStatus(DONE_STATUS);
        dispatch(success());
      } catch (error) {
        handleFormDialogNewStatus(FAIL_STATUS);

        const errorMessage = t('com.muralis.qcx.erro.erroCarregarProcessoEspecifico', {
          erro: !isEmpty(error?.response) ? error?.response?.data?.message : error?.message,
        });

        dispatch(failure());
        dispatch(
          setError({
            message: errorMessage,
          })
        );
      }
    },
    [normalize, handleFormDialogNewStatus]
  );

  const isFormDialogNewOpen = useMemo(() => isOpenStatus(formDialogNewStatus), [formDialogNewStatus]);

  const handleCloseFormDialogNew = useCallback(() => {
    handleFormDialogNewStatus(CLOSED_STATUS);
    _.debounce(() => {
      setIsTipoSolicitacaoPagamentoOperacional(false);
    }, 200)();
  }, [handleFormDialogNewStatus, setIsTipoSolicitacaoPagamentoOperacional]);

  const disableCancelarStatusList = useMemo(
    () => [
      SolicitacaoPagamentoUtils.CANCELADO,
      SolicitacaoPagamentoUtils.APROVADO,
      SolicitacaoPagamentoUtils.RECUSADO_PELO_APROVADOR,
    ],
    []
  );

  const columns = useMemo(
    () => [
      {
        field: 'processo',
        headerName: t('com.muralis.qcx.NumeroProcesso'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 200,
        valueGetter: ({ row }) => row?.followUp?.numero || '-',
      },
      {
        field: 'importador',
        headerName: t('com.muralis.qcx.importador.label'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 200,
        valueGetter: ({ row }) => row?.followUp?.importador?.pessoa?.nome || '-',
      },
      {
        field: 'solicitacao',
        headerName: t('com.muralis.qcx.solicitacao'),
        headerAlign: 'center',
        align: 'center',
        type: 'date',
        flex: 200,
        valueGetter: ({ row }) => formatDate(row?.dataSolicitacao) || '-',
      },
      {
        field: 'solicitante',
        headerName: t('com.muralis.qcx.solicitante'),
        headerAlign: 'center',
        align: 'center',
        flex: 150,
        type: 'string',
        valueGetter: ({ row }) =>
          row?.solicitante ? `${row?.solicitante?.nomeSolicitante} ${row?.solicitante?.sobrenomeSolicitante}` : '-',
      },
      {
        field: 'recebimento',
        headerName: t('com.muralis.qcx.pagamento.labelSigular'),
        headerAlign: 'center',
        align: 'center',
        type: 'date',
        flex: 200,
        valueGetter: ({ row }) => formatDate(row?.dataRecebimento) || '-',
      },
      {
        field: 'despesaReceita',
        headerName: t('com.muralis.qcx.despesa.label'),
        headerAlign: 'center',
        align: 'center',
        flex: 150,
        type: 'string',
        valueGetter: ({ row }) => (row?.despesaReceita ? `${row?.despesaReceita?.description}` : '-'),
      },
      {
        field: 'valorSolicitado',
        headerName: t('com.muralis.qcx.valorSolicitado'),
        headerAlign: 'center',
        align: 'center',
        type: 'number',
        flex: 200,
        valueGetter: ({ row }) =>
          row?.valorPagarMoeda ? unnormalizeNumeral(row?.valorPagarMoeda, formatBrazilianNumericDecimal(2)) : '-',
      },
      {
        field: 'valorRecebido',
        headerName: t('com.muralis.qcx.valorRecebido'),
        headerAlign: 'center',
        align: 'center',
        type: 'number',
        flex: 200,
        valueGetter: ({ row }) => unnormalizeNumeral(row?.valorRecebido, formatBrazilianNumericDecimal(2)),
      },
      {
        field: 'saldo',
        headerName: t('com.muralis.qcx.saldo'),
        headerAlign: 'center',
        align: 'center',
        type: 'number',
        flex: 200,
        valueGetter: ({ row }) => unnormalizeNumeral(row?.saldo, formatBrazilianNumericDecimal(2)),
      },
      {
        field: 'dataVencimento',
        headerName: t('com.muralis.qcx.dataVencimento'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 200,
        valueGetter: ({ row }) => row?.dataVencimento,
      },
      {
        field: 'numeroDocumento',
        headerName: t('com.muralis.qcx.documento.numeroDocumento'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 200,
        valueGetter: ({ row }) => row?.numeroDocumento,
      },
      {
        field: 'status',
        headerName: t('com.muralis.qcx.status'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 200,
        valueGetter: ({ row }) => row?.status.replaceAll('_', ' ') || '-',
        renderCell: ({ row }) =>
          row?.situacaoAprovacao?.justificativa ? (
            <TableCellWithTooltip value={row?.situacaoAprovacao?.justificativa} />
          ) : null,
      },
      {
        field: 'actions',
        headerName: t('com.muralis.qcx.acoes.label'),
        headerAlign: 'center',
        align: 'center',
        flex: 200,
        renderCell: ({ row }) => (
          <>
            <QCXPopupState popupId="popup-menu-solicitacao-de-pagamento">
              {(popupState) => (
                <>
                  <IconButton
                    key={`btn-more-options-${row?.id}`}
                    name={`btn-more-options-${row?.id}`}
                    {...bindTrigger(popupState)}
                  >
                    <MoreHorizIcon color="secondary" size={20} />
                  </IconButton>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);

                        handleConsultarClick(event, {
                          id: row?.id,
                          status,
                        });
                      }}
                    >
                      <QCXListItemIcon>
                        <PageviewIcon fontSize="small" color="primary" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.consultar').toUpperCase()}
                      </Typography>
                    </MenuItem>
                    {userHasDeleteRole(authInfo, 'solicitacao-pagamento') && (
                      <>
                        <MenuItem
                          onClick={handleSolicitarClick(row, popupState)}
                          disabled={
                            !(
                              SolicitacaoPagamentoUtils.isSolicitacaoPendente(row?.status) ||
                              SolicitacaoPagamentoUtils.isSolicitacaoProcessoSaldoInsuficiente(row?.status)
                            )
                          }
                        >
                          <QCXListItemIcon>
                            <ArticleIcon fontSize="small" htmlColor="#312783" />
                          </QCXListItemIcon>
                          <Typography
                            variant="inherit"
                            style={{
                              fontSize: 12,
                            }}
                          >
                            {t('com.muralis.qcx.acoes.solicitar').toUpperCase()}
                          </Typography>
                        </MenuItem>
                      </>
                    )}
                    {userHasDeleteRole(authInfo, 'solicitacao-pagamento') && (
                      <>
                        <MenuItem
                          onClick={handleImprimirByIdClick(row, popupState)}
                          disabled={SolicitacaoPagamentoUtils.isCancelado(row?.status)}
                        >
                          <QCXListItemIcon>
                            <PrintIcon fontSize="small" color="primary" />
                          </QCXListItemIcon>
                          <Typography
                            variant="inherit"
                            style={{
                              fontSize: 12,
                            }}
                          >
                            {t('com.muralis.qcx.acoes.imprimir').toUpperCase()}
                          </Typography>
                        </MenuItem>
                      </>
                    )}
                    {userHasDeleteRole(authInfo, 'solicitacao-pagamento') && (
                      <>
                        <MenuItem
                          onClick={handleCancelarClick(row, popupState)}
                          disabled={disableCancelarStatusList.includes(row?.status)}
                        >
                          <QCXListItemIcon>
                            <HighlightOffIcon fontSize="small" htmlColor="#FF0000" />
                          </QCXListItemIcon>
                          <Typography
                            variant="inherit"
                            style={{
                              fontSize: 12,
                            }}
                          >
                            {t('com.muralis.qcx.acoes.cancelar').toUpperCase()}
                          </Typography>
                        </MenuItem>
                      </>
                    )}
                  </Menu>
                </>
              )}
            </QCXPopupState>
          </>
        ),
      },
    ],
    [handleConsultarClick]
  );

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: t('com.muralis.qcx.inicio'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloFinanceiro'),
          name: t('com.muralis.qcx.financeiro.label'),
        },
      },
      {
        text: {
          name: t('com.muralis.qcx.solicitacaoPagamento.label'),
        },
        default: true,
      },
    ],
    []
  );

  const templateProps = useMemo(
    () => ({
      page: {
        title: t('com.muralis.qcx.solicitacaoPagamento.label'),
        icon: <ReceiptIcon />,
        breadcrumbs,
      },
      control: {
        buttonNew: {
          description: t('com.muralis.qcx.acoes.nova'),
          onClick: handleButtonNewClickMiddleware,
        },
      },
      table: {
        pageSize: 15,
        columns,
      },
    }),
    [breadcrumbs, columns, handleButtonNewClickMiddleware]
  );

  const onChangeListeners = useMemo(
    () => [
      {
        name: solicitacaoPagamentoInput,
        fn: onTipoSolicitacaoPagamentoChange,
      },
    ],
    [solicitacaoPagamentoInput, onTipoSolicitacaoPagamentoChange]
  );
  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={solicitacoesPagamento}
      isLoading={isAlternativeLoading}
      buttonNewText={t('com.muralis.qcx.acoes.nova').toUpperCase()}
      authInfo={authInfo}
      requiredRoles={['solicitacao-pagamento']}
    >
      <QCXConfirmDialog
        key="confirm-dialog-cancelar-numerario"
        id="confirm-dialog-cancelar-numerario"
        open={operationOfSolicitar?.active}
        title={operationOfSolicitar?.title}
        content={operationOfSolicitar?.message}
        endContent={operationOfSolicitar?.endMessage}
        onConfirm={operationOfSolicitar.confirm}
        onClose={operationOfSolicitar?.reset}
      />
      <QCXConfirmDialog
        key="confirm-dialog-cancelar-numerario"
        id="confirm-dialog-cancelar-numerario"
        open={operationOfCancelar?.active}
        title={operationOfCancelar?.title}
        content={operationOfCancelar?.message}
        endContent={operationOfCancelar?.endMessage}
        onConfirm={operationOfCancelar.confirm}
        onClose={operationOfCancelar?.reset}
      />
      <QCXFinalFormDialog
        key="form-dialog-nova-solicitacao-pagamento"
        id="form-dialog-nova-solicitacao-pagamento"
        title={t('com.muralis.qcx.solicitacaoPagamento.novoRegistroSolicitacaoPagamento')}
        content={t('com.muralis.qcx.preencherCamposContinuar')}
        dialogActionsProps={{
          button: {
            submit: {
              description: t('com.muralis.qcx.acoes.continuar'),
              size: 'small',
            },
            cancel: {
              description: t('com.muralis.qcx.acoes.cancelar'),
              size: 'small',
            },
          },
        }}
        open={isFormDialogNewOpen}
        initialValues={{}}
        onSubmit={handleProcessoNewSubmit}
        onClose={handleCloseFormDialogNew}
      >
        {({ values, form }) => (
          <>
            <QCXFormListenerManager form={form} values={values} onChangeListeners={onChangeListeners} />
            <Grid item container spacing={2} xs={12}>
              <Grid item xs={12}>
                <QCXMoveFocusInside>
                  <QCXSelectTipoSolicitacaoPagamentoAutocompleteField
                    key="select-autocomplete-tipo-solicitacao-pagamento-field"
                    id="select-autocomplete-tipo-solicitacao-pagamento-field"
                    name="solicitacaoPagamento.tipo"
                    label={t('com.muralis.qcx.solicitacaoPagamento.tipoSolicitacaoPagamento')}
                    validate={required}
                    required
                    authInfo={authInfo}
                  />
                </QCXMoveFocusInside>
              </Grid>
              <Grid item xs={12}>
                {isTipoSolicitacaoPagamentoOperacional && (
                  <FinalSelectFollowUpWithSearch
                    key="select-autocomplete-processo-field"
                    id="select-autocomplete-processo-field"
                    name="followUp.id"
                    label={t('com.muralis.qcx.processo')}
                    validate={required}
                    controlled
                    required
                    applyImportExportFilter
                  />
                )}
              </Grid>
            </Grid>
          </>
        )}
      </QCXFinalFormDialog>
      <QCXConfirmDialog
        key="confirm-dialog-imprimir-solicitacao-pagamento"
        id="confirm-dialog-imprimir-solicitacao-pagamento"
        open={operationOfImprimirById?.active}
        title={operationOfImprimirById?.title}
        content={operationOfImprimirById?.message}
        endContent={operationOfImprimirById?.endMessage}
        onConfirm={operationOfImprimirById.confirm}
        onClose={operationOfImprimirById?.reset}
      />
    </QCXSimpleConsultPageTemplate>
  );
}
