import { MediaType, validateQueryParams } from '../../utils/api/api-utils';
import {
  doGetQCXRequest,
  doPostQCXRequest,
  doPutQCXActivateRequest,
  doPutQCXDuplicarRequest,
  doPutQCXRequest,
} from '../../utils/api/domain/domain-api-utils';
import {
  DESTINO_ENDPOINT,
  ETAPAS_ENDPOINT,
  FILTER_ENDPOINT,
  FOLLOW_UP_ENDPOINT,
  ORIGEM_ENDPOINT,
  PROCESS_XML_ENDPOINT,
  QCX_EMAIL_ENDPOINT,
  QCX_FATURAMENTO_ENDPOINT,
  QCX_STORAGE_ENDPOINT,
  TRANSFERIR_ENDPOINT,
} from '../../utils/api/domain/domain-endpoint-utils';

async function fetchAll() {
  return doGetQCXRequest([FOLLOW_UP_ENDPOINT]);
}

async function fetchByFilterPagination(queryParams = []) {
  const validatedParams = validateQueryParams(queryParams);

  return doGetQCXRequest([FOLLOW_UP_ENDPOINT, 'filter', 'pagination'], validatedParams);
}

async function fetchByFilter(queryParams = []) {
  return doGetQCXRequest([FOLLOW_UP_ENDPOINT, FILTER_ENDPOINT], queryParams);
}

async function fetchByFilterReduzido(queryParams = []) {
  return doGetQCXRequest([FOLLOW_UP_ENDPOINT, 'filter/reduzido'], queryParams);
}

async function fetchByFilterDto(queryParams = []) {
  return doGetQCXRequest([FOLLOW_UP_ENDPOINT, 'filter/dto'], queryParams);
}

async function fetchPreviewByFilter(queryParams = []) {
  return doGetQCXRequest([FOLLOW_UP_ENDPOINT, 'preview'], queryParams);
}

async function fetchById(id) {
  return doGetQCXRequest([FOLLOW_UP_ENDPOINT, id]);
}

async function fetchAllEtapasById(id) {
  return doGetQCXRequest([FOLLOW_UP_ENDPOINT, ETAPAS_ENDPOINT, id]);
}

async function register(data, queryParams = [], config = {}) {
  return doPostQCXRequest([FOLLOW_UP_ENDPOINT], data, queryParams, {
    ...config,
    headers: {
      'Content-Type': MediaType.MULTIPART_FORM_DATA,
    },
  });
}

async function save(data, queryParams = [], config = {}) {
  return doPutQCXRequest([FOLLOW_UP_ENDPOINT], data, queryParams, {
    ...config,
    headers: {
      'Content-Type': MediaType.MULTIPART_FORM_DATA,
    },
  });
}

async function addDocumentTo(receiver, data, queryParams = [], config = {}) {
  return doPostQCXRequest([FOLLOW_UP_ENDPOINT, QCX_STORAGE_ENDPOINT, receiver.id], data, queryParams, {
    ...config,
    headers: {
      'Content-Type': MediaType.MULTIPART_FORM_DATA,
    },
  });
}

async function sendEmailBy(data, queryParams = [], config = {}) {
  return doPostQCXRequest([FOLLOW_UP_ENDPOINT, QCX_EMAIL_ENDPOINT], data, queryParams, config);
}

async function activateById(id) {
  return doPutQCXActivateRequest([FOLLOW_UP_ENDPOINT], id);
}

async function inactivateById({ id, motivoCancelamento }) {
  return doPutQCXRequest([FOLLOW_UP_ENDPOINT, 'inactivate', id, 'motivo'], { motivoCancelamento });
}

async function duplicarById(id, subPath = [], data = null) {
  return doPutQCXDuplicarRequest([FOLLOW_UP_ENDPOINT], id, subPath, data);
}

async function processXml(receiver, data, queryParams = [], config = {}) {
  return doPutQCXRequest([FOLLOW_UP_ENDPOINT, PROCESS_XML_ENDPOINT, receiver.id], data, queryParams, {
    ...config,
    headers: {
      'Content-Type': MediaType.MULTIPART_FORM_DATA,
    },
  });
}

async function fetchAllByFaturamentoId(id) {
  return doGetQCXRequest([QCX_FATURAMENTO_ENDPOINT, TRANSFERIR_ENDPOINT, ORIGEM_ENDPOINT, id]);
}

async function fetchAllByImportadorFaturamentoId(id) {
  return doGetQCXRequest([QCX_FATURAMENTO_ENDPOINT, TRANSFERIR_ENDPOINT, DESTINO_ENDPOINT, id]);
}

async function clearStatus(id) {
  return doPutQCXRequest([FOLLOW_UP_ENDPOINT, id, 'status', 'clear']);
}

async function fetchByNumero(numero) {
  const queryParams = [{ name: 'name', value: numero }];

  return doGetQCXRequest([FOLLOW_UP_ENDPOINT, 'filter', 'search'], queryParams);
}

async function fetchFollowUpsComDiRegistrada() {
  return doGetQCXRequest([FOLLOW_UP_ENDPOINT, 'disponiveis-para-custeio'], []);
}

const followUpAPI = {
  fetchAll,
  fetchByFilter,
  fetchById,
  fetchAllEtapasById,
  register,
  save,
  addDocumentTo,
  sendEmailBy,
  activateById,
  inactivateById,
  duplicarById,
  processXml,
  fetchAllByFaturamentoId,
  fetchAllByImportadorFaturamentoId,
  fetchPreviewByFilter,
  fetchByFilterDto,
  fetchByFilterPagination,
  clearStatus,
  fetchByNumero,
  fetchFollowUpsComDiRegistrada,
};

export {
  activateById,
  addDocumentTo,
  duplicarById,
  fetchAll,
  fetchAllByFaturamentoId,
  fetchAllByImportadorFaturamentoId,
  fetchAllEtapasById,
  fetchByFilter,
  fetchByFilterDto,
  fetchById,
  fetchPreviewByFilter,
  followUpAPI,
  inactivateById,
  processXml,
  register,
  save,
  sendEmailBy,
  fetchByFilterPagination,
  clearStatus,
  fetchByNumero,
  fetchFollowUpsComDiRegistrada,
};
