import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXFinalForm from '../../shared-components/final-form/QCXFinalForm';
import QCXSelectMoedaAutocomplete from '../../shared-components/final-select-moeda-field/QCXSelectMoedaAutocomplete';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXSelectContaContabilAutocomplete from '../../shared-components/select-conta-contabil/QCXSelectContaContabilAutocomplete';
import QCXSelectTipoCalculoAutocomplete from '../../shared-components/select-tipo-calculo/QCXSelectTipoCalculoAutocomplete';
import QCXFinalSelectTipoModeloAutocomplete from '../../shared-components/select-tipo-modelo/QCXFinalSelectTipoModeloAutocomplete';
import QCXFinalSelectTipoRateioDespesaReceitaAutocomplete from '../../shared-components/select-tipo-rateio-despesa-receita/QCXFinalSelectTipoRateioDespesaReceitaAutocomplete';
import { required as requiredValidator } from '../../utils/validators/field/validator';

export default function QCXGrupoDespesaReceitaForm({
isCreate,
isConsult,
initialValues,
handleSubmit,
handleChangeToConsult,
handleChangeToUpdate,
handleCancelUpdate,
...restProps
}) {

    const { t } = useTranslation();

    return (
      <>
        <QCXFinalForm
          isCreate={isCreate}
          isConsult={isConsult}
          initialValues={initialValues}
          handleSubmitValues={handleSubmit}
          handleChangeToUpdate={handleChangeToUpdate}
          handleCancelUpdate={handleCancelUpdate}
          {...restProps}
        >
          {({ values }) => (
            <>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <QCXFinalTextField
                  id="text-field-code"
                  key="text-field-code"
                  name="id"
                  label={t('com.muralis.qcx.codigo')}
                  disabled={isConsult}
                />
              </Grid>
  
              <Grid item xs={12} sm={isCreate ? 12 : 8} md={8} lg={isCreate ? 8 : 6}>
                <QCXFinalTextField
                  id="text-field-description"
                  key="text-field-description"
                  name="description"
                  label={t('com.muralis.qcx.descricao')}
                  disabled={isConsult}
                  validate={requiredValidator}
                  required
                />
              </Grid>
            </>
          )}
        </QCXFinalForm>
      </>
    );
}