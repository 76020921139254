import { Grid } from '@material-ui/core';
import { isFunction } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllAsync, selectClientes, selectStatus, resetList } from '../../features/cliente/clienteSlice';
import QCXSimpleSelectManyPageTemplate from '../../templates/simple-select-many-page/QCXSimpleSelectManyPageTemplate';
import { isAlternativeLoadingStatus } from '../../utils/store/store-utils';
import { formatCnpj } from '../../utils/hooks/form/field/formatters';

export default function QCXSelectManyClienteTable({ ...props }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const clientes = useSelector(selectClientes);
  const status = useSelector(selectStatus);

  const isAlternativeLoading = useMemo(() => isAlternativeLoadingStatus(status), [status]);

  const {
    title = t('com.muralis.qcx.cliente.selecioneClientes'),
    filterProp = 'description',
    selected,
    handleChange,
    isConsult,
    configureFilterPredicate,
    ...restProps
  } = props;

  const filteredClientesList = useMemo(() => {
    if (isFunction(configureFilterPredicate)) {
      const filterPredicate = configureFilterPredicate();
      const filteredValues = clientes.filter(filterPredicate);

      return filteredValues;
    }

    return clientes;
  }, [clientes, configureFilterPredicate]);

  // essa função 'getRow' é necessária, pois os dados da variável selected podem estar lazy loaded e não conter as informações exibidas na tabela (description, cnpj etc.)
  const getRow = useCallback((id) => filteredClientesList?.find((c) => c.id === id), [filteredClientesList]);

  useEffect(() => {
    dispatch(fetchAllAsync());
  }, []);

  useEffect(
    () => () => {
      dispatch(resetList());
    },
    []
  );

  const columns = [
    {
      field: 'id',
      headerName: t('com.muralis.qcx.codigo'),
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 50,
      valueGetter: ({ row }) => row?.id,
    },
    {
      field: 'description',
      headerName: t('com.muralis.qcx.cliente.label'),
      type: 'string',
      flex: 300,
      valueGetter: ({ row }) => getRow(row?.id)?.description,
    },
    {
      field: 'cnpj',
      headerName: t('com.muralis.qcx.empresa.CNPJ'),
      type: 'string',
      flex: 100,
      valueGetter: ({ row }) => formatCnpj(getRow(row?.id)?.pessoa?.cnpj),
    },
    {
      field: 'active',
      headerName: t('com.muralis.qcx.estado.ativo'),
      type: 'boolean',
      valueGetter: ({ row }) => getRow(row?.id)?.active,
      flex: 50,
    },
  ];

  return (
    <>
      <Grid container direction="row" style={{ marginTop: '0.7rem' }}>
        <QCXSimpleSelectManyPageTemplate
          title={title}
          tableColumns={columns}
          tableData={filteredClientesList}
          filterProp={filterProp}
          isLoading={isAlternativeLoading}
          isConsult={isConsult}
          handleSetSelected={handleChange}
          selected={selected}
          initialValues={selected}
          {...restProps}
        />
      </Grid>
    </>
  );
}
