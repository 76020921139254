import { Grid } from '@material-ui/core';
import { useFormState, useForm } from 'react-final-form';
import _ from 'lodash';
import { useEffect, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';
import QCXFinalBondManager from '../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManager';
import QCXSelectEmpresaEstrangeiraAutocomplete from '../../shared-components/final-select-empresa-estrangeira-field/QCXSelectEmpresaEstrangeiraAutocomplete';
import {
  fetchAllAsync,
  selectEmpresasEstrangeiras,
  fetchFilteredAsync,
  fetchByIdAndInsertList,
} from '../../features/empresa-estrangeira/empresaEstrangeiraSlice';

import { required as requiredValidator } from '../../utils/validators/field/validator';

export default function QCXSelectManyExportadores({ ...props }) {
  const {
    title,
    name,
    exportador,
    inputConfig,
    isConsult,
    isAlternativeReasonToDisabled,
    autoCompleteProps = {},
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const form = useForm();
  const { values } = useFormState();

  const list = useSelector(selectEmpresasEstrangeiras);

  useEffect(() => {
    dispatch(fetchFilteredAsync());
  }, []);

  const getEndereco = (row) => {
    let strEndereco = '';
    if (row?.endereco?.logradouro) {
      strEndereco += row?.endereco?.logradouro;
    }

    if (row?.endereco?.pais?.description) {
      if (strEndereco) strEndereco += ' - ';
      strEndereco += row?.endereco?.pais?.description;
    }

    return strEndereco;
  };

  const fornecedorProps = useMemo(
    () => ({
      name,
      columns: [
        {
          field: 'pessoaJuridica.id',
          headerName: t('com.muralis.qcx.codigo'),
          headerAlign: 'center',
          align: 'center',
          type: 'string',
          flex: 50,
          valueGetter: ({ row }) => row?.id,
        },
        {
          field: 'pessoaJuridica.nomeResumido',
          headerName: t('com.muralis.qcx.cliente.label'),
          type: 'string',
          flex: 300,
          valueGetter: ({ row }) => row?.pessoaJuridica?.nomeResumido,
        },
        {
          field: 'endereco.pais.description',
          headerName: t('com.muralis.qcx.endereco.endereco'),
          type: 'string',
          flex: 300,
          valueGetter: ({ row }) => getEndereco(row),
        },
      ],
    }),
    [list]
  );

  const fornecedorFormProps = useMemo(
    () => ({
      rootName: `ignorableFields.fornecedor`,
      fields: [
        {
          name: 'exportador',
          label: 'Exportador',
        },
      ],
    }),
    []
  );

  const handleAddFornecedor = useCallback(
    async (currentVinculo, currentVinculos, handleSuccessAddVinculo) => {
      if (!list || (list && Array.isArray(list) && list.length === 0)) {
        console.log(`sem lista de fornecedores`);
        return;
      }

      if (currentVinculo.exportador) {
        const id = currentVinculo.exportador;
        const exportadorSelecionado = list.find((el) => el.id === id);

        const exportadoresForm = form.getState().values?.exportadores;

        if (exportadoresForm && Array.isArray(exportadoresForm)) {
          const exportadorClone = _.cloneDeep(exportadorSelecionado);

          form.change('exportadores', [...exportadoresForm, exportadorClone]);
        } else if (exportadorSelecionado) {
          const exportadorClone = _.cloneDeep(exportadorSelecionado);
          form.change('exportadores', [exportadorClone]);
        }
      }

      form.change('ignorableFields.fornecedor.exportador', null);
    },
    [list]
  );

  const handleUpdateFornecedor = useCallback(
    async (currentVinculo, currentVinculos, handleSuccessUpdateVinculo) => {
      const idVinculoAntigo = currentVinculos.findIndex((objeto) => objeto.id === currentVinculo.id);
      if (idVinculoAntigo !== -1) {
        const exportadorId = _.get(form.getState().values, 'ignorableFields.fornecedor.exportador');
        const exportadorNovo = list.find((el) => el.id === exportadorId);

        if (exportadorNovo) {
          const exportadorCopia = _.cloneDeep(exportadorNovo);
          const arrVinculos = [...currentVinculos];
          arrVinculos[idVinculoAntigo] = exportadorCopia;
          form.change('exportadores', arrVinculos);

          const relacaoFabricante = _.get(form.getState()?.values, 'relacao');
          if (relacaoFabricante && `${relacaoFabricante}`.toLowerCase() === `FABRICANTE_EXPORTADOR`.toLowerCase()) {
            // atualiza fabricante
            form.change('fabricantes', arrVinculos);
          }
        }
      }
    },
    [list]
  );

  const exportadorFilterPredicate = useCallback(
    (empresaEstrangeira) => empresaEstrangeira?.exportador && empresaEstrangeira?.active,
    []
  );

  return (
    <>
      <Grid item xs={12}>
        <QCXFormSubtitle title={title} />
      </Grid>

      <QCXFinalBondManager
        isParentConsult={isConsult}
        listProps={fornecedorProps}
        formProps={fornecedorFormProps}
        handleAdd={handleAddFornecedor}
        handleUpdate={handleUpdateFornecedor}
        forceShowDelete
      >
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <QCXSelectEmpresaEstrangeiraAutocomplete
            id="select-field-empresa-estrangeira-exportador"
            key="select-field-empresa-estrangeira-exportador"
            name="ignorableFields.fornecedor.exportador"
            label={t('com.muralis.qcx.exportador.label')}
            initialValues={values}
            filterPredicate={exportadorFilterPredicate}
            // fieldProps={{
            //   validate: requiredValidator,
            // }}
            disabled={isConsult || isAlternativeReasonToDisabled}
            {...autoCompleteProps}
          />
        </Grid>
      </QCXFinalBondManager>
    </>
  );
}
