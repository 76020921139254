import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import i18n from '../../i18n';
import { getOptionByValueInList } from '../../utils/general/general-utils';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

const list = [
  {
    label: i18n.t('com.muralis.qcx.despesa.label'),
    value: 'DESPESA',
  },
  {
    label: i18n.t('com.muralis.qcx.receita'),
    value: 'RECEITA',
  },
  {
    label: i18n.t('com.muralis.qcx.imposto.impostosFederais'),
    value: 'IMPOSTOS_FEDERAIS',
  },
  {
    label: i18n.t('com.muralis.qcx.imposto.impostosEstaduais'),
    value: 'IMPOSTOS_ESTADUAIS',
  },
  {
    label: i18n.t('com.muralis.qcx.credito'),
    value: 'CREDITO',
  },
];

export default function QCXFinalSelectTipoModeloAutocomplete({ label, name, initialValues, ...restProps }) {
  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    const initialValue = _.get(initialValues, name);

    const option = list.find((item) => item?.value === initialValue);

    if (option) {
      setDefaultValue(option);
    } else {
      setDefaultValue('');
    }
  }, [list, initialValues]);

  const filterOptions = useMemo(
    () =>
      createFilterOptions({
        stringify: (option) => option.value,
      }),
    []
  );

  const getOptionLabel = useCallback((item) => item?.label || '', []);

  const getOptionValue = useCallback((item) => item?.value || '', []);

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={list}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      {...restProps}
    />
  );
}

function getTipoModeloLabelByValue(value) {
  const foundOption = getOptionByValueInList(list, value);
  return foundOption && foundOption?.label;
}

export { getTipoModeloLabelByValue };
