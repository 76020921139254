import { Box, Dialog, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import { PictureAsPdf as PdfIcon, Code as CodeIcon, Cancel as CancelIcon } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import QCXButton from '../../../../../shared-components/button/QCXButton';
import QCXIconButton from '../../../../../shared-components/button/QCXIconButton';
import QCXDataGrid from '../../../../../shared-components/data-grid/QCXDataGrid';
import TableCellWithTooltip from '../../../../../shared-components/tooltip-cell/TableCellWithTooltip';
import { forceUnnormalizeNumeral } from '../../../../../utils/general/general-utils';
import { formatBrazilianNumericDecimal, formatCnpj } from '../../../../../utils/hooks/form/field/formatters';

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paper': {
      maxWidth: 'none',
      width: '70vw',
    },
  },
});

export default function FaturamentoNfseListaDialog({
  isOpen,
  closeModal,
  nfseList,
  onConsultNFSe,
  onPdfDownload,
  onXmlDownload,
  onCancelNfse,
}) {
  const [updateCooldown, setUpdateCooldown] = useState(0);

  useEffect(() => {
    if (updateCooldown === 0) return;

    const intervalId = setInterval(() => {
      setUpdateCooldown((prev) => {
        if (prev <= 1) {
          clearInterval(intervalId);
          return 0;
        }

        return prev - 1;
      });
    }, 1000);

    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(intervalId);
    };
  }, [updateCooldown]);

  const classes = useStyles();

  const columns = [
    {
      field: 'nroNota',
      headerName: 'Número da Nota',
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 1,
      valueGetter: ({ row }) => row?.numeroNota ?? '',
    },
    {
      field: 'nfse.prestador',
      headerName: 'Prestador',
      flex: 1,
      valueGetter: ({ row }) => (row?.prestador ? formatCnpj(row.prestador) : ''),
    },
    {
      field: 'nfse.tomador',
      headerName: 'Tomador',
      flex: 1,
      valueGetter: ({ row }) => (row?.tomador ? formatCnpj(row.tomador) : ''),
    },
    {
      field: 'nfse.valorServico',
      headerName: 'Valor Serviço',
      flex: 1,
      valueGetter: ({ row }) =>
        row?.valor ? forceUnnormalizeNumeral(row?.valor, formatBrazilianNumericDecimal(2)) : '',
    },
    {
      field: 'nfse.status',
      headerName: 'Status',
      flex: 1,
      valueGetter: ({ row }) => row?.status?.replace('_', ' '),
      renderCell: ({ row }) => (
        <TableCellWithTooltip label={row?.status?.replace('_', ' ')} value={row?.retornoIntegracao} />
      ),
    },
    {
      field: 'nfse.acoes',
      headerName: 'Ações',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }) => (
        <>
          <QCXIconButton
            disabled={!['CONCLUIDO', 'CANCELADO'].includes(row?.status)}
            onClick={() => onPdfDownload(row?.idPlugNotas)}
            tooltipProps={{ title: 'Baixar PDF' }}
          >
            <PdfIcon color={!['CONCLUIDO', 'CANCELADO'].includes(row?.status) ? undefined : 'primary'} />
          </QCXIconButton>
          <QCXIconButton
            disabled={!['CONCLUIDO', 'CANCELADO'].includes(row?.status)}
            onClick={() => onXmlDownload(row?.idPlugNotas)}
            tooltipProps={{ title: 'Baixar XML' }}
          >
            <CodeIcon color={!['CONCLUIDO', 'CANCELADO'].includes(row?.status) ? undefined : 'primary'} />
          </QCXIconButton>
          <QCXIconButton
            disabled={!['CONCLUIDO'].includes(row?.status)}
            onClick={() => onCancelNfse(row?.idPlugNotas)}
            tooltipProps={{ title: 'Solicitar cancelamento ' }}
          >
            <CancelIcon color={!['CONCLUIDO'].includes(row?.status) ? undefined : 'error'} />
          </QCXIconButton>
        </>
      ),
    },
  ];

  const handleConsultNfseWithCooldown = () => {
    onConsultNFSe();

    setUpdateCooldown(30);
  };

  return (
    <Dialog className={classes.root} fullWidth open={isOpen} onClose={closeModal}>
      <DialogTitle>Consultar NFS-e</DialogTitle>
      <DialogContent>
        <QCXDataGrid columns={columns} rows={nfseList} />

        <Box gridGap={4} mt={4} mb={4} display="flex" flexDirection="row" justifyContent="flex-end">
          <QCXButton onClick={closeModal} variant="outlined" color="primary">
            Fechar
          </QCXButton>

          <QCXButton
            showTooltipForDisabledButton
            disabled={updateCooldown > 0}
            tooltip={updateCooldown > 0}
            tooltipDescription={`Aguarde ${updateCooldown} para atualizar novamente.`}
            onClick={handleConsultNfseWithCooldown}
            color="secondary"
          >
            Atualizar
          </QCXButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
