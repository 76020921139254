import { Box, Grid, FormControl, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useState, SyntheticEvent, useCallback } from 'react';
import QCXFinalDatePickerField from '../../../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import { useDispatch, useSelector } from 'react-redux';
import { useListClientes } from '../../../common/hooks/useListClientes';
import { loading, success } from '../../../../features/danfe/danfeSlice';
import {
    selectUnidadesDeNegocioAssociadas,
    selectUnidadeSelecionada,
} from '../../../../features/usuario-logado/usuarioLogadoSlice';
import { useUnidadeNegocioGuard } from '../../../common/hooks/useUnidadeNegocioGuard';
import { Form, Field } from 'react-final-form';
import moment from 'moment';
import { required as requiredValidator } from '../../../../utils/validators/field/validator';
import MultiSelectStyled, { SimpleMultiSelectOption } from '../../../common/components/input/multiSelectStyled';
import { KEYCLOAK_TOKEN_TIMEOUT } from '../../../../App';
import { useKeycloak } from '@react-keycloak/web';
import { downloadSheet } from './relatorioContaCorrentePage.helpers';
import { GridItemModalProps } from '../../../common/components/cardsGrid/cardsModalGrid';
import { makeStyles } from '@material-ui/core';
import { setErrorFeedback } from '../../../../features/feedback/feedbackSlice';
import { fetchByFilter } from '../../../../features/follow-up/followUpAPI';

interface ControlledValue {
    id: string | number;
    value: string;
}

const useStyles = makeStyles({
    root: {
        '& .MuiDialog-paper': {
            minWidth: '50vw',
        },
    },
});

const RelatorioContaCorrentePage = ({ title, isOpen, closeModal }: GridItemModalProps) => {
    useUnidadeNegocioGuard();
    const classes = useStyles();
    const { keycloak } = useKeycloak();
    const { token } = keycloak;
    keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);

    const dispatch = useDispatch();

    const selectedUnit = useSelector(selectUnidadeSelecionada);
    const list = useSelector(selectUnidadesDeNegocioAssociadas);

    const [clientes] = useListClientes();
    const [selectedClients, setSelectedClients] = useState<ControlledValue[]>([]);

    const [followUpList, setFollowUpList] = useState<ControlledValue[]>([]);
    const [selectedProcesses, setSelectedProcesses] = useState<ControlledValue[]>([]);

    const [selectedBusiness, setSelectedBusiness] = useState<SimpleMultiSelectOption[]>([]);
    const [businessError, setBusinessError] = useState(false);

    const [isFetchingProcesses, setIsFetchingProcesses] = useState<boolean>(false);

    const fetchFilteredFollowUps = useCallback(async (clients: ControlledValue[]) => {
        try {
            setIsFetchingProcesses(true);
            const clientIds = clients.map((client) => client.id).join(',');
            if (clientIds) {
                const response = await fetchByFilter([{ name: 'clientIds', value: clientIds }]);
                const followUps = response.data.map((item: any) => ({
                    id: item.id?.toString() || '',
                    value: item.numero?.toString() || '[Sem número de processo]',
                }));
                setFollowUpList(followUps);
            } else {
                setFollowUpList([]);
            }
        } catch (error) {
            console.error('Erro ao buscar processos:', error);
            setFollowUpList([]);
        } finally {
            setIsFetchingProcesses(false);
        }
    }, []);

    const handleClientsChange = (
        event: SyntheticEvent<Element, Event>,
        value: { id: string | number | undefined; value: string | undefined }[] | null
    ) => {
        const newSelectedClients: ControlledValue[] = [];
        value?.forEach((val) => {
            const foundClient = clientes.find((c) => c.id === val.id);
            if (foundClient && foundClient.id) {
                if (!newSelectedClients.some((cli) => cli.id === foundClient.id)) {
                    newSelectedClients.push({
                        id: foundClient.id.toString(),
                        value: `(${foundClient.pessoa?.cnpj}) ${foundClient.pessoa?.nome}`,
                    });
                }
            }
        });
        setSelectedClients(newSelectedClients);
        setSelectedProcesses([]);
        fetchFilteredFollowUps(newSelectedClients);
    };

    const handleProcessesChange = (
        event: SyntheticEvent<Element, Event>,
        value: { id: string | number | undefined; value: string | undefined }[] | null
    ) => {
        const newSelectedProcesses: ControlledValue[] = [];
        value?.forEach((val) => {
            const foundProcess = followUpList.find((f) => f.id === val.id);
            if (foundProcess) {
                newSelectedProcesses.push(foundProcess);
            }
        });
        setSelectedProcesses(newSelectedProcesses);
    };

    const handleBusinessChange = (
        event: SyntheticEvent<Element, Event>,
        value: { id: string | number | undefined; value: string | undefined } | null
    ) => {
        if (value == null) setSelectedBusiness([]);
        else setSelectedBusiness([{
            id: value!.id as string,
            value: value!.value as string
        }]);
    }

    const handleSubmit = async (values: any) => {
        keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);

        if (selectedBusiness.length === 0) {
            setBusinessError(true);
            return;
        } else {
            setBusinessError(false);
        }

        if (token && selectedUnit) {
            try {
                dispatch(loading());

                const dataInicio = values.startDate ? moment(values.startDate).format('YYYY-MM-DD') : '';
                const dataFim = values.endDate ? moment(values.endDate).format('YYYY-MM-DD') : '';

                const businessIDs = selectedBusiness.map((b) => b.id.toString());
                const clienteIDs = selectedClients.map((c) => c.id.toString());
                const processoIDs = selectedProcesses.map((p) => p.id.toString());

                const downloadResponse = await downloadSheet(
                    token,
                    selectedUnit,
                    businessIDs,
                    clienteIDs,
                    processoIDs,
                    dataInicio,
                    dataFim
                );
                dispatch(success());

                if (downloadResponse?.error) {
                    dispatch(setErrorFeedback({ message: downloadResponse.message }));
                    return;
                }
            } catch (error) {
                console.error('Erro ao baixar a planilha geral:', error);
                dispatch(success());
                dispatch(setErrorFeedback({ message: 'Erro inesperado ao gerar o relatório.' }));
            }
        }
    };

    return (
        <Dialog className={classes.root} open={isOpen} onClose={closeModal}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Grid item lg={12} xs={12}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: 2 }}>
                            <Box sx={{ flex: 2 }}>
                                <Form
                                    onSubmit={handleSubmit}
                                    initialValues={{
                                        startDate: moment().subtract(5, 'days').format('YYYY-MM-DD'),
                                        endDate: moment().add(5, 'days').format('YYYY-MM-DD'),
                                    }}
                                    render={({ handleSubmit, values }) => (
                                        <Box
                                            component="form"
                                            onSubmit={handleSubmit}
                                            mt={2}
                                            p={2}
                                            border={1}
                                            borderRadius={4}
                                            borderColor="grey.300"
                                        >
                                            <Typography variant="h6">Filtros</Typography>
                                            <Grid container spacing={2}>
                                                <Grid item sm={4} style={{ margin: '5px 0 0 0' }}>
                                                    <FormControl fullWidth error={businessError}>
                                                        <MultiSelectStyled
                                                            disablePortal={false}
                                                            controlledValues={selectedBusiness}
                                                            options={list.map((business: any) => ({
                                                                id: business.id,
                                                                value: business.pessoa.nomeResumido,
                                                            }))}
                                                            onChangeAction={(event, value) => {
                                                                setSelectedBusiness(value || []);
                                                                values.business = value;
                                                            }}
                                                            label={'Unidade de Negócio'}
                                                        />
                                                        {businessError && (
                                                            <Typography variant="body2" color="error">
                                                                Este campo é obrigatório
                                                            </Typography>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item sm={4} style={{ margin: '5px 0 0 0' }}>
                                                    <FormControl fullWidth>
                                                        <MultiSelectStyled
                                                            disablePortal={false}
                                                            options={clientes.map((client) => ({
                                                                id: client.id ?? '',
                                                                value: `(${client.pessoa?.cnpj}) ${client.pessoa?.nome}`,
                                                            }))}
                                                            onChangeAction={(event, value) => {
                                                                handleClientsChange(event, value);
                                                                values.clients = value;
                                                            }}
                                                            label={'Clientes'}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item sm={4} style={{ margin: '5px 0 0 0' }}>
                                                    <FormControl fullWidth>
                                                        <MultiSelectStyled
                                                            disablePortal={false}
                                                            options={followUpList.map((process) => ({
                                                                id: process.id,
                                                                value: process.value,
                                                            }))}
                                                            onChangeAction={(event, value) => {
                                                                handleProcessesChange(event, value);
                                                                values.processes = value;
                                                            }}
                                                            label={'Processos'}
                                                            placeholder={
                                                                selectedClients.length === 0
                                                                    ? "Selecione um Cliente"
                                                                    : isFetchingProcesses
                                                                        ? "Carregando..."
                                                                        : "Selecionar Processos"
                                                            }
                                                            disabled={selectedClients.length === 0 || isFetchingProcesses}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item sm={4} style={{ margin: '0px 0 0 0' }}>
                                                    <Field
                                                        name="startDate"
                                                        render={({ input }) => (
                                                            <QCXFinalDatePickerField
                                                                id="date-picker-start-date"
                                                                key="date-picker-start-date"
                                                                name={input.name}
                                                                label={'Data de Início'}
                                                                required={false}
                                                                validate={requiredValidator}
                                                                format="DD/MM/YYYY"
                                                                placeholder="DD/MM/YYYY"
                                                                disablePast={false}
                                                                disableFuture={false}
                                                                minDate={undefined}
                                                                onChange={(date: any) => {
                                                                    input.onChange(date);
                                                                    values.startDate = date;
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item sm={4} style={{ margin: '0px 0 0 0' }}>
                                                    <Field
                                                        name="endDate"
                                                        render={({ input }) => (
                                                            <QCXFinalDatePickerField
                                                                id="date-picker-end-date"
                                                                key="date-picker-end-date"
                                                                name={input.name}
                                                                label={'Data de Fim'}
                                                                required={false}
                                                                validate={requiredValidator}
                                                                format="DD/MM/YYYY"
                                                                placeholder="DD/MM/YYYY"
                                                                disablePast={false}
                                                                disableFuture={false}
                                                                minDate={undefined}
                                                                onChange={(date: any) => {
                                                                    input.onChange(date);
                                                                    values.endDate = date;
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    sm={4}
                                                    style={{ display: 'flex', alignItems: 'flex-end', margin: '0px 0 0 0' }}
                                                >
                                                    <Button type="submit" variant="contained" color="secondary" fullWidth>
                                                        Buscar
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    )}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Box
                    sx={{ padding: 2, display: 'flex', gap: 1 }}
                >
                    <Button variant="outlined" color="primary" onClick={closeModal}>
                        Fechar
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default RelatorioContaCorrentePage;
