import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { useHistory } from 'react-router';
import { Button, Grid, SelectChangeEvent } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, makeStyles, MenuItem } from '@material-ui/core';
import { setErrorFeedback, setSuccessFeedback } from '../../../../features/feedback/feedbackSlice';
import { generateSpreadsheetMovimentacao } from './relatorioMovimentacaoPage.helpers';
import { useUnidadeNegocioGuard } from '../../../common/hooks/useUnidadeNegocioGuard';
import {
  selectUnidadeSelecionada,
  selectUnidadesDeNegocioAssociadas,
} from '../../../../features/usuario-logado/usuarioLogadoSlice';
import { GridItemModalProps } from '../../../common/components/cardsGrid/cardsModalGrid';
import { Field, Form } from 'react-final-form';
import QCXFinalDatePickerField from '../../../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import theme from '../../../common/themes/theme';
import { fetchAllAsync as fetchAllFornecedores } from '../../../../features/fornecedor-favorecido/FornecedorSlice';
import { useTranslation } from 'react-i18next';
import QCXSelectContaBancariaAutocompleteField from '../../../../shared-components/select-conta-bancaria/QCXSelectContaBancariaAutocompleteField';
import { MultiSelectFornecedor } from './MultiSelectFornecedor';
import QCXTextField from '../../../../shared-components/text-field/QCXTextField';
import SelectStyled from '../../../common/components/input/selectStyled'; // Single-select from your code

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paper': {
      minWidth: '50vw',
    },
  },
  inputLabel: {
    backgroundColor: 'white',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    gap: '10px',
  },
  input: {
    height: 40,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
  button: {
    borderRadius: '4px',
  },
});

interface ControlledValue {
  id: number | string;
  value: string;
}

const RelatorioMovimentacaoPage = ({ title, isOpen, closeModal }: GridItemModalProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { keycloak } = useKeycloak();
  const { token } = keycloak;
  keycloak.updateToken(300);

  const selectedUnit = useSelector(selectUnidadeSelecionada);
  const businessUnits = useSelector(selectUnidadesDeNegocioAssociadas);

  const [origem, setOrigem] = useState('');
  const [datas, setDatas] = useState({ dataInicio: '', dataFim: '' });
  const [bancoId, setBancoId] = useState<number | null>(null);
  const [selectedBusiness, setSelectedBusiness] = useState<ControlledValue | undefined>();
  const [selectedFornecedores, setSelectedFornecedores] = useState<number[]>([]);

  useUnidadeNegocioGuard();

  const businessUnitsOptions: ControlledValue[] = businessUnits.map((unit: any) => ({
    id: unit.id,
    value: unit.pessoa.nomeResumido ?? unit.pessoa.nome,
  }));

  const handleChangeBanco = (_event: any, option: any) => {
    setBancoId(option?.id || null);
  };

  useEffect(() => {
    dispatch(fetchAllFornecedores());
  }, [dispatch]);

  const validarDatas = (d: { dataInicio: string; dataFim: string }): boolean => {
    if (!d.dataInicio || !d.dataFim) return false;
    const dataInicio = new Date(d.dataInicio);
    const dataFim = new Date(d.dataFim);
    return dataInicio <= dataFim;
  };

  const handleChangeDataInicio = (isoDate: string) => {
    const value = isoDate.split('T')[0];
    setDatas((prev) => ({ ...prev, dataInicio: value }));
  };

  const handleChangeDataFim = (isoDate: string) => {
    const value = isoDate.split('T')[0];
    setDatas((prev) => ({ ...prev, dataFim: value }));
  };

  const handleOrigemChange = (event: SelectChangeEvent<string>) => {
    setOrigem(event.target.value);
  };

  const handleBusinessChange = (_event: any, value: { id: string | number | undefined; value: string | undefined } | null) => {
      if (!value || value.id === undefined || value.value === undefined) {
        setSelectedBusiness(undefined);
      } else {
        setSelectedBusiness({ id: value.id, value: value.value });
      }
    };

  const handleSubmit = useCallback(async () => {
    keycloak.updateToken(300);

    if (!validarDatas(datas)) {
      dispatch(setErrorFeedback({ message: 'Preencha as datas de início e fim corretamente.' }));
      return;
    }

    if (token && selectedUnit) {
      try {
        const error = await generateSpreadsheetMovimentacao(
          datas.dataInicio,
          datas.dataFim,
          token,
          selectedUnit,
          selectedBusiness ? [Number(selectedBusiness.id)] : [],
          selectedFornecedores,
          bancoId,
          origem
        );
        if (error != null) {
          dispatch(setErrorFeedback({ message: error }));
        } else {
          dispatch(setSuccessFeedback({ message: 'Planilha gerada com sucesso!' }));
        }
      } catch (err) {
        dispatch(setErrorFeedback({ message: 'Erro inesperado ao gerar o relatório.' }));
      }
    }

    keycloak.updateToken(300);
    history.push('/financeiro/relatorios');
  }, [datas, token, selectedUnit, selectedBusiness, selectedFornecedores, dispatch, keycloak, history, origem, bancoId]);

  return (
    <Dialog className={classes.root} open={isOpen} onClose={closeModal}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Form onSubmit={() => {}}>
          {() => (
            <form>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <FormControl fullWidth>
                    <SelectStyled
                      disablePortal={false}
                      controlledValue={selectedBusiness}
                      options={businessUnitsOptions}
                      onChangeAction={handleBusinessChange}
                      label="Unidade de negócio"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <QCXSelectContaBancariaAutocompleteField
                    id="autocomplete-select-conta-bancaria"
                    key="autocomplete-select-conta-bancaria"
                    name="contaBancaria"
                    label="Conta bancária"
                    initialValues={undefined}
                    filtraControlaSaldo={true}
                    onChange={handleChangeBanco}
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <FormControl fullWidth>
                    <QCXTextField
                      style={{ width: '100%' }}
                      label="Origem"
                      key="select-origem-movimentacao"
                      id="select-origem-movimentacao"
                      select
                      value={origem}
                      onChange={handleOrigemChange}
                      {...({} as any)}
                    >
                      <MenuItem value="">Todos</MenuItem>
                      <MenuItem value="OPERACIONAL">OPERACIONAL</MenuItem>
                      <MenuItem value="ADMINISTRATIVA">ADMINISTRATIVA</MenuItem>
                    </QCXTextField>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <MultiSelectFornecedor
                    onChangeCallback={(selected) => setSelectedFornecedores(selected.map((s) => s.id as number))}
                  />
                </Grid>

                <Grid item xs={6} style={{ marginBottom: '25px' }}>
                  <Field
                    name="startDate"
                    render={({ input }) => (
                      <QCXFinalDatePickerField
                        id="start-date"
                        name={input.name}
                        label="Data de Início"
                        required={true}
                        format="DD/MM/YYYY"
                        placeholder="DD/MM/YYYY"
                        onChange={(date: any) => {
                          handleChangeDataInicio(date.toISOString());
                          input.onChange(date);
                        }}
                        validate={undefined}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} style={{ marginBottom: '25px' }}>
                  <Field
                    name="endDate"
                    render={({ input }) => (
                      <QCXFinalDatePickerField
                        id="end-date"
                        name={input.name}
                        label="Data final"
                        required={true}
                        format="DD/MM/YYYY"
                        placeholder="DD/MM/YYYY"
                        onChange={(date: any) => {
                          handleChangeDataFim(date.toISOString());
                          input.onChange(date);
                        }}
                        validate={undefined}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </form>
          )}
        </Form>
      </DialogContent>
      <DialogActions>
        <Box padding={2} display="flex" gridGap={4}>
          <Button variant="outlined" color="primary" onClick={closeModal}>
            Fechar
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            fullWidth
            disabled={!selectedBusiness || !datas.dataInicio || !datas.dataFim || bancoId === null}
          >
            Gerar Relatório
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default RelatorioMovimentacaoPage;
