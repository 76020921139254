import axios from 'axios';

export interface BasicFormData {
  arquivo: File;
  cliente: {
    id: number;
  };
  processo: string;
  servico: {
    id: number;
  };
  clientes: { id: number }[];
}

const uploadFile = async (value: BasicFormData, unidadeId: number, token: string, url: string) => {
  let formData = new FormData();

  const clientesIds = value.clientes
    ? value.clientes
        .map((cliente) => cliente?.id)
        .filter((id) => id !== null && id !== undefined)
        .join(',')
    : '';

  formData.append('clientes', clientesIds);

  if (value.cliente && value.cliente.id !== null && value.cliente.id !== undefined) {
    formData.append('clienteId', value.cliente.id.toString());
  }

  if (value.processo !== null && value.processo !== undefined) {
    formData.append('processo', value.processo);
  }

  if (value.servico && value.servico.id !== null && value.servico.id !== undefined) {
    formData.append('servicoId', value.servico.id.toString());
  }

  if (value.arquivo) {
    formData.append('bodyData', value.arquivo);
  }

  const response = await axios
    .post(url, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
        quickcomexTenant: unidadeId,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });

  return response;
};


const commonHelpers = {
  uploadFile,
};

export default commonHelpers;
