import axios from 'axios';

const baseUrl = process.env.REACT_APP_QCX_API_BASE_URL! + '/' + process.env.REACT_APP_RELATORIOS_API_CONTA_CORRENTE!;

export const downloadSheet = async (
  token: string,
  unidadeId: number,
  unidadesNegocioID: string[],
  clientesID: string[],
  processosID: string[],       
  dataInicio: string,
  dataFim: string
): Promise<void | { error: boolean; message: string }> => {
  const params = new URLSearchParams();
  params.set('tenantId', unidadesNegocioID.join(',')); 
  params.set('clientesId', clientesID.join(','));      
  params.set('processosIds', processosID.join(','));
  params.set('inicio', dataInicio);
  params.set('fim', dataFim);

  try {
    const response = await axios.get(`${baseUrl}?${params.toString()}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
        quickcomexTenant: unidadeId,
      },
      responseType: 'blob',
    });

    if (response.status === 200) {
      const file = new File([response.data], `relatorioContaCorrente.xlsx`, {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = file.name;
      a.click();
    } else {
      return { error: true, message: await response.data };
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 404) {
        return { error: true, message: 'Nenhum resultado para os filtros selecionados.' };
      }
      if (error.response) {
        let msg: string;
        try {
          msg = await error.response.data.text();
        } catch {
          msg = 'Erro inesperado ao ler a resposta do servidor';
        }
        return { error: true, message: msg };
      }
    }
    return { error: true, message: 'Erro inesperado' };
  }
};
