import { Grid, makeStyles, useTheme } from '@material-ui/core';
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _, { isEmpty } from 'lodash';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import QCXIconButton from '../../shared-components/button/QCXIconButton';
import useDownloader from '../../utils/hooks/downloader/useDownloader';
import useValidators from '../../utils/hooks/useValidators';
import useModeParam from '../../utils/hooks/mode-param/useModeParam';
import { maxValue, required as requiredValidator, validateWeekDay } from '../../utils/validators/field/validator';
import QCXFormTitle from '../form-title/QCXFormTitle';
import QCXSolicitacaoPagamentoFinanceiroObservacoesGroup from './QCXSolicitacaoPagamentoFinanceiroObservacoesGroup';
import QCXFinalAlternativeCurrencyField from '../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXFinalDatePickerField from '../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXFinalForm from '../../shared-components/final-form/QCXFinalForm';
import QCXSelectMoedaAutocomplete from '../../shared-components/final-select-moeda-field/QCXSelectMoedaAutocomplete';
import QCXSelectFornecedorAutocomplete from '../../shared-components/select-fornecedor-favorecido-autocomplete/QCXSelectFornecedorAutocomplete';
import QCXSelectFavorecidoAutocomplete from '../../shared-components/select-fornecedor-favorecido-autocomplete/QCXSelectFavorecidoAutocomplete';
import QCXSelectContaBancariaAutocompleteField from '../../shared-components/select-conta-bancaria/QCXSelectContaBancariaAutocompleteField';
import QCXSelectFormaPagamentoAutocompleteField from '../../shared-components/select-forma-pagamento/QCXSelectFormaPagamentoAutocompleteField';
import QCXFinalPercentualField from '../../shared-components/final-percentual-field/QCXFinalPercentualField';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXFinalPdfInputFile from '../../shared-components/input-file/QCXFinalPdfInputFile';
import QCXDataGrid from '../../shared-components/data-grid/QCXDataGrid';
import {
  getCurrentDate,
  isValid,
  isWeekend,
  normalizeNumeral,
  unnormalizeNumeral,
} from '../../utils/general/general-utils';
import QCXFinalDateTimePickerField from '../../shared-components/final-date-time-picker-field/QCXFinalDateTimePickerField';
import { MediaType } from '../../utils/api/api-utils';
import SolicitacaoPagamentoUtils from '../../utils/general/solicitacao-pagamento/SolicitacaoPagamentoUtils';
import { fetchById as fetchFornecedorById } from '../../features/fornecedor-favorecido/FornecedorAPI';
import { fetchById as fetchMoedaById } from '../../features/moeda/moedaAPI';
import MoedaUtils from '../../utils/general/moeda/MoedaUtils';
import { formatBrazilianNumericDecimal } from '../../utils/hooks/form/field/formatters';
import QCXInfoAlert from '../../shared-components/alert/QCXInfoAlert';
import QCXSelectDespesaReceitaAutocomplete from '../../shared-components/select-despesa-receita/QCXSelectDespesaReceitaAutocomplete';
import { solicitacaoPagamentoAPI } from '../../features/solicitacao-pagamento/solicitacaoPagamentoAPI';
import {
  resetBalances,
  setBalances,
  failure,
  setError,
} from '../../features/solicitacao-pagamento/solicitacaoPagamentoSlice';
import { selectRelatedModelBalances } from '../../features/solicitacao-pagamento/solicitacaoPagamentoSelectors';
import { despesaReceitaAPI } from '../../features/despesa-receita/despesaReceitaAPI';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFinalNumericIntegerField from '../../shared-components/final-numeric-integer-field/QCXFinalNumericIntegerField';
import QCXFinalMultilineTextField from '../../shared-components/final-text-field/QCXFinalMultilineTextField';

const useStyles = makeStyles(() => ({
  gridFields: {
    paddingBottom: '16px',
  },
  subtitle: {
    margin: '20px 0',
  },
}));

export default function QCXSolicitacaoPagamentoFinanceiroForm({
  isCreate,
  isConsult,
  isUpdate,
  initialValues,
  handleSubmit,
  handleChangeToConsult,
  handleChangeToUpdate,
  handleCancelUpdate,
  tableRows,
  disableUpdate,
  isContasAPagarView = false,
  ...restProps
}) {
  const { t } = useTranslation();

  const theme = useTheme();

  const dispatch = useDispatch();

  const modeParam = useModeParam();

  const classes = useStyles();

  const clientAndProcessBalances = useSelector(selectRelatedModelBalances);

  const [isActivate, setIsActivate] = useState('');

  const onClickProcesso = (key) => {
    setIsActivate(() => (isActivate !== key ? key : ''));
  };

  const followUp = useMemo(() => initialValues?.followUp, [initialValues]);

  const columns = useMemo(
    () => [
      {
        field: 'processo',
        headerName: t('com.muralis.qcx.processo'),
        flex: 50,
        type: 'string',
        headerAlign: 'center',
        align: 'center',
        valueGetter: ({ row }) => row?.processo || '-',
      },
      {
        field: 'cliente',
        headerName: t('com.muralis.qcx.cliente.label'),
        flex: 170,
        type: 'string',
        headerAlign: 'center',
        align: 'center',
        valueGetter: ({ row }) => row?.cliente || '-',
      },
      {
        field: 'servico',
        headerName: t('com.muralis.qcx.servico.labelSingular'),
        flex: 100,
        type: 'string',
        valueGetter: ({ row }) => row?.servico || '-',
      },
      {
        field: 'viaTransporte',
        headerName: t('com.muralis.qcx.transporte.viaTransporte'),
        flex: 100,
        type: 'string',
        headerAlign: 'center',
        align: 'center',
        valueGetter: ({ row }) => row?.viaTransporte || '-',
      },
    ],
    []
  );

  useEffect(() => {
    const ganhoProcesso = initialValues?.ganhoProcesso;
    const perdaProcesso = initialValues?.perdaProcesso;

    if (ganhoProcesso) {
      setIsActivate(SolicitacaoPagamentoUtils.GANHO_PROCESSO);
      return;
    }

    if (perdaProcesso) {
      setIsActivate(SolicitacaoPagamentoUtils.PERDA_PROCESSO);
    }
  }, [initialValues]);

  useEffect(async () => {
    if (followUp?.id) {
      const response = await solicitacaoPagamentoAPI.fetchClientAndProcessBalances(followUp?.id);

      if (response?.status === 200) {
        const data = response?.data;
        dispatch(setBalances(data));
      }
    }
  }, [followUp]);

  useEffect(() => () => dispatch(resetBalances()), []);

  const taxaCambialValidators = useMemo(() => useValidators([requiredValidator, maxValue(9.99999)]), []);

  const dataValidators = useMemo(
    () =>
      useValidators([
        (value) => {
          if (!value) {
            return undefined;
          }
          return requiredValidator(value) || validateWeekDay(value);
        },
      ]),
    []
  );

  const configureDespesaReceitaFilterPredicateWith = useMemo(
    () => [
      {
        name: 'tipo',
        value: initialValues?.tipo,
      },
    ],
    [initialValues]
  );

  const formControlButtons = useMemo(
    () => ({
      create: {
        description: t('com.muralis.qcx.acoes.salvar').toUpperCase(),
      },
      cancel: {
        description: t('com.muralis.qcx.acoes.cancelar').toUpperCase(),
      },
      edit: {
        hidden: disableUpdate || modeParam.readOnly,
      },
    }),
    [disableUpdate, modeParam]
  );

  const documentoPagamentoGetter = useCallback((values) => {
    if (!values?.id && values?.length > 0) {
      return values[0];
    }

    return values;
  }, []);

  const documentoPagamentoNameGetter = useCallback((file) => {
    if (file?.id) {
      return file?.nome;
    }

    return file?.name;
  }, []);

  const dataSolicitacao = useMemo(() => getCurrentDate().toDate(), []);

  const internalInitialValues = useMemo(
    () =>
      isCreate
        ? {
            ...initialValues,
            dataSolicitacao,
            status: SolicitacaoPagamentoUtils.SOLICITACAO_PENDENTE,
            valorSaldoCliente: isValid(clientAndProcessBalances?.valorSaldoCliente)
              ? unnormalizeNumeral(clientAndProcessBalances?.valorSaldoCliente, formatBrazilianNumericDecimal(2, true))
              : undefined,
            valorSaldoProcesso: isValid(clientAndProcessBalances?.valorSaldoProcesso)
              ? unnormalizeNumeral(clientAndProcessBalances?.valorSaldoProcesso, formatBrazilianNumericDecimal(2, true))
              : undefined,
          }
        : {
            ...initialValues,
            valorSaldoCliente: isValid(clientAndProcessBalances?.valorSaldoCliente)
              ? unnormalizeNumeral(clientAndProcessBalances?.valorSaldoCliente, formatBrazilianNumericDecimal(2, true))
              : undefined,
            valorSaldoProcesso: isValid(clientAndProcessBalances?.valorSaldoProcesso)
              ? unnormalizeNumeral(clientAndProcessBalances?.valorSaldoProcesso, formatBrazilianNumericDecimal(2, true))
              : undefined,
          },
    [isCreate, dataSolicitacao, clientAndProcessBalances, initialValues]
  );

  const dataGridRows = useMemo(() => tableRows, [tableRows]);

  const onGanhoProcessoChange = useCallback(
    (form, values) => (value) => {
      if (value) {
        form.change('perdaProcesso', false);
      }

      if (!value && !values?.perdaProcesso) {
        form.change('observacaoPagamento', undefined);
      }
    },
    []
  );

  const onPerdaProcessoChange = useCallback(
    (form, values) => (value) => {
      if (value) {
        form.change('ganhoProcesso', false);
      }

      if (!value && !values?.ganhoProcesso) {
        form.change('observacaoPagamento', undefined);
      }
    },
    []
  );

  const onFavorecidoChange = useCallback(
    (form) => async (value) => {
      if (value && !isConsult) {
        const response = await fetchFornecedorById(value);
        const contaBancariaFornecedor = response?.data?.contaBancaria;

        if (response?.status === 200 && contaBancariaFornecedor?.id) {
          form.change('contaBancariaPagamento.id', contaBancariaFornecedor?.id);
        }
      }
    },
    [isConsult]
  );

  const onFornecedorChange = useCallback((form) => async (value) => {
    if (value && !isConsult) {
      const response = await fetchFornecedorById(value);

      const fornecedor = response.data;

      if (fornecedor.active && fornecedor.favorecido) {
        form.change('favorecido.id', fornecedor.id);
      }
    }
  });

  const onMoedaChange = useCallback(
    (form) => async (value) => {
      if (value && !isConsult) {
        const response = await fetchMoedaById(value);
        const moeda = response?.data;

        if (response?.status === 200 && moeda?.code === MoedaUtils.BRASIL) {
          form.change('taxaCambio', '1,00000');
        }
      }
    },
    [isConsult]
  );

  const onDespesaReceitaChange = useCallback(
    (form) => async (value) => {
      const solicitacaoPagamentoOperacional = SolicitacaoPagamentoUtils.isOperacional(initialValues?.tipo);

      if (isConsult || solicitacaoPagamentoOperacional) {
        return;
      }

      if (value) {
        try {
          const response = await despesaReceitaAPI.fetchById(value);

          if (response?.status === 200) {
            const foundDespesaReceita = response?.data;

            if (foundDespesaReceita && !isEmpty(foundDespesaReceita)) {
              form.change('moeda.id', foundDespesaReceita?.moeda?.id);
            }
          }
        } catch (error) {
          const defaultMessage = t('com.muralis.qcx.mensagem.impossivelCarregarDadosReceitaDespesaSelecionada');
          let fullMessage = defaultMessage;

          if (error?.response) {
            fullMessage = `${defaultMessage}: ${error?.response?.data?.message}`;
          }

          if (error?.message) {
            fullMessage = `${defaultMessage}: ${error?.message}`;
          }

          dispatch(failure());
          dispatch(
            setError({
              message: fullMessage,
            })
          );
        }

        return;
      }

      form.resetFieldState('despesaReceita.id');
    },
    [isConsult, initialValues]
  );

  const handleValorMoedaOnBlur = useCallback(
    (form, values, fieldName = '') =>
      () => {
        if (!isConsult) {
          if (fieldName === 'valorPagarMoeda') {
            const valorPagarMoeda = normalizeNumeral(_.get(values, 'valorPagarMoeda')) || 0;
            const formattedNovoValorTotalMoeda = unnormalizeNumeral(valorPagarMoeda, formatBrazilianNumericDecimal(2));
            form.change('valorPagarMoeda', formattedNovoValorTotalMoeda);
            return;
          }

          const valorOriginalMoeda = normalizeNumeral(_.get(values, 'valorOriginalMoeda')) || 0;

          if (fieldName === 'valorOriginalMoeda') {
            const mapValorAndPercentualFields = SolicitacaoPagamentoUtils.mapValorMoedaAndValorPercentualFieldNames;

            // Calculando possivel novo valor para o campo de moeda com base no seu percentual
            const mapUpdatedValorMoedaAndPercentualFields = mapValorAndPercentualFields.map((value) => {
              const updatedValorMoeda = SolicitacaoPagamentoUtils.calculaValorMoedaByPercentual({
                valorOriginalMoeda,
                values,
                fields: value,
              });

              const formattedValorMoedaField = unnormalizeNumeral(
                updatedValorMoeda?.value,
                formatBrazilianNumericDecimal(2)
              );

              return {
                ...value,
                valorMoedaField: formattedValorMoedaField,
              };
            });

            // setando novos valores na moeda no status do formulário
            mapUpdatedValorMoedaAndPercentualFields.forEach(({ valorField, valorMoedaField }) => {
              const novoValorMoeda = normalizeNumeral(valorMoedaField);

              if (novoValorMoeda) {
                form.change(valorField, valorMoedaField);
              }
            });

            // Separando somente os novos valores na moeda
            const updatedValoresMoedaValues = mapUpdatedValorMoedaAndPercentualFields.reduce(
              (previous, currentKey) => ({
                ...previous,
                [currentKey.valorField]: currentKey.valorMoedaField,
              }),
              {}
            );

            const newValues = {
              ...values,
              ...updatedValoresMoedaValues,
            };

            // Calculando valores percentuais a partir dos novos valores na moeda
            const updatedPercentuaisFields = SolicitacaoPagamentoUtils.calculaPercentuaisByValorMoeda(newValues) || [];

            updatedPercentuaisFields
              .filter((obj) => obj !== undefined)
              .forEach(({ name, value }) => {
                const formattedPercentual = unnormalizeNumeral(value, formatBrazilianNumericDecimal(5));

                form.change(name, formattedPercentual);
              });

            // Calculando novo valor total (valorPagarMoeda)
            let valorPagarMoeda;

            if (values?.alterarValorAPagar) {
              valorPagarMoeda = normalizeNumeral(_.get(values, 'valorPagarMoeda')) || 0;
            } else {
              valorPagarMoeda = SolicitacaoPagamentoUtils.calculaValorTotal(newValues);
            }

            const formattedNovoValorTotalMoeda = unnormalizeNumeral(valorPagarMoeda, formatBrazilianNumericDecimal(2));
            form.change('valorPagarMoeda', formattedNovoValorTotalMoeda);

            return;
          }

          let valorPagarMoeda;

          if (values?.alterarValorAPagar) {
            valorPagarMoeda = normalizeNumeral(_.get(values, 'valorPagarMoeda')) || 0;
          } else {
            valorPagarMoeda = SolicitacaoPagamentoUtils.calculaValorTotal(values);
          }

          const formattedNovoValorTotalMoeda = unnormalizeNumeral(valorPagarMoeda, formatBrazilianNumericDecimal(2));
          form.change('valorPagarMoeda', formattedNovoValorTotalMoeda);

          // CALCULA PERCENTUAIS
          if (valorOriginalMoeda) {
            const updatedPercentuaisFields = SolicitacaoPagamentoUtils.calculaPercentuaisByValorMoeda(values) || [];

            updatedPercentuaisFields
              .filter((obj) => obj !== undefined)
              .forEach(({ name, value }) => {
                const formattedPercentual = unnormalizeNumeral(value, formatBrazilianNumericDecimal(5));

                form.change(name, formattedPercentual);
              });
          }
        }
      },
    [isConsult]
  );

  const handlePercentualOnBlur = useCallback(
    (form, values, fieldName = '') =>
      () => {
        if (!isConsult) {
          const mapValorAndPercentualFields = SolicitacaoPagamentoUtils.mapValorMoedaAndValorPercentualFieldNames;

          const valorPercentualField = mapValorAndPercentualFields.find(
            ({ percentualField }) => percentualField === fieldName
          );

          const valorOriginalMoeda = normalizeNumeral(_.get(values, 'valorOriginalMoeda')) || 0;

          if (valorOriginalMoeda) {
            const updatedValorMoeda = SolicitacaoPagamentoUtils.calculaValorMoedaByPercentual({
              valorOriginalMoeda,
              values,
              fields: valorPercentualField,
            });

            const formattedPercentual = unnormalizeNumeral(updatedValorMoeda?.value, formatBrazilianNumericDecimal(2));

            form.change(updatedValorMoeda?.name, formattedPercentual);

            const updatedValues = {
              ...values,
              [updatedValorMoeda?.name]: formattedPercentual,
            };

            let valorPagarMoeda;

            if (values?.alterarValorAPagar) {
              valorPagarMoeda = normalizeNumeral(_.get(values, 'valorPagarMoeda')) || 0;
            } else {
              valorPagarMoeda = SolicitacaoPagamentoUtils.calculaValorTotal(updatedValues);
            }

            const formattedNovoValorTotalMoeda = unnormalizeNumeral(valorPagarMoeda, formatBrazilianNumericDecimal(2));
            form.change('valorPagarMoeda', formattedNovoValorTotalMoeda);
          }
        }
      },
    [isConsult]
  );

  const onChangeListeners = useMemo(
    () => [
      {
        name: 'ganhoProcesso',
        fn: onGanhoProcessoChange,
      },
      {
        name: 'perdaProcesso',
        fn: onPerdaProcessoChange,
      },
      {
        name: 'fornecedor.id',
        fn: onFornecedorChange,
      },
      {
        name: 'favorecido.id',
        fn: onFavorecidoChange,
      },
      {
        name: 'moeda.id',
        fn: onMoedaChange,
      },
      {
        name: 'despesaReceita.id',
        fn: onDespesaReceitaChange,
      },
    ],
    [onGanhoProcessoChange, onPerdaProcessoChange, onFavorecidoChange, onMoedaChange, onDespesaReceitaChange]
  );

  const downloader = useDownloader({
    autoDownload: true,
  });

  const configureDownloadDocumentHandler = useCallback(() => {
    if (initialValues?.documentoPagamento?.id) {
      downloader.downloadFileById(initialValues.documentoPagamento.id);
    }
  }, [downloader, initialValues?.documentoPagamento?.id]);

  return (
    <QCXFinalForm
      isConsult={isConsult}
      isUpdate={isUpdate}
      initialValues={internalInitialValues}
      controlButtons={formControlButtons}
      handleSubmitValues={handleSubmit}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      onChangeListeners={onChangeListeners}
      {...restProps}
    >
      {({ form, values }) => (
        <>
          {disableUpdate && (
            <>
              <Grid
                item
                xs={12}
                style={{
                  marginTop: '0.5rem',
                  paddingBottom: '16px',
                }}
              >
                <QCXInfoAlert>
                  <Trans i18nKey="com.muralis.qcx.numerarioImportacao.avisoAlteracoesBloqueadasStatus">
                    <b>
                      {{
                        status: values?.status?.replaceAll('_', ' '),
                      }}
                    </b>
                  </Trans>
                </QCXInfoAlert>
              </Grid>

              <Grid item xs={12}>
                <QCXFinalMultilineTextField
                  id="multiline-text-field-justificativa"
                  key="multiline-text-field-justificativa"
                  name="titulo.justificativa"
                  label={t('com.muralis.qcx.justificativa')}
                  rows={10}
                  disabled
                />
              </Grid>
            </>
          )}
          <Grid item container spacing={3}>
            {SolicitacaoPagamentoUtils.isOperacional(values?.tipo) && (
              <Grid item className={classes.gridFields} xs={12} sm={12} md={12}>
                <QCXDataGrid columns={columns} rows={dataGridRows} />
              </Grid>
            )}
            <Grid item className={classes.gridFields} xs={12} sm={6} md={4}>
              <QCXFinalDateTimePickerField
                id="date-picker-data-solicitacao-field"
                key="date-picker-data-solicitacao-field"
                name="dataSolicitacao"
                label={t('com.muralis.qcx.numerarioImportacao.dataSolicitacao')}
                disabled
              />
            </Grid>
            {SolicitacaoPagamentoUtils.isOperacional(values?.tipo) && (
              <>
                <Grid item xs={12} sm={12} md={4}>
                  <QCXFinalAlternativeCurrencyField
                    id="financeiro-valor-saldo-processo-field"
                    key="financeiro-valor-saldo-processo-field"
                    name="valorSaldoProcesso"
                    label={t('com.muralis.qcx.valorSaldoProcesso')}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <QCXFinalAlternativeCurrencyField
                    id="financeiro-valor-saldo-cliente-field"
                    key="financeiro-valor-saldo-cliente-field"
                    name="valorSaldoCliente"
                    label={t('com.muralis.qcx.valorSaldoCliente')}
                    disabled
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={12} md={12}>
              <QCXFormTitle
                title={t('com.muralis.qcx.despesa.labelPlural')}
                boxProps={{
                  pt: 1,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <QCXSelectDespesaReceitaAutocomplete
                id="autocomplete-select-field-despesa"
                key="autocomplete-select-field-despesa"
                name="despesaReceita.id"
                label={t('com.muralis.qcx.despesa.labelPlural')}
                initialValues={values}
                disabled={isConsult || isContasAPagarView}
                fieldProps={{ validate: requiredValidator }}
                filterParams={configureDespesaReceitaFilterPredicateWith}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <QCXSelectMoedaAutocomplete
                id="select-field-moeda-seguro"
                key="select-field-moeda-seguro"
                name="moeda.id"
                label={t('com.muralis.qcx.moeda.label')}
                disabled={isConsult || isContasAPagarView}
                initialValues={values}
                fieldProps={{ validate: requiredValidator }}
                defaultCode={SolicitacaoPagamentoUtils.isAdministrativa(values?.tipo) ? '790' : undefined}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <QCXFinalAlternativeCurrencyField
                id="financeiro-valor-taxa-cambio-field"
                key="financeiro-valor-taxa-cambio-field"
                name="taxaCambio"
                label={t('com.muralis.qcx.taxaCambio')}
                validate={taxaCambialValidators}
                disabled={isConsult || isContasAPagarView}
                scale={5}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <QCXSelectFornecedorAutocomplete
                id="select-field-fornecedor"
                key="select-field-fornecedor"
                name="fornecedor.id"
                label={t('com.muralis.qcx.fornecedor.label')}
                initialValues={values}
                disabled={isConsult || isContasAPagarView}
                fieldProps={{ validate: requiredValidator }}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <QCXSelectFavorecidoAutocomplete
                id="select-field-favorecido"
                key="select-field-favorecido"
                name="favorecido.id"
                label={t('com.muralis.qcx.favorecido')}
                initialValues={values}
                disabled={isConsult || isContasAPagarView}
                fieldProps={{ validate: requiredValidator }}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <QCXSelectContaBancariaAutocompleteField
                id="autocomplete-select-conta-bancaria-pagamento-field"
                key="autocomplete-select-conta-bancaria-pagamento-field"
                name="contaBancariaPagamento"
                label={t('com.muralis.qcx.conta.contaCorrentePagamento')}
                initialValues={values}
                disabled={isConsult}
                fieldProps={{ validate: requiredValidator }}
                filtraControlaSaldo={SolicitacaoPagamentoUtils.isAdministrativa(values?.tipo)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <QCXFinalDatePickerField
                id="date-picker-data-vencimento-field"
                key="date-picker-data-vencimento-field"
                name="dataVencimento"
                label={t('com.muralis.qcx.dataVencimento')}
                disabled={isConsult || isContasAPagarView}
                validate={!isConsult && dataValidators}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <QCXFinalDatePickerField
                id="date-picker-data-pagamento-field"
                key="date-picker-data-pagamento-field"
                name="dataPagamento"
                label={t('com.muralis.qcx.pagamento.labelSigular')}
                disabled
                validate={!isConsult && dataValidators}
                shouldDisableDate={isWeekend}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <QCXSelectFormaPagamentoAutocompleteField
                id="select-field-forma-pagamento"
                key="select-field-forma-pagamento"
                name="formaPagamento"
                initialValues={values}
                label={t('com.muralis.qcx.formaPagamento.label')}
                disabled={isConsult || isContasAPagarView}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <QCXFinalTextField
                id="numero-documento-field"
                key="numero-documento-field"
                name="numeroDocumento"
                label={t('com.muralis.qcx.documento.numeroDocumento')}
                disabled={isConsult || isContasAPagarView}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <QCXFinalAlternativeCurrencyField
                id="financeiro-valor-original-field"
                key="financeiro-valor-original-field"
                name="valorOriginalMoeda"
                label={t('com.muralis.qcx.valorOriginal')}
                disabled={isConsult || isContasAPagarView}
                onBlur={handleValorMoedaOnBlur(form, values, 'valorOriginalMoeda')}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <QCXFinalPercentualField
                id="valor-percentual-desconto-field"
                key="valor-percentual-desconto-field"
                name="percentualDesconto"
                label={t('com.muralis.qcx.percentualDesconto')}
                scale={5}
                onBlur={handlePercentualOnBlur(form, values, 'percentualDesconto')}
                disabled={isConsult || isContasAPagarView || values?.alterarValorAPagar}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <QCXFinalAlternativeCurrencyField
                id="financeiro-valor-desconto-field"
                key="financeiro-valor-desconto-field"
                name="valorDescontoMoeda"
                label={t('com.muralis.qcx.valorDesconto')}
                onBlur={handleValorMoedaOnBlur(form, values)}
                disabled={isConsult || isContasAPagarView || values?.alterarValorAPagar}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <QCXFinalPercentualField
                id="valor-percentual-multa-field"
                key="valor-percentual-multa-field"
                name="percentualMulta"
                label={t('com.muralis.qcx.percentualMulta')}
                scale={5}
                onBlur={handlePercentualOnBlur(form, values, 'percentualMulta')}
                disabled={isConsult || isContasAPagarView || values?.alterarValorAPagar}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <QCXFinalAlternativeCurrencyField
                id="financeiro-valor-multa-field"
                key="financeiro-valor-multa-field"
                name="valorMultaMoeda"
                label={t('com.muralis.qcx.valorMulta')}
                onBlur={handleValorMoedaOnBlur(form, values)}
                disabled={isConsult || isContasAPagarView || values?.alterarValorAPagar}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <QCXFinalPercentualField
                id="valor-percentual-juros-field"
                key="valor-percentual-juros-field"
                name="percentualJuros"
                label={t('com.muralis.qcx.percentualJuros')}
                scale={5}
                onBlur={handlePercentualOnBlur(form, values, 'percentualJuros')}
                disabled={isConsult || isContasAPagarView || values?.alterarValorAPagar}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <QCXFinalAlternativeCurrencyField
                id="financeiro-valor-desconto-field"
                key="financeiro-valor-desconto-field"
                name="valorJurosMoeda"
                label={t('com.muralis.qcx.valorJuros')}
                onBlur={handleValorMoedaOnBlur(form, values)}
                disabled={isConsult || isContasAPagarView || values?.alterarValorAPagar}
              />
            </Grid>
            {SolicitacaoPagamentoUtils.isAdministrativa(values?.tipo) ? (
              <Grid item xs={12} sm={12} md={6}>
                <QCXFinalNumericIntegerField
                  id="quantidade-parcelas-field"
                  key="quantidade-parcelas-field"
                  name="quantidadeParcelas"
                  label={t('com.muralis.qcx.quantidadeParcelas')}
                  disabled={isConsult || isContasAPagarView || values?.alterarValorAPagar}
                  maxLength={2}
                  allowNegative={false}
                />
              </Grid>
            ) : null}

            <Grid container item xs={12} spacing={3} alignItems="center">
              <Grid item xs={6} sm={6} md={6}>
                <QCXFinalAlternativeCurrencyField
                  id="financeiro-valor-pagar-field"
                  key="financeiro-valor-pagar-field"
                  name="valorPagarMoeda"
                  label={t('com.muralis.qcx.valorPagar')}
                  onBlur={handleValorMoedaOnBlur(form, values, 'valorPagarMoeda')}
                  disabled={isConsult || isContasAPagarView || !values?.alterarValorAPagar}
                />
              </Grid>

              <Grid item xs={5} sm={5} md={5}>
                <QCXFinalPdfInputFile
                  id="upload-input-anexo-documento-para-pagamento"
                  key="upload-input-anexo-documento-para-pagamento"
                  name="documentoPagamento"
                  label={t('com.muralis.qcx.anexarDocumentoPagamento')}
                  tooltipTitle={t('com.muralis.qcx.uploadDocumentoPagamento')}
                  accept={MediaType.APPLICATION_PDF}
                  fileGetter={documentoPagamentoGetter}
                  fileNameGetter={documentoPagamentoNameGetter}
                  disableRemoveControl
                  disabled={isConsult || isContasAPagarView}
                />
              </Grid>

              <Grid item xs={1} sm={1} md={1} container justifyContent="center">
                <QCXIconButton
                  key={`icon-button-documento-file-download-${initialValues?.documentoPagamento?.id}`}
                  name={`icon-button-documento-file-download-${initialValues?.documentoPagamento?.id}`}
                  tooltipProps={{
                    title: t('com.muralis.qcx.documento.baixarDocumento'),
                  }}
                  onClick={configureDownloadDocumentHandler}
                  disabled={!initialValues?.documentoPagamento?.id}
                >
                  <FileDownloadIcon htmlColor={theme.palette.primary.main} size={20} />
                </QCXIconButton>
              </Grid>
            </Grid>

            {SolicitacaoPagamentoUtils.isOperacional(values?.tipo) && (
              <>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <QCXFinalCheckboxField
                    id="checkbox-ganho-no-processo"
                    key="checkbox-ganho-no-processo"
                    name="ganhoProcesso"
                    label={t('com.muralis.qcx.ganhoProcesso')}
                    checked={SolicitacaoPagamentoUtils.isGanhoProcesso(isActivate)}
                    onClick={() => onClickProcesso('ganhoProcesso')}
                    disabled={isConsult || isContasAPagarView}
                  />

                  <QCXFinalCheckboxField
                    id="checkbox-perda-no-processo"
                    key="checkbox-perda-no-processo"
                    name="perdaProcesso"
                    label={t('com.muralis.qcx.perdaProcesso')}
                    checked={SolicitacaoPagamentoUtils.isPerdaProcesso(isActivate)}
                    onClick={() => onClickProcesso('perdaProcesso')}
                    disabled={isConsult || isContasAPagarView}
                  />
                </Grid>
                {isActivate && (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <QCXSolicitacaoPagamentoFinanceiroObservacoesGroup disabled={isConsult || isContasAPagarView} />
                  </Grid>
                )}
              </>
            )}

            {isUpdate && (
              <Grid item xs={12} sm={12} md={6}>
                <QCXFinalCheckboxField
                  id="checkbox-alterar-valor-a-pagar"
                  key="checkbox-alterar-valor-a-pagar"
                  name="alterarValorAPagar"
                  label={t('com.muralis.qcx.solicitacaoPagamento.alterarValorAPagar')}
                  onChange={(event) => form.change('alterarValorAPagar', event.target.checked)}
                  checked={values?.alterarValorAPagar || false}
                />
              </Grid>
            )}
          </Grid>
        </>
      )}
    </QCXFinalForm>
  );
}
