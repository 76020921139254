/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import { Grid, makeStyles } from '@material-ui/core';
import { debounce, isEmpty } from 'lodash';
import { React, useCallback, useEffect, useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectTiposDeclaracao } from '../../features/tipo-declaracao/tipoDeclaracaoSlice';
import {
  fetchByFilterAsync as fetchTransportadorInternacionalByFilterAsync,
  selectTransportadoresInternacionais,
} from '../../features/transportador-internacional/transportadorInternacionalSlice';
import { selectViasTransporte } from '../../features/via-transporte/viaTransporteSlice';
import { fetchAllAsync as fetchAllPortorAeroportosAsync } from '../../features/porto-aeroporto/PortoAeroportoSlice';
import QCXInfoAlert from '../../shared-components/alert/QCXInfoAlert';
import QCXAloneInlineBoxWrapper from '../../shared-components/alone-inline-box-wrapper/QCXAloneInlineBoxWrapper';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXFinalDatePickerField from '../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXFinalDateTimePickerField from '../../shared-components/final-date-time-picker-field/QCXFinalDateTimePickerField';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXSelectEmbarcacaoAutocomplete from '../../shared-components/select-embarcacao/QCXSelectEmbarcacaoAutocomplete';
import QCXSelectPaisAutocomplete from '../../shared-components/select-pais/QCXSelectPaisAutocomplete';
import QCXSelectPortoAeroportoAutocomplete from '../../shared-components/select-porto-aeroporto/QCXSelectPortoAeroportoAutocomplete';
import QCXSelectTipoConhecimentoAutocomplete from '../../shared-components/select-tipo-conhecimento/QCXSelectTipoConhecimentoAutocomplete';
import {
  isSomeTipoConhecimento,
  TIPOS_CONHECIMENTO_AEREO,
  TIPOS_CONHECIMENTO_FERROVIARIO,
  TIPO_CONHECIMENTO_AWB,
  TIPO_CONHECIMENTO_HOUSE_AWB,
  TIPO_CONHECIMENTO_HOUSE_RWB,
  TIPO_CONHECIMENTO_RWB,
} from '../../shared-components/select-tipo-conhecimento/tipoConhecimentoUtils';
import {
  configureGeneralTipoDeclaracaoMatcherWith,
  TIPO_DECLARACAO_NACIONALIZACAO_DE_ADMISSAO_TEMPORARIA,
  TIPO_DECLARACAO_NACIONALIZACAO_DE_DEA_DEPOSITO_ESPECIAL_ALFANDEGADO,
  TIPO_DECLARACAO_NACIONALIZACAO_DE_ENTREPOSTO_ADUANEIRO,
  TIPO_DECLARACAO_NACIONALIZACAO_DE_REGIME_ADUANEIRO_GERAL,
  TIPO_DECLARACAO_SAIDA_DE_EIZOF_ENTREPOSTO_INTERNACIONAL_DA_ZFM,
} from '../../shared-components/select-tipo-declaracao/tipoDeclaracaoUtils';
import QCXSelectTipoDocumentoChegadaCargaAutocomplete from '../../shared-components/select-tipo-documento-chegada-carga/QCXSelectTipoDocumentoChegadaCargaAutocomplete';
import {
  TIPO_DOCUMENTO_CHEGADA_CARGA_DTA,
  TIPO_DOCUMENTO_CHEGADA_CARGA_MANIFESTO_CARGA,
  TIPO_DOCUMENTO_CHEGADA_CARGA_MIC_DTA,
  TIPO_DOCUMENTO_CHEGADA_CARGA_TERMO_ENTRADA,
} from '../../shared-components/select-tipo-documento-chegada-carga/tipoDocumentoChegadaCargaUtils';
import QCXSelectTransportadorInternacionalAutocomplete from '../../shared-components/select-transportador-internacional/QCXSelectTransportadorInternacionalAutocomplete';
import QCXSelectUtilizacaoTransporteAutocomplete from '../../shared-components/select-utilizacao-transporte/QCXSelectUtilizacaoTransporteAutocomplete';
import QCXSelectViaTransporteAutocomplete from '../../shared-components/select-via-transporte/QCXSelectViaTransporteAutocomplete';
import {
  configureSpecificViaTransporteAereaMatcher,
  configureSpecificViaTransporteFerroviariaMatcher,
  configureSpecificViaTransporteMaritimaMatcher,
  configureSpecificViaTransportePostalMatcher,
  configureSpecificViaTransporteRodoviarioMatcher,
  VIA_TRANSPORTE_AEREA_CODE,
  VIA_TRANSPORTE_CONDUTO_REDE_TRANSMISSAO_CODE,
  VIA_TRANSPORTE_ENTRADA_SAIDA_FICTA_CODE,
  VIA_TRANSPORTE_FERROVIARIA_CODE,
  VIA_TRANSPORTE_FLUVIAL_CODE,
  VIA_TRANSPORTE_LACUSTRE_CODE,
  VIA_TRANSPORTE_MARITIMA_CODE,
  VIA_TRANSPORTE_MEIOS_PROPRIOS_CODE,
  VIA_TRANSPORTE_OUTROS_MEIOS_CODE,
  VIA_TRANSPORTE_POSTAL_CODE,
  VIA_TRANSPORTE_RODOVIARIO_CODE,
} from '../../shared-components/select-via-transporte/viaTransporteUtils';
import {
  CE_MERCANTE_TRANSPORTE_ROOT_FIELD_NAME,
  CE_MERCANTE_VIA_TRANSPORTE_MARITIMA_VALUE,
  IDENTIFICACAO_CRT_TRANSPORTE_ROOT_FIELD_NAME,
  ID_HOUSE_TRANSPORTE_ROOT_FIELD_NAME,
  ID_MASTER_TRANSPORTE_ROOT_FIELD_NAME,
  TRANSPORTE_ROOT_FIELD_NAMES,
} from '../../utils/general/declaracao-Importacao/declaracaoImportacaoUtils';
import { isValid, MatcherConfigureUtils } from '../../utils/general/general-utils';
import { parseToAlphanumericOnlyWithMaxLength } from '../../utils/hooks/form/field/parsers';
import QCXFormTitle from '../form-title/QCXFormTitle';

const useStyles = makeStyles((theme) => ({
  gridFields: {
    paddingBottom: '16px',
  },
  customGridFields: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '16px',
    },
    paddingBottom: '16px',
  },
  fakeFields: {
    height: '40px !important',
    paddingBottom: '16px',
  },
  customContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: '0px !important',
    },
  },
  sibling: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '8px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '16px',
    },
  },
}));

export default function QCXDeclaracaoImportacaoTransporteForm({
  initialValues,
  handleSubmit,
  isConsult,
  isLocked,
  controlComponentProps,
  ...restProps
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const dispatch = useDispatch();

  const viasTransporte = useSelector(selectViasTransporte);
  const tiposDeclaracao = useSelector(selectTiposDeclaracao);
  const transportadores = useSelector(selectTransportadoresInternacionais);

  useEffect(() => {
    dispatch(fetchAllPortorAeroportosAsync());
  }, []);

  const getViaTransporteIdFrom = useCallback(
    (values) => values?.atributosAdicionais?.transporte?.viaTransporte?.id,
    []
  );

  const transportadorInternacionalFilter = useCallback(
    (values) => {
      const filterParams = [
        {
          name: 'viatransporte',
          value: getViaTransporteIdFrom(values),
        },
      ];

      return filterParams;
    },
    [getViaTransporteIdFrom]
  );

  const isValidViaTransporte = useCallback(
    (values) => isValid(getViaTransporteIdFrom(values)),
    [getViaTransporteIdFrom]
  );

  const isSomeViaTransporteBy = useCallback(
    (values, codesToCompare) =>
      MatcherConfigureUtils.getGeneralByCodes(viasTransporte)(getViaTransporteIdFrom(values), codesToCompare),
    [viasTransporte, getViaTransporteIdFrom]
  );

  const isViaTransporteAereoBy = useCallback(
    (values) => configureSpecificViaTransporteAereaMatcher(viasTransporte)(getViaTransporteIdFrom(values)),
    [viasTransporte, getViaTransporteIdFrom]
  );

  const isViaTransporteFerroviariaBy = useCallback(
    (values) => configureSpecificViaTransporteFerroviariaMatcher(viasTransporte)(getViaTransporteIdFrom(values)),
    [viasTransporte, getViaTransporteIdFrom]
  );

  const isViaTransporteRodoviarioBy = useCallback(
    (values) => configureSpecificViaTransporteRodoviarioMatcher(viasTransporte)(getViaTransporteIdFrom(values)),
    [viasTransporte, getViaTransporteIdFrom]
  );

  const isViaTransportePostalBy = useCallback(
    (values) => configureSpecificViaTransportePostalMatcher(viasTransporte)(getViaTransporteIdFrom(values)),
    [viasTransporte, getViaTransporteIdFrom]
  );

  const isViaTransporteMaritimaBy = useCallback(
    (values) => configureSpecificViaTransporteMaritimaMatcher(viasTransporte)(getViaTransporteIdFrom(values)),
    [viasTransporte, getViaTransporteIdFrom]
  );

  const getTipoConhecimentoFrom = useCallback(
    (values) => values?.atributosAdicionais?.transporte?.conhecimento?.tipoConhecimento,
    []
  );

  const isSomeTipoConhecimentoBy = useCallback(
    (values, valuesToCompare) => isSomeTipoConhecimento(getTipoConhecimentoFrom(values), valuesToCompare),
    [getTipoConhecimentoFrom]
  );

  const tipoDeclaracao = useMemo(() => initialValues?.atributosAdicionais?.tipoDeclaracao?.id, [initialValues]);

  const isSomeTipoDeclaracaoBy = useCallback(
    (codesToCompare) => configureGeneralTipoDeclaracaoMatcherWith(tiposDeclaracao)(tipoDeclaracao, codesToCompare),
    [tiposDeclaracao, tipoDeclaracao]
  );

  const isNacionalizacoesSaidas = useMemo(
    () =>
      isSomeTipoDeclaracaoBy([
        TIPO_DECLARACAO_NACIONALIZACAO_DE_ADMISSAO_TEMPORARIA,
        TIPO_DECLARACAO_NACIONALIZACAO_DE_ENTREPOSTO_ADUANEIRO,
        TIPO_DECLARACAO_SAIDA_DE_EIZOF_ENTREPOSTO_INTERNACIONAL_DA_ZFM,
      ]),
    [isSomeTipoDeclaracaoBy]
  );

  const configureTipoConhecimentoFilterPredicateWith = useCallback(
    (values) => {
      const tipoConhecimentoFilters = (() => {
        if (isViaTransporteAereoBy(values)) {
          return TIPOS_CONHECIMENTO_AEREO;
        }

        if (isViaTransporteFerroviariaBy(values)) {
          return TIPOS_CONHECIMENTO_FERROVIARIO;
        }

        return [];
      })();

      const tipoConhecimentoFilterPredicate = (tipoConhecimento) => {
        if (isEmpty(tipoConhecimentoFilters)) {
          return true;
        }

        const filteredTiposConhecimento = tipoConhecimentoFilters.includes(tipoConhecimento?.value);

        return filteredTiposConhecimento;
      };

      return tipoConhecimentoFilterPredicate;
    },
    [isViaTransporteAereoBy, isViaTransporteFerroviariaBy]
  );

  const configureDocumentoChegadaCargaFilterPredicateWith = useCallback(
    (values) => {
      const tipoDocumentoChegadaCargaFilters = (() => {
        if (isViaTransporteAereoBy(values)) {
          return [TIPO_DOCUMENTO_CHEGADA_CARGA_TERMO_ENTRADA, TIPO_DOCUMENTO_CHEGADA_CARGA_DTA];
        }

        if (
          isSomeViaTransporteBy(values, [
            VIA_TRANSPORTE_MARITIMA_CODE,
            VIA_TRANSPORTE_FLUVIAL_CODE,
            VIA_TRANSPORTE_LACUSTRE_CODE,
            VIA_TRANSPORTE_FERROVIARIA_CODE,
          ])
        ) {
          return [TIPO_DOCUMENTO_CHEGADA_CARGA_MANIFESTO_CARGA, TIPO_DOCUMENTO_CHEGADA_CARGA_DTA];
        }

        if (isViaTransporteRodoviarioBy(values)) {
          return [
            TIPO_DOCUMENTO_CHEGADA_CARGA_TERMO_ENTRADA,
            TIPO_DOCUMENTO_CHEGADA_CARGA_DTA,
            TIPO_DOCUMENTO_CHEGADA_CARGA_MIC_DTA,
          ];
        }

        return [];
      })();

      const tipoDocumentoChegadaCargaFilterPredicate = (tipoConhecimento) => {
        if (isEmpty(tipoDocumentoChegadaCargaFilters)) {
          return true;
        }

        const filteredTiposDocumentoChegadaCarga = tipoDocumentoChegadaCargaFilters.includes(tipoConhecimento?.value);

        return filteredTiposDocumentoChegadaCarga;
      };

      return tipoDocumentoChegadaCargaFilterPredicate;
    },
    [isViaTransporteRodoviarioBy, isSomeViaTransporteBy, isViaTransporteAereoBy]
  );

  const handleViaTransporteChange = useCallback(
    (form, values) => (value) => {
      dispatch(fetchTransportadorInternacionalByFilterAsync(transportadorInternacionalFilter(values)));

      if (!isConsult) {
        if (!value) {
          TRANSPORTE_ROOT_FIELD_NAMES.forEach((fieldName) => form.change(fieldName, undefined));

          return;
        }
        const docInstrucoesDespacho = values?.atributosAdicionais?.documentosInstrucoesDespacho || [];
        const conhecimentoCarga = docInstrucoesDespacho.find(
          (doc) =>
            doc?.documentoInstrucaoDespachoImportacao?.code === '28' ||
            doc?.documentoInstrucaoDespachoImportacao?.description === 'CONHECIMENTO DE CARGA'
        );

        const foundViaTransporte = viasTransporte.find((viaTransporte) => viaTransporte?.id === value);

        const executeDebounce = debounce(() => {
          form.change(CE_MERCANTE_TRANSPORTE_ROOT_FIELD_NAME, CE_MERCANTE_VIA_TRANSPORTE_MARITIMA_VALUE);
        }, 200);
        const maritimaDebounce = debounce(() => {
          if (
            (foundViaTransporte?.code === VIA_TRANSPORTE_MARITIMA_CODE ||
              foundViaTransporte?.code === VIA_TRANSPORTE_LACUSTRE_CODE ||
              foundViaTransporte?.code === VIA_TRANSPORTE_FLUVIAL_CODE) &&
            conhecimentoCarga
          ) {
            form.change(ID_HOUSE_TRANSPORTE_ROOT_FIELD_NAME, conhecimentoCarga?.identificacao);
          }
        }, 200);
        const rodoviariaDebounce = debounce(() => {
          if (foundViaTransporte?.code === VIA_TRANSPORTE_RODOVIARIO_CODE && conhecimentoCarga) {
            form.change(IDENTIFICACAO_CRT_TRANSPORTE_ROOT_FIELD_NAME, conhecimentoCarga?.identificacao);
          }
        }, 200);

        maritimaDebounce();
        rodoviariaDebounce();
        if (isViaTransporteMaritimaBy(values)) {
          executeDebounce();
          return;
        }
        form.change(CE_MERCANTE_TRANSPORTE_ROOT_FIELD_NAME, undefined);
      }
    },
    [isConsult, isViaTransporteMaritimaBy, transportadorInternacionalFilter]
  );

  const handleTipoConhecimentoChange = useCallback(
    (form, values) => (value) => {
      const selectedViaTransporte = values?.atributosAdicionais?.transporte?.viaTransporte?.id;

      const foundViaTransporte = viasTransporte.find((viaTransporte) => viaTransporte?.id === selectedViaTransporte);

      if (foundViaTransporte?.code === VIA_TRANSPORTE_AEREA_CODE) {
        if (value === TIPO_CONHECIMENTO_AWB) {
          form.change(ID_MASTER_TRANSPORTE_ROOT_FIELD_NAME, values?.followUp?.numeroConhecimentoMaster);
          form.change(ID_HOUSE_TRANSPORTE_ROOT_FIELD_NAME, undefined);
        }
        if (value === TIPO_CONHECIMENTO_HOUSE_AWB) {
          form.change(ID_HOUSE_TRANSPORTE_ROOT_FIELD_NAME, values?.followUp?.numeroConhecimentoHouse);
        }
        if (value != null) {
          form.change(ID_MASTER_TRANSPORTE_ROOT_FIELD_NAME, values?.followUp?.numeroConhecimentoMaster);
        }
        if (!value) {
          form.change(ID_MASTER_TRANSPORTE_ROOT_FIELD_NAME, undefined);
          form.change(ID_HOUSE_TRANSPORTE_ROOT_FIELD_NAME, undefined);
        }
      }
      const docInstrucoesDespacho = values?.atributosAdicionais?.documentosInstrucoesDespacho || [];
      const conhecimentoCarga = docInstrucoesDespacho.find(
        (doc) =>
          doc?.documentoInstrucaoDespachoImportacao?.code === '28' ||
          doc?.documentoInstrucaoDespachoImportacao?.description === 'CONHECIMENTO DE CARGA'
      );
      if (!conhecimentoCarga) {
        return;
      }
      if (foundViaTransporte?.code === VIA_TRANSPORTE_FERROVIARIA_CODE) {
        if (value === TIPO_CONHECIMENTO_HOUSE_RWB) {
          form.change(ID_HOUSE_TRANSPORTE_ROOT_FIELD_NAME, conhecimentoCarga?.identificacao);
        }
        if (value != null) {
          form.change(ID_MASTER_TRANSPORTE_ROOT_FIELD_NAME, conhecimentoCarga?.identificacao);
        }
        if (!value) {
          form.change(ID_MASTER_TRANSPORTE_ROOT_FIELD_NAME, undefined);
          form.change(ID_HOUSE_TRANSPORTE_ROOT_FIELD_NAME, undefined);
        }
      }
    },
    [
      isConsult,
      isViaTransporteMaritimaBy,
      TRANSPORTE_ROOT_FIELD_NAMES,
      CE_MERCANTE_TRANSPORTE_ROOT_FIELD_NAME,
      CE_MERCANTE_VIA_TRANSPORTE_MARITIMA_VALUE,
    ]
  );

  const transportadorInternacionalChange = (form) => (value) => {
    const foundTransportador = transportadores.find((item) => item?.id === value);
    const transportadorPaisId = foundTransportador?.endereco?.cidade?.estado?.pais?.id;
    const paisDebounce = debounce(() => {
      if (transportadorPaisId) {
        form.change('atributosAdicionais.transporte.transportadorInternacional.pais.id', transportadorPaisId);
      }
    }, 200);
    paisDebounce();
  };

  const changeListeners = useMemo(
    () => [
      {
        name: 'atributosAdicionais.transporte.viaTransporte.id',
        fn: handleViaTransporteChange,
      },
      {
        name: 'atributosAdicionais.transporte.conhecimento.tipoConhecimento',
        fn: handleTipoConhecimentoChange,
      },
      {
        name: 'atributosAdicionais.transporte.transportadorInternacional.transportador.id',
        fn: transportadorInternacionalChange,
      },
    ],
    [handleViaTransporteChange]
  );

  return (
    <QCXFormStepWizard
      initialValues={initialValues}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      onChangeListeners={changeListeners}
      {...restProps}
    >
      {({ values }) => (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <QCXFormTitle title={t('com.muralis.qcx.transporte.fichaTransporte')} />
            </Grid>
            <Grid item className={classes.gridFields} xs={12} sm={12} md={6} lg={6}>
              <QCXSelectViaTransporteAutocomplete
                id="autocomplete-select-via-transporte-field"
                key="autocomplete-select-via-transporte-field"
                name="atributosAdicionais.transporte.viaTransporte.id"
                label={t('com.muralis.qcx.transporte.viaTransporte')}
                disabled={isConsult}
                initialValues={values}
              />
            </Grid>
            {isValidViaTransporte(values) &&
              !isSomeTipoDeclaracaoBy([
                TIPO_DECLARACAO_NACIONALIZACAO_DE_DEA_DEPOSITO_ESPECIAL_ALFANDEGADO,
                TIPO_DECLARACAO_NACIONALIZACAO_DE_REGIME_ADUANEIRO_GERAL,
              ]) && (
                <>
                  {isSomeViaTransporteBy(values, [
                    VIA_TRANSPORTE_AEREA_CODE,
                    VIA_TRANSPORTE_MARITIMA_CODE,
                    VIA_TRANSPORTE_RODOVIARIO_CODE,
                    VIA_TRANSPORTE_FERROVIARIA_CODE,
                    VIA_TRANSPORTE_FLUVIAL_CODE,
                    VIA_TRANSPORTE_LACUSTRE_CODE,
                  ]) &&
                    !isNacionalizacoesSaidas && (
                      <Grid item className={classes.customGridFields} xs={12} sm={12} md={4} lg={4}>
                        <QCXFinalCheckboxField
                          id="checkbox-transporte-multimodal-field"
                          key="checkbox-transporte-multimodal-field"
                          name="atributosAdicionais.transporte.multimodal"
                          label={t('com.muralis.qcx.multimodal')}
                          disabled={isConsult}
                        />
                      </Grid>
                    )}
                  {isSomeViaTransporteBy(values, [
                    VIA_TRANSPORTE_AEREA_CODE,
                    VIA_TRANSPORTE_MARITIMA_CODE,
                    VIA_TRANSPORTE_RODOVIARIO_CODE,
                    VIA_TRANSPORTE_FERROVIARIA_CODE,
                    VIA_TRANSPORTE_FLUVIAL_CODE,
                    VIA_TRANSPORTE_LACUSTRE_CODE,
                  ]) && (
                    <>
                      <QCXAloneInlineBoxWrapper
                        renderSiblings={(boxProps) => (
                          <Grid item container spacing={2} {...boxProps} className={classes.sibling}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <QCXFinalTextField
                                id="text-field-tipo-documento-chegada-carga-identificacao"
                                key="text-field-tipo-documento-chegada-carga-identificacao"
                                name="atributosAdicionais.transporte.documentoChegadaCarga.identificacaoDocumentoChegadaCarga"
                                label={t('com.muralis.qcx.identificacao')}
                                maxLength={15}
                                disabled={isConsult}
                              />
                            </Grid>
                          </Grid>
                        )}
                      >
                        {(fieldProps) => (
                          <QCXSelectTipoDocumentoChegadaCargaAutocomplete
                            id="text-field-tipo-documento-chegada-carga-documento"
                            key="text-field-tipo-documento-chegada-carga-documento"
                            name="atributosAdicionais.transporte.documentoChegadaCarga.tipoDocumentoChegadaCarga"
                            label={t('com.muralis.qcx.carga.documentoChegadaCarga')}
                            configureFilterPredicate={configureDocumentoChegadaCargaFilterPredicateWith}
                            disabled={isConsult}
                            {...fieldProps}
                          />
                        )}
                      </QCXAloneInlineBoxWrapper>
                    </>
                  )}
                  {isSomeViaTransporteBy(values, [
                    VIA_TRANSPORTE_MARITIMA_CODE,
                    VIA_TRANSPORTE_FLUVIAL_CODE,
                    VIA_TRANSPORTE_LACUSTRE_CODE,
                  ]) && (
                    <Grid item xs={12} md={6}>
                      <QCXSelectEmbarcacaoAutocomplete
                        id="text-field-embarcacao-transporte"
                        key="text-field-embarcacao-transporte"
                        name="atributosAdicionais.transporte.embarcacao"
                        label={t('com.muralis.qcx.embarcacao.label')}
                        disabled={isConsult}
                        initialValues={values}
                      />
                    </Grid>
                  )}
                  {isSomeViaTransporteBy(values, [VIA_TRANSPORTE_MARITIMA_CODE]) && (
                    <Grid item xs={12} md={6}>
                      <QCXFinalTextField
                        id="text-field-numer-bl"
                        key="text-field-numer-bl"
                        name="followUp.numeroConhecimentoHouse"
                        label="Número B/L"
                        disabled={isConsult}
                        initialValues={values}
                      />
                    </Grid>
                  )}
                  {isSomeViaTransporteBy(values, [
                    VIA_TRANSPORTE_AEREA_CODE,
                    VIA_TRANSPORTE_MARITIMA_CODE,
                    VIA_TRANSPORTE_RODOVIARIO_CODE,
                    VIA_TRANSPORTE_FERROVIARIA_CODE,
                    VIA_TRANSPORTE_FLUVIAL_CODE,
                    VIA_TRANSPORTE_LACUSTRE_CODE,
                  ]) && (
                    <Grid item container xs={12} sm={12} md={6} lg={6}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <QCXSelectTransportadorInternacionalAutocomplete
                          id="text-field-transportador-internacional-transportador"
                          key="text-field-transportador-internacional-transportador"
                          name="atributosAdicionais.transporte.transportadorInternacional.transportador"
                          label={t('com.muralis.qcx.transportadorInternacional.label')}
                          disabled={isConsult}
                          initialValues={values}
                          getFilters={transportadorInternacionalFilter}
                          disableFilter
                        />
                      </Grid>
                    </Grid>
                  )}
                  {isSomeViaTransporteBy(values, [
                    VIA_TRANSPORTE_AEREA_CODE,
                    VIA_TRANSPORTE_MARITIMA_CODE,
                    VIA_TRANSPORTE_FERROVIARIA_CODE,
                    VIA_TRANSPORTE_FLUVIAL_CODE,
                    VIA_TRANSPORTE_LACUSTRE_CODE,
                  ]) && (
                    <Grid item container xs={12} sm={12} md={6} lg={6}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <QCXSelectPaisAutocomplete
                          id="autocomplete-select-bandeira-transporte-field"
                          key="autocomplete-select-bandeira-transporte-field"
                          name="atributosAdicionais.transporte.transportadorInternacional.pais.id"
                          label={t('com.muralis.qcx.bandeira')}
                          initialValues={values}
                          disabled={isConsult}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {isSomeViaTransporteBy(values, [VIA_TRANSPORTE_RODOVIARIO_CODE]) && !isNacionalizacoesSaidas && (
                    <Grid item container xs={12} sm={12} md={6} lg={6}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <QCXFinalTextField
                          id="text-field-transporte-placa-veiculo"
                          key="text-field-transporte-placa-veiculo"
                          name="atributosAdicionais.transporte.placaVeiculo"
                          label={t('com.muralis.qcx.placaVeiculo')}
                          disabled={isConsult}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {isSomeViaTransporteBy(values, [VIA_TRANSPORTE_RODOVIARIO_CODE]) && (
                    <Grid item className={classes.gridFields} xs={12} sm={12} md={6} lg={6}>
                      <QCXFinalTextField
                        id="text-field-transporte-placa-veiculo"
                        key="text-field-transporte-placa-veiculo"
                        name="atributosAdicionais.transporte.identificacaoCrt"
                        label={t('com.muralis.qcx.identificacaoCRT')}
                        maxLength={18}
                        disabled={isConsult}
                      />
                    </Grid>
                  )}
                  {isSomeViaTransporteBy(values, [
                    VIA_TRANSPORTE_MARITIMA_CODE,
                    VIA_TRANSPORTE_FLUVIAL_CODE,
                    VIA_TRANSPORTE_LACUSTRE_CODE,
                  ]) && (
                    <Grid item className={classes.gridFields} xs={12} sm={12} md={6} lg={6}>
                      <QCXFinalTextField
                        id="text-field-transporte-ce-mercante"
                        key="text-field-transporte-ce-mercante"
                        name="atributosAdicionais.transporte.ceMercante"
                        label={t('com.muralis.qcx.CEMercante')}
                        maxLength={34}
                        parse={parseToAlphanumericOnlyWithMaxLength(34)}
                        disabled={isConsult}
                      />
                    </Grid>
                  )}
                  {isSomeViaTransporteBy(values, [
                    VIA_TRANSPORTE_AEREA_CODE
                  ]) && (
                    <Grid item className={classes.gridFields} xs={12} sm={12} md={6} lg={6}>
                      <QCXFinalTextField
                        id="text-field-transporte-ce-mercante"
                        key="text-field-transporte-ce-mercante"
                        name="atributosAdicionais.transporte.ceMercante"
                        label="NUMERO RUC"
                        maxLength={34}
                        disabled={isConsult}
                      />
                    </Grid>
                  )}
                  {isSomeViaTransporteBy(values, [VIA_TRANSPORTE_AEREA_CODE, VIA_TRANSPORTE_FERROVIARIA_CODE]) && (
                    <Grid item className={classes.gridFields} xs={12} sm={12} md={6} lg={6}>
                      <QCXSelectTipoConhecimentoAutocomplete
                        id="autocomplete-select-tipo-conhecimento-transporte-field"
                        key="autocomplete-select-tipo-conhecimento-transporte-field"
                        name="atributosAdicionais.transporte.conhecimento.tipoConhecimento"
                        label={t('com.muralis.qcx.tipoConhecimento.label')}
                        configureFilterPredicate={configureTipoConhecimentoFilterPredicateWith}
                        disabled={isConsult}
                      />
                    </Grid>
                  )}
                  {isSomeViaTransporteBy(values, [VIA_TRANSPORTE_AEREA_CODE, VIA_TRANSPORTE_FERROVIARIA_CODE]) &&
                    isSomeTipoConhecimentoBy(values, [TIPO_CONHECIMENTO_HOUSE_AWB, TIPO_CONHECIMENTO_HOUSE_RWB]) && (
                      <Grid item className={classes.gridFields} xs={12} sm={12} md={6} lg={6}>
                        <QCXFinalTextField
                          id="text-field-id-house-transporte"
                          key="text-field-id-house-transporte"
                          name="atributosAdicionais.transporte.idHouse"
                          label={t('com.muralis.qcx.idHouse')}
                          maxLength={18}
                          disabled={isConsult}
                        />
                      </Grid>
                    )}
                  {isSomeViaTransporteBy(values, [
                    VIA_TRANSPORTE_AEREA_CODE,
                    VIA_TRANSPORTE_MARITIMA_CODE,
                    VIA_TRANSPORTE_FERROVIARIA_CODE,
                    VIA_TRANSPORTE_FLUVIAL_CODE,
                    VIA_TRANSPORTE_LACUSTRE_CODE,
                  ]) &&
                    isSomeTipoConhecimentoBy(values, [TIPO_CONHECIMENTO_AWB, TIPO_CONHECIMENTO_RWB]) && (
                      <Grid item className={classes.gridFields} xs={12} sm={12} md={6} lg={6}>
                        <QCXFinalTextField
                          id="text-field-transporte-id-master"
                          key="text-field-transporte-id-master"
                          name="atributosAdicionais.transporte.idMaster"
                          label={t('com.muralis.qcx.idMaster')}
                          maxLength={18}
                          disabled={isConsult}
                        />
                      </Grid>
                    )}
                  {isSomeViaTransporteBy(values, [VIA_TRANSPORTE_POSTAL_CODE]) && (
                    <Grid item className={classes.gridFields} xs={12} sm={12} md={6} lg={6}>
                      <QCXFinalTextField
                        id="text-field-transporte-codigo-barras"
                        key="text-field-transporte-codigo-barras"
                        name="atributosAdicionais.transporte.codigoBarras"
                        label={t('com.muralis.qcx.codigoBarras')}
                        maxLength={11}
                        disabled={isConsult}
                      />
                    </Grid>
                  )}
                  {isSomeViaTransporteBy(values, [
                    VIA_TRANSPORTE_POSTAL_CODE,
                    VIA_TRANSPORTE_MEIOS_PROPRIOS_CODE,
                    VIA_TRANSPORTE_CONDUTO_REDE_TRANSMISSAO_CODE,
                    VIA_TRANSPORTE_OUTROS_MEIOS_CODE,
                  ]) &&
                    !isNacionalizacoesSaidas && (
                      <Grid item className={classes.gridFields} xs={12} sm={12} md={6} lg={6}>
                        <QCXFinalDatePickerField
                          id="date-picker-data-postagem-field"
                          key="date-picker-data-postagem-field"
                          name="atributosAdicionais.transporte.dataPostagem"
                          label={t('com.muralis.qcx.dataPostagem')}
                          disabled={isConsult}
                        />
                      </Grid>
                    )}
                  {!(
                    (isSomeViaTransporteBy(values, [VIA_TRANSPORTE_RODOVIARIO_CODE]) ||
                      isSomeTipoConhecimentoBy(values, [TIPO_CONHECIMENTO_AWB])) &&
                    isNacionalizacoesSaidas
                  ) && (
                    <Grid
                      item
                      className={isNacionalizacoesSaidas && classes.customContainer}
                      container
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                    >
                      {!isSomeViaTransporteBy(values, [VIA_TRANSPORTE_ENTRADA_SAIDA_FICTA_CODE]) &&
                        !isNacionalizacoesSaidas && (
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <QCXSelectPortoAeroportoAutocomplete
                              id="autocomplete-select-local-embarque-transporte-field"
                              key="autocomplete-select-local-embarque-transporte-field"
                              name="atributosAdicionais.transporte.portoAeroporto.id"
                              label={
                                isViaTransportePostalBy(values)
                                  ? t('com.muralis.qcx.localPostagem')
                                  : t('com.muralis.qcx.localEmbarque')
                              }
                              initialValues={values}
                              disabled={isConsult}
                            />
                          </Grid>
                        )}
                    </Grid>
                  )}
                  {isSomeViaTransporteBy(values, [VIA_TRANSPORTE_AEREA_CODE, VIA_TRANSPORTE_FERROVIARIA_CODE]) &&
                    !isSomeTipoConhecimentoBy(values, [TIPO_CONHECIMENTO_AWB, TIPO_CONHECIMENTO_RWB]) && (
                      <Grid item className={classes.gridFields} xs={12} sm={12} md={6} lg={6}>
                        <QCXFinalTextField
                          id="text-field-transporte-id-master"
                          key="text-field-transporte-id-master"
                          name="atributosAdicionais.transporte.idMaster"
                          label={t('com.muralis.qcx.idMaster')}
                          maxLength={18}
                          disabled={isConsult}
                        />
                      </Grid>
                    )}
                  {isSomeViaTransporteBy(values, [VIA_TRANSPORTE_RODOVIARIO_CODE, VIA_TRANSPORTE_MARITIMA_CODE]) && (
                    <Grid item className={classes.customGridFields} xs={12} sm={12} md={4} lg={4}>
                      <QCXFinalTextField
                        id="text-field-transporte-placa-veiculo"
                        key="text-field-transporte-placa-veiculo"
                        name="followUp.numeroPresencaCarga"
                        label="Presença de Carga"
                        maxLength={18}
                        disabled={isConsult}
                      />
                    </Grid>
                  )}
                  {isSomeViaTransporteBy(values, [
                    VIA_TRANSPORTE_AEREA_CODE,
                    VIA_TRANSPORTE_MARITIMA_CODE,
                    VIA_TRANSPORTE_RODOVIARIO_CODE,
                    VIA_TRANSPORTE_FERROVIARIA_CODE,
                    VIA_TRANSPORTE_FLUVIAL_CODE,
                    VIA_TRANSPORTE_LACUSTRE_CODE,
                  ]) &&
                    !isSomeTipoConhecimentoBy(values, [TIPO_CONHECIMENTO_AWB, TIPO_CONHECIMENTO_RWB]) && (
                      <Grid item className={classes.gridFields} xs={12} sm={12} md={4} lg={4}>
                        <QCXSelectUtilizacaoTransporteAutocomplete
                          id="autocomplete-select-utilizacao-transporte-field"
                          key="autocomplete-select-utilizacao-transporte-field"
                          name="atributosAdicionais.transporte.utilizacao"
                          label={t('com.muralis.qcx.utilizacao')}
                          initialValues={values}
                          disabled={isConsult}
                        />
                      </Grid>
                    )}
                  {isSomeViaTransporteBy(values, [
                    VIA_TRANSPORTE_AEREA_CODE,
                    VIA_TRANSPORTE_MARITIMA_CODE,
                    VIA_TRANSPORTE_RODOVIARIO_CODE,
                    VIA_TRANSPORTE_FERROVIARIA_CODE,
                    VIA_TRANSPORTE_FLUVIAL_CODE,
                    VIA_TRANSPORTE_LACUSTRE_CODE,
                  ]) &&
                    !isNacionalizacoesSaidas && (
                      <Grid item className={classes.gridFields} xs={12} sm={12} md={4} lg={4}>
                        <QCXFinalDateTimePickerField
                          id="date-picker-data-embarque-transporte-field"
                          key="date-picker-data-embarque-transporte-field"
                          name="atributosAdicionais.transporte.dataEmbarque"
                          label={t('com.muralis.qcx.dataEmbarque')}
                          disabled={isConsult}
                          format="DD/MM/YYYY"
                        />
                      </Grid>
                    )}
                  {isSomeViaTransporteBy(values, [
                    VIA_TRANSPORTE_AEREA_CODE,
                    VIA_TRANSPORTE_MARITIMA_CODE,
                    VIA_TRANSPORTE_RODOVIARIO_CODE,
                    VIA_TRANSPORTE_FERROVIARIA_CODE,
                    VIA_TRANSPORTE_FLUVIAL_CODE,
                    VIA_TRANSPORTE_LACUSTRE_CODE,
                  ]) &&
                    isSomeTipoConhecimentoBy(values, [TIPO_CONHECIMENTO_AWB, TIPO_CONHECIMENTO_RWB]) && (
                      <Grid item className={classes.gridFields} xs={12} sm={12} md={6} lg={6}>
                        <QCXSelectUtilizacaoTransporteAutocomplete
                          id="autocomplete-select-utilizacao-transporte-field"
                          key="autocomplete-select-utilizacao-transporte-field"
                          name="atributosAdicionais.transporte.utilizacao"
                          label={t('com.muralis.qcx.utilizacao')}
                          initialValues={values}
                          disabled={isConsult}
                        />
                      </Grid>
                    )}
                  {isSomeViaTransporteBy(values, [VIA_TRANSPORTE_POSTAL_CODE]) && (
                    <Grid item xs={12}>
                      <QCXInfoAlert>
                        <Trans i18nKey="com.muralis.qcx.mensagem.tiposCodigosBarras">
                          <strong>
                            {{
                              campo: t(
                                'com.muralis.qcx.modeloInformacaoComplementar.informacoesComplementares'
                              ).toUpperCase(),
                            }}
                          </strong>
                          <em>
                            {{
                              ficha: t('com.muralis.qcx.basicas'),
                            }}
                          </em>
                        </Trans>
                      </QCXInfoAlert>
                    </Grid>
                  )}
                  {isSomeViaTransporteBy(values, [
                    VIA_TRANSPORTE_MEIOS_PROPRIOS_CODE,
                    VIA_TRANSPORTE_CONDUTO_REDE_TRANSMISSAO_CODE,
                    VIA_TRANSPORTE_OUTROS_MEIOS_CODE,
                  ]) && (
                    <Grid item xs={12}>
                      <QCXInfoAlert>
                        <Trans i18nKey="com.muralis.qcx.mensagem.informacaoAdicionalViaTransporte">
                          <strong>
                            {{
                              campo: t('com.muralis.qcx.importador.informacoesComplementares').toUpperCase(),
                            }}
                          </strong>
                          <em>
                            <strong>
                              {{
                                ficha: t('com.muralis.qcx.basicas'),
                              }}
                            </strong>
                          </em>
                        </Trans>
                      </QCXInfoAlert>
                    </Grid>
                  )}
                  {isSomeViaTransporteBy(values, [VIA_TRANSPORTE_ENTRADA_SAIDA_FICTA_CODE]) && (
                    <Grid item xs={12}>
                      <QCXInfoAlert>
                        <Trans i18nKey="com.muralis.qcx.mensagem.viaTransporteNaoNecessarioTransporteCarga">
                          <strong>
                            {{
                              necessidade: t('com.muralis.qcx.naoNecessario'),
                            }}
                          </strong>
                        </Trans>
                      </QCXInfoAlert>
                    </Grid>
                  )}
                </>
              )}
          </Grid>
        </>
      )}
    </QCXFormStepWizard>
  );
}
