import { Grid, makeStyles } from '@material-ui/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectImpostos } from '../../features/faturamento/faturamentoSelectors';
import QCXDataGrid from '../../shared-components/data-grid/QCXDataGrid';
import { unnormalizeNumeral } from '../../utils/general/general-utils';
import { formatBrazilianNumericDecimal } from '../../utils/hooks/form/field/formatters';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';

const useStyles = makeStyles(() => ({
  gridFields: {
    paddingBottom: '16px',
  },
  subtitle: {
    margin: '16px 0',
  },
}));

export default function QCXFaturamentoImpostos() {
  const classes = useStyles();

  const { t } = useTranslation();

  const impostos = useSelector(selectImpostos);

  const columns = useMemo(
    () => [
      {
        field: 'description',
        headerName: t('com.muralis.qcx.descricao'),
        flex: 90,
        type: 'string',
        headerAlign: 'center',
        align: 'center',
        valueGetter: ({ row }) => row?.description || '-',
      },
      {
        field: 'aliquota',
        headerName: t('com.muralis.qcx.aliquota'),
        flex: 90,
        type: 'string',
        headerAlign: 'center',
        align: 'center',
        valueGetter: ({ row: { aliquota } }) => unnormalizeNumeral(aliquota, formatBrazilianNumericDecimal(2)) || '-',
      },
      {
        field: 'valorReais',
        headerName: t('com.muralis.qcx.moeda.valorReais'),
        flex: 90,
        type: 'string',
        headerAlign: 'center',
        align: 'center',
        valueGetter: ({ row: { totalReal } }) => unnormalizeNumeral(totalReal, formatBrazilianNumericDecimal(2)) || '-',
      },
    ],
    []
  );

  return (
    <>
      <Grid item xs={12}>
        <QCXFormSubtitle className={classes.subtitle} title={t('com.muralis.qcx.imposto.labelPluralNfse')} />
      </Grid>
      <Grid item xs={12}>
        <QCXDataGrid columns={columns} rows={impostos} />
      </Grid>
    </>
  );
}
