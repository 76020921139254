/* eslint-disable no-unused-vars */
import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useFormState } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllAsync, selectGrupoDespesasReceitas } from '../../features/grupo-despesa-receita/grupoDespesaReceitaSlice';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

export default function QCXSelectGrupoDespesaAutocomplete({ label, name, initialValues, defaultCode, ...restProps }) {
  
  const dispatch = useDispatch();
  const list = useSelector(selectGrupoDespesasReceitas);
  
  useEffect(() => {
    if (list.length === 0) {
      dispatch(fetchAllAsync());
    }
  }, []);

  const { initialValues: formInitialValues } = useFormState();
  const localInitialValues = useMemo(() => initialValues || formInitialValues, [initialValues, formInitialValues]);

  const [defaultValue, setDefaultValue] = useState('');
  
  useEffect(() => {
    const defaultMoeda = list?.find((m) => m.id === defaultCode);
    if (defaultMoeda && defaultValue !== defaultMoeda) {
      setDefaultValue(defaultMoeda);
      return;
    }
  
    const value = list.find((item) => item.id === Number(_.get(localInitialValues, name)));
  
    if (value && defaultValue !== value) {
      setDefaultValue(value);
    } else if (!value && defaultValue.id !== null) {
      setDefaultValue({ id: null, description: '' });
    }
  }, [list, localInitialValues, name, defaultCode, defaultValue]);
  
  const filterOptions = createFilterOptions({
    stringify: (option) => `${option.id} - ${option.description}`,
  });
  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={list}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => (item?.id && item?.description ? `${item?.id} - ${item?.description}` : '')}
      {...restProps}
    />
  );
}
