import React, { useMemo } from 'react';
import { omit } from 'lodash';
import QCXConfirmDialog from '../../shared-components/dialog/QCXConfirmDialog';
import QCXDanfeBasicasForm from './QCXDanfeBasicasForm';
import QCXFinalFormWizard from '../../shared-components/final-form-wizard/QCXFinalFormWizard';
import QCXDanfeAdicoesForm from './QCXDanfeAdicoesForm';
import { isDanfeFilhote } from '../../utils/general/danfe/danfeUtils';
import QCXDanfeTransferenciaForm from './QCXDanfeTransferenciaForm';

const wizardSteps = ['Básicas', 'Adições', 'Itens'];

export default function QXCDanfeWizardFinalForm({
  model,
  handleChangeModel,
  isCreate,
  isConsult,
  isUpdate,
  isBackgroundCreate,
  handleSubmit,
  handleChangeToCreate,
  handleChangeToConsult,
  handleChangeToUpdate,
  handleCancelUpdate,
  handleChangeToBackgroundCreate,
  handleResetBackgroundMode,
  handleAlternativeSave,
  authInfo = {},
  requiredRoles = [],
  danfeNormalizer,
  ...restProps
}) {
  const isFilhote = useMemo(() => isDanfeFilhote(model?.tipoDanfe), [model]);

  const wizardConditionalSteps = useMemo(() => {
    const itens = ['Itens'];
    const adicoes = ['Adições'];

    if (!isCreate) {
      return itens;
    }

    return isFilhote ? adicoes : itens;
  }, [isFilhote, isCreate]);

  return (
    <>
      <QCXFinalFormWizard
        steps={wizardSteps}
        conditionalSteps={wizardConditionalSteps}
        initialValues={model}
        handleChangeValues={handleChangeModel}
        handleAlternativeSave={handleAlternativeSave}
        checkIfIsAlternativeSaveByStep={() => false}
        isCreate={isCreate}
        isConsult={isConsult}
        isUpdate={isUpdate}
        isBackgroundCreate={isBackgroundCreate}
        handleSubmit={handleSubmit}
        handleChangeToCreate={handleChangeToCreate}
        handleChangeToConsult={handleChangeToConsult}
        handleChangeToUpdate={handleChangeToUpdate}
        handleCancelUpdate={handleCancelUpdate}
        handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
        handleResetBackgroundMode={handleResetBackgroundMode}
        skipAutomaticChangeToConsult
        {...restProps}
      >
        {(formProps) => (
          <QCXDanfeBasicasForm
            key="ficha-danfe-basicas"
            isCreate={isCreate}
            isConsult={isConsult}
            isUpdate={isUpdate}
            authInfo={authInfo}
            requiredRoles={requiredRoles}
            {...formProps}
          />
        )}
        {(formProps) => (
          <QCXDanfeAdicoesForm
            key="ficha-danfe-adicoes"
            isCreate={isCreate}
            isConsult={isConsult}
            isUpdate={isUpdate}
            authInfo={authInfo}
            requiredRoles={requiredRoles}
            {...formProps}
          />
        )}
        {(formProps) => (
          <QCXDanfeTransferenciaForm
            key="ficha-danfe-itens"
            isCreate={isCreate}
            isConsult={isConsult}
            isUpdate={isUpdate}
            authInfo={authInfo}
            requiredRoles={requiredRoles}
            danfeNormalizer={danfeNormalizer}
            {...omit(formProps, ['handleSubmit'])}
          />
        )}
      </QCXFinalFormWizard>
      <QCXConfirmDialog key="confirm-dialog-remove-embalagem-danfe" id="confirm-dialog-remove-embalagem-danfe" />
    </>
  );
}
