import { Button, Grid } from "@mui/material";
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import * as _ from 'lodash';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from "@react-keycloak/web";
import {  SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUnidadeSelecionada } from "../../../../../../features/usuario-logado/usuarioLogadoSlice";
import { KEYCLOAK_TOKEN_TIMEOUT } from "../../../../../../App";
import { setErrorFeedback } from "../../../../../../features/feedback/feedbackSlice";
import PageLayout from "../../../../../common/layouts/pageLayout";
import FormHeaderMedium from "../../../../../common/components/forms/formHeaderMedium";
import SelectStyled from "../../../../../common/components/input/selectStyled";
import ProgressBarModal, { ProgressBarStep } from "../../../planilhas/components/progressModal";
import ModalRelatorio from "../../../../../../shared-components/snackbar/ModalRelatorio";
import { fetchClientesWithFilterDto } from "../../../../../../features/cliente/clienteAPI";
import { formatCnpj } from "../../../../../../utils/hooks/form/field/formatters";
import FileDragAndDrop from "../../../../../common/components/fileDragAndDrop";
import { MediaType } from "../../../../../../utils/api/api-utils";
import { required } from "../../../../../../utils/validators/field/validator";
import commonHelpers, { BasicFormData } from "../../../../../common/components/commonHelpers";
import { ClientIdAndDescriptionDTO } from "../../../../../common/types/clientData";
import { SimpleServicoDTO } from "../../../../../common/types/servico";
import { fetchByFilterDto } from "../../../../../../features/servico/servicoAPI";

const SapMtnDiLeituraPage = () => {  const { t } = useTranslation();
const dispatch = useDispatch();
const [isModalOpen, setIsModalOpen] = useState(false);
const [currentStep, setCurrentStep] = useState<ProgressBarStep>({
  step: 0,
  description: 'Carrengando Dados Xml',
});

const { keycloak } = useKeycloak();
const selectedUnidade = useSelector(selectUnidadeSelecionada);
const { token } = keycloak;
keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);

const [isLoading, setIsLoading] = useState(false);
const [cliente, setCliente] = useState<number | undefined>(undefined);
const [clientes, setClientes] = useState<ClientIdAndDescriptionDTO[] | undefined>(undefined);
const [servico, setServico] = useState<number | undefined>(undefined);
const [servicosData, setServicosData] = useState<SimpleServicoDTO[] | undefined>(undefined);
const [error, setError] = useState<string | string[] | undefined>();
const [currentMessage, setCurrentMessage] = useState(null);
const [confirmModalOpen, setConfirmModalOpen] = useState(false);
const [titleModal, setTitleModal] = useState('Alerta');

const url = process.env.REACT_APP_QCX_API_BASE_URL! + "/"
+ process.env.REACT_APP_QCX_API_DECLARACAO_IMPORTACAO_ENDPOINT!
+ "/sap-mtn/xml/leitura"

useEffect(() => {
  if (currentStep.step > 0 && currentStep.step < 4) {
    const timer = setTimeout(() => {
      setCurrentStep((prevStep) => {
        switch (prevStep.step) {
          case 1:
            setIsModalOpen(true);
            return { step: 2, description: 'Continuando processo' };
          case 2:
            setIsModalOpen(true);
            return { step: 3, description: 'Finalizando processo' };
          default:
            return prevStep;
        }
      });
    }, 30000);

    return () => clearTimeout(timer);
  }
}, [currentStep.step]);

useEffect(() => {
  if (!cliente) {
    setError(undefined);
    return;
  }
  return undefined;
}, [cliente]);

useEffect(() => {
  getClients();
}, []);

useEffect(() => {
  if (cliente !== undefined) {
    fetchServicos(cliente);
  }
}, [cliente, dispatch]);

const fetchServicos = async (clienteId: number) => {
  try {
    const { data: servicosData } = await fetchByFilterDto([
      { name: 'clienteId', value: clienteId },
      { name: 'produto', value: 'FATURA' },
      { name: 'filterDadosMinimos', value: 'TRUE' }
    ]);
    setServicosData(servicosData);
  } catch (error) {
    dispatch(setErrorFeedback({ message: 'Erro ao obter serviços' }));
  }
};


const handleServicoChange = (
  event: SyntheticEvent<Element, Event>,
  value: newValueIdValue | null,
  form: any
) => {

    if (value) {
      setServico(Number(value.id));
      form.change('servico', { id: value.id });
    }
};

interface newValueIdValue {
  id?: string | number | undefined;
  value?: string;
}

const clearData = () => {
  setIsLoading(false);
  setIsModalOpen(false);
  resetProgressBar();
};

interface newValueIdValue {
  id?: string | number | undefined;
  value?: string;
}

const getClients = async () => {
  try {
    const { data } = await fetchClientesWithFilterDto([{ name: 'filterDadosMinimos', value: 'TRUE' }]);
    setClientes(data);
  } catch (error) {
    dispatch(
      setErrorFeedback({
        message: 'Erro ao obter clientes:',
      })
    );
  }
};


const handleClienteChange = (
  event: SyntheticEvent<Element, Event>,
  value: newValueIdValue | null,
  form: any
) => {
  if (clientes) {
    const selectedClient = clientes.find(
      (cliente) => cliente.id === value?.id
    );

    if (selectedClient) {
      setCliente(selectedClient.id);
      setServico(undefined);
      form.change('cliente', { id: selectedClient.id });
    }
  }
};


const resetProgressBar = useCallback(() => {
  setCurrentStep({ step: 0, description: 'Carrengando Dados Planilha' });
}, []);

const handleCatalogosSubmit = async (values: BasicFormData) => {
  setIsModalOpen(true);
  setCurrentStep({ step: 1, description: 'Criando Catalogo de Produtos' });

  if (selectedUnidade && token) {
    try {
      const resp = await commonHelpers.uploadFile(values, selectedUnidade, token, url);

      if (resp.status !== 200) {
        const errorMessage = resp.data
          ? resp.data
          : t('com.muralis.qcx.integracao.Xmls.erros.erroAoGerarXml');
        setCurrentMessage(errorMessage);
        setTitleModal('error');
        setConfirmModalOpen(true);
      } else {
        const sucessMessage = resp.data
          ? resp.data
          : t('com.muralis.qcx.integracao.Xmls.sucesso.xmlGeradaComSuceso');
        setCurrentMessage(sucessMessage);
        setTitleModal('success');
        setConfirmModalOpen(true);
      }
    } catch (error: any) {
      console.log(error);
      setCurrentMessage(error.message);
      setTitleModal('error');
      setConfirmModalOpen(true);
    } finally {
      clearData();
    }
  }
};

return (
  <PageLayout title={'Planilhas'} icon={<HistoryEduIcon color={'secondary'} />}>
    <>
      <ProgressBarModal
        title="Importando xml"
        open={isModalOpen}
        totalSteps={7}
        currentStep={currentStep}
        onClose={() => setIsModalOpen(false)}
      />

      <ModalRelatorio
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        content={currentMessage}
        severity={titleModal}
        disableScrollLock
      />

      <Form onSubmit={(values: BasicFormData) => handleCatalogosSubmit(values)}>
        {({ handleSubmit, form, submitting, pristine, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <FormHeaderMedium>Informações básicas</FormHeaderMedium>
                <Grid item xs={6}>
                  <SelectStyled
                    options={(clientes || []).map((cliente, index) => ({
                      id: cliente.id,
                      value:
                        cliente.id +
                        ' - ' +
                        cliente.description +
                        ' - ' +
                        (cliente?.pessoa?.cnpj ? formatCnpj(cliente?.pessoa?.cnpj) : ''),
                    }))}
                    onChangeAction={(event, newValue) => handleClienteChange(event, newValue, form)}
                    label={'Cliente'}
                  />
                </Grid>
                <Grid item xs={6}>
                    <SelectStyled
                      options={(servicosData || []).map((servico) => ({
                        id: servico.id,
                        value: servico.nome,
                      }))}
                      label={"Serviço"}
                      onChangeAction={(event, newValue) => handleServicoChange(event, newValue, form)}
                    />
                  </Grid>
                <FormHeaderMedium>Seleção de arquivo</FormHeaderMedium>
                <Field name="arquivo" validate={required}>
                  {({ input }) => <FileDragAndDrop input={input} accept={MediaType.APPLICATION_XML} />}
                </Field>
                <Grid item xs={12} textAlign="right">
                  <Button
                    disabled={!cliente}
                    color="secondary"
                    variant="contained"
                    onClick={() => handleCatalogosSubmit(values)}
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Form>
    </>
  </PageLayout>
);
};

export default SapMtnDiLeituraPage;