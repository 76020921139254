import axios from "axios";

const baseUrl = process.env.REACT_APP_QCX_API_BASE_URL! + "/"
    + process.env.REACT_APP_RELATORIOS_API_DASHBOARD!;

const fetchColumnValues = async (
    token: string,
    unidadeId: number,
    unidadesNegocioID: string[],
    clientesID: string[],
    analistasID: string[],
    canal: string | null,
    URFdespachoId: string | null,
    produto: string | null,
    dataInicio: string,
    dataFim: string,
    etapaReferencia: string
): Promise<any> => {

    const params = new URLSearchParams();
    params.set('tenantId', unidadesNegocioID.join(','));
    params.set("clientesId", clientesID.join(','));
    params.set("analista", analistasID.join(','));
    if (canal) params.set("canalLiberacao", canal);
    if (URFdespachoId) params.set("urfDespachoId", URFdespachoId);
    if (produto) params.set("produto", produto);
    params.set("inicio", dataInicio);
    params.set("fim", dataFim);
    params.set("etapaReferencia", etapaReferencia);

    let message = null;

    try {
        const response = await axios.get(
            `${baseUrl}?${params.toString()}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    quickcomexTenant: unidadeId,
                },
                responseType: "json",
            }
        );

        if (response.status === 200) {
            return response.data;
        } else {
            message = await response.data;
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response && error.response.data) {
                message = error.response.data;
            } else {
                message = "Erro inesperado";
            }
        } else {
            message = "Erro inesperado";
        }
    }
    throw new Error(message);
};

const downloadAllProcessesSheet = async (
    token: string,
    unidadeId: number,
    unidadesNegocioID: string[],
    clientesID: string[],
    analistasID: string[],
    canal: string | null,
    URFdespachoId: string | null,
    produto: string | null,
    dataInicio: string,
    dataFim: string,
    activeColumns: string[]
): Promise<void> => {

    const params = new URLSearchParams();
    params.set('tenantId', unidadesNegocioID.join(','));
    params.set("clientesId", clientesID.join(','));
    params.set("analista", analistasID.join(','));
    if (canal) params.set("canalLiberacao", canal);
    if (URFdespachoId) params.set("urfDespachoId", URFdespachoId);
    if (produto) params.set("produto", produto);
    params.set("inicio", dataInicio);
    params.set("fim", dataFim);
    params.set("colunas", activeColumns.join(',')); // Adiciona colunas ativas

    let message = null;

    try {
        const response = await axios.get(
            `${baseUrl}/download?${params.toString()}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                    quickcomexTenant: unidadeId,
                },
                responseType: "blob", // Response type blob for file downloads
            }
        );

        if (response.status === 200) {
            const file = new File([response.data], `dashboardFollowups.xlsx`, {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            const url = window.URL.createObjectURL(file);
            const a = document.createElement("a");
            a.href = url;
            a.download = file.name;
            a.click();
        } else {
            message = await response.data;
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response && error.response.data) {
                message = error.response.data;
            } else {
                message = "Erro inesperado";
            }
        } else {
            message = "Erro inesperado";
        }
    }
    if (message) {
        throw new Error(message);
    }
};


export { fetchColumnValues, downloadAllProcessesSheet };
