import { Grid, Typography } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import QCXBox from '../../shared-components/box/QCXBox';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXFinalDatePickerField from '../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXFinalOnblurMaskedField from '../../shared-components/final-onblur-masked-field/QCXFinalOnblurMaskedField';
import QCXFinalSelectgGeneroPessoaAutocomplete from '../../shared-components/select-genero-pessoa/QCXFinalSelectGeneroPessoaAutocomplete';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXRegistrationOneToManyPageTemplate from '../../templates/registration-one-to-many-page/QCXRegistrationOneToManyPageTemplate';
import { formatTelefoneCelular, formatTelefoneFixo } from '../../utils/hooks/form/field/formatters';
import { parseTelefoneCelular, parseTelefoneFixoWithDDD } from '../../utils/hooks/form/field/parsers';
import {
  configureNumberNotBetweenMinAndMaxValidator,
  isCurrentOrPastDate,
  required as requiredValidator,
  validTelefoneLength,
  validTelefoneCelularLength,
  validTelefoneFixoWithDDDLength,
  validateEmail,
} from '../../utils/validators/field/validator';
import useValidators from '../../utils/hooks/useValidators';

export default function QCXContatoFinalFormManager({
  isConsult,
  isUpdate,
  isSubNone,
  isSubCreate,
  isSubConsult,
  isSubUpdate,
  initialValues,
  list,
  handleSubmit,
  handleNew,
  handleEdit,
  handleCancelCreate,
  handleCancelUpdate,
  handleRemove,
  handleRowClick,
  handleClearForm,
  controlComponentProps,
  authInfo = {},
  requiredRoles = [],
}) {
  const { t } = useTranslation();

  const telefoneFixoValidators = useMemo(
    () =>
      useValidators([
        validTelefoneLength,
        configureNumberNotBetweenMinAndMaxValidator(8, 10),
        configureNumberNotBetweenMinAndMaxValidator(10, 12),
      ]),
    []
  );

  const telefoneFixoRequiredValidators = useMemo(
    () =>
      useValidators([
        requiredValidator,
        validTelefoneFixoWithDDDLength,
        configureNumberNotBetweenMinAndMaxValidator(10, 12),
      ]),
    []
  );

  const celularValidators = useMemo(
    () => useValidators([validTelefoneCelularLength, configureNumberNotBetweenMinAndMaxValidator(9, 11)]),
    []
  );

  const emailValidators = useMemo(() => useValidators([requiredValidator, validateEmail]), []);

  const handleConfiguracoesResponsavelIcmsChange = useCallback(
    (form, values) => (value) => {
      if (!isSubNone) {
        if (value && values?.contato?.telefone?.length < 10) {
          form.change('contato.telefone', '');
        } else {
          form.resetFieldState('contato.telefone');
        }
      }
    },
    [isSubNone]
  );

  const listeners = useMemo(
    () => [
      {
        name: 'configuracoes.responsavelIcms',
        fn: handleConfiguracoesResponsavelIcmsChange,
      },
    ],
    [handleConfiguracoesResponsavelIcmsChange]
  );

  const tableProps = useMemo(
    () => ({
      title: t('com.muralis.qcx.contato.contatosAdicionados'),
      columns: [
        {
          field: 'dadosPessoais.nome',
          headerName: t('com.muralis.qcx.nome'),
          width: 300,
          valueGetter: (params) => params?.row?.dadosPessoais?.nome,
        },
        {
          field: 'departamento',
          headerName: t('com.muralis.qcx.departamento'),
          headerAlign: 'center',
          align: 'center',
          flex: 1,
          valueGetter: ({ row }) => row?.departamento || '-',
        },
        {
          field: 'cargo',
          headerName: t('com.muralis.qcx.cargo'),
          headerAlign: 'center',
          align: 'center',
          flex: 1,
          valueGetter: ({ row }) => row?.cargo || '-',
        },
        {
          field: 'contato.telefone',
          headerName: t('com.muralis.qcx.telefone'),
          headerAlign: 'center',
          align: 'center',
          flex: 1,
          valueFormatter: (params) => params?.row?.contato?.telefone || '-',
        },
        {
          field: 'contato.email',
          headerName: t('com.muralis.qcx.email'),
          headerAlign: 'center',
          align: 'center',
          flex: 2,
          valueGetter: (params) => params?.row?.contato?.email,
        },
      ],
    }),
    []
  );

  const localeTableText = useMemo(
    () => ({
      noRowsLabel: t('com.muralis.qcx.nenhumContatoAdicionado'),
    }),
    []
  );

  return (
    <QCXFormStepWizard
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onChangeListeners={listeners}
      controlComponentProps={controlComponentProps}
      authInfo={authInfo}
      requiredRoles={requiredRoles}
    >
      {({ form, submitSucceeded, values }) => (
        <QCXRegistrationOneToManyPageTemplate
          title={t('com.muralis.qcx.contato.label')}
          tableProps={tableProps}
          tableData={list}
          isConsult={isConsult}
          isUpdate={isUpdate}
          isSubNone={isSubNone}
          isSubCreate={isSubCreate}
          isSubConsult={isSubConsult}
          isSubUpdate={isSubUpdate}
          submitSucceeded={submitSucceeded}
          handleNew={handleNew}
          handleEdit={handleEdit}
          handleCancelCreate={handleCancelCreate}
          handleCancelUpdate={handleCancelUpdate}
          handleRemove={handleRemove}
          handleClearForm={handleClearForm}
          handleRestartForm={() =>
            form.restart({
              contato: {
                telefone: '',
                celular: '',
              },
            })
          }
          handleRowClick={handleRowClick}
          localeTableText={localeTableText}
        >
          <Grid item xs={12} sm={6} md={6}>
            <QCXFinalTextField
              label={t('com.muralis.qcx.contato.nomeContato')}
              id="text-field-nome"
              key="text-field-nome"
              name="dadosPessoais.nome"
              disabled={isConsult || isSubConsult || isSubNone}
              maxLength={40}
              validate={requiredValidator}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <QCXFinalTextField
              label={t('com.muralis.qcx.nomeResumido')}
              id="text-field-nomeResumido"
              key="text-field-nomeResumido"
              name="dadosPessoais.nomeResumido"
              disabled={isConsult || isSubConsult || isSubNone}
              maxLength={20}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <QCXFinalSelectgGeneroPessoaAutocomplete
              id="select-field-genero"
              key="select-field-genero"
              name="genero"
              label={t('com.muralis.qcx.genero.label')}
              initialValues={values}
              disabled={isConsult || isSubConsult || isSubNone}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <QCXFinalTextField
              label={t('com.muralis.qcx.saudacao.label')}
              id="text-field-saudacao"
              key="text-field-saudacao"
              name="saudacao"
              placeholder={t('com.muralis.qcx.saudacao.exemploFormaTratamento')}
              disabled={isConsult || isSubConsult || isSubNone}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <QCXFinalDatePickerField
              id="date-picker-dataNascimento"
              key="text-field-dataNascimento"
              name="dadosPessoais.dataNascimento"
              label={t('com.muralis.qcx.dataNascimento')}
              validate={isCurrentOrPastDate}
              disabled={isConsult || isSubConsult || isSubNone}
              disableFuture
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <QCXFinalOnblurMaskedField
              id="text-field-telefone"
              key="text-field-telefone"
              name="contato.telefone"
              label={t('com.muralis.qcx.telefone')}
              format={formatTelefoneFixo}
              parse={parseTelefoneFixoWithDDD}
              maxLength={12}
              disabled={isConsult || isSubConsult || isSubNone}
              submitSucceeded={submitSucceeded}
              validate={requiredValidator}
              required={values?.configuracoes?.responsavelIcms}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <QCXFinalOnblurMaskedField
              id="text-field-celular"
              key="text-field-celular"
              name="contato.celular"
              label={t('com.muralis.qcx.celular')}
              format={formatTelefoneCelular}
              parse={parseTelefoneCelular}
              maxLength={13}
              disabled={isConsult || isSubConsult || isSubNone}
              submitSucceeded={submitSucceeded}
              validate={celularValidators}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <QCXFinalTextField
              id="text-field-email"
              key="text-field-email"
              label={t('com.muralis.qcx.email')}
              name="contato.email"
              validate={emailValidators}
              maxLength={100}
              required
              disabled={isConsult || isSubConsult || isSubNone}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={4}>
            <QCXFinalTextField
              label={t('com.muralis.qcx.departamento')}
              id="text-field-departamento"
              key="text-field-departamento"
              name="departamento"
              disabled={isConsult || isSubConsult || isSubNone}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <QCXFinalTextField
              label={t('com.muralis.qcx.cargo')}
              id="text-field-cargo"
              key="text-field-cargo"
              name="cargo"
              disabled={isConsult || isSubConsult || isSubNone}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" color="primary">
              <QCXBox fontWeight="fontWeightBold" pt={3}>
                CONFIGURAÇÕES
              </QCXBox>
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <QCXFinalCheckboxField
              id="cbx-field-responsavel-icms"
              key="cbx-field-responsavel-icms"
              name="configuracoes.responsavelIcms"
              label={t('com.muralis.qcx.ICMS.responsavelICMS')}
              disabled={isConsult || isSubConsult || isSubNone}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" color="primary">
              <QCXBox fontWeight="fontWeightBold" pt={3}>
                {t('com.muralis.qcx.notificacao.labelPlural').toUpperCase()}
              </QCXBox>
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <QCXFinalCheckboxField
              id="cbx-field-faturamento"
              key="cbx-field-faturamento"
              name="notificaClienteFollowUp"
              label={t('com.muralis.qcx.receberEtapa')}
              disabled={isConsult || isSubConsult || isSubNone}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <QCXFinalCheckboxField
              id="cbx-field-faturamento"
              key="cbx-field-faturamento"
              name="notificacoes.faturamento"
              label={t('com.muralis.qcx.faturamento.receberFaturamento')}
              disabled={isConsult || isSubConsult || isSubNone}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <QCXFinalCheckboxField
              id="cbx-field-numerario"
              key="cbx-field-numerario"
              name="notificacoes.numerario"
              label={t('com.muralis.qcx.numerario.receberNumerario')}
              disabled={isConsult || isSubConsult || isSubNone}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <QCXFinalCheckboxField
              id="cbx-field-mantraSiscarga"
              key="cbx-field-mantraSiscarga"
              name="notificacoes.mantraSiscarga"
              label={t('com.muralis.qcx.receberMANTRASiscarga')}
              disabled={isConsult || isSubConsult || isSubNone}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <QCXFinalCheckboxField
              id="cbx-field-mapaFollowup"
              key="cbx-field-mapaFollowup"
              name="notificacoes.mapaFollowup"
              label={t('com.muralis.qcx.receberMapaFollowUp')}
              disabled={isConsult || isSubConsult || isSubNone}
            />
          </Grid>
        </QCXRegistrationOneToManyPageTemplate>
      )}
    </QCXFormStepWizard>
  );
}
