import _ from 'lodash';
import { Grid, makeStyles } from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { despesaReceitaAPI } from '../../features/despesa-receita/despesaReceitaAPI';
import QCXFinalAlternativeCurrencyField from '../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXFinalBondManagerOld from '../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManagerOld';
import QCXSelectDespesaReceitaOperacionalAutoComplete from '../../shared-components/select-despesa-receita/QCXSelectDespesaReceitaOperacionalAutoComplete';
import QCXFinalSelectTipoModeloAutocomplete, {
  getTipoModeloLabelByValue,
} from '../../shared-components/select-tipo-modelo/QCXFinalSelectTipoModeloAutocomplete';
import { isStrictEquals, normalizeNumeral, unnormalizeNumeral } from '../../utils/general/general-utils';
import { BRAZILIAN } from '../../utils/hooks/form/field/mask-types';
import { maxValue } from '../../utils/validators/field/validator';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';
import {
  vinculoDespesaReceitaActions,
  vinculoDespesaReceitaSelectors,
} from '../../features/vinculo-despesa-receita/vinculoDespesaReceitaSlice';
import { formatBrazilianNumericDecimal } from '../../utils/hooks/form/field/formatters';

const useStyles = makeStyles(() => ({
  gridFields: {
    paddingBottom: '16px',
  },
  subtitle: {
    margin: '16px 0',
  },
}));

export default function QCXDespesaReceitaModeloFaturamentoFormTable({
  isEdicaoBloqueada,
  isPreparingAction,
  isPerformingAction,
  isSubUpdate,
  formValues,
  formState,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const despesaReceitaReducerConfig = useMemo(
    () => ({
      selectors: {
        selectStatus: vinculoDespesaReceitaSelectors.selectStatus,
        selectMode: vinculoDespesaReceitaSelectors.selectMode,
        selectModel: vinculoDespesaReceitaSelectors.selectModel,
      },
      actions: {
        loading: vinculoDespesaReceitaActions.loading,
        resetStatus: vinculoDespesaReceitaActions.resetStatus,
        changeToUpdateMode: vinculoDespesaReceitaActions.changeToUpdateMode,
        changeToCreateMode: vinculoDespesaReceitaActions.changeToCreateMode,
        resetMode: vinculoDespesaReceitaActions.resetMode,
        setModel: vinculoDespesaReceitaActions.setModel,
        resetModel: vinculoDespesaReceitaActions.resetModel,
      },
    }),
    []
  );

  const despesaReceitaFormProps = useMemo(
    () => ({
      rootName: 'draftFields.current.lancamento',
      fields: [
        {
          name: 'despesaReceita.id',
          label: t('com.muralis.qcx.despesa.despesaReceita'),
        },
        {
          name: 'totalMoeda',
          label: t('com.muralis.qcx.valorSolicitado'),
        },
        {
          name: 'totalReal',
          label: t('com.muralis.qcx.moeda.valorReais'),
        },
        {
          name: 'despesaReceita.tipo',
          label: t('com.muralis.qcx.conta.contaDebito'),
        },
      ],
    }),
    []
  );

  const despesaReceitaListProps = useMemo(
    () => ({
      name: 'draftFields.current.lancamentos',
      columns: [
        {
          field: 'despesaReceita.description',
          headerName: t('com.muralis.qcx.despesa.despesaReceita'),
          headerAlign: 'center',
          align: 'center',
          flex: 240,
          valueGetter: ({ row }) => row?.despesaReceita?.description || '',
        },
        {
          field: 'tipo',
          headerName: t('com.muralis.qcx.tipo'),
          headerAlign: 'center',
          align: 'center',
          flex: 180,
          valueGetter: ({ row }) => getTipoModeloLabelByValue(row?.despesaReceita?.tipo) || '',
        },
        {
          field: 'totalMoeda',
          headerName: t('com.muralis.qcx.valorSolicitado'),
          headerAlign: 'center',
          align: 'center',
          flex: 180,
          valueGetter: ({ row }) => row?.totalMoeda || '',
        },
        {
          field: 'totalReal',
          headerName: t('com.muralis.qcx.moeda.valorReais'),
          headerAlign: 'center',
          align: 'center',
          flex: 180,
          valueGetter: ({ row }) => row?.totalReal || '',
        },
        {
          field: 'despesaReceita.tipo',
          headerName: t('com.muralis.qcx.conta.contaDebito'),
          flex: 220,
          valueGetter: ({ row }) => getTipoModeloLabelByValue(row?.despesaReceita?.tipo) || '-',
        },
      ],
    }),
    []
  );

  const handleAddDespesaReceitaVinculo = useCallback(
    async (currentVinculo, currentVinculos, handleSuccessAddVinculo) => {
      const despesaReceitaResponse = await despesaReceitaAPI.fetchById(currentVinculo?.despesaReceita?.id);

      if (despesaReceitaResponse?.status === 200) {
        const vinculo = {
          id: uuid(),
          ...currentVinculo,
          despesaReceita: {
            ...despesaReceitaResponse?.data,
          },
        };

        const updatedVinculos = [...currentVinculos, vinculo];

        handleSuccessAddVinculo(updatedVinculos);
      }
    },
    []
  );

  const handleUpdateDespesaReceitaVinculo = useCallback(
    async (currentVinculo, currentVinculos, handleSuccessUpdateVinculo) => {
      const despesaReceitaResponse = await despesaReceitaAPI.fetchById(currentVinculo?.despesaReceita?.id);

      if (despesaReceitaResponse?.status === 200) {
        const updatedVinculo = {
          ...currentVinculo,
          despesaReceita: {
            ...despesaReceitaResponse?.data,
          },
        };

        const updatedVinculos =
          currentVinculos?.map((existingVinculo) =>
            isStrictEquals(existingVinculo?.id, updatedVinculo?.id) ? updatedVinculo : existingVinculo
          ) || currentVinculos;

        handleSuccessUpdateVinculo(updatedVinculos);
      }
    },
    []
  );

  const handleDespesaReceitaAlreadyExists = useCallback(() => false, []);

  const handleValorSolicitadoMoedaOnBlur = useCallback(
    (form, values) => async () => {
      const despesaReceitaId = Number(_.get(values, 'draftFields.current.lancamento.despesaReceita.id'));

      const despesaReceitaResponse = await despesaReceitaAPI.fetchById(despesaReceitaId);

      if (despesaReceitaResponse?.status !== 200) {
        return;
      }

      const foundDespesaReceita = despesaReceitaResponse?.data;

      const moedaId = foundDespesaReceita?.moeda?.id;

      const valorSolicitadoMoeda = normalizeNumeral(_.get(values, 'draftFields.current.lancamento.totalMoeda'));

      if (moedaId && (valorSolicitadoMoeda || valorSolicitadoMoeda === 0)) {
        const taxaConversao = normalizeNumeral(values?.taxaCambio);

        const valorEmReaisPath = 'draftFields.current.lancamento.totalReal';

        if (!taxaConversao) {
          form.change(valorEmReaisPath, '0,00');

          return;
        }

        const valorEmReais = (valorSolicitadoMoeda * taxaConversao).toFixed(2);

        const formattedValorEmReais = unnormalizeNumeral(valorEmReais, formatBrazilianNumericDecimal(2));

        form.change(valorEmReaisPath, formattedValorEmReais);
      }
    },
    [unnormalizeNumeral, formatBrazilianNumericDecimal]
  );

  return (
    <>
      <Grid item xs={12}>
        <QCXFormSubtitle className={classes.subtitle} title={t('com.muralis.qcx.despesaReceitasModeloFaturamento')} />
      </Grid>
      <QCXFinalBondManagerOld
        listProps={despesaReceitaListProps}
        formProps={despesaReceitaFormProps}
        reducerConfig={despesaReceitaReducerConfig}
        handleAdd={handleAddDespesaReceitaVinculo}
        handleUpdate={handleUpdateDespesaReceitaVinculo}
        handleAlreadyExists={handleDespesaReceitaAlreadyExists}
        disableButtons={isPreparingAction || isPerformingAction || !isSubUpdate}
        readOnlyMode={isEdicaoBloqueada}
      >
        <>
          <Grid item xs={12} sm={12} md={6}>
            <QCXSelectDespesaReceitaOperacionalAutoComplete
              id="autocomplete-select-despesa-receita-capa-field"
              key="autocomplete-select-despesa-receita-capa-field"
              name="draftFields.current.lancamento.despesaReceita.id"
              label={t('com.muralis.qcx.despesa.despesaReceita')}
              initialValues={formValues}
              disabled={isEdicaoBloqueada || isPreparingAction || isPerformingAction || !isSubUpdate}
            />
          </Grid>
          <Grid item className={classes.gridFields} xs={12} sm={6} md={3}>
            <QCXFinalAlternativeCurrencyField
              id="currency-valor-solicitado-despesa-receita-capa-field"
              key="currency-valor-solicitado-despesa-receita-capa-field"
              name="draftFields.current.lancamento.totalMoeda"
              label={t('com.muralis.qcx.valorSolicitado')}
              onBlur={handleValorSolicitadoMoedaOnBlur(formState, formValues)}
              validate={maxValue(999999999999)}
              disabled={
                isEdicaoBloqueada ||
                isPreparingAction ||
                isPerformingAction ||
                !isSubUpdate ||
                !formValues?.draftFields?.current?.lancamento?.despesaReceita?.id
              }
              formatOnBlur
            />
          </Grid>
          <Grid item className={classes.gridFields} xs={12} sm={6} md={3}>
            <QCXFinalAlternativeCurrencyField
              id="currency-valor-reais-despesa-receita-capa-field"
              key="currency-valor-reais-despesa-receita-capa-field"
              name="draftFields.current.lancamento.totalReal"
              label={t('com.muralis.qcx.moeda.valorReais')}
              intl={BRAZILIAN}
              validate={maxValue(999999999999)}
              adorned
              disabled
            />
          </Grid>
          <Grid item className={classes.gridFields} xs={12} sm={12} md={6}>
            <QCXFinalSelectTipoModeloAutocomplete
              id="autocomplete-select-tipo-modelo-field"
              key="autocomplete-select-tipo-modelo-field"
              name="draftFields.current.lancamento.despesaReceita.tipo"
              label={t('com.muralis.qcx.conta.contaDebito')}
              initialValues={formValues}
              controlled
              disabled
            />
          </Grid>
        </>
      </QCXFinalBondManagerOld>
    </>
  );
}
