import _ from 'lodash';
import { Grid, makeStyles, MenuItem, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { InfoOutlined as InfoOutlinedIcon, Adjust as AdjustIcon } from '@material-ui/icons';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  faturamentoActions,
  setPagosRecebidos,
  setImpostos,
  setRelatedPagosPeloCliente,
  setRelatedFaturamentoCapaId,
} from '../../features/faturamento/faturamentoSlice';
import { vinculoFollowUpCapaFaturamentoSelectors } from '../../features/vinculo-followup-capa-faturamento/vinculoFollowUpCapaFaturamentoSelectors';
import { vinculoFollowUpCapaFaturamentoActions } from '../../features/vinculo-followup-capa-faturamento/vinculoFollowUpCapaFaturamentoSlice';
import QCXFinalBondManagerOld from '../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManagerOld';
import QCXSelectClienteAutocomplete from '../../shared-components/final-select-cliente-field/QCXSelectClienteAutocomplete';
import QCXInputAdornment from '../../shared-components/input-adornment/QCXInputAdornment';
import QCXSelectFollowUpImportacaoAutocompleteField from '../../shared-components/select-follow-up/QCXSelectFollowUpImportacaoAutocompleteField';
import QCXReadOnlyTextField from '../../shared-components/text-field/QCXReadOnlyTextField';
import QCXMoveFocusInside from '../focus-lock/QCXMoveFocusInside';
import QCXListItemIcon from '../../shared-components/list-item-icon/QCXListItemIcon';
import { faturamentoAPI } from '../../features/faturamento/faturamentoAPI';
import { setErrorFeedback } from '../../features/feedback/feedbackSlice';
import { followUpActions } from '../../features/follow-up/followUpSlice';
import { maxValue } from '../../utils/validators/field/validator';
import { vinculoDespesaReceitaActions } from '../../features/vinculo-despesa-receita/vinculoDespesaReceitaSlice';

const useStyles = makeStyles(() => ({
  gridFields: {
    paddingBottom: '16px',
  },
  subtitle: {
    margin: '20px 0',
  },
}));

export default function QCXCapaFaturamentoForm({
  isEdicaoBloqueada,
  isPreparingAction,
  isPerformingAction,
  initialValues,
  normalize,
  unnormalize,
  ...restProps
}) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const fetchFollowUpsBy = useCallback(async (payload) => {
    const query = payload?.faturamento?.id
      ? [
          {
            name: 'faturamento',
            value: payload?.faturamento?.id,
          },
          {
            name: 'filterImportAndExport',
            value: 1,
          },
        ]
      : [
          {
            name: 'filterImportAndExport',
            value: 1,
          },
        ];

    try {
      const response = await faturamentoAPI.filterFollowUpsBy(
        {
          importador: {
            id: payload?.importador?.id,
          },
        },
        query
      );
      if (response?.status === 200) {
        const { data } = response;
        if (_.isArrayLikeObject(data)) {
          dispatch(
            followUpActions.setList({
              data,
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        setErrorFeedback({
          message: t('com.muralis.qcx.erro.erroFiltroProcessosImportador'),
        })
      );
    }
  }, []);

  const handleSelecionarCapa = useCallback(async (selected, tools) => {
    const {
      formUtils: { form },
      stateUtils,
    } = tools;

    const { id, pagosRecebidos, pagosPeloCliente, impostos } = selected;

    dispatch(setPagosRecebidos(pagosRecebidos));
    dispatch(setRelatedPagosPeloCliente(pagosPeloCliente ?? []));
    dispatch(setImpostos(impostos));
    dispatch(setRelatedFaturamentoCapaId(id));

    dispatch(faturamentoActions.preparingAction());
    form.change('draftFields.current.followUp.numero', selected?.followUp?.numero);
    dispatch(faturamentoActions.changeToSubUpdateMode());

    stateUtils.changeToUpdateMode();

    stateUtils.loading();

    stateUtils.setModel(selected);

    _.debounce(() => {
      form.change('draftFields.current', selected);

      dispatch(faturamentoActions.resetStatus());

      stateUtils.resetStatus();
    }, 500)();
  }, []);

  const followUpCapaReducerConfig = useMemo(
    () => ({
      selectors: {
        selectStatus: vinculoFollowUpCapaFaturamentoSelectors.selectStatus,
        selectMode: vinculoFollowUpCapaFaturamentoSelectors.selectMode,
        selectModel: vinculoFollowUpCapaFaturamentoSelectors.selectModel,
      },
      actions: {
        loading: vinculoFollowUpCapaFaturamentoActions.loading,
        resetStatus: vinculoFollowUpCapaFaturamentoActions.resetStatus,
        changeToUpdateMode: vinculoFollowUpCapaFaturamentoActions.changeToUpdateMode,
        changeToCreateMode: vinculoFollowUpCapaFaturamentoActions.changeToCreateMode,
        resetMode: vinculoFollowUpCapaFaturamentoActions.resetMode,
        setModel: vinculoFollowUpCapaFaturamentoActions.setModel,
        resetModel: vinculoFollowUpCapaFaturamentoActions.resetModel,
      },
    }),
    []
  );

  const followUpCapaGeneralConfig = useMemo(
    () => ({
      feedbackConfig: {
        add: {
          success: {
            message: t('com.muralis.qcx.mensagem.capaItemFaturamentoAdicionadoComSucesso'),
          },
        },
        update: {
          success: {
            message: t('com.muralis.qcx.mensagem.capaItemAtualizadoFaturamentoComSucesso'),
          },
        },
        remove: {
          success: {
            message: t('com.muralis.qcx.mensagem.capaItemRemovidoFaturamentoComSucesso'),
          },
        },
      },
      actionConfig: {
        add: {
          success: {
            disableUpdateList: true,
            disableResetRootValues: true,
          },
        },
        update: {
          success: {
            disableUpdateList: true,
          },
        },
        remove: {
          confirm: {
            title: t('com.muralis.qcx.acoes.confirmarOperacao'),
            message: t('com.muralis.qcx.mensagem.confirmarOperacaoCapaItemFaturamentoRemoverMensagem'),
          },
        },
      },
    }),
    []
  );

  const followUpFormProps = useMemo(
    () => ({
      rootName: 'draftFields.current',
      fields: [
        {
          name: 'followUp.id',
          label: t('com.muralis.qcx.processo'),
        },
      ],
    }),
    []
  );

  const followUpListProps = useMemo(
    () => ({
      name: 'capas',
      columns: [
        {
          field: 'id',
          headerName: t('com.muralis.qcx.processo'),
          headerAlign: 'center',
          align: 'center',
          flex: 160,
          valueGetter: ({ row }) => row?.followUp?.numero || '',
        },
        {
          field: 'importador',
          headerName: t('com.muralis.qcx.importador.label'),
          headerAlign: 'center',
          align: 'center',
          flex: 200,
          valueGetter: ({ row }) => row?.followUp?.importador?.pessoa?.nome || '',
        },
        {
          field: 'servico',
          headerName: t('com.muralis.qcx.servico.labelSingular'),
          headerAlign: 'center',
          align: 'center',
          flex: 180,
          valueGetter: ({ row }) => row?.followUp?.servico?.nome || '-',
        },
        {
          field: 'servico.viaTransporte',
          headerName: t('com.muralis.qcx.transporte.viaTransporte'),
          headerAlign: 'center',
          align: 'center',
          flex: 160,
          valueGetter: ({ row }) => row?.followUp?.servico?.viaTransporte?.description || '-',
        },
      ],
      renderOtherMenuItems: (...args) => {
        const [{ id, ...restRow }, popupState, , tools] = args;

        return (
          <>
            <MenuItem
              key={`btn-selecionar-processo-${id}`}
              name={`btn-selecionar-processo-${id}`}
              onClick={(event) => {
                popupState.close(event);

                handleSelecionarCapa({ id, ...restRow }, tools);
              }}
            >
              <QCXListItemIcon>
                <AdjustIcon fontSize="small" color="primary" />
              </QCXListItemIcon>
              <Typography
                variant="inherit"
                style={{
                  fontSize: 12,
                }}
              >
                {t('com.muralis.qcx.acoes.selecionar').toUpperCase()}
              </Typography>
            </MenuItem>
          </>
        );
      },
    }),
    [handleSelecionarCapa]
  );

  const handleAddCapaFollowUpVinculo = useCallback(
    async (currentCapa, _currentVinculos, handleSuccessAddVinculo, tools) => {
      try {
        dispatch(faturamentoActions.performingAction());

        const {
          formUtils: { form, values },
        } = tools;

        const fetchAndLoadById = async (faturamentoId) => {
          const faturamentoResponse = await faturamentoAPI.fetchById(faturamentoId);

          const isSuccessfulFaturamentoStatusCode = [faturamentoResponse?.status].includes(200);

          if (isSuccessfulFaturamentoStatusCode) {
            const foundFaturamento = faturamentoResponse?.data;
            const unnormalizedFaturamento = unnormalize(foundFaturamento);
            const sortedCapasFaturamentoByItem = _.sortBy(unnormalizedFaturamento?.capas, 'item');

            const updatedFaturamento = {
              ...unnormalizedFaturamento,
              capas: sortedCapasFaturamentoByItem,
            };

            dispatch(faturamentoActions.changeToBackgroundCreateMode());

            dispatch(faturamentoActions.setModel(updatedFaturamento));

            form.restart(updatedFaturamento);

            const savedCurrentCapa = _.last(sortedCapasFaturamentoByItem);
            handleSelecionarCapa(savedCurrentCapa, tools);

            handleSuccessAddVinculo([]);

            const followUpFilterPayload = {
              faturamento: {
                id: foundFaturamento?.id,
              },
              importador: foundFaturamento?.importador,
            };

            await fetchFollowUpsBy(followUpFilterPayload);
          }
        };

        const novaCapa = {
          followUp: currentCapa?.followUp,
        };

        const isPrimeiraCapa = !values?.id;

        if (isPrimeiraCapa) {
          const payload = {
            importador: values?.importador,
            capas: [novaCapa],
          };

          const normalizedData = normalize(payload);

          const faturamentoResponse = await faturamentoAPI.register(normalizedData);

          const isSuccessfulStatusCode = [faturamentoResponse?.status].includes(201);

          if (isSuccessfulStatusCode) {
            const createdFaturamento = faturamentoResponse?.data;

            await fetchAndLoadById(createdFaturamento?.id);
          }
        } else {
          const payload = {
            ...values,
            capas: [...values.capas, novaCapa],
          };

          const normalizedData = normalize(payload);

          const faturamentoResponse = await faturamentoAPI.save(normalizedData);

          const isSuccessfulStatusCode = [faturamentoResponse?.status].includes(200);

          if (isSuccessfulStatusCode) {
            const savedFaturamento = faturamentoResponse?.data;

            dispatch(vinculoDespesaReceitaActions.resetMode());
            dispatch(vinculoDespesaReceitaActions.resetModel());

            await fetchAndLoadById(savedFaturamento?.id);
          }
        }
      } catch (error) {
        throw new Error(error?.response?.data?.message || error?.message);
      } finally {
        dispatch(faturamentoActions.resetStatus());
      }
    },
    []
  );

  const handleUpdateCapaFollowUpVinculo = useCallback(
    async (currentCapa, _currentVinculos, handleSuccessUpdateVinculo, tools) => {
      try {
        dispatch(faturamentoActions.performingAction());

        const {
          formUtils: { form, values },
        } = tools;

        const fetchAndLoadById = async (faturamentoId, currentCapaId) => {
          const faturamentoResponse = await faturamentoAPI.fetchById(faturamentoId);
          const isSuccessfulFaturamentoStatusCode = [faturamentoResponse?.status].includes(200);

          if (isSuccessfulFaturamentoStatusCode) {
            const foundFaturamento = faturamentoResponse?.data;
            const unnormalizedFaturamento = unnormalize(foundFaturamento);

            dispatch(faturamentoActions.changeToBackgroundCreateMode());

            dispatch(faturamentoActions.setModel(unnormalizedFaturamento));

            form.restart(unnormalizedFaturamento);

            const savedCurrentCapa = unnormalizedFaturamento?.capas?.find((capa) => capa?.id === currentCapaId);

            handleSelecionarCapa(savedCurrentCapa, tools);

            handleSuccessUpdateVinculo();

            const followUpFilterPayload = {
              faturamento: {
                id: foundFaturamento?.id,
              },
              importador: foundFaturamento?.importador,
            };

            await fetchFollowUpsBy(followUpFilterPayload);
          }
        };
        const fieldLevelValidations = [
          {
            field: 'moeda.id',
            validators: [],
          },
          {
            field: 'taxaCambio',
            validators: [maxValue(9.99999)],
          },
          {
            field: 'contaBancariaImpostosFederais.id',
            validators: [],
          },
          {
            field: 'contaBancariaImpostosEstaduais.id',
            validators: [],
          },
          {
            field: 'contaBancariaDespesas.id',
            validators: [],
          },
        ];
        const validationError = fieldLevelValidations.find(({ field, validators }) => {
          const error = validators.some((validator) => {
            const value = _.get(currentCapa, field);
            const result = validator.call(this, value);

            return result !== undefined;
          });

          return error;
        });

        if (validationError !== undefined) {
          form.submit();
          return;
        }

        const currentCapaId = currentCapa?.id;

        const updatedCapas = values?.capas?.map((capa) =>
          capa?.id === currentCapaId ? { ...currentCapa, pagosRecebidos: capa.pagosRecebidos } : capa
        );
        const payload = {
          ...values,
          capas: updatedCapas,
        };
        const normalizedData = normalize(payload);

        const faturamentoResponse = await faturamentoAPI.save(normalizedData);

        const isSuccessfulStatusCode = [faturamentoResponse?.status].includes(200);

        if (isSuccessfulStatusCode) {
          const savedFaturamento = faturamentoResponse?.data;

          dispatch(vinculoDespesaReceitaActions.resetMode());
          dispatch(vinculoDespesaReceitaActions.resetModel());

          await fetchAndLoadById(savedFaturamento?.id, currentCapaId);
        }
      } catch (error) {
        throw new Error(error?.response?.data?.message || error?.message);
      } finally {
        dispatch(faturamentoActions.resetStatus());
      }
    },
    [normalize, fetchFollowUpsBy, handleSelecionarCapa]
  );

  const handleRemoveCapaFollowUp = useCallback(
    async ({ target, handleSuccess, tools }) => {
      try {
        dispatch(faturamentoActions.loading());

        const {
          formUtils: { form, values },
        } = tools;

        const fetchAndLoadById = async (faturamentoId) => {
          const faturamentoResponse = await faturamentoAPI.fetchById(faturamentoId);

          const isSuccessfulFaturamentoStatusCode = [faturamentoResponse?.status].includes(200);

          if (isSuccessfulFaturamentoStatusCode) {
            const foundFaturamento = faturamentoResponse?.data;
            const unnormalizedFaturamento = unnormalize(foundFaturamento);

            dispatch(faturamentoActions.setModel(unnormalizedFaturamento));

            form.restart(unnormalizedFaturamento);

            const followUpFilterPayload = {
              faturamento: {
                id: foundFaturamento?.id,
              },
              importador: foundFaturamento?.importador,
            };

            await fetchFollowUpsBy(followUpFilterPayload);
          }
        };

        const updatedCapas = values?.capas?.filter((existingCapa) => target?.id !== existingCapa?.id);

        const payload = {
          ...values,
          capas: updatedCapas,
        };

        const normalizedData = normalize(payload);

        const faturamentoResponse = await faturamentoAPI.save(normalizedData);

        const isSuccessfulStatusCode = [faturamentoResponse?.status].includes(200);

        if (isSuccessfulStatusCode) {
          dispatch(vinculoFollowUpCapaFaturamentoActions.resetMode());

          handleSuccess(target);

          const savedFaturamento = faturamentoResponse?.data;

          dispatch(vinculoDespesaReceitaActions.resetMode());
          dispatch(vinculoDespesaReceitaActions.resetModel());

          await fetchAndLoadById(savedFaturamento?.id);

          dispatch(faturamentoActions.resetStatus());
        }
      } catch (error) {
        throw new Error(error?.response?.data?.message || error?.message);
      } finally {
        dispatch(faturamentoActions.resetStatus());
      }
    },
    [normalize, unnormalize, fetchFollowUpsBy]
  );

  useEffect(() => dispatch(faturamentoActions.resetPagosRecebidos()), []);

  const handleClearCapaFollowUp = useCallback(() => {
    dispatch(faturamentoActions.resetSubMode());
    dispatch(faturamentoActions.resetPagosRecebidos());
    dispatch(faturamentoActions.resetImpostos());
  }, []);

  const handleCapaFollowUpAlreadyExists = useCallback(() => false, []);

  const hasOnlyOneCapaBy = useCallback(
    (values) => _.isArrayLikeObject(values?.capas) && values?.capas?.length === 1,
    []
  );

  return (
    <QCXFinalBondManagerOld
      listProps={followUpListProps}
      formProps={followUpFormProps}
      reducerConfig={followUpCapaReducerConfig}
      generalConfig={followUpCapaGeneralConfig}
      handleAdd={handleAddCapaFollowUpVinculo}
      handleUpdate={handleUpdateCapaFollowUpVinculo}
      handleRemove={handleRemoveCapaFollowUp}
      handleAlreadyExists={handleCapaFollowUpAlreadyExists}
      onClearDecorator={handleClearCapaFollowUp}
      readOnlyMode={isEdicaoBloqueada}
      disableConsult
      disableRemove={hasOnlyOneCapaBy(initialValues)}
    >
      {({ loading, updating }) => (
        <Grid item container xs={8} spacing={2}>
          <Grid item className={classes.gridFields} xs={12} sm={12} md={6} lg={6}>
            <QCXMoveFocusInside>
              <QCXSelectClienteAutocomplete
                id="autocomplete-select-importador-field"
                key="autocomplete-select-importador-field"
                name="importador.id"
                label={t('com.muralis.qcx.cliente.label')}
                initialValues={initialValues}
                disabled={
                  isEdicaoBloqueada ||
                  isPreparingAction ||
                  isPerformingAction ||
                  (!!initialValues?.id && !!initialValues?.importador?.id)
                }
              />
            </QCXMoveFocusInside>
          </Grid>
          <Grid item className={classes.gridFields} xs={12} sm={12} md={6} lg={6}>
            {!updating && (
              <QCXSelectFollowUpImportacaoAutocompleteField
                id="autocomplete-select-follow-up-field"
                key="autocomplete-select-follow-up-field"
                name="draftFields.current.followUp.id"
                label={t('com.muralis.qcx.processo')}
                initialValues={initialValues}
                controlled
                disabled={
                  isEdicaoBloqueada ||
                  isPreparingAction ||
                  isPerformingAction ||
                  loading ||
                  !initialValues?.importador?.id
                }
              />
            )}
            {updating && (
              <QCXReadOnlyTextField
                id="autocomplete-select-follow-up-field"
                key="autocomplete-select-follow-up-field"
                name="draftFields.current.followUp.id"
                label={t('com.muralis.qcx.processo')}
                InputProps={{
                  endAdornment: (
                    <QCXInputAdornment position="end" style={{ paddingRight: '12px' }}>
                      <InfoOutlinedIcon htmlColor="#31278385" fontSize="small" />
                    </QCXInputAdornment>
                  ),
                }}
                defaultValue={initialValues?.draftFields?.current?.followUp?.numero}
                disabled={isPreparingAction || isPerformingAction || loading}
                disableHelperText
                {...restProps}
              />
            )}
          </Grid>
        </Grid>
      )}
    </QCXFinalBondManagerOld>
  );
}
